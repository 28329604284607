import { useCallback, useEffect } from 'react'
import { askForBrowserPermission } from '../utils/UtilFunctions'

type MediaPermission = 'video'

type BrowserPermissionParams = {
  permission: MediaPermission
  onSuccess?: (isGranted: boolean) => void
  onFailure?: (error: unknown) => void
  askOnMount?: boolean
}

/**
 * Requests a browser permission if `askOnMount` is provided the permission is
 * requested as soon as the component mounts.
 */
export const useBrowserPermission = ({
  permission,
  onSuccess,
  onFailure,
  askOnMount,
}: BrowserPermissionParams) => {
  const askForPermission = useCallback(askForBrowserPermission, [])

  const handleDeniedPermission = useCallback(
    (error: unknown) => {
      onFailure?.(error)
    },
    [onFailure]
  )

  const handleGrantedPermission = useCallback(
    (isGranted: boolean) => {
      onSuccess?.(isGranted)
    },
    [onSuccess]
  )

  useEffect(() => {
    if (askOnMount) {
      askForPermission(
        permission,
        {
          onSuccess: handleGrantedPermission,
          onFailure: handleDeniedPermission,
        },
        {}
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission, askOnMount])

  return {
    askForPermission,
  }
}
