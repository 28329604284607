import { useTranslate } from '../../hooks/useTranslate'
import { CONSTANTS } from '../../constants/ConstantValues'
import style from '../../scss/pages/ReferralDetails.module.scss'
import TextInput from '../../components/inputs/TextInput'
import { copyToClipboard } from '../../utils/UtilFunctions'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { ASSET } from '../../constants/Assets'
import SocialShare from '../../components/media-and-icons/SocialShare'
import { debounce } from '../../utils/TSUtilFunctions'
import { tontineWebsiteOrigin } from 'MyTontineConfig'

type ReferralLinkViewProps = {
  editingLimitReached: boolean
  openEditReferralCodeModal?: (value: boolean) => void
  referralLink: string
  className?: string
  label: string
  hideDescription?: boolean
  socialMediaIcons?: 'row'
}

/**
 * Debounced copy to clipboard in order not to spam toast messages
 */
const debouncedCopy = debounce(copyToClipboard, CONSTANTS.SEARCH_DEBOUNCE_MS)

/**
 * Displays user's referral link and opens a referral link edit modal
 */
const ReferralLinkView = ({
  editingLimitReached,
  openEditReferralCodeModal,
  referralLink,
  label,
  className,
  hideDescription,
}: ReferralLinkViewProps) => {
  const t = useTranslate()

  /**
   * Makes the edited referral code not clickable if the edit limit
   * has been reached
   */
  const editReferralCode = () => {
    if (editingLimitReached) {
      debouncedCopy(
        `${tontineWebsiteOrigin}${referralLink}`,
        t('REFERRAL_LINK_TO_CLIPBOARD')
      )
    } else {
      openEditReferralCodeModal?.(true)
    }
  }

  return (
    <section
      className={`${className ?? ''} ${style[`referral__link-view-container`]}`}
    >
      <section className={style[`referral__link-container`]}>
        <div
          className={
            style[`referral__link-center${editingLimitReached ? '--copy' : ''}`]
          }
        >
          <TextInput
            label={label}
            value={`${tontineWebsiteOrigin}${referralLink}`}
            suffixIcon={
              editingLimitReached
                ? ASSET.iconaccountcopy
                : ASSET.icononboardinarrowforward
            }
            onClick={editReferralCode}
            readOnly
            className={style[`referral__link-view`]}
            dataTestID={UI_TEST_ID.editRefLinkButton}
          />
        </div>
        {!hideDescription && (
          <p className={style['referral__link-view-desc']}>
            {t('INVITE_FRIENDS.UNIQUE_LINK_DESC')}
          </p>
        )}
        <br />
        <SocialShare
          size={40}
          urlToShare={`${tontineWebsiteOrigin}${referralLink}`}
          postTitle={t('SHARE_LINK_TITLE')}
          postContent={t('SHARE_LINK_CONTENTS')}
          facebookHashtag={t('FACEBOOK_HASHTAG')}
          hashTags={[...t('TWITTER_HASHTAGS').split(',')]}
          twitterAccountsToFollow={[
            ...t('TWITTER_ACCOUNTS_TO_FOLLOW').split(','),
          ]}
          roundIcons
        />
      </section>
    </section>
  )
}

export default ReferralLinkView
