import { useEffect, useState } from 'react'
import { useBrowserPermission } from '../../hooks/useBrowserPermission'
import { checkForBrowserPermissionStatus } from '../../utils/TSUtilFunctions'
import ConfirmationModal from './ConfirmationModal'

type PermissionModalProps = {
  title: string
  content: string
  icon: string
  onPermissionGranted: () => void
  onPermissionDenied: (error: unknown) => void
  permission: 'video'
  askOnMount: boolean
}

/**
 * Handles permission request and displays an explainer modal to what browser
 * permission he user needs to give access to
 */
const PermissionModal = ({
  title,
  content,
  icon,
  onPermissionGranted,
  onPermissionDenied,
  permission,
  askOnMount,
}: PermissionModalProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    checkForBrowserPermissionStatus('camera')
      .then((browserPermission) => {
        if (browserPermission.state === 'prompt') {
          setModalIsOpen(true)
        }
      })
      .catch((error) => {
        // TODO: Can't handle this, needs to be additional modal
        // To tell the user how to unblock their browser permission
        // https://3.basecamp.com/5235135/buckets/25328289/chats/4433098432@7115099427
        console.error(error)
      })
  }, [])

  useBrowserPermission({
    permission: permission,
    askOnMount: askOnMount,
    onSuccess: () => {
      setModalIsOpen(false)
      onPermissionGranted()
    },
    onFailure: (error) => {
      setModalIsOpen(false)
      onPermissionDenied(error)
    },
  })

  return (
    <div>
      <ConfirmationModal
        icon={icon}
        title={title}
        content={content}
        isOpen={modalIsOpen}
      />
    </div>
  )
}

export default PermissionModal
