import { useAccountService } from '../../state-management/authentication/useAccountService'
import MobileBarItems from './MobileBarItems'
import style from '../../scss/layout/MobileNav.module.scss'

/**
 * When the app is on a phone, a mobile navigation bar will be
 * displayed at the bottom. Also when the phone's native keyboard is on screen
 * this bar gets removed and appears back when the keyboard is closed to avoid
 * obstructing the screen
 */
const MobileAppBar = ({ completedKyc }: { completedKyc: boolean }) => {
  const { isAuthenticated } = useAccountService()

  return (
    <div className={style.mobileNav}>
      <MobileBarItems
        completedKyc={completedKyc}
        isAuthenticated={isAuthenticated}
        className={style[`mobileNav__item-wrapper`]}
      />
    </div>
  )
}

export default MobileAppBar
