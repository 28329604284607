import {
  dayNumberToString,
  daysInMonth,
  generateUniqueId,
} from '../../../../utils/UtilFunctions'
import { disabledPickerValues } from '../../../../utils/TSUtilFunctions'
import PropTypes from 'prop-types'
import style from '../../../../scss/components/DateInput.module.scss'

/**
 * @param {object} date Object containing a weekday number and month number
 * @param {function():void} setDay Sets the day for the parent component
 * @param {number} pickerDaysGrid Number of how many values will the day picker
 * show, default is 42
 * @param {function():void} nextMonth Switches to the next month when a day from
 * the next month is clicked
 * @param {function():void} previousMonth Switches to the previous month when a
 * day from the previous month is clicked
 *
 * @description Renders the week days in string format and days of the month
 * depending which month it is, leap years are handled by `daysInMonth`
 * function.
 */
const Days = ({
  date,
  setDay,
  pickerDaysGrid = 42,
  nextMonth,
  previousMonth,
  disabledFrom,
  disabledTo,
}) => {
  const firstDayOfTheMonth = new Date(date?.year, date?.month - 1, 1).getDay()
  const currentMonthDays = daysInMonth(date?.year, date?.month)
  const previousMonthDays = daysInMonth(date?.year, date?.month - 1)

  const renderDaysOfSelectedMonth = (_, day) => {
    if (day !== 0) {
      return (
        <div
          key={generateUniqueId()}
          onClick={() => setDay(day)}
          className={`${
            style[`date-picker${date?.day === day ? '--selected' : ''}`]
          }
          ${style[`date-picker__day-elements`]} 
          ${
            style[
              `${disabledPickerValues({
                value: day,
                disabledFrom,
                disabledTo,
              })}`
            ]
          }  
          `}
        >
          {day}
        </div>
      )
    }
  }

  /**
   * Renders weekdays from Sunday to Saturday in string format, if localization
   * options are passed the days will be localized
   */
  const renderWeekDays = () => (
    <div className={style[`date-picker__day`]}>
      {dayNumberToString().map((day) => (
        <div key={generateUniqueId()} className={style[`date-picker__weekday`]}>
          {day}
        </div>
      ))}
    </div>
  )

  /**
   * Fills in the last days from the previous month, if the current month's
   * first day falls anything else than Monday
   */
  const renderPreviousMonthDates = () =>
    Array.from({
      //Starts adding previous month's days from the current's month 1st day
      length: firstDayOfTheMonth,
    })
      .map((_, index) => (
        <div
          onClick={() => {
            previousMonth()
            setDay(previousMonthDays - index)
          }}
          key={generateUniqueId()}
          className={`${style[`date-picker__previous-month-days`]} ${style[`date-picker__day-elements`]}`}
        >
          {previousMonthDays - index}
        </div>
      ))
      //Starts the count from previous month's last day
      .reverse()

  /**
   * Renders the next month's days starting from next month's first day
   */
  const renderNextMonthDates = () =>
    Array.from({
      length: pickerDaysGrid - currentMonthDays - firstDayOfTheMonth,
    }).map((_, index) => {
      return (
        <div
          key={generateUniqueId()}
          onClick={() => {
            nextMonth()
            setDay(index + 1)
          }}
          className={`${style[`date-picker__next-month-days`]} 
          ${style[`date-picker__day-elements`]}
          ${
            style[
              `${disabledPickerValues({
                value: index + 1,
                disabledFrom:
                  disabledFrom || disabledTo ? index + 1 : undefined,
                disabledTo,
              })}`
            ]
          }   
          `}
        >
          {index + 1}
        </div>
      )
    })

  return (
    <>
      {renderWeekDays()}
      <div className={style[`date-picker__days`]}>
        {renderPreviousMonthDates()}
        {Array.from({ length: currentMonthDays + 1 }).map(
          renderDaysOfSelectedMonth
        )}
        {renderNextMonthDates()}
      </div>
    </>
  )
}

Days.propTypes = {
  date: PropTypes.object.isRequired,
  setDay: PropTypes.func.isRequired,
  pickerDaysGrid: PropTypes.number,
  nextMonth: PropTypes.func.isRequired,
  previousMonth: PropTypes.func.isRequired,
  disabledFrom: PropTypes.number,
  disabledTo: PropTypes.number,
}
export default Days
