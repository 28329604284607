import Divider from '../data-display/Divider'
import style from '../../scss/components/DividerProgress.module.scss'

type DividerProgressProps = {
  steps: number
  className?: string
  activeForm: number
}

/**
 *  Renders row of dividers to serve as a progress bar
 */
const DividerProgress = ({
  steps = 1,
  className,
  activeForm,
}: DividerProgressProps) => {
  return (
    <article className={`${style[`divider-progress`]} ${className ?? ''}`}>
      {Array.from({ length: steps })?.map((_, index) => (
        <Divider key={`${index}divider-progress`} active={index < activeForm} />
      ))}
    </article>
  )
}

export default DividerProgress
