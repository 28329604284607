import axios from 'axios'
import { TrackRequest } from './Analytics.types'
import { API } from '../api/API'
import { debounce, detectDeviceType, throttle } from '../utils/TSUtilFunctions'
import { isLite } from '../config/lite'
import { writeToConsoleAndIssueAlert } from '../state-management/StateUtils'
import { CONSTANTS } from '../constants/ConstantValues'
import { axiosConfig } from '../api/RequestConfig'
import { analyticsTrack } from 'MyTontineConfig'

// 2 minutes might be too fast, what if there is an error
// that affects a lot of users
const issueAlert = debounce(writeToConsoleAndIssueAlert, 120_000)

const _track = async ({ event, properties }: TrackRequest) => {
  try {
    if (!event) {
      throw new TypeError(`Required event argument not provided`)
    }

    if (!properties) {
      throw new TypeError(`Required property: >>properties<< not provided`)
    }

    if (!properties.object_id) {
      throw new TypeError(
        `Required >>object_id<< argument not provided, every properties object must contain >>object_id<<`
      )
    }

    //TODO: Only LITE token is passed in for now
    const persistedAuthToken = localStorage?.getItem(
      CONSTANTS.LITE_TOKEN
    ) as string

    await axios.post(
      API.track,
      {
        event,
        properties: {
          // If lite version this needs to be website source
          // reason being that MyTontine lite is just a section of the
          // website and source is the only thing that will link the data with
          // website events
          source: isLite ? 'website' : 'webapp',
          device: detectDeviceType(),
          ...properties,
        },
      },
      axiosConfig({ authToken: persistedAuthToken })
    )
  } catch (error) {
    // Tracking errors can happen, in order not to spam alerts
    // calls need to be throttled
    issueAlert({
      error,
      skipRethrow: true,
    })
  }
}

/**
 * Sends an API request to track user actions. The function is throttled to
 * 200ms by default.
 *
 * If no arguments are provided the function will not track anything.
 */
const track = throttle((trackProperties: TrackRequest) => {
  // We are only gonna track LITE for now, so we don't
  // spam data to the backend
  if (analyticsTrack && isLite) {
    // If no properties provided do not track
    if (!trackProperties) {
      return undefined
    }

    void _track({
      event: trackProperties.event,
      properties: trackProperties.properties,
    })
  }
}, 200)

export { track }
