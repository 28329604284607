import PropTypes from 'prop-types'

/**
 * Renders an array of items in an unordered list
 */
const ListItems = ({ arrayOfItems }: { arrayOfItems: Array<string> }) => {
  return (
    <ul>
      {arrayOfItems?.map((item, index) => (
        <li
          key={`${index}${item}`}
          style={{
            fontWeight: 'bold',
          }}
        >
          - {item}
        </li>
      ))}
    </ul>
  )
}

ListItems.propTypes = {
  arrayOfItems: PropTypes.array,
}

export default ListItems
