import { CONSTANTS } from '../../constants/ConstantValues'
import { useDebouncedValidation } from '../../hooks/useDebouncedValidation'
import { SelectValueProps } from '../../types/CommonInterfaces.interfaces'
import InputLabel from '../inputs/InputLabel'
import TextError from '../typography/TextError'
import Button from './Button'
import { useId } from 'react'
import style from '../../scss/components/SelectValue.module.scss'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/**
 * Takes in an array of options to select and renders all the options as buttons.
 * The icon and label props follow the order of `optionsToSelect`
 */
const SelectValue = <T extends string>({
  value,
  setValue,
  buttonLabels,
  activeButtonIcons,
  buttonIcons,
  label,
  className,
  optionsToSelect,
  tooltipText,
  labelInfoIcon,
  errorMessage,
  validatorFunction,
  debounceTime = CONSTANTS.DEBOUNCE_TIME,
  optional,
  trackActivities,
}: SelectValueProps<T>) => {
  const id = useId()
  const validateDebounced = useDebouncedValidation(
    debounceTime,
    validatorFunction
  )

  const _setValue = (option: T) => {
    // Prevents the user for spamming the same value
    // Because in some instances it can be harmful for example in the
    // DynamicForm.tsx where one field depends on another
    if (value !== option) {
      setValue(option)

      //Validate option
      validateDebounced && validateDebounced(option)
    }
  }

  return (
    <article
      className={`${style.selectValue}  ${
        label || errorMessage ? '' : style[`selectValue--without-label`]
      }`}
    >
      {label && (
        <InputLabel
          label={label}
          infoIcon={labelInfoIcon}
          tooltipText={tooltipText}
          //if field is required and if it has more than 1 option we should render
          renderAsterisk={!optional && optionsToSelect?.length > 1}
        />
      )}

      <div className={`${style['selectValue__container']} ${className}`}>
        {optionsToSelect.map((option: T, optionIndex: number) => {
          return (
            <Button
              key={`${optionIndex}${id}`}
              onClick={value !== option ? () => _setValue(option) : undefined}
              variant={value === option ? 'blue' : 'select-value'}
              icon={
                value === option
                  ? activeButtonIcons?.[optionIndex]
                  : buttonIcons?.[optionIndex]
              }
              dataTestID={`${UI_TEST_ID.selectValueBtn}${option}`}
              trackActivity={
                // tracks only if the value has been changed
                trackActivities &&
                trackActivities?.length > 0 &&
                value !== option
                  ? {
                      ...trackActivities?.[optionIndex],
                      value: option,
                    }
                  : undefined
              }
            >
              {buttonLabels?.[optionIndex]}
            </Button>
          )
        })}
        {optionsToSelect?.length === 1 && (
          //Makes sure if we don't render the 2nd button the first button does
          //not stretch full flex
          <Button
            onClick={undefined}
            className={`${style['selectValue__button--hidden']}`}
          >
            0
          </Button>
        )}
      </div>
      {errorMessage?.message && <TextError validationObject={errorMessage} />}
    </article>
  )
}

export default SelectValue
