import { ReactNode } from 'react'
import { AccountServiceContext } from './AccountServiceContext'

/**
 * Prop types for the `AccountServiceProvider` component
 */
type AccountServiceProviderProps = {
  children: ReactNode
}

/**
 *
 */
const AccountServiceProvider = ({ children }: AccountServiceProviderProps) => {
  return (
    <AccountServiceContext.Provider>{children}</AccountServiceContext.Provider>
  )
}

export { AccountServiceProvider }
