import { useState, ReactNode } from 'react'
import style from '../../scss/components/Tooltip.module.scss'

interface TooltipProps {
  children: ReactNode
  content?: string
  direction?: 'top' | 'right' | 'bottom' | 'left'
  className?: string
}

/**
 * Tooltip wrapper that shows text when the user hover over the
 * wrapped component
 */
const Tooltip = ({ children, content, direction, className }: TooltipProps) => {
  const [active, setActive] = useState(false)

  const showTip = () => {
    setActive(true)
  }

  const hideTip = () => setActive(false)

  return (
    <div
      className={`${style.tooltip} ${className}`}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className={`${style[`tooltip__box`]} ${direction ?? style.top}`}>
          {content}
        </div>
      )}
    </div>
  )
}

export default Tooltip
