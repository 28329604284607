import CurrencyStat from './CurrencyStat'
import PercentTag from './PercentTag'
import style from '../../scss/components/IncomeStats.module.scss'
import { IncomeStatsProps } from './DataDisplay.types'

/**
 * Renders total contribution, income percentage, and total income by age 100
 */
const IncomeStats = ({
  contributionAmount,
  contributionLabel,
  incomeAmount,
  incomeLabel,
  incomePercentage,
  currency,
  variant,
  isLoading,
}: IncomeStatsProps) => {
  return (
    <main className={style['income-stats']}>
      <section
        className={
          style[`income-stats__container${variant ? `--${variant}` : ''}`]
        }
      >
        <CurrencyStat
          amount={contributionAmount}
          currency={currency}
          label={contributionLabel}
          disableAnimation
          isLoading={isLoading}
        />
        <PercentTag
          percentage={incomePercentage}
          isLoading={isLoading}
          prefix="+"
          dividerTop
        />
        <CurrencyStat
          amount={incomeAmount}
          currency={currency}
          label={incomeLabel}
          isLoading={isLoading}
        />
      </section>
    </main>
  )
}

export default IncomeStats
