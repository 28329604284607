import { SUPPORTED_BROWSERS_LINKS } from '../../constants/ConstantValues'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/components/BrowsersIcons.module.scss'
import { NavLink } from 'react-router-dom'
import Icon from '../media-and-icons/Icon'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

/**
 * Renders a list of supported browsers with their icons and names.
 */
const BrowsersIcons = () => {
  const { isMobileOrTablet } = useDeviceScreen()

  const browsers = [
    {
      name: 'Chrome',
      icon: ASSET.logoGoogleChrome,
      href: SUPPORTED_BROWSERS_LINKS.GOOGLE_CHROME,
    },
    {
      name: 'Firefox',
      icon: ASSET.logoMozillaFirefox,
      href: SUPPORTED_BROWSERS_LINKS.MOZILLA_FIREFOX,
    },
    {
      name: 'Safari',
      icon: ASSET.logoSafari,
      href: SUPPORTED_BROWSERS_LINKS.SAFARI,
    },
  ]

  // This filters out Mozilla Firefox for mobile devices since FaceTec does not support it
  const filteredBrowsers = isMobileOrTablet
    ? browsers.filter((browser) => browser.name !== 'Firefox')
    : browsers

  return (
    <div className={style['browsers-icons__all-browsers']}>
      {filteredBrowsers.map((browser, index) => (
        <figure key={index}>
          <NavLink to={browser.href}>
            <Icon
              fileName={browser.icon}
              className={style['browsers-icons__individual-browser']}
            />
          </NavLink>
          <figcaption className={style['browsers-icons__caption']}>
            {browser.name}
          </figcaption>
        </figure>
      ))}
    </div>
  )
}

export default BrowsersIcons
