import { ChangeEvent } from 'react'
import { CONSTANTS } from '../../constants/ConstantValues'
import style from '../../scss/components/Checkbox.module.scss'
import { TrackActivity } from '../Common.types'
import { track } from '../../analytics/Analytics'
import { ButtonEvent, CheckboxEvent } from '../../analytics/EventData'

type CheckboxProps = {
  checked?: boolean
  onChange: (checked: boolean) => void
  label?: string
  className?: string
  onClickLabel?: () => void
  labelClassName?: string
  optional?: boolean
  dataTestIDLabel?: string
  trackActivity?: TrackActivity
}

/**
 * Checkbox component with a label element on the right side
 */
const Checkbox = ({
  checked = false,
  onChange,
  label,
  className,
  onClickLabel,
  labelClassName,
  optional,
  dataTestIDLabel,
  trackActivity,
}: CheckboxProps) => {
  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)

    void track({
      event: CheckboxEvent.toggled,
      properties: {
        object_id: trackActivity?.trackId,
        object_value: event.target.checked,
      },
    })
  }

  const _onClickLabel = () => {
    onClickLabel?.()

    void track({
      event: ButtonEvent.clicked,
      properties: {
        object_id: trackActivity?.trackId,
        label,
      },
    })
  }

  return (
    <div className={`${style.checkbox} ${className ?? ''}`}>
      <input
        // checked || false allows for a third null state without react throwing
        //and going crazy with the warning: going from uncontrolled to controlled
        //component
        checked={checked || false}
        className={style['checkbox__body']}
        onChange={_onChange}
        type="checkbox"
      />
      <div
        className={`${labelClassName} ${style['checkbox__label']}`}
        onClick={onClickLabel ? _onClickLabel : undefined}
        data-testid={dataTestIDLabel}
      >
        {`${optional ? label : `${label}${CONSTANTS.REQUIRED_FIELD}`}`}
      </div>
    </div>
  )
}

export default Checkbox
