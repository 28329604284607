const Color = {
  blue: '#2975bf',
  gradientBlue: 'radial-gradient(50% 50% at 50% 50%, $blue 0%, #065db3 100%)',
  darkGrey: '#383838',
  grey: '#bdc2c9',
  lightGrey: '#f3f3f3',
  green: '#39bd82',
  orangeYellow: '#f7931e',
  pink: '#f869bf',
  red: 'red',
  white: 'white',
  yellow: '#ffca42',
  lightDark: '#4d5155',
  lightBlue: '#7ebaf7',
  boringGray: '#f869bf',
}

const getFaceTecStyle = (FaceTecSDK) => {
  const style = new FaceTecSDK.FaceTecCustomization()

  const font = 'Titillium Web, sans-serif'

  //Overlay customization
  style.overlayCustomization.backgroundColor = 'white'
  style.overlayCustomization.showBrandingImage = false

  // Frame Customization
  style.frameCustomization.backgroundColor = 'white'
  style.frameCustomization.borderWidth = '1px'
  style.frameCustomization.borderCornerRadius = '5px'
  style.frameCustomization.shadow = 'none'

  //First screen loading animation
  style.initialLoadingAnimationCustomization.animationRelativeScale = 1.0
  style.initialLoadingAnimationCustomization.backgroundColor = Color.blue
  style.initialLoadingAnimationCustomization.foregroundColor = Color.lightBlue
  style.initialLoadingAnimationCustomization.messageTextColor = Color.lightDark
  style.initialLoadingAnimationCustomization.messageFont = font

  //Oval customization
  style.ovalCustomization.strokeColor = Color.blue
  style.ovalCustomization.strokeWidth = '1px'
  style.ovalCustomization.progressColor1 = Color.lightBlue
  style.ovalCustomization.progressColor2 = Color.blue

  //Guidance customization
  style.guidanceCustomization.backgroundColors = 'white'
  style.guidanceCustomization.foregroundColor = Color.blue
  style.guidanceCustomization.headerFont = font
  style.guidanceCustomization.subtextFont = font
  style.guidanceCustomization.buttonFont = font
  style.guidanceCustomization.buttonTextNormalColor = 'white'
  style.guidanceCustomization.buttonBackgroundNormalColor = Color.blue
  style.guidanceCustomization.buttonTextHighlightColor = 'white'
  //style.guidanceCustomization.buttonBackgroundHighlightColor = 'rgb(86, 86, 86)'
  style.guidanceCustomization.buttonTextDisabledColor = 'white'
  style.guidanceCustomization.buttonBackgroundDisabledColor =
    'rgb(173, 173, 173)'
  style.guidanceCustomization.buttonBorderColor = 'white'
  style.guidanceCustomization.buttonBorderWidth = '0px'
  style.guidanceCustomization.buttonCornerRadius = '5px'
  style.guidanceCustomization.readyScreenOvalFillColor = 'transparent'
  style.guidanceCustomization.readyScreenTextBackgroundColor = Color.blue
  style.guidanceCustomization.readyScreenTextBackgroundCornerRadius = '5px'
  style.guidanceCustomization.retryScreenImageBorderColor = Color.blue
  style.guidanceCustomization.retryScreenImageBorderWidth = '2px'
  style.guidanceCustomization.retryScreenImageCornerRadius = '10px'
  style.guidanceCustomization.retryScreenOvalStrokeColor = Color.blue
  //   style.guidanceCustomization.retryScreenSlideshowImages =
  //     retryScreenSlideshowImages
  style.guidanceCustomization.retryScreenSlideshowInterval = '2000ms'
  style.guidanceCustomization.enableRetryScreenSlideshowShuffle = true
  //   style.guidanceCustomization.cameraPermissionsScreenImage =
  //     themeResourceDirectory + 'pseudo-fullscreen/camera_shutter_black.png'

  // Feedback Customization
  style.feedbackCustomization.backgroundColor = 'white'
  style.feedbackCustomization.textColor = Color.blue
  style.feedbackCustomization.textFont = font
  style.feedbackCustomization.cornerRadius = '3px'
  style.feedbackCustomization.shadow = `1px 1px 1px 1px ${Color.blue}`

  // Cancel Button Customization
  style.cancelButtonCustomization.customImage =
    '../../../../images/my-tt_icons-close-window.svg'

  style.cancelButtonCustomization.location =
    FaceTecSDK.FaceTecCancelButtonLocation.TopRight

  //style.cancelButtonCustomization.setCustomLocation(20, 20, 20, 20)
  style.guidanceCustomization.readyScreenHeaderFont = font
  style.guidanceCustomization.readyScreenHeaderTextColor = Color.blue
  // Ready Screen Subtext
  style.guidanceCustomization.readyScreenSubtextFont = font
  style.guidanceCustomization.readyScreenSubtextTextColor = Color.lightDark
  // Retry Screen Header
  style.guidanceCustomization.retryScreenHeaderFont = font
  style.guidanceCustomization.retryScreenHeaderTextColor = Color.lightDark
  // Retry Screen Subtext
  style.guidanceCustomization.retryScreenSubtextFont = font
  style.guidanceCustomization.retryScreenSubtextTextColor = Color.lightDark

  //Results screen

  style.resultScreenCustomization.foregroundColor = Color.lightDark
  style.resultScreenCustomization.messageFont = font
  style.resultScreenCustomization.resultAnimationBackgroundColor =
    Color.gradientBlue
  style.resultScreenCustomization.resultAnimationForegroundColor = 'white'
  style.resultScreenCustomization.uploadProgressFillColor = Color.blue

  //Security
  style.securityWatermarkCustomization.setSecurityWatermarkImage('')

  return style
}

export { getFaceTecStyle }
