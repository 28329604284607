import { useState, useEffect } from 'react'
import { OptionValue } from '../components/inputs/dropdown/Dropdown.types'
import { CONSTANTS } from '../constants/ConstantValues'

/**
 * Searches an array of objects with the provided `searchBy` array of object keys
 */
export const useSearch = ({
  options,
  searchBy,
}: {
  options: Array<object>
  searchBy: Array<string>
}) => {
  const [foundOptions, setFoundOptions] = useState(options)
  const [searchQuery, setSearchQuery] = useState('')
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)

  // Debounce function
  const debounce = (func: () => void, delay: number) => {
    if (timerId) clearTimeout(timerId)
    setTimerId(setTimeout(func, delay))
  }

  useEffect(() => {
    // 500ms debounce
    debounce(() => {
      const filteredOptions = searchForOption({
        options,
        searchQuery,
        searchBy,
      })

      setFoundOptions(filteredOptions)
    }, CONSTANTS.SEARCH_DEBOUNCE_MS)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  return {
    foundOptions,
    searchQuery,
    setSearchQuery,
  }
}

/**
 * Filters passed in options based on the passed in search query and search by keys
 */
const searchForOption = ({
  options,
  searchQuery,
  searchBy,
}: {
  options: Array<object>
  searchQuery: string
  searchBy?: Array<string>
}) => {
  if (searchBy && searchBy?.length > 0) {
    return options.filter((option) => {
      const isMatch = searchBy.some((key) => {
        const value = (option as OptionValue)[key]?.toLowerCase()
        return value?.indexOf(searchQuery?.toLowerCase()) > -1
      })

      return isMatch
    })
  }

  return options
}
