import PropTypes from 'prop-types'
import Icon from '../media-and-icons/Icon'
import style from '../../scss/components/InputLabel.module.scss'
import TontineTooltip from '../overlay/Tooltip'
import { CONSTANTS } from '../../constants/ConstantValues'
import { ASSET } from '../../constants/Assets'

type InputLabelProps = {
  label?: string
  className?: string
  infoIcon?: boolean
  tooltipText?: string
  renderAsterisk?: boolean
}

/**
 *  Renders a text label used for input fields. If `infoIcon` prop is passed in
 *  an info icon will be rendered next to the label
 */
const InputLabel = ({
  label,
  className = '',
  infoIcon,
  tooltipText,
  renderAsterisk,
}: InputLabelProps) => {
  if (infoIcon) {
    return (
      <div className={style.inputLabel}>
        <label
          className={`${style['inputLabel__input-label']} ${className}`}
        >{`${label}${renderAsterisk ? CONSTANTS.REQUIRED_FIELD : ''}`}</label>

        <TontineTooltip content={tooltipText}>
          <Icon
            fileName={ASSET.infoIconSmall}
            className={style[`inputLabel__icon`]}
          />
        </TontineTooltip>
      </div>
    )
  }

  return (
    <label
      className={`${style['inputLabel__input-label']} ${className}`}
    >{`${label}${renderAsterisk ? CONSTANTS.REQUIRED_FIELD : ''}`}</label>
  )
}

InputLabel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  infoIcon: PropTypes.bool,
}

export default InputLabel
