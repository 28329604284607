import PropTypes from 'prop-types'

import LottieAnimation from '../../components/feedback/LottieAnimation'
import { ANIMATION } from '../../constants/Animations'
import { CONSTANTS } from '../../constants/ConstantValues'
import { useTranslate } from '../../hooks/useTranslate'
import ContributionCards from '../../components/cards/ContributionCards'
import NoTransactions from '../../components/feedback/NoTransactions'
import PayoutCards from '../../components/cards/PayoutCards'
import style from '../../scss/components/ContributionHistory.module.scss'

/**
 * @param {boolean} loading Controls if an animation should be displayed
 * @param {array} transactions Array of transactions
 * @param {boolean} unfilteredTransactions To render a message if filter options
 * has not returned any filtered data
 * @param {array} formattedTransactions Passed in from the parent component
 * @param {boolean} payoutHistory Displays payout history content
 * @description Renders transactions if there are transactions, if no
 * transactions are found in a specific date range filter then a
 * `<NoTransactions />` component is rendered.
 */
const UserTransactions = ({
  loading,
  transactions,
  unfilteredTransactions,
  formattedTransactions,
  payoutHistory,
}) => {
  const t = useTranslate()

  /**
   * @description Renders end of history text at the bottom of the last
   * transaction card
   */
  const renderEndOfHistory = () =>
    transactions.length === CONSTANTS.TRANSACTIONS_INITIAL_COUNT ? (
      <></>
    ) : (
      <p className={style['contributionHistory__end']}>
        {t('END_OF_TRANSACTION_HISTORY')}
      </p>
    )

  const changeContentToPayout = () => {
    if (payoutHistory) {
      return {
        noTransactionsFromFilter: 'PAYOUT_HISTORY.INVALID_RANGE',
        noTransactions: 'BANKING.CONTRIBUTIONS_HISTORY_FILTER_FEEDBACK',
        //button label is an empty string so the CTA to fund your pension is not
        //displayed when the component is in payout history
        noTransactionsButtonLabel: '',
      }
    }
    return {
      noTransactionsFromFilter: 'BANKING.CONTRIBUTIONS_HISTORY_PAGE_CONTENT',
      noTransactions: 'BANKING.CONTRIBUTIONS_HISTORY_FILTER_FEEDBACK',
      noTransactionsButtonLabel: 'CONTRIBUTION_HISTORY.TO_FUND_PENSION',
    }
  }

  /**
   * @description Checks if the user has any transactions
   */
  const userHasContributions = () =>
    formattedTransactions && formattedTransactions?.length !== 0

  if (loading) {
    //Animation renders if data is being fetched
    return (
      <LottieAnimation
        autoplay
        loop
        animationName={ANIMATION.loadingLightBlueDots}
      />
    )
  }

  if (!unfilteredTransactions && formattedTransactions?.length > 0) {
    //Payout history renders payout cards, if nothing is passed in contributions
    //are rendered

    return (
      <article className={style[`contributionHistory__transactions`]}>
        <p className={style['contributionHistory__transactions-message']}>
          {t('TRANSACTIONS_TAKE_TIME')}
        </p>
        {payoutHistory ? (
          <PayoutCards payouts={transactions} />
        ) : (
          <ContributionCards contributions={transactions} />
        )}
        {renderEndOfHistory()}
      </article>
    )
  }
  //if user has not made transactions then render a message with button
  else if (!userHasContributions()) {
    return (
      <NoTransactions
        noTransactionsMessage={t(changeContentToPayout().noTransactions)}
        contributionButtonLabel={t(
          changeContentToPayout().noTransactionsButtonLabel
        )}
      />
    )
  }
  //if there are no transactions, render no filter message
  return (
    <NoTransactions
      noTransactionsMessage={t(
        changeContentToPayout().noTransactionsFromFilter
      )}
    />
  )
}

UserTransactions.propTypes = {
  loading: PropTypes.bool,
  transactions: PropTypes.array,
  unfilteredTransactions: PropTypes.bool,
  formattedTransactions: PropTypes.array,
  payoutHistory: PropTypes.bool,
}

export default UserTransactions
