import { useState } from 'react'
import { useTranslate } from '../hooks/useTranslate'
import { useAccountService } from '../state-management/authentication/useAccountService'
import ConfirmationModal from '../components/overlay/ConfirmationModal'
import FaceScan from '../pages/authentication/FaceScan'
import { ACCOUNT, PAYOUT, PERSONAL_DETAILS, PRIVATE } from './Route'
import { UserDetails } from '../state-management/authentication/AuthMachineTypes.type'
import { useCustomNavigation } from '../hooks/useCustomNavigation'
import { ASSET } from '../constants/Assets'

/**
 * Paths that are write protected only if there is certain data from
 * `userDetails`. For path as key returns the `dataKey` needed to check if that
 * data is present in order to write protection to be triggered
 */
const pathsToOverride: { [key: string]: string } = {
  [ACCOUNT.PIN]: 'pin_set',
  [PERSONAL_DETAILS.PHONE_NUMBER]: 'phone_number',
  [PAYOUT.SETUP]: 'payout_account',
}

/**
 * Checks if the path should be write protected if the specified data in
 * `pathsToOverride` is present. Example if `pin_set:false` then the `/pin` path
 * should always be accessible no matter the permissions, otherwise the `/pin`
 * path should only be accessed if `permissions === 'write'`
 */
const shouldOverrideWriteProtection = (
  path?: string,
  user_details?: UserDetails
) => {
  if (user_details && path) {
    const writeProtectedData = pathsToOverride[path]

    return Boolean(
      (user_details as UserDetails & { [key: string]: string })[
        writeProtectedData
      ]
    )
  }

  return false
}

/**
 * Prevents the user from accessing certain routes if they don't have `write` permissions
 */
const PermissionGuard = ({
  requestFaceScan,
  children,
  path,
}: {
  children: React.ReactNode
  path?: string
  requestFaceScan?: boolean
}) => {
  const {
    context: { permissions, user_details },
  } = useAccountService()
  const t = useTranslate()
  const navigate = useCustomNavigation()

  const [faceScan, setFaceScan] = useState(false)
  const [isOpen, setIsOpen] = useState(true)

  if (
    shouldOverrideWriteProtection(path, user_details) &&
    requestFaceScan &&
    permissions === 'read'
  ) {
    return (
      <>
        <ConfirmationModal
          isOpen={isOpen}
          icon={ASSET.yellowShield}
          title={t('FUND_PENSION.WARNING_MODAL_TITLE')}
          content={t('ONBOARDING.MODAL_ERROR_LOGIN_MAGIC_LINK')}
          firstButtonAction={() => {
            setIsOpen(false)
            setFaceScan(true)
          }}
          firstButtonLabel={t('PROMPT_BUTTON.ENROLL_FACE')}
          secondButtonAction={() => {
            setIsOpen(false)
            setFaceScan(false)
            navigate(PRIVATE.ACCOUNT)
          }}
          secondButtonLabel={t('CONFIRMATION_MODAL_BUTTONS.CANCEL')}
        />
        {faceScan && (
          <FaceScan
            asModal
            scanType={user_details?.face_enrolled ? 'auth-scan' : 'match-id'}
            onClickExitScan={() => setFaceScan(false)}
            onSuccessfulScan={() => setFaceScan(false)}
          />
        )}
      </>
    )
  }

  return children
}

export default PermissionGuard
