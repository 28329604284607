import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * @param {object} time - Time object must contain hours, minutes and seconds
 * numbers
 * @param {function():boolean} onCountdownFinished Callback when the countdown
 * has reached 0
 *
 * @description Renders a countdown timer,  with `hours`, `minutes` and
 * `seconds` if provided, if seconds are only provided the timer will start
 * countdown from seconds
 */
const CountDownTimer = ({
  hours = 0,
  minutes = 0,
  seconds = 0,
  text,
  onCountdownFinished,
}) => {
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds])

  //Countdown timer ticks every second
  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) {
      //Checks if there is a callback function to be called when the timer
      //finishes, otherwise this line is skipped to prevent an error from
      onCountdownFinished ? onCountdownFinished(true) : null
      reset()
    } else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59])
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59])
    } else {
      setTime([hrs, mins, secs - 1])
    }
  }

  //Resets the timer to initial values that were provided from props
  const reset = () =>
    setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)])

  //Runs on every render to tick the timer
  useEffect(() => {
    const timerId = setInterval(() => tick(), 1_000)
    return () => clearInterval(timerId)
  })

  const displayMinutesDoubleDigit = () => `0${mins.toString()}:`

  const displaySecondsDoubleDigit = () =>
    secs.toString().length < 2 ? `0${secs.toString()}` : secs.toString()

  return (
    <>
      {text}
      {displayMinutesDoubleDigit()}
      {displaySecondsDoubleDigit()}
    </>
  )
}

CountDownTimer.propTypes = {
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  text: PropTypes.string,
  onCountdownFinished: PropTypes.func,
}

export default CountDownTimer
