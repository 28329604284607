import PropTypes from 'prop-types'
import RangeSlider from './RangeSlider'
import { isLite } from '../../../config/lite'
import { UI_TEST_ID } from '../../../constants/DataTestIDs'

/**
 * @param {number} currentAge Current age, used for disabling the slider if the
 * `currentAge` is equal to the `retirementAge`
 * @param {number} retirementAge Retirement age, used for disabling the slider if the
 * `currentAge` is equal to the `retirementAge`
 * @param {number} monthlyContribution Monthly contribution value
 * @param {function} setMonthlyContribution Lifts the monthly contribution value to the parent component
 * @param {number} oneTimeContribution One time contribution contribution value
 * @param {function} setOneTimeContribution Lifts the monthly contribution value to the parent component
 * @param {function} formatter Formatter function to format the slider value
 * @param {string} prefix Prefix for the slider value
 * @param {string} labelMonthly Label for the monthly contribution slider
 * @param {string} labelOneTime Label for the one time contribution slider
 * @param {string} className Class name for the parent container for the contribution sliders
 *
 * @description Renders an slider input slider for monthly and one time contribution
 */
const ContributionSliders = ({
  monthlyContribution,
  setMonthlyContribution,
  monthlySteps,
  oneTimeSteps,
  oneTimeStepsIfRetired,
  oneTimeContribution,
  setOneTimeContribution,
  formatter,
  prefix,
  labelMonthly,
  labelOneTime,
  className,
  disabledSliderTooltipText,
  isRetired,
  variant,
  monthlyBoxDataTestID,
  oneTimeBoxDataTestID,
  incOneTimeDataTestID,
  incMonthlyDataTestID,
  decOneTimeDataTestID,
  decMonthlyDataTestID,
  trackActivityOneTime,
  trackRangeOneTime,
  enabledStepsMonthly,
}) => {
  return (
    <main className={className}>
      <RangeSlider
        formatter={formatter}
        prefix={prefix}
        onChange={setOneTimeContribution}
        steps={isRetired ? oneTimeStepsIfRetired : oneTimeSteps}
        label={labelOneTime}
        value={oneTimeContribution}
        variant={variant}
        boxValueDataTestID={oneTimeBoxDataTestID}
        incrementButtonDataTestID={incOneTimeDataTestID}
        decrementButtonDataTestID={decOneTimeDataTestID}
        sliderTestID={UI_TEST_ID.oneTimeContributionSlider}
        trackActivity={trackActivityOneTime}
        trackRangeActivity={trackRangeOneTime}
      />

      {!isLite && (
        <RangeSlider
          disabled={isRetired}
          formatter={formatter}
          prefix={prefix}
          onChange={setMonthlyContribution}
          steps={monthlySteps}
          label={labelMonthly}
          value={monthlyContribution}
          disabledSliderTooltipText={disabledSliderTooltipText}
          variant={variant}
          boxValueDataTestID={monthlyBoxDataTestID}
          incrementButtonDataTestID={incMonthlyDataTestID}
          decrementButtonDataTestID={decMonthlyDataTestID}
          sliderTestID={UI_TEST_ID.monthlyContributionSlider}
          enabledSteps={enabledStepsMonthly}
        />
      )}
    </main>
  )
}

ContributionSliders.propTypes = {
  retirementAge: PropTypes.number,
  currentAge: PropTypes.number,
  monthlyContribution: PropTypes.number,
  setMonthlyContribution: PropTypes.func,
  monthlySteps: PropTypes.arrayOf(PropTypes.number),
  monthlyMinIfOnly: PropTypes.number,
  oneTimeContribution: PropTypes.number,
  setOneTimeContribution: PropTypes.func,
  oneTimeSteps: PropTypes.arrayOf(PropTypes.number),
  oneTimeStepsIfRetired: PropTypes.arrayOf(PropTypes.number),
  oneTimeMinIfOnly: PropTypes.number,
  formatter: PropTypes.func,
  prefix: PropTypes.string,
  labelMonthly: PropTypes.string,
  labelOneTime: PropTypes.string,
  className: PropTypes.string,
  disabledSliderTooltipText: PropTypes.string,
  isRetired: PropTypes.bool,
  variant: PropTypes.string,
  monthlyBoxDataTestID: PropTypes.string,
  oneTimeBoxDataTestID: PropTypes.string,
  incOneTimeDataTestID: PropTypes.string,
  incMonthlyDataTestID: PropTypes.string,
  decOneTimeDataTestID: PropTypes.string,
  decMonthlyDataTestID: PropTypes.string,
  trackActivityOneTime: PropTypes.object,
  trackRangeOneTime: PropTypes.object,
  enabledStepsMonthly: PropTypes.array,
}

export { ContributionSliders }
