import { useEffect } from 'react'

/**
 * @param {reference} ref DOM element reference from `useRef` React hooks
 * @param {function():void} clickedOutsideCallback Issued when a click outside
 * the passed in element reference is detected
 *
 * @description Issues a callback when a click is detected that is outside the
 * attached element's reference
 */
const useDetectOutsideClick = (ref, clickedOutsideCallback = undefined) => {
  useEffect(() => {
    /**
     * Event handler when a click outside the passed in element's ref is
     * detected
     */
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event?.target)) {
        clickedOutsideCallback && clickedOutsideCallback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, clickedOutsideCallback])
}

export { useDetectOutsideClick }
