import Icon from '../media-and-icons/Icon'
import style from '../../scss/components/ContributionHistory.module.scss'
import { ASSET } from '../../constants/Assets'

/**
 * @param {string} noTransactionsMessage  Displays text under the contribution button
 *
 * Renders an icon with text notifying the user that they don't
 * have any transactions. If `contributionButtonLabel` is passed in a CTA button
 * render that takes the user to the "Fund your pension" page.
 */
const NoTransactions = ({
  noTransactionsMessage,
}: {
  noTransactionsMessage?: string
}) => {
  return (
    <div className={style['contributionHistory__no-contributions']}>
      <Icon
        fileName={ASSET.iconaccountnocontribution}
        className={style['contributionHistory__image']}
      />

      <div className={style['contributionHistory__message']}>
        {noTransactionsMessage}
      </div>
    </div>
  )
}

export default NoTransactions
