import { CONSTANTS } from '../../constants/ConstantValues'
import style from '../../scss/layout/AccountMenu.module.scss'
import { ACCOUNT_MENU } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import AuthButton from '../buttons/AuthButton'
import MenuCard from '../cards/MenuCard'
import MenuCardItem from '../cards/MenuCardItem'
import MobileAppBar from './MobileAppBar'
import Divider from '../data-display/Divider'
import { hasCompletedL1KYC, kycAlertCount } from '../../utils/UtilFunctions'
import AccountSummary from '../data-display/AccountSummary'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { ASSET } from '../../constants/Assets'

//TODO: Menu cards can be simplified
/**
 * Renders menu specific to the "Account" section of the app
 */
const AccountMenu = ({ className }: { className?: string }) => {
  //Hooks
  const {
    context: { user_details },
  } = useAccountService()
  const t = useTranslate()

  if (user_details) {
    return (
      <main className={className}>
        <AccountSummary pageTitle={t('ROOT_HEADER_ACCOUNT')} />
        <section className={style.accountMenu}>
          <MenuCard title={t('ACCOUNT.MENU_GROUP_TITLE_PROFILE')}>
            <MenuCardItem
              to={ACCOUNT_MENU.PERSONAL_DETAILS}
              mainText={t('PERSONAL_DETAILS.FORM_TITLE')}
              icon={ASSET.iconaccountmenuperonaldetail}
              alertCount={kycAlertCount(user_details)?.personalDetails}
            />
          </MenuCard>

          <MenuCard title={t('ACCOUNT.MENU_FUNDING_AND_PAYOUTS_TITLE')}>
            <MenuCardItem
              to={ACCOUNT_MENU.FUND_PLAN_MENU}
              mainText={t('BANKING.PAGE_TITLE_FUND_YOUR_PLAN')}
              icon={ASSET.iconaccountmenufundyourpenionUS}
              dataTestID={UI_TEST_ID.menuItemFunds}
            />
            <Divider className={style['accountMenu__divider']} />
            <MenuCardItem
              to={ACCOUNT_MENU.PAYOUTS_MENU}
              mainText={t('ACCOUNT.MENU_ITEM_PAYOUT_SETUP')}
              icon={ASSET.iconaccountmenupayout}
              dataTestID={UI_TEST_ID.menuItemIncome}
            />
          </MenuCard>

          <MenuCard title={t('ACCOUNT.MENU_REWARDS_GROUP_TITLE')}>
            <MenuCardItem
              to={ACCOUNT_MENU.REWARDS}
              mainText={t('REWARDS.INVITE_FRIENDS_PAGE_TITLE')}
              icon={ASSET.iconaccountmenuiniteyourfriend}
            />
          </MenuCard>

          <MenuCard title={t('ACCOUNT.MENU_SETTINGS_SECURITY_TITLE')}>
            <MenuCardItem
              to={ACCOUNT_MENU.VIEW_AGREEMENTS}
              mainText={t('ACCOUNT.MENU_ITEM_TERMS_OF_CONDITIONS')}
              icon={ASSET.iconaccountcopy}
            />
            <Divider className={style['accountMenu__divider']} />
            <MenuCardItem
              to={ACCOUNT_MENU.PIN}
              mainText={t('ACCOUNT.MENU_ITEM_CREATE_PIN')}
              icon={ASSET.iconaccountmenupin}
              writeProtected={user_details?.pin_set}
              dataTestID={UI_TEST_ID.menuItemPinChange}
            />
            <Divider className={style[`accountMenu__divider`]} />

            <MenuCardItem
              to={ACCOUNT_MENU.CLOSE_ACCOUNT}
              mainText={t('ACCOUNT.PAGE_TITLE_DELETE_ACCOUNT')}
              icon={ASSET.deleteAccount}
              writeProtected
              dataTestID={UI_TEST_ID.menuItemCloseAccount}
            />
          </MenuCard>

          <AuthButton
            type={CONSTANTS.AUTH_BUTTON_TYPE_LOGOUT}
            className={style['accountMenu__auth-button']}
          />
        </section>

        {window.matchMedia('(max-width: 900px)').matches && (
          // Do not render on desktop at all
          // causes tests to be flaky with double selectors
          <MobileAppBar
            //Displays a red dot as a notification on an menu bar if the user has
            //not completed KYC
            completedKyc={Boolean(hasCompletedL1KYC(user_details))}
          />
        )}
      </main>
    )
  }

  return <></>
}

export default AccountMenu
