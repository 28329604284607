import { useEffect } from 'react'
import { allowedOrigins } from '../constants/TontineOrigins'
import { tontineWebsiteOrigin } from 'MyTontineConfig'

type Event = MessageEvent<{
  source: 'MTL'
  // Only event Id issues from the webapp
  payload: { eventId: 'TERMINATE_REF_SESSION'; analyticsSessionId?: string }
}>

const allowedEventsToListen = ['TERMINATE_REF_SESSION'] as const
const allowedEventSources = ['MTL'] as const

/**
 * Listens for messages from allowed origins and allowed event ids
 */
const useMessageListener = (
  onMessageReceived: ({
    eventId,
    source,
    analyticsSessionId,
  }: {
    eventId: 'TERMINATE_REF_SESSION'
    source: Event['source']
    analyticsSessionId?: string
    origin: string
  }) => void
) => {
  useEffect(() => {
    window.onmessage = (event: Event) => {
      if (allowedOrigins.includes(event?.origin)) {
        if (allowedEventsToListen.includes(event?.data?.payload?.eventId)) {
          onMessageReceived({
            eventId: event?.data?.payload?.eventId,
            source: event?.source,
            origin: event?.origin,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

/**
 * Sends the host parent a message. The origin is already included, no need to
 * specify
 */
const postMessageToParent = ({
  eventId,
  eventData,
}: {
  eventId: 'SUCCESS_VERIFY' | 'REDIRECT'
  // Can be anything
  eventData?: object | string
}) => {
  window?.parent?.postMessage(
    {
      source: allowedEventSources[0],
      payload: { eventId, eventData },
    },
    tontineWebsiteOrigin
  )
}

export { useMessageListener, postMessageToParent }
