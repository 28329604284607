import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/components/Card.module.scss'
import { CardFooterProps } from '../../types/Card.type'
import Button from '../buttons/Button'
import CardAlert from './CardAlert'
import CardArrow from './CardArrow'

/**
 * Renders the footer section for the `Card` component.
 */
const CardFooter = ({
  alertAndArrowPosition,
  alert,
  variant,
  ctaButtonLabel,
  ctaButtonVariant,
  onClick,
  rotateArrow,
  showArrow,
  arrowInvisible,
}: CardFooterProps) => (
  <div
    data-testid={UI_TEST_ID?.cardFooter}
    className={`${style[`card__footer`]} ${style[`card__footer${variant ? `--${variant}` : ''}`]}`}
  >
    {alertAndArrowPosition === 'end' && (
      <>
        <CardAlert alert={alert ?? 0} />
        {(showArrow || arrowInvisible) && (
          <CardArrow
            rotateArrow={rotateArrow}
            variant={variant}
            arrowInvisible={arrowInvisible}
          />
        )}
      </>
    )}
    {ctaButtonLabel && (
      <Button variant={ctaButtonVariant} onClick={onClick}>
        {ctaButtonLabel}
      </Button>
    )}
  </div>
)
export default CardFooter
