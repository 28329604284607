import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import { CONSTANTS } from '../../constants/ConstantValues'
import PinInput from './PinInput'
import { focusDomElement } from '../../utils/UtilFunctions'
import TextError from '../typography/TextError'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/components/PinSetup.module.scss'

const pinConfirmationId = 'con'
const pinInputId = 'ch'

/**
 * @param {function():void} onSuccessfulPinSubmit Callback called only when pin
 * has been set successfully
 * @param {function():void} onFailedPinSubmit Callback called only when pin
 * has failed to to be set
 * @param {function():void} tontineAuthEndpoint Endpoint to be called to set the pin
 * @param {function():string} onEndpointCalled Callback that returns the
 * submitted pin
 * @param {string} headerTitle Header text that is above the pin input
 * @param {object} authData Auth data needed to be sent with the pin endpoint
 * @param {boolean} loadingState Renders a loading modal when the pin has been
 * submitted, a pin is submitted when all of the pin fields have values
 *
 * @description Renders two pin input fields for the user to enter their pin
 * and confirm their pin. If the pins match the pin gets added to the user's account
 */
const PinSetup = ({
  onSuccessfulPinSubmit,
  onFailedPinSubmit,
  onEndpointCalled,
  headerTitle,
  authData,
  focusPinSetup,
  loadingState,
  authMachineEvent,
  openLoadingModal,
}) => {
  //Hooks
  const t = useTranslate()

  const {
    setPin,
    setConfirmPin,
    error,
    callEndpointIfPinsMatch,
    pinSetSuccessfully,
  } = usePinSetup({
    t,
    onSuccessfulPinSubmit,
    onFailedPinSubmit,
    onEndpointCalled,
    authData,
    authMachineEvent,
  })

  return (
    <section className={style['pin-setup']}>
      {headerTitle && (
        <h2 className={style[`pin-setup__header`]}>{headerTitle}</h2>
      )}
      <PinInput
        numberOfFields={CONSTANTS.PIN_INPUT_FIELDS}
        clearFields={Boolean(error)}
        onChange={setPin}
        label={t('PIN_PROMPT.PIN_INPUT_LABEL')}
        type={CONSTANTS.HIDE_PIN}
        confirmPin={pinInputId}
        focusPinFieldOnError={false}
        focusOnMount={focusPinSetup}
      />
      <PinInput
        numberOfFields={CONSTANTS.PIN_INPUT_FIELDS}
        clearFields={Boolean(error)}
        onChange={setConfirmPin}
        label={t('AUTH.INPUT_LABEL_CONFIRM_PIN')}
        type={CONSTANTS.HIDE_PIN}
        authData={authData}
        authMachineEvent={callEndpointIfPinsMatch()}
        onSuccessfulPinSubmit={pinSetSuccessfully}
        onFailedPinSubmit={onFailedPinSubmit}
        confirmPin={pinConfirmationId}
        focusPinFieldOnError={false}
        focusOnMount={false}
        loadingState={loadingState}
        openLoadingModal={openLoadingModal}
        loadingMessage={t('PIN_SUBMITTING_MESSAGE')}
      />

      {error && (
        <TextError errorText={error} dataTestID={UI_TEST_ID.pinInputError} />
      )}
    </section>
  )
}

//Checks if the pin and the confirm pin fields match
const pinsMatching = (pin, confirmPin) => pin === confirmPin

//Checks if all pin and confirm input fields have been populated
const allFieldsPopulated = (pin, confirmPin) =>
  pin?.length === CONSTANTS.PIN_INPUT_FIELDS &&
  confirmPin?.length === CONSTANTS.PIN_INPUT_FIELDS

const pinFieldsPopulated = (pin) => pin?.length === CONSTANTS.PIN_INPUT_FIELDS

/**
 * Handles the pin setup logic
 * @param {function} t
 * @param {string} authMachineEvent
 * @param {function} onSuccessfulPinSubmit
 * @param {function} onEndpointCalled
 */
const usePinSetup = ({
  t,
  authMachineEvent,
  onSuccessfulPinSubmit,
  onEndpointCalled,
}) => {
  //States
  const [pin, setPin] = useState(null)
  const [confirmPin, setConfirmPin] = useState(null)
  const [error, setError] = useState(null)

  //Calls the `saveNumericPin` function when both input values match
  const callEndpointIfPinsMatch = () => {
    if (!pinsMatching(pin, confirmPin)) {
      return authMachineEvent
    }
    return ''
  }

  const pinSetSuccessfully = () => {
    onSuccessfulPinSubmit
      ? onSuccessfulPinSubmit({ pin, authMachineEvent })
      : null
  }

  useEffect(() => {
    //If all fields are populated and the pins don't match
    //generate an error
    if (allFieldsPopulated(pin, confirmPin)) {
      if (!pinsMatching(pin, confirmPin)) {
        setError(t('ERROR_PINS_NOT_MATCHING'))
        focusDomElement('#pi0ch')
      }

      if (pinsMatching(pin, confirmPin)) {
        onEndpointCalled ? onEndpointCalled(confirmPin) : null
      }
    }

    //If a user enters one number the error message will be wiped
    if (pin?.length >= 1 && error) {
      setError(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin, confirmPin])

  // Side effect for focusing pin fields
  useEffect(() => {
    //Focuses to confirm pin next
    if (pinFieldsPopulated(pin)) {
      focusDomElement(`#pi0${pinConfirmationId}`)
    }
  }, [pin])

  return {
    pin,
    confirmPin,
    setPin,
    setConfirmPin,
    error,
    setError,
    callEndpointIfPinsMatch,
    pinSetSuccessfully,
  }
}

PinSetup.propTypes = {
  onSuccessfulPinSubmit: PropTypes.func,
  onFailedPinSubmit: PropTypes.func,
  onEndpointCalled: PropTypes.func,
  headerTitle: PropTypes.string,
  authData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pinSetupMode: PropTypes.bool,
  focusPinSetup: PropTypes.bool,
  loadingState: PropTypes.bool,
  authMachineEvent: PropTypes.string,
  openLoadingModal: PropTypes.bool,
}

export default PinSetup
