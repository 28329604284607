import PropTypes from 'prop-types'
import DividerProgress from './DividerProgress'
import style from '../../scss/layout/FormProgress.module.scss'

//FIXME: Really bad way of doing this
// the numbers are used to calculate the progress bar
const activeForm: { [key: string]: number } = {
  'Step 1 of 3': 1,
  'Step 2 of 3': 2,
  'Step 3 of 3': 3,
}

/**
 * Show the form progress bar with text on desktop
 */
const FormProgress = ({
  progress,
  skipSpacing,
}: {
  progress: string
  skipSpacing?: boolean
}) => {
  return (
    <article className={`${style.formProgress}`}>
      <p className={style[`formProgress__steps`]}>{progress}</p>

      {!skipSpacing ? (
        <DividerProgress
          steps={Object.keys(activeForm)?.length}
          activeForm={activeForm[progress]}
          className={style[`formProgress__divider`]}
        />
      ) : (
        <DividerProgress
          steps={Object.keys(activeForm)?.length}
          activeForm={activeForm[progress]}
        />
      )}
    </article>
  )
}

FormProgress.propTypes = {
  progress: PropTypes.string,
  skipSpacing: PropTypes.bool,
}

export default FormProgress
