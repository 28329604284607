import PropTypes from 'prop-types'
import Layout from '../../components/layout/Layout'
import sliderStyle from '../../scss/pages/SliderPage.module.scss'
import PublicTontinatorInputs from './PublicTontinatorInputs'
import BottomCtaLiteLayout from '../../components/layout/BottomCtaLiteLayout'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

/**
 * Renders a layout for the Public Tontinator with sign in and sign up layout
 */
const PublicTontinatorInputLayout = ({
  isSliderPageOpen,
  incomeForecastParams,
  setIncomeForecastParams,
  comparison,
  blueForecastParams,
  setBlueForecastParams,
  yellowForecastParams,
  setYellowForecastParams,
  setIsCompareOpen,
  setIsOpenSignInModal,
  setRegisterForm,
  isCompareOpen,
  setOpenSliderPage,
}) => {
  const { isMobileOrTablet } = useDeviceScreen()

  const sharedProps = {
    isSliderPageOpen,
    incomeForecastParams,
    setIncomeForecastParams,
    comparison,
    blueForecastParams,
    setBlueForecastParams,
    yellowForecastParams,
    setYellowForecastParams,
  }

  if (isMobileOrTablet) {
    if (isSliderPageOpen) {
      return (
        <div className={sliderStyle['sliderPage']}>
          <Layout
            hideDividerHeader
            hideMobileHeader
            containerHeight="lite-build"
          >
            <PublicTontinatorInputs {...sharedProps} />
            <BottomCtaLiteLayout
              isSliderPageOpen={isSliderPageOpen}
              blueForecastParams={blueForecastParams}
              yellowForecastParams={yellowForecastParams}
              onClickBack={() => setIsCompareOpen(false)}
              setIsOpenSignInModal={setIsOpenSignInModal}
              onClickSignUpButton={() => setRegisterForm(true)}
              onClickMobileTopButtonOnly={() =>
                setOpenSliderPage((prev) => !prev)
              }
              onClickComparison={() => setIsCompareOpen(true)}
              isCompareOpen={isCompareOpen}
              onClickPlan1={() => {
                setIncomeForecastParams(undefined, blueForecastParams)
                setOpenSliderPage(false)
                setIsCompareOpen(false)
              }}
              onClickPlan2={() => {
                setIncomeForecastParams(undefined, yellowForecastParams)
                setOpenSliderPage(false)
                setIsCompareOpen(false)
              }}
              hideClickableText
            />
          </Layout>
        </div>
      )
    }
  } else {
    // No mobile detected, just return the normal desktop layout
    return <PublicTontinatorInputs {...sharedProps} />
  }
}

PublicTontinatorInputLayout.propTypes = {
  isSliderPageOpen: PropTypes.bool,
  setOpenSliderPage: PropTypes.func,
  incomeForecastParams: PropTypes.object,
  setIncomeForecastParams: PropTypes.func,
  comparison: PropTypes.bool,
  blueForecastParams: PropTypes.object,
  setBlueForecastParams: PropTypes.func,
  yellowForecastParams: PropTypes.object,
  setYellowForecastParams: PropTypes.func,
  setIsCompareOpen: PropTypes.func,
  setIsOpenSignInModal: PropTypes.func,
  setRegisterForm: PropTypes.func,
  isCompareOpen: PropTypes.bool,
}

export default PublicTontinatorInputLayout
