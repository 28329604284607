import { useTranslate } from '../../hooks/useTranslate'
import { convertDateToClientLocale } from '../../utils/UtilFunctions'
import ExtendedContent from '../../pages/banking/ExtendedContent'
import { CONSTANTS } from '../../constants/ConstantValues'
import { useLocalization } from '../../hooks/useLocalization'
import { ASSET } from '../../constants/Assets'
import { TransactionContribution } from '../../types/CommonTypes.types'
import ExtendedContentCard from './ExtendedContentCard'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/** Renders contributions array as a list of cards
 * - `contributions` Array of objects containing user contributions
 * (pay-in transactions)
 */
const ContributionCards = ({
  contributions,
}: {
  contributions: TransactionContribution
}) => {
  const t = useTranslate()
  const { formatAmount } = useLocalization()

  return (
    contributions.map((contribution) => {
      return (
        <ExtendedContentCard
          key={contribution?.transaction?.time}
          headerImage={ASSET.iconaccountcontributionbicolorUS}
          title={
            formatAmount({
              amount: contribution?.transaction?.amount?.amount ?? 0,
              currency: contribution?.nominalBalance?.currency,
              style: 'currency',
            })?.formattedAmountWithSymbol ?? ''
          }
          variant="gray-dirty"
          expandClickDisabled
          autoExpand
          dataTestID={UI_TEST_ID?.contributionCard}
        >
          <ExtendedContent
            date={
              convertDateToClientLocale(
                contribution?.transaction?.time,
                CONSTANTS.CLOSURE_ACCOUNT_DATE_FORMAT
              )?.formattedLocaleDate
            }
            firstLineLabel={t('PAYOUT_EXTENDED.FIRST_LINE_LABEL')}
            variant="gray-dirty"
          />
        </ExtendedContentCard>
      )
    }) ?? []
  )
}

export default ContributionCards
