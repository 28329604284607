import Header from '../typography/Header'
import { ReactNode } from 'react'
import style from '../../scss/layout/PageLayout.module.scss'

/**
 * Flex page layout with a header if a `headerTitle` is passed in
 */
const PageLayout = ({
  children,
  headerTitle,
  headerTextColor,
  containerWidth,
  containerHeight,
  headerVariant,
  layoutVariant,
  containerMt,
}: {
  children: ReactNode
  headerTitle?: string
  headerTextColor?: 'default' | 'blue'
  containerWidth?: 'small' | 'medium'
  containerHeight?: 'sh' | 'lh' | 'xlh' | 'auto' | 'lite-build'
  containerMt?: 'nomt' | 'mt-20'
  headerVariant?: 'spaced'
  layoutVariant?: 'sun-bg' | 'sun-blue-bg'
}) => {
  return (
    <main
      className={
        style[`page-layout${layoutVariant ? `--${layoutVariant}` : ''}`]
      }
    >
      <section
        className={`
          ${
            style[
              `page-layout__container${containerWidth ? `--${containerWidth}` : ''}`
            ]
          }
          ${style[`page-layout__container${containerHeight ? `--${containerHeight}` : ''}`]}
          ${
            style[
              `page-layout__container${containerMt ? `--${containerMt}` : ''}`
            ]
          }
          `}
      >
        {headerTitle && (
          <Header
            title={headerTitle}
            textColor={headerTextColor}
            variant={headerVariant}
          />
        )}
        {children}
      </section>
    </main>
  )
}

export default PageLayout
