import { Trans } from 'react-i18next'
import Icon from '../media-and-icons/Icon'
import TextInput from '../inputs/TextInput'
import style from '../../scss/components/PageContent.module.scss'

type PageContentProps = {
  icon?: string
  title?: string
  locizeKey?: string
  mainContent?: string | JSX.Element
  values?: object
  textArea?: JSX.Element
  setTextAreaFeedback?: () => void
  className?: string
  smallIcon?: string
  value?: string
  backgroundColor?: 'white' | 'default'
}

/**
 * Component that displays a page with a title, an icon main
 * content and a button if it is passed in
 */
const PageContent = ({
  icon,
  title,
  locizeKey,
  mainContent,
  values,
  textArea,
  setTextAreaFeedback,
  className = '',
  smallIcon,
  value,
  backgroundColor,
}: PageContentProps) => {
  return (
    <main className={`${style['pageContent']} ${className}`}>
      <section
        className={`${style['pageContent__container']} 
        ${style[`pageContent__container--${backgroundColor ?? ''}`]}`}
      >
        {icon && (
          <Icon
            className={`${style[`pageContent__icon${smallIcon ? '--small' : ''}`]}`}
            fileName={icon}
          />
        )}

        {title && <h3 className={style['pageContent__title']}>{title}</h3>}

        {locizeKey && (
          <p className={style['pageContent__text-content']}>
            {locizeKey && <Trans i18nKey={locizeKey} values={values} />}
          </p>
        )}

        {mainContent && (
          <div className={style['pageContent__main-content']}>
            {mainContent}
          </div>
        )}

        {textArea && (
          <TextInput isTextArea value={value} onChange={setTextAreaFeedback} />
        )}
      </section>
    </main>
  )
}

export default PageContent
