import AccountMenu from '../navigation/AccountMenu'
import ErrorBoundaryAndSuspense from '../error-handling/ErrorBoundaryAndSuspense'
import { ReactNode } from 'react'
import style from '../../scss/layout/AccountLayout.module.scss'

/**
 * Account section layout for all the account pages, the account
 * menu is rendered on the left side and the pages are on the right side,
 * passing in `noMenu` does not render the account menu
 */
const AccountLayout = ({ children }: { children: ReactNode }) => {
  return (
    <main className={style['account-layout']}>
      <section className={style[`account-layout__container`]}>
        <AccountMenu className={style[`account-layout__left`]} />
        <section className={style['account-layout__right']}>
          <ErrorBoundaryAndSuspense hideNavButton>
            {children}
          </ErrorBoundaryAndSuspense>
        </section>
      </section>
    </main>
  )
}

export default AccountLayout
