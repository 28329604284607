import { Trans } from 'react-i18next'
import style from '../../scss/components/ScanInstructions.module.scss'
import Icon from '../../components/media-and-icons/Icon'
import { ASSET } from '../../constants/Assets'

/**
 * Scanning instructions for face biometrics, if there is a scanning error, the
 * component renders in error state with instructions to help the user correct
 * their scan error
 */
const ScanInstructions = ({
  scanningErrorMessage,
  title,
}: {
  scanningErrorMessage?: string
  title?: string
}) => {
  return (
    <section className={style.scanInstructions}>
      <article className={style['scanInstructions__container']}>
        {title && !scanningErrorMessage && (
          <h2 className={style['scanInstructions__title']}>{title}</h2>
        )}

        {scanningErrorMessage && (
          <>
            <Icon
              fileName={ASSET.infoamber}
              className={style['scanInstructions__error-icon']}
            />
            <h1 className={style['scanInstructions__title']}>
              {<Trans i18nKey={scanningErrorMessage} />}
            </h1>
          </>
        )}

        <Icon
          fileName={ASSET.iconaccountfacecanintruction}
          className={style['scanInstructions__image']}
        />

        <p className={style['scanInstructions__explainer']}>
          <Trans i18nKey={'VERIFY_ACC_INSTRUCTIONS.EXPLAINER_TEXT'} />
        </p>
      </article>
    </section>
  )
}

export default ScanInstructions
