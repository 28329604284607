import Button from '../../components/buttons/Button'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/pages/Home.module.scss'

type OnboardingButtonsProps = {
  tontinatorButtonLabel: string
  tontinatorButtonOnClick: () => void
  signupButtonLabel: string
  signupButtonOnClick: () => void
  showSignupButton?: boolean
}

/**
 *Renders two buttons, one for signing up and one for going
 * trough the tontinator flow
 */
const OnboardingButtons = ({
  tontinatorButtonLabel,
  tontinatorButtonOnClick,
  signupButtonLabel,
  signupButtonOnClick,
  showSignupButton,
}: OnboardingButtonsProps) => {
  return (
    <article className={style['home__onboarding-buttons']}>
      <Button
        dataTestID={UI_TEST_ID.howItWorksBtn}
        onClick={tontinatorButtonOnClick}
      >
        {tontinatorButtonLabel}
      </Button>
      {showSignupButton && (
        <Button
          dataTestID={UI_TEST_ID.signUpHomeBtn}
          variant={'alternative'}
          onClick={signupButtonOnClick}
        >
          {signupButtonLabel}
        </Button>
      )}
    </article>
  )
}

export default OnboardingButtons
