import {
  generateRange,
  generateUniqueId,
} from '../../../../utils/UtilFunctions'
import PropTypes from 'prop-types'
import { CONSTANTS } from '../../../../constants/ConstantValues'
import { disabledPickerValues } from '../../../../utils/TSUtilFunctions'
import style from '../../../../scss/components/DateInput.module.scss'

/**
 * @param {function():void} setMode Changes the mode of the date picker
 * @param {number} selectedYear Year used to generate years range
 * @param {function():void} setYear Changes the picker's year when the user clicks the
 * year they want
 * @param {number} additionalChoices Generates `n` number of years forward and
 * backwards from current year, `currentYear - n` and `currentYear + n`
 *
 * @description Renders a range of years from the currently selected year. The
 * range starts from `year + n`, where `n` is passed in or default value witch
 * is `11`
 */
const Years = ({
  setMode,
  selectedYear,
  setYear,
  additionalChoices = 4,
  disabledFrom,
  disabledTo,
}) => {
  const renderYearOptions = (startRange, endRange) =>
    generateRange(startRange, endRange).map((year) => (
      <div
        key={`${year}${generateUniqueId()}`}
        onClick={() => setYear(year)}
        className={`${style[`date-picker${selectedYear === year ? '--selected' : ''}`]} 
        ${style[`date-picker__year-element`]}
       ${
         style[
           `${disabledPickerValues({
             value: year,
             disabledFrom,
             disabledTo,
           })}`
         ]
       }
        
        `}
      >
        {year}
      </div>
    ))

  /*
   * Exits the year selection mode and takes the user to select a date, when a
   * has been selected
   */
  const changeModeOnClick = () => setMode?.(CONSTANTS.DATE_PICKER_MODES.DAYS)
  return (
    <div onClick={changeModeOnClick} className={style[`date-picker__years`]}>
      {renderYearOptions(selectedYear - additionalChoices, selectedYear - 1)}
      {renderYearOptions(selectedYear, selectedYear + additionalChoices)}
    </div>
  )
}

Years.propTypes = {
  setMode: PropTypes.func,
  selectedYear: PropTypes.number,
  setYear: PropTypes.func,
  additionalChoices: PropTypes.number,
  disabledFrom: PropTypes.number,
  disabledTo: PropTypes.number,
}

export default Years
