//Replaced `any` type with void, because `any` type is not allowed in our code
//base
type WebSocketEventMethod = ((this: WebSocket, ev: Event) => void) | null

/**
 * WebSocket function params for `webSocketConnection`
 */
type WebSocketConnectionParams = {
  url: string | URL
  onOpenConnection: WebSocketEventMethod
  onError?: WebSocketEventMethod
  onMessageReceivedFromServer?: WebSocketEventMethod
  onCloseConnection?: WebSocketEventMethod
}

/**
 * Generic implementation of webSockets. Opens a webSocket connection for a
 * specified URL and returns the instance of the WebSocket if the connection was
 * successfully established.
 *
 * @note This function does not close the connection, it is expected from the
 * developer to close the connection with react `onUnmount` or any clean up lifecycle
 */
const webSocketConnection = ({
  url,
  onOpenConnection,
  onError,
  onMessageReceivedFromServer,
  onCloseConnection,
}: WebSocketConnectionParams): WebSocket | null => {
  let webSocket: WebSocket | null = null

  if (url) {
    try {
      //Creates a websocket connection with the provided urls
      webSocket = new WebSocket(url)

      if (onOpenConnection) {
        webSocket.onopen = onOpenConnection
      }

      if (onError) {
        webSocket.onerror = onError
      }

      if (onMessageReceivedFromServer) {
        webSocket.onmessage = onMessageReceivedFromServer
      }

      if (onCloseConnection) {
        webSocket.onclose = onCloseConnection
      }
    } catch (error) {
      console.error('ERROR From websocket instance', error)
    }

    //Returns the instance of the WebSocket in order for the closing to be performed
    //by react lifecycle methods or to support any other use case
    return webSocket
  }

  return null
}

export const tontineAuthTS = {
  webSocketConnection,
}
