import PropTypes from 'prop-types'
import { useRef, useMemo } from 'react'
import LottieAnimation from '../../components/feedback/LottieAnimation'
import { ANIMATION } from '../../constants/Animations'
import Button from '../../components/buttons/Button'
import Icon from '../../components/media-and-icons/Icon'
import { ASSET } from '../../constants/Assets'
import Card from '../../components/cards/Card'
import { useTranslate } from '../../hooks/useTranslate'

/**
 * @param {object} newsData News data to be rendered
 * @param {function} onClickNewsArticleId Callback issued when the news card is clicked
 * @param {function} onReachedEnd Callback issues when the end of fetched data
 * is reached
 * @param {number} total Total news items to be fetched
 *
 * @description Renders news cards for the news feed with infinite scrolling
 */
const NewsCards = ({ newsData, onClickNewsArticleId }) => {
  const dataFetched = newsData?.items?.length > 0 || false
  const firstElement = useRef(null)
  const t = useTranslate()

  const articles = useMemo(
    () =>
      newsData?.items
        ?.sort((currentArticle, previousArticle) =>
          previousArticle.featuredArticle ? 1 : -1
        )
        ?.map((item, index) => {
          return (
            <div
              onClick={() => onClickNewsArticleId(item?.articleSlug?.current)}
              key={item['_id']}
              ref={index === 0 ? firstElement : null}
            >
              <Card
                title={item?.articleTitle}
                onClick={() => onClickNewsArticleId(item?.articleSlug?.current)}
                headerImage={item?.articleImage?.imageField?.asset?.url}
                ctaButtonLabel={t('LATEST_NEWS.CTA_BUTTON_LABEL')}
                variant="news"
                ctaButtonVariant="alternative"
              />
            </div>
          )
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newsData?.items?.length]
  )

  if (dataFetched) {
    return (
      <>
        {articles}
        <Button
          onClick={() =>
            firstElement.current.scrollIntoView({ behavior: 'smooth' })
          }
        >
          <Icon fileName={ASSET.icononboardinarrowupwhite} />
        </Button>
      </>
    )
  }

  return (
    <LottieAnimation
      animationName={ANIMATION.loadingLightBlueDots}
      autoplay
      loop
    />
  )
}

NewsCards.propTypes = {
  newsData: PropTypes.object,
  onClickNewsArticleId: PropTypes.func,
  onReachedEnd: PropTypes.func,
  total: PropTypes.number,
}

export default NewsCards
