import { ButtonVariant } from './Button.type'

// If both Card and it's children should have variant enter here, else enter individually
const baseVariants = {
  news: 'news',
  box: 'box',
  boxAlt: 'box-alt',
} as const

export const cardVariants = {
  ...baseVariants,
  stat: 'stat',
  feedback: 'feedback',
  grayDirty: 'gray-dirty',
} as const

export const cardHeaderVariants = {
  ...baseVariants,
} as const

export const cardBodyFooterVariants = {
  ...baseVariants,
  feedback: 'feedback',
} as const

type CardVariantType = (typeof cardVariants)[keyof typeof cardVariants]
type CardHeaderVariantType =
  (typeof cardHeaderVariants)[keyof typeof cardHeaderVariants]
type CardBodyFooterVariantType =
  (typeof cardBodyFooterVariants)[keyof typeof cardBodyFooterVariants]

type CardAlertType = number | 'completed' | 'warn' | 'error' | 'pending'

interface CardArrowAndAlertProps {
  alert?: CardAlertType
  showArrow?: boolean
  alertAndArrowPosition?: 'start' | 'end'
  rotateArrow?: 'down' | 'up'
  arrowInvisible?: boolean
}

interface CardRoundness {
  roundness?: 'off' | 'rounded-sm' | 'rounded' | 'rounded-l'
}

interface CardHeaderProps extends CardArrowAndAlertProps {
  headerImage?: string
  headerImageSize?: 'small' | 'large' | 'x-large'
  variant?: CardHeaderVariantType
}

interface CardBodyProps {
  title: string
  subTitle?: string
  variant?: CardBodyFooterVariantType
}

interface CardFooterProps extends CardArrowAndAlertProps {
  variant?: CardBodyFooterVariantType
  ctaButtonLabel?: string
  ctaButtonVariant?: ButtonVariant
  onClick?: () => void
}

type CardProps = Omit<CardHeaderProps, 'variant'> &
  Omit<CardBodyProps, 'variant'> &
  Omit<CardFooterProps, 'variant'> &
  CardRoundness & {
    active?: boolean
    interactEnabled?: boolean
    disabled?: boolean
    dataTestID?: string
    variant?: CardVariantType
  }

interface ExtendedContentCardProps extends CardProps {
  children: JSX.Element
  autoExpand?: boolean
  expandClickDisabled?: boolean
  extendedCardVariant?: 'payout'
}

export type {
  CardVariantType,
  CardBodyFooterVariantType,
  CardHeaderVariantType,
  CardAlertType,
  CardHeaderProps,
  CardBodyProps,
  CardFooterProps,
  CardProps,
  ExtendedContentCardProps,
  CardRoundness,
  CardArrowAndAlertProps,
}
