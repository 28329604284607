import Icon from '../../../media-and-icons/Icon'
import PropTypes from 'prop-types'
import Divider from '../../../data-display/Divider'
import { ASSET } from '../../../../constants/Assets'
import style from '../../../../scss/components/BoxDoubleIncrements.module.scss'

/**
 * @param {string} className Targets the main component wrapper
 * @param {function} onClickIncrement Callback issued when the increment arrow is clicked
 * @param {function} onClickDecrement Callback issued when the decrement arrow is clicked
 * @param {boolean} renderRight Whether the the icon should be rendered on the
 * right side if `true`, if not specified left is default value
 *
 * @description Renders increment and decrement buttons with arrow icons
 */
const BoxDoubleIncrements = ({
  className,
  onClickIncrement,
  onClickDecrement,
  disabledIncrement,
  disabledDecrement,
  renderRight,
}) => {
  return (
    <section className={`${style.incrementBox} ${className}`}>
      <article className={style[`incrementBox__container`]}>
        <Icon
          fileName={ASSET.icononboardinarrowup}
          className={`
          ${style['incrementBox__icon-up']}
          ${style['incrementBox__icon']} 
          ${
            style[
              `incrementBox__icon-up${renderRight ? 'right' : 'left'}${disabledIncrement ? '--disabled' : ''}`
            ]
          }`}
          onClick={onClickIncrement}
        />
        <Divider className={`${style[`incrementBox__divider`]}`} />
        <Icon
          fileName={ASSET.icononboardinarrowup}
          className={`
          ${style['incrementBox__icon-down']} 
          ${style['incrementBox__icon']}
          ${
            style[
              `incrementBox__icon-down${renderRight ? 'right' : 'left'}${disabledDecrement ? '--disabled' : ''}`
            ]
          }
          `}
          onClick={onClickDecrement}
        />
      </article>
    </section>
  )
}

BoxDoubleIncrements.propTypes = {
  className: PropTypes.string,
  onClickIncrement: PropTypes.func.isRequired,
  onClickDecrement: PropTypes.func.isRequired,
  disabledDecrement: PropTypes.bool,
  disabledIncrement: PropTypes.bool,
  renderRight: PropTypes.bool,
}

export default BoxDoubleIncrements
