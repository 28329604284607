import { CONSTANTS } from '../../../../constants/ConstantValues'
const {
  DATE_PICKER_MODES: { YEARS, MONTHS },
} = CONSTANTS
import { monthNumberToString } from '../../../../utils/UtilFunctions'
import PropTypes from 'prop-types'
import Icon from '../../../media-and-icons/Icon'
import { ASSET } from '../../../../constants/Assets'
import style from '../../../../scss/components/DateInput.module.scss'

/**
 * @param {string} parentBlock CSS class of the parent component
 * @param {function} previousMonth Navigates to the previous month,
 * `currentMonth -1`
 * @param {function} nextMonth Navigates to the next month `nextMonth+1`
 * @param {function} setPickerMode Sets the date picker mode, that can be days,
 * months or years
 * @param {function} nextYear Navigates to the next year `currentYear+1`
 * @param {function} previousYear Navigates to the previous year `currentYear - 1`
 * @param {object} date Contains `day`, `month` and `year`
 * @param {string} locale Locale to format the date top section to
 *
 * @description Top navigation section for the date picker, where the month is
 * displayed and the year. Also contains navigation buttons to navigate to the
 * next year and month as well as previous year and month.
 */
const PickerTopSection = ({
  locale,
  parentBlock,
  previousMonth,
  previousYear,
  setPickerMode,
  nextYear,
  nextMonth,
  date: { year, month },
  disabledMonthNext,
  disabledYearNext,
  disabledMonthBack,
  disabledYearBack,
  pickerMonthFormat,
}) => {
  return (
    <section className={style[`date-picker__top-section`]}>
      <article className={style[`date-picker__left-nav`]}>
        <Icon
          fileName={ASSET.icondoublearrowback}
          onClick={previousYear}
          className={`${style[`date-picker__nav-btns`]} 
          ${style[`${disabledElement(disabledYearBack, parentBlock)}`]}`}
        />
        <Icon
          fileName={ASSET.icononboardinarrowback}
          onClick={previousMonth}
          className={`${style[`date-picker__nav-btns`]} 
          ${style[`${disabledElement(disabledMonthBack, parentBlock)}`]}`}
        />
      </article>
      <div className={style[`date-picker__top-section-date`]}>
        <p onClick={() => setPickerMode(MONTHS)}>
          {monthNumberToString(month, locale, pickerMonthFormat ?? 'long')}
        </p>
        <p onClick={() => setPickerMode(YEARS)}>{year}</p>
      </div>
      <article className={style[`date-picker__right-nav`]}>
        <Icon
          fileName={ASSET.icononboardinarrowforward}
          onClick={nextMonth}
          className={`${style[`date-picker__nav-btns`]} 
          ${style[`${disabledElement(disabledMonthNext, parentBlock)}`]}`}
        />
        <Icon
          fileName={ASSET.icondoublearrowforward}
          onClick={nextYear}
          className={`${style[`date-picker__nav-btns`]} 
          ${style[`${disabledElement(disabledYearNext, parentBlock)}`]}`}
        />
      </article>
    </section>
  )
}

/**
 * Applies the `--disabled` style if `disabled` argument is `true`.
 */
const disabledElement = (disabled) =>
  disabled ? `date-picker__nav-btns--disabled` : ''

PickerTopSection.propTypes = {
  parentBlock: PropTypes.string.isRequired,
  previousMonth: PropTypes.func.isRequired,
  previousYear: PropTypes.func.isRequired,
  setPickerMode: PropTypes.func.isRequired,
  nextYear: PropTypes.func.isRequired,
  nextMonth: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  locale: PropTypes.string,
  disabledMonthNext: PropTypes.bool,
  disabledYearNext: PropTypes.bool,
  disabledMonthBack: PropTypes.bool,
  disabledYearBack: PropTypes.bool,
  pickerMonthFormat: PropTypes.string,
}

export default PickerTopSection
