import PropTypes from 'prop-types'
import Button from '../../components/buttons/Button'
import NextPayoutCard from '../../components/feedback/NextPayoutCard'
import DividerHeader from '../../components/headers/DividerHeader'
import { DASHBOARD_NAVIGATION } from '../../routes/Route'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useTranslate } from '../../hooks/useTranslate'

import PensionPlanDashboard from './PensionPlanDashboard'
import { ANIMATION } from '../../constants/Animations'
import TextError from '../../components/typography/TextError'
import Icon from '../../components/media-and-icons/Icon'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import LottieAnimation from '../../components/feedback/LottieAnimation'
import ErrorBoundaryAndSuspense from '../../components/error-handling/ErrorBoundaryAndSuspense'
import { hasCompletedL1KYC } from '../../utils/UtilFunctions'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/layout/ProgressDashboard.module.scss'

/**
 * @wip Until design is done and the feature is well defined
 * Renders pension plan lines as views, DOES NOT MODIFY OR SAVE DATA
 */
const FundedDashboard = ({ data, error, isLoading, currencySymbol }) => {
  const t = useTranslate()
  const navigate = useCustomNavigation()
  const {
    context: { user_details },
  } = useAccountService()

  if (isLoading) {
    return (
      <LottieAnimation
        loop
        autoplay
        animationName={ANIMATION.loadingLightBlueDots}
      />
    )
  }

  return (
    <>
      {hasCompletedL1KYC(user_details) && (
        <NextPayoutCard className={style[`progress-dashboard__next-payout`]} />
      )}

      <DividerHeader
        headerText={t('DASHBOARD.CHART.TITLE.EXPECTED.MONTHLY.INCOME')}
      />
      {error && <TextError errorText={error} validationObject={error} />}

      {!isLoading && data?.plan_to_draw === null && (
        <>
          <div className={style[`progress-dashboard__animation-container`]}>
            <Icon
              fileName={ASSET.dashboardPlaceholder}
              className={style[`progress-dashboard__animation`]}
            />
          </div>
          <section className={style[`progress-dashboard__button-container`]}>
            <Button
              className={style[`progress-dashboard__button`]}
              variant="primary"
              onClick={() => navigate(DASHBOARD_NAVIGATION.TONTINATOR)}
            >
              {t('CRATE_PLAN_BUTTON')}
            </Button>
          </section>
        </>
      )}

      {!isLoading && data?.plan_to_draw && (
        <ErrorBoundaryAndSuspense>
          <PensionPlanDashboard
            currencySymbol={currencySymbol}
            dataToDraw={[
              {
                planID: data?.draft_plan.plan_id,
                countryOfResidence: user_details?.residency,
              },
            ]}
            className={style[`progress-dashboard__chart`]}
          />
        </ErrorBoundaryAndSuspense>
      )}
    </>
  )
}

FundedDashboard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
  error: PropTypes.any,
  isLoading: PropTypes.any,
  currencySymbol: PropTypes.string,
}

export default FundedDashboard
