import { useEffect } from 'react'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { PensionPlan, PlanData } from '../types/CommonTypes.types'
import { useGetRequest } from './useGetRequest'
import { UserDetails } from '../state-management/authentication/AuthMachineTypes.type'
import { API } from '../api/API'

type PlanDataResponse = {
  [PensionPlanStates.Draft]: { data: PensionPlan }
  [PensionPlanStates.Pending]: { data: PensionPlan }
  [PensionPlanStates.Active]: { data: PensionPlan }
  data: PensionPlan
  length: number
}

type PensionPlans = {
  data?: PlanData
  isLoading: boolean
  error?: string | object
  refetch?: () => void
}

/**
 * Can be used in an array as index for now
 */
enum PensionPlanStates {
  Draft,
  Pending,
  Active,
}

/**
 * Fetches pension plan data and controls which pension plan should be rendered
 * by updating the `plan_to_draw` key
 *
 * Returns an object containing pension plans
 *
 * `plan_to_draw` is the plan that we want to init inputs when a pension plan
 * setup page loads, it always prioritizes the highest level of plan which is
 * `retired`, `active`, `pending` and `draft`. Listed by priority
 *
 * @deprecated Going to be done with xstate when requirements and design are
 * defined for pension plans
 */
export const usePensionPlans = (): PensionPlans => {
  const {
    isAuthenticated,
    context: { user_details },
  } = useAccountService() as {
    isAuthenticated: boolean
    context: { user_details: UserDetails }
  }

  const { data, isLoading, error, refetch } = useGetRequest(
    isAuthenticated ? [API.readDraftPlan] : []
  )

  useEffect(() => {
    if (user_details?.date_of_birth) {
      refetch && refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_details?.date_of_birth])

  if (!data) {
    return { data: undefined, isLoading, error, refetch }
  }

  return {
    data: prasePlanData(data),
    isLoading,
    error,
    refetch,
  } as PensionPlans
}

/**
 * Parses unknown api response to PlanData
 */
const prasePlanData = (apiResponse: unknown): PlanData => {
  const planData = apiResponse as PlanDataResponse

  const draft_plan = planData[PensionPlanStates.Draft]?.data
  const pending_plan = planData[PensionPlanStates.Pending]?.data
  const active_plan = planData[PensionPlanStates.Active]?.data

  const plan_to_draw = active_plan || pending_plan || draft_plan

  //Draft is when there is no money received no direct debit is setup Pending is
  //when direct debit is setup and NO money is received (waiting to get the
  //money) Active plan is when direct debit or lumpsum deposit is received

  //Check for pending plan, if no set inputs to active plan values if both of
  //these cannot be found then use draft plan if there us one SPECULATION: It
  //should be draft, pending then active, after the frontend meeting we will
  //talk about the draft plan values
  const plan_based_forecast = pending_plan || active_plan || draft_plan

  return {
    draft_plan,
    pending_plan,
    active_plan,
    plan_to_draw,
    plan_based_forecast,
  }
}
