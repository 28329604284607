import { useCallback, useRef } from 'react'
import { LegalMachineEventPayload, States } from './LegalMachineTypes.types'
import { LegalServiceContext } from './LegalServiceContext'
import { generateStatesObject } from '../StateUtils'
import { legalMachine } from './LegalMachine'
import { environment } from '../../config/development'
import { useAccountService } from '../authentication/useAccountService'

type EventType =
  | 'FETCH_FORM'
  | 'SAVE_FORM_PROGRESS'
  | 'SUBMIT_FORM'
  | 'FETCH_AGREEMENT'
  | 'GENERATE_FORM_AS_PDF'
  | 'SIGN_AGREEMENT'

const { useActorRef, useSelector } = LegalServiceContext

/**
 * Consumes the LegalServiceProvider (subscribed to all states).
 *
 * An `AbortController` is automatically sent with every event, and the request
 * is aborted in the `finally` block of some services.
 *
 * Provided functionalities:
 * 1. `send` sends an event to the legal machine
 * 2. `currentLegalState` the state the legal machine is currently in
 * 3. `legalContext` data stored in the legal machine, fetched data and errors
 * 4. `states` all the possible states that the legal machine can be in
 */
export const useLegalMachine = () => {
  const abortController = useRef<AbortController>(new AbortController())

  const {
    context: { authToken },
  } = useAccountService()
  const actorRef = useActorRef()

  const { currentLegalState, legalContext } = useSelector((state) => {
    return {
      currentLegalState: state.value,
      legalContext: state.context,
    }
  })

  //Send from xstate function wrapped in a callback
  const callbackSend = useCallback(
    ({
      type,
      payload,
    }: {
      type: EventType
      payload: LegalMachineEventPayload
    }) => {
      actorRef.send({
        type,
        payload: {
          abortController: abortController.current,
          finallyCallback: () => {
            abortController.current = new AbortController()
          },
          authToken,
          ...payload,
        },
      })
    },

    // Actor ref should not change, it is a static reference!
    [actorRef, authToken]
  )

  // Only used in developer env to directly send events to the auth machine
  // for testing. Also used in Cypress test as a hack for now until real model
  // based tests are added for xstate
  if (environment === 'development') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.sendDevLegalEvent = actorRef.send.bind(actorRef)
  }

  return {
    /**
     * Sends an event to the legal machine
     */
    sendLegalEvent: callbackSend,
    /**
     * Current state of the legal machine
     */
    currentLegalState,
    /**
     * Data stored in the legal machine, fetched data and errors
     */
    legalContext,
    /**
     * All the possible states that the legal machine can be in
     */
    states: generateStatesObject(legalMachine.states) as States,
  }
}
