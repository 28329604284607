import Toggle from '../inputs/Toggle'
import { useLocalization } from '../../hooks/useLocalization'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/layout/GraphSwitches.module.scss'
import { EVENT_DESC } from '../../analytics/EventDescription'
import { useTranslate } from '../../hooks/useTranslate'

type GraphSwitchesProps = {
  breakevenLabel: string
  inflationLabel: string
  percentage: boolean
  handlePercentage: () => void
  breakeven: boolean
  handleBreakeven: () => void
  inflation: boolean
  handleInflation: () => void
  togglesVariant?: 'button'
}

/**
 * GraphSwitches is a component that provides a group of toggles
 * that can be used to switch between different views of a graph.
 */
const GraphSwitches = ({
  breakevenLabel,
  inflationLabel,
  percentage,
  handlePercentage,
  breakeven,
  handleBreakeven,
  inflation,
  handleInflation,
  togglesVariant,
}: GraphSwitchesProps) => {
  const { formatAmount } = useLocalization()
  const t = useTranslate()

  const changeCurrencyLabel = () =>
    t('FORECAST_PAGE.GRAPH_SWITCH_PERCENT', {
      symbol: percentage
        ? formatAmount({
            amount: 0,
            style: 'currency',
          })?.symbol
        : formatAmount({
            amount: 0,
            style: 'percent',
          })?.symbol,
    })

  return (
    <article className={style.graphSwitches}>
      <div
        className={
          style[
            `graphSwitches__container${togglesVariant ? `--${togglesVariant}` : ``}`
          ]
        }
      >
        <Toggle
          toggled={percentage}
          onChange={handlePercentage}
          label={changeCurrencyLabel()}
          testID={UI_TEST_ID.showPercentToggle}
          variant={togglesVariant}
          trackActivity={{
            trackId: 'tontinator_percentage',
            eventDescription: EVENT_DESC.tontinatorPercentToggle,
          }}
        />

        <Toggle
          toggled={breakeven}
          label={breakevenLabel}
          onChange={handleBreakeven}
          testID={UI_TEST_ID.breakevenToggle}
          variant={togglesVariant}
          trackActivity={{
            trackId: 'tontinator_breakeven',
            eventDescription: EVENT_DESC.tontinatorBreakevenToggle,
          }}
        />

        <Toggle
          toggled={inflation}
          onChange={handleInflation}
          label={inflationLabel}
          testID={UI_TEST_ID.showInflationToggle}
          variant={togglesVariant}
          trackActivity={{
            trackId: 'tontinator_inflation',
            eventDescription: EVENT_DESC.tontinatorPercentToggle,
          }}
        />
      </div>
    </article>
  )
}

export default GraphSwitches
