import { ReactNode } from 'react'
import { BankServiceContext } from './BankingServiceContext'

/**
 * Banking service provider that interprets the `bankMachine` from xstate and
 * exposes the global BankingServiceContext with state management functionalities
 * to be used globally.
 */
const BankingServiceProvider = ({ children }: { children: ReactNode }) => {
  return <BankServiceContext.Provider>{children}</BankServiceContext.Provider>
}

export { BankingServiceProvider }
