import PropTypes from 'prop-types'
import Icon from '../../components/media-and-icons/Icon'
import { MarkdownParser } from '../../components/data-display/MarkdownParser'
import style from '../../scss/pages/NewsArticleContent.module.scss'

type NewsArticleContentProps = {
  date: string
  readingTime: string
  title: string
  summary: string
  image: string
  content: string
  dateIcon: string
  readingTimeIcon: string
}

/**
 * Renders a selected article from the NewsFeed
 */
const NewsArticleContent = ({
  date,
  readingTime,
  title,
  summary,
  image,
  content,
  dateIcon,
  readingTimeIcon,
}: NewsArticleContentProps) => {
  return (
    <main className={style.newsArticleContent}>
      <section className={style[`newsArticleContent__date-container`]}>
        <div className={style[`newsArticleContent__date`]}>
          <Icon
            className={style[`newsArticleContent__date-icon`]}
            fromWeb={dateIcon}
            fileName=""
          />
          <p>{date}</p>
        </div>
        {readingTime && readingTimeIcon && (
          <div className={style[`newsArticleContent__reading-time`]}>
            <Icon
              className={style[`newsArticleContent__reading-time-icon`]}
              fromWeb={readingTimeIcon}
              fileName=""
            />
            <p>{readingTime}</p>
          </div>
        )}
      </section>
      <section className={style[`newsArticleContent__summary-container`]}>
        <h1 className={style[`newsArticleContent__title`]}>{title}</h1>
        <p className={style[`newsArticleContent__summary`]}>{summary}</p>
      </section>
      <section className={style[`newsArticleContent}__main`]}>
        <img
          className={style[`newsArticleContent__main-image`]}
          src={image}
          alt="..."
        />
        <MarkdownParser markdownContent={content} />
      </section>
    </main>
  )
}

NewsArticleContent.propTypes = {
  date: PropTypes.any,
  readingTime: PropTypes.any,
  title: PropTypes.any,
  summary: PropTypes.any,
  image: PropTypes.any,
  mainTitle: PropTypes.any,
  content: PropTypes.any,
  dateIcon: PropTypes.string,
  readingTimeIcon: PropTypes.string,
}

export default NewsArticleContent
