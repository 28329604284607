import Days from './Days'
import Months from './Months'
import Years from './Years'
import PropTypes from 'prop-types'
import { CONSTANTS } from '../../../../constants/ConstantValues'

/**
 * @param {string} mode Selects the calendar mode, available mode values are:
 * `days`, `months`, `years`
 * @param {function():void} setMode Sets the parent's component mode state
 * @param {object} date Object that contains month and day as numbers
 * @param {function():void} setYear Sets the year state on the parent component
 * @param {function():void} setMonth Sets the month state on the parent component
 * @param {function():void} nextMonth Switches to the next month when a day from the next
 * month is clicked
 * @param {function():void} previousMonth Switches to the previous month when a day from the previous
 * month is clicked
 * @param {boolean} onlyMonths Does not allow selecting a month or a year to
 * change to days mode, usually used for places where we only need to select a
 * month a year
 *
 * @description Renders days, months and years pickers depending on the passed
 * in `mode`. If a user is in years mode and selects a year the component then
 * renders days mode, same for months mode, after the user selects a month days
 * are rendered
 */
const Pickers = ({
  mode,
  setMode,
  date,
  setYear,
  setMonth,
  setDay,
  nextMonth,
  previousMonth,
  onlyMonths,
  disabledMonthsFrom,
  disabledMonthsTo,
  disabledYearFrom,
  disabledYearTo,
  disabledDayFrom,
  disabledDayTo,
}) => {
  if (mode === CONSTANTS.DATE_PICKER_MODES.MONTHS) {
    return (
      <Months
        setMode={onlyMonths ? undefined : setMode}
        setMonth={setMonth}
        selectedMonth={date?.month}
        disabledFrom={disabledMonthsFrom}
        disabledTo={disabledMonthsTo}
      />
    )
  }
  if (mode === CONSTANTS.DATE_PICKER_MODES.YEARS) {
    return (
      <Years
        setMode={onlyMonths ? undefined : setMode}
        selectedYear={date?.year}
        setYear={setYear}
        disabledFrom={disabledYearFrom}
        disabledTo={disabledYearTo}
      />
    )
  }

  return (
    <Days
      setMode={setMode}
      setDay={setDay}
      date={date}
      nextMonth={nextMonth}
      previousMonth={previousMonth}
      disabledFrom={disabledDayFrom}
      disabledTo={disabledDayTo}
    />
  )
}

Pickers.propTypes = {
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  date: PropTypes.object,
  setYear: PropTypes.func.isRequired,
  setMonth: PropTypes.func.isRequired,
  setDay: PropTypes.func.isRequired,
  nextMonth: PropTypes.func.isRequired,
  previousMonth: PropTypes.func.isRequired,
  onlyMonths: PropTypes.bool,
  disabledMonthsFrom: PropTypes.number,
  disabledMonthsTo: PropTypes.number,
  disabledYearFrom: PropTypes.number,
  disabledYearTo: PropTypes.number,
  disabledDayFrom: PropTypes.number,
  disabledDayTo: PropTypes.number,
}

export default Pickers
