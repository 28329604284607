import StaticToolTip from '../feedback/StaticToolTip'
import InputLabel from '../inputs/InputLabel'
import style from '../../scss/layout/MobileIncomeStatTip.module.scss'

type MobileIncomeStatTipProps = {
  label?: string
  tooltipText1: string
  tooltipText2: string
  onClickTip1: () => void
  onClickTip2: () => void
  tip1Active?: boolean
  tip2Active?: boolean
}

/**
 * Renders two clickable static tooltips next to each other
 */
const MobileIncomeStatTip = ({
  label,
  tooltipText1,
  tooltipText2,
  onClickTip1,
  onClickTip2,
  tip1Active,
  tip2Active,
}: MobileIncomeStatTipProps) => {
  return (
    <article className={style['mobile-income-stat-tip']}>
      {label && <InputLabel label={label} />}
      <div className={style['mobile-income-stat-tip__tip-container']}>
        <StaticToolTip
          text={tooltipText1}
          onClick={onClickTip1}
          color={tip1Active ? 'blue' : 'inactive'}
        />
        <StaticToolTip
          text={tooltipText2}
          onClick={onClickTip2}
          color={tip2Active ? 'gold' : 'inactive'}
        />
      </div>
    </article>
  )
}

export default MobileIncomeStatTip
