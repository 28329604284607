import { StylingOptionsD3 } from './types/Visualization.interfaces'
import { ASSET } from '../constants/Assets'

type D3Colors = {
  lightBlue: string
  blue: string
  boringBrown: string
}

const Colors: Readonly<D3Colors> = {
  lightBlue: '#7ebaf7',
  blue: '#2975bf',
  boringBrown: '#887A6C',
}

const chartLine: StylingOptionsD3 = {
  color: Colors.blue,
  stroke: '3px',
  fill: 'none',
  inflationAreaOpacity: 0.2,
  areaOpacity: 0.1,
  dashArray: '0, 0',
  showAnnotations: false,
  arrowHead: true,
  areaColor: Colors.blue,
}

/**
 * Default styling for all D3 elements
 */
export const Styling = {
  transitionDurationMilliseconds: 3_500,
  hidden: 'transparent',
  textColor: 'white',
  textAnchor: 'middle',
  fontWeight: '800',
  image: {
    comparisonBox: ASSET.chartthumb,
  },

  tontinatorLineChart: {
    ticksForX: 8,
    ticksForY: 8,
    xAxisDistanceFromChart: 12,
    tontineLine: chartLine,
    bankDepositLine: {
      ...chartLine,
      areaOpacity: 0,
      color: 'red',
      areaColor: 'red',
    },
    annuityLine: {
      ...chartLine,
      areaOpacity: 0,
      color: Colors.boringBrown,
      areaColor: Colors.boringBrown,
    },
  },
  comparisonLine: {
    line: {
      dashArray: '0, 0',
      stroke: Colors.lightBlue,
      strokeWidth: '2px',
    },
    comparisonBox: {
      fill: Colors.lightBlue,
      stroke: Colors.lightBlue,
      strokeWidth: '2px',
    },
  },
  focusCircles: {
    radius: 6,
    fill: Colors.blue,
    stroke: 'white',
  },
  breakEvenCircles: {
    circleColor: 'white',
    radius: '13px',
    stroke: 'white',
  },
  callouts: {
    xPadding: 6,
    yPadding: 0,
    tipWidth: 6,
    tipHeight: 8,
  },
  staticAnnotations: {
    transitionDurationMilliseconds: 3_500,
  },
} as const
