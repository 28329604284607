import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import Button from '../../components/buttons/Button'
import Checkbox from '../../components/inputs/Checkbox'
import TextInput from '../../components/inputs/TextInput'
import Divider from '../../components/data-display/Divider'
import { generateRange } from '../../utils/UtilFunctions'
import { getDetectedIpCountry } from '../../utils/TSUtilFunctions'
import { PUBLIC } from '../../routes/Route'
import { useInputValidation } from '../../hooks/useInputValidation'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import RangeSlider from '../../components/inputs/slider/RangeSlider'
import { useParams } from 'react-router-dom'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import InputGroup from '../../components/layout/InputGroup'
import { useSupportedCountries } from '../../hooks/useSupportedCountries'
import SelectSex from '../../components/buttons/SelectSex'
import {
  getSupportedTontinatorParams,
  sanitizeInputValue,
} from '../../utils/TSUtilFunctions'
import { inputRestrictionRegex } from '../../constants/Regex'
import { useLegalMachine } from '../../state-management/legal/useLegalMachine'
import TermsAndConditions from '../legal/TermsAndConditions'
import TextError from '../../components/typography/TextError'
import { useUserInfo } from '../../hooks/InputHandlers'
import CountryDropdown from '../../components/inputs/CountryDropdown'
import { INPUT_LIMIT } from '../../constants/InputLimits'
import { CONSTANTS } from '../../constants/ConstantValues'
import style from '../../scss/pages/Register.module.scss'

// Will do for now, we have no idea what domains and how many domain aliases
// there will be
const appDomains = [
  'https://ton.money',
  'https://app.mytontine.com',
  'https://5t4g1ng.robotontine.com',
  'http://localhost:9000',
  'http://localhost:8080',
  'https://tontine.com',
]

/**
 * @param {string} referralLink
 */
const excludeDomainFromUrl = (referralLink) => {
  if (referralLink) {
    // destruct the referral link into chunks
    const [domain] = referralLink.split('/u')
    // construct the url back with protocol domain and the referral prefix
    const constructedUrlWithPrefix = `${domain}/${CONSTANTS.REFERRAL_CODE_PREFIX}`
    if (appDomains.includes(domain)) {
      // return the referral link without domain, prefix only the referral code
      return referralLink.replaceAll(constructedUrlWithPrefix, '')
    }
  }

  return referralLink
}

/**
 * @param {string} fieldsTitle Title that is displayed on top of the form
 * @param {boolean} hideAdditionalFields Hides the additional fields like
 * `currentAge`, `sex` and `residency`
 * @param {function} handleRegister Make an API request to the UAS with the
 * @param {string} registerButtonLabel Label for the register button
 * @param {function():object} validatedFieldValues Callback that state lifts the
 * validated field values
 * @param {boolean} registerState `true` if the user has been registered successfully
 * @param {boolean} forecastPageRegisterModal Whether the the sign up field are
 * going to be placed in a modal
 *
 * Renders sign up form fields. The fields are validated if the
 * user has entered the data in he right format. If all the validation passes
 * then an object with the fields values is returned. Via ` validatedFieldValues`
 */
const SignUpFields = ({
  fieldsTitle,
  hideAdditionalFields,
  handleRegister,
  registerButtonLabel,
  forecastPageRegisterModal,
  signUpError,
  authState,
  hideDivider,
  backButtonAction,
}) => {
  const { legalContext } = useLegalMachine()
  //Hooks
  const { referral_code } = useParams()
  const t = useTranslate()
  const navigate = useCustomNavigation()

  //Fields validation states
  const {
    validateEmail,
    emailValidated,
    validateFirstName,
    firstNameValidated,
    validateLastName,
    lastNameValidated,
    allInputFieldsValidated,
    referralCodeValidated,
    validateReferralCode,
  } = useInputValidation()

  const [renderTermsAndConditions, setRenderTermsAndConditions] =
    useState(false)

  //Form input values
  const [referralCode, setReferralCode] = useState(
    excludeDomainFromUrl(referral_code)
  )
  const [termsVersion, setTermsVersion] = useState(undefined)
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [emailUpdates, setEmailUpdates] = useState(false)
  const [country, setCountry] = useState(getDetectedIpCountry())
  const {
    supportedCountry: { tontinatorParams: supportedCountryParams },
  } = useSupportedCountries(country)
  const [currentAge, setCurrentAge] = useState(
    supportedCountryParams?.defaultRetirementAgeSlider
  )

  const handleReferralCode = (referralCode) => {
    referralCode = excludeDomainFromUrl(referralCode)
    setReferralCode(sanitizeInputValue({ inputValue: referralCode }))
  }

  const {
    userEmail,
    setUserEmail,
    setFirstName,
    firstName,
    lastName,
    setLastName,
    sex,
    setSex,
  } = useUserInfo({ default_sex: supportedCountryParams?.defaultSex })

  const handlePolicyCheckbox = () => {
    setTermsVersion(legalContext?.agreement?.['TermsAndConditions']?.version)
    setTermsAndConditions((currCheck) => !currCheck)
  }

  //If sign up parameters are passed from the Tontinator flow, then validate the fields
  const validatedAdditionalFields = () =>
    hideAdditionalFields || (sex && country)

  const validatedRegisterInfo = () =>
    allInputFieldsValidated() &&
    termsAndConditions &&
    validatedAdditionalFields() &&
    userEmail &&
    firstName &&
    lastName

  const handleCountry = (country) => {
    setCountry(country)

    const defaultRetirementAge =
      getSupportedTontinatorParams(country)?.tontinatorParams
        ?.defaultRetirementAgeSlider

    //Default to country default retirement age
    setCurrentAge(defaultRetirementAge)
  }

  const handleRegistration = () => {
    if (validatedRegisterInfo()) {
      handleRegister({
        firstName,
        lastName,
        email: userEmail,
        sex,
        currentAge,
        country,
        referralCode: referralCode,
        termsVersion,
        emailUpdates: emailUpdates,
      })
    }
  }

  /**
   *
   * @param {string} firstName
   */
  const handleFirstName = (firstName) => {
    setFirstName(
      sanitizeInputValue({ inputValue: firstName, onlySpaces: true })
    )
  }
  /**
   *
   * @param {string} lastName
   */
  const handleLastName = (lastName) => {
    setLastName(sanitizeInputValue({ inputValue: lastName, onlySpaces: true }))
  }

  /**
   * @param {string} email
   */
  const handleEmail = (email) => {
    //Don't allow user to enter white spaces
    setUserEmail(email.trim())
  }

  return (
    <>
      {/* Only appears if these fields are used in the register modal */}
      {fieldsTitle && (
        <h2 className={style['register__title']}>{fieldsTitle}</h2>
      )}
      <InputGroup noStyle={forecastPageRegisterModal}>
        <TextInput
          value={firstName}
          onChange={handleFirstName}
          label={t('INPUT_LABEL.SIGN_UP_FIRSTNAME')}
          validatorFunction={validateFirstName}
          errorMessage={firstNameValidated}
          dataTestID={UI_TEST_ID.firstNameInput}
          maxLength={INPUT_LIMIT.GENERIC_MAX}
          placeholder={t('FIRST_NAME_PLACEHOLDER')}
          restrictionRegex={inputRestrictionRegex.names}
          trackActivity={{
            trackId: 'register_first_name',
          }}
        />

        <TextInput
          value={lastName}
          onChange={handleLastName}
          label={t('INPUT_LABEL.SIGN_UP_LASTNAME')}
          validatorFunction={validateLastName}
          errorMessage={lastNameValidated}
          dataTestID={UI_TEST_ID.lastNameInput}
          maxLength={INPUT_LIMIT.GENERIC_MAX}
          placeholder={t('LAST_NAME_PLACEHOLDER')}
          restrictionRegex={inputRestrictionRegex.names}
          trackActivity={{
            trackId: 'register_last_name',
          }}
        />

        <TextInput
          value={userEmail}
          onChange={handleEmail}
          label={t('INPUT_LABEL.SIGN_IN_EMAIL')}
          validatorFunction={validateEmail}
          errorMessage={emailValidated}
          dataTestID={UI_TEST_ID.emailInput}
          inputMode={'email'}
          autoComplete={'on'}
          maxLength={INPUT_LIMIT.GENERIC_MAX}
          placeholder={t('EMAIL_PLACEHOLDER_TEXT')}
          restrictionRegex={inputRestrictionRegex.noWhiteSpace}
          trackActivity={{
            trackId: 'register_email',
          }}
        />

        {!hideAdditionalFields && <SelectSex sex={sex} setSex={setSex} />}

        {!hideAdditionalFields && (
          <div className={style['register__age-slider']}>
            <RangeSlider
              value={currentAge}
              onChange={setCurrentAge}
              steps={generateRange(
                supportedCountryParams?.minCurrentAge,
                supportedCountryParams?.maxCurrentAge
              )}
              label={t('INPUT_LABEL.CURRENT_AGE')}
            />
          </div>
        )}

        {!hideAdditionalFields && (
          <CountryDropdown
            value={country}
            onChange={handleCountry}
            label={t('INPUT_LABEL.COUNTRY')}
            className={style['register__country-dropdown']}
          />
        )}

        {!hideAdditionalFields && <div />}

        <TextInput
          value={referralCode}
          onChange={handleReferralCode}
          label={t('REFERRAL_LINK_OR_CODE')}
          labelInfoIcon
          tooltipText={t('TOOLTIP_MSG_REF_CODE')}
          optional
          validatorFunction={(referralCode) => {
            // We want to validate the referral code only, otherwise
            // when the user pastes the whole url, the validator function will
            // be validating the whole link instead only the referral code
            referralCode = excludeDomainFromUrl(referralCode)
            // Overrides the function to do optional validation
            validateReferralCode(referralCode, true)
          }}
          errorMessage={referralCodeValidated}
          restrictionRegex={inputRestrictionRegex.referralCodeFormat}
          trackActivity={{
            trackId: 'register_referral_code',
          }}
        />
      </InputGroup>

      {!hideDivider && <Divider className={style[`register__divider`]} />}
      <div className={style['register__checkboxes']}>
        <Checkbox
          checked={termsAndConditions}
          onChange={handlePolicyCheckbox}
          label={t('ONBOARDING.CHECKBOX_TEXT')}
          onClickLabel={() => setRenderTermsAndConditions(true)}
          labelClassName={style['register__terms']}
          dataTestIDLabel={UI_TEST_ID.termsLabel}
          trackActivity={{
            trackId: 'register_tac',
          }}
        />
        <Checkbox
          checked={emailUpdates}
          onChange={setEmailUpdates}
          label={t('EMAIL_UPDATES_LABEL')}
          optional
          trackActivity={{
            trackId: 'register_email_news',
          }}
        />
      </div>
      {signUpError && (
        <TextError
          errorText={t('ERROR_HARD_FAIL_SIGNUP')}
          className={style['register__error-msg']}
        />
      )}
      {forecastPageRegisterModal ? (
        <NavigationButtons
          hideBackButton={backButtonAction ? false : forecastPageRegisterModal}
          onClickFirst={() => backButtonAction?.()}
          disabledSecond={!validatedRegisterInfo()}
          onClickSecond={handleRegistration}
          secondButtonLoading={
            authState === 'REGISTERING_USER' ||
            authState === 'SENDING_NEW_TAB_EMAIL'
          }
          secondButtonLabel={registerButtonLabel}
          className={
            style[`register__modal-button${backButtonAction ? '--mt' : ''}`]
          }
          backButtonWhite={Boolean(backButtonAction)}
          showOnMobile={Boolean(backButtonAction)}
          trackActivityBackButton={{
            trackId: 'register_back',
          }}
          trackActivityActionButton={{
            trackId: 'register_submit_signup',
          }}
          dataTestIDSecondBtn={UI_TEST_ID.registerButton}
        />
      ) : (
        <NavigationButtons
          hideBackButton={forecastPageRegisterModal}
          className={style['register__nav-buttons']}
          hideActionButton
          backButtonWhite
          disabledFixedOnMobile
          onClickFirst={() => navigate(PUBLIC.HOME)}
          trackActivityBackButton={{
            trackId: 'register_back',
          }}
          customButton={
            <Button
              disabled={!validatedRegisterInfo()}
              variant="primary"
              onClick={handleRegistration}
              loading={
                authState === 'REGISTERING_USER' ||
                authState === 'SENDING_NEW_TAB_EMAIL'
              }
              trackActivity={{
                trackId: 'register_submit_signup',
              }}
            >
              {registerButtonLabel}
            </Button>
          }
        />
      )}

      <TermsAndConditions
        isOpen={renderTermsAndConditions}
        onClickCloseModalButton={() => setRenderTermsAndConditions(false)}
        signature={{
          checkboxChecked: termsAndConditions,
          agreementVersion: termsVersion,
        }}
        onSigned={(signature) => {
          setTermsAndConditions(signature?.checkboxChecked)
          setTermsVersion(signature?.agreementVersion)
        }}
      />
    </>
  )
}

SignUpFields.propTypes = {
  handleRegister: PropTypes.func,
  fieldsTitle: PropTypes.string,
  hideAdditionalFields: PropTypes.bool,
  registerButtonLabel: PropTypes.string,
  forecastPageRegisterModal: PropTypes.bool,
  signUpError: PropTypes.any,
  authState: PropTypes.string,
  hideDivider: PropTypes.bool,
  backButtonAction: PropTypes.func,
}

export default SignUpFields
