import TontineDashboardLayout from '../../components/layout/TontineDashboardLayout'
import RegisterForm from '../authentication/RegisterForm'
import TontinatorPage from '../mytt-dashboard/TontinatorPage'
import Modal from '../../components/overlay/Modal'

import { parseParamsForEmail } from '../../utils/TSUtilFunctions'
import PropTypes from 'prop-types'

/**
 * Renders a blurred Tontinator page
 */
const BluredForecast = ({ incomeForecastParams, isAuthenticated }) => {
  return (
    <>
      {!isAuthenticated && (
        <Modal isOpen backdrop>
          <RegisterForm
            forecastPageRegisterModal
            forecastUserData={parseParamsForEmail(incomeForecastParams)}
            registerResidency={incomeForecastParams?.countryOfResidence}
          />
        </Modal>
      )}
      <div
        style={{
          // Blurs the forecast page for external users
          filter: 'blur(5px)',
        }}
      >
        <TontineDashboardLayout>
          <TontinatorPage onboardingForecastParams={incomeForecastParams} />
        </TontineDashboardLayout>
      </div>
    </>
  )
}

BluredForecast.propTypes = {
  incomeForecastParams: PropTypes.object,
  isAuthenticated: PropTypes.bool,
}

export default BluredForecast
