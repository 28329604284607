import Icon from '../media-and-icons/Icon'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/components/Toggle.module.scss'
import ToggleButton from '../buttons/ToggleButton'
import { ToggleProps } from './InputTypes.type'
import { track } from '../../analytics/Analytics'
import { GraphEvent } from '../../analytics/EventData'

/**
 * Toggle with two variants, icon with on and off and button toggle. Default
 * variant is `icon`
 */
const Toggle = ({
  className,
  label,
  onChange,
  toggled,
  testID,
  variant,
  trackActivity,
}: ToggleProps & { variant?: 'button' }) => {
  const onClick = () => {
    onChange?.()

    const stringLabel = typeof label === 'string' ? label : ''

    void track({
      event: GraphEvent.option_choose,
      properties: {
        object_id: trackActivity?.trackId,
        description: trackActivity?.eventDescription,
        label: stringLabel,
        object_value: !toggled,
      },
    })
  }

  if (variant === 'button') {
    return (
      <ToggleButton
        label={label}
        onChange={onClick}
        toggled={toggled}
        testID={testID}
      />
    )
  }

  return (
    <article className={`${style.toggle} ${className}`}>
      <div
        className={`${style[`toggle__container${toggled ? '--on' : '--off'}`]}`}
        onClick={onClick}
        data-testid={testID}
      >
        <p className={style[`toggle__label`]}>{label}</p>
        <Icon
          className={style[`toggle__icon`]}
          fileName={toggled ? ASSET.iconmttoleon : ASSET.iconmttoleoff}
        />
      </div>
    </article>
  )
}

export default Toggle
