import Icon from '../media-and-icons/Icon'
import LottieAnimation from '../feedback/LottieAnimation'
import { ANIMATION } from '../../constants/Animations'
import { MouseEventHandler } from 'react'
import style from '../../scss/components/Button.module.scss'
import { ButtonVariant } from '../../types/Button.type'
import { track } from '../../analytics/Analytics'
import { ButtonEvent } from '../../analytics/EventData'
import { TrackActivity } from '../Common.types'

type ButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  children: JSX.Element | string
  trackActivity?: TrackActivity
  className?: string
  icon?: string
  /**
   * Might get deprecated
   */
  textOnLoading?: string
  loading?: boolean
  dataTestID?: string
  loadingAnimation?: object
  /**
   * Default value for variant is `primary`
   */
  variant?: ButtonVariant
}

/**
 * Generic button component that offers extended functionalities
 * like different button types, tracking and disabling the button when a promise
 * is pending and enabling the button when the promise has been resolved via
 * `loading` property
 */
const Button = ({
  onClick,
  disabled,
  children,
  className = '',
  icon,
  textOnLoading,
  loading,
  dataTestID,
  loadingAnimation,
  variant = 'primary',
  trackActivity,
}: ButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)

    const buttonLabel = typeof children === 'string' ? children : ''
    if (trackActivity) {
      void track({
        event: ButtonEvent.clicked,
        properties: {
          object_id: trackActivity?.trackId,
          description: trackActivity?.eventDescription,
          label: buttonLabel,
          // See what to do with value, it comes from other elements
          object_value: trackActivity?.value,
        },
      })
    }
  }

  return (
    <button
      className={`${style[`button${loading ? '--loading' : `--${variant}`}`]} ${className}`}
      disabled={disabled}
      onClick={handleClick}
      // eslint-disable-next-line react/no-unknown-property
      data-testid={dataTestID}
    >
      {loading ? (
        <LottieAnimation
          animationName={loadingAnimation ?? ANIMATION.loadingWhiteDots}
          className={style[`button__loading-animation`]}
          autoplay
          loop
        />
      ) : (
        <div className={style[`button__inner`]}>
          {!loading && icon && (
            <Icon fileName={icon} className={style['button__icon']} />
          )}
          <p className={style['button__text']}>
            {loading ? (textOnLoading ? textOnLoading : '') : children}
          </p>
        </div>
      )}
    </button>
  )
}

export default Button
