import SliderBox from './SliderBox'
import PropTypes from 'prop-types'
import Range from './Range'

/**
 * @param {string=} label Label for the slider
 * @param {number=} value Value of the slider
 * @param {function=} onChange Lifts the value to the parent component
 * @param {array=} steps Steps for the slider to use
 * @param {string=} className Class name for the parent container
 * @param {string=} prefix Prefix to the slider value
 * @param {boolean=} disabled Whether the slider is disabled
 *
 * @description Renders a slider box and a range input
 */
const RangeSlider = ({
  label,
  value,
  onChange,
  steps,
  className,
  formatter,
  prefix,
  disabled,
  disabledSliderTooltipText,
  disabledIncrement,
  disabledDecrement,
  variant,
  boxValueDataTestID,
  incrementButtonDataTestID,
  decrementButtonDataTestID,
  sliderTestID,
  trackActivity,
  trackRangeActivity,
  enabledSteps,
}) => {
  const sharedProps = {
    onChange: onChange,
    steps,
    title: label,
    value: value,
    className,
    formatter,
    prefix,
    disabled,
    disabledSliderTooltipText,
    disabledIncrement,
    disabledDecrement,
    variant,
  }
  return (
    <SliderBox
      {...sharedProps}
      boxValueDataTestID={boxValueDataTestID}
      incrementButtonDataTestID={incrementButtonDataTestID}
      decrementButtonDataTestID={decrementButtonDataTestID}
      sliderTestID={sliderTestID}
      trackActivity={trackActivity}
    >
      <Range
        {...sharedProps}
        trackActivity={trackRangeActivity}
        enabledSteps={enabledSteps}
      />
    </SliderBox>
  )
}

RangeSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  formatter: PropTypes.func,
  value: PropTypes.number,
  label: PropTypes.string,
  steps: PropTypes.array,
  className: PropTypes.string,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  disabledSliderTooltipText: PropTypes.string,
  disabledIncrement: PropTypes.bool,
  disabledDecrement: PropTypes.bool,
  variant: PropTypes.string,
  boxValueDataTestID: PropTypes.string,
  incrementButtonDataTestID: PropTypes.string,
  decrementButtonDataTestID: PropTypes.string,
  sliderTestID: PropTypes.string,
  trackActivity: PropTypes.object,
  trackRangeActivity: PropTypes.object,
  enabledSteps: PropTypes.array,
}

export default RangeSlider
