import PropTypes from 'prop-types'
import style from '../../../../scss/components/DateBox.module.scss'

/**
 * @param {number} dateNumber Year or a month as a number
 * @param {string} dateLabel Label displayed on top of the date value
 * @param {function} dateConvertFunction Converts a number to a month string if
 * passed in, otherwise it defaults using `dateNumber`
 * @param {string} locale Used by `dateConvertFunction` to convert a month to
 * locale string
 * @param {object} formatting Options for formatting dates
 * @param {string} className Name of the class targeting the main container
 *
 * @description Renders a date box with a label and a passed in date as a
 * number. Also supports passing in a formatter function to format the passed in date
 */
const DateBox = ({
  dateNumber,
  dateLabel,
  dateConvertFunction,
  locale,
  formatting,
  className,
}) => {
  return (
    <article className={`${style[`date-box`]} ${className}`}>
      <h2 className={style[`date-box__label`]}>{dateLabel}</h2>
      <p className={style[`date-box__main-content`]}>
        {dateConvertFunction
          ? dateConvertFunction(dateNumber, locale, formatting)
          : dateNumber}
      </p>
    </article>
  )
}

DateBox.propTypes = {
  dateNumber: PropTypes.number,
  dateLabel: PropTypes.string,
  dateConvertFunction: PropTypes.func,
  locale: PropTypes.string,
  formatting: PropTypes.string,
  className: PropTypes.string,
}

export default DateBox
