import { StrictMode, Suspense } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import '../web-build/scss/main.scss'
// Localization
import '../web-build/config/i18n'

import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import FeedbackCard from '../web-build/components/cards/FeedbackCard'
import NavBar from '../web-build/components/navigation/NavBar'
import InitializeUI from '../web-build/components/other/InitializeUI'
import SessionExtensionModal from '../web-build/components/overlay/SessionExtensionModal'
import { environment } from 'MyTontineConfig'
import { ENVIRONMENTS } from '../web-build/constants/ConstantValues'
import DevLogin from '../web-build/dev/DevLogin'
import MagicLogin from '../web-build/pages/authentication/MagicLogin'
import PageRoutes from '../web-build/routes/PageRoutes'
import { AccountServiceProvider } from '../web-build/state-management/authentication/AccountServiceProvider'
import { BankingServiceProvider } from '../web-build/state-management/banking/BankingServiceProvider'
import { LegalServiceProvider } from '../web-build/state-management/legal/LegalServiceProvider'
import SuspenseLoader from '../web-build/components/feedback/SuspenseLoader'
import { isLite } from '../web-build/config/lite'
import { useDeviceScreen } from '../web-build/hooks/useDeviceScreen'
import LiteAuthProvider from '../web-build/state-management/lite-auth/LiteAuthProvider'

/**
 * MyTontine app
 */
export default function App() {
  const { isMobileOrTablet } = useDeviceScreen()

  return (
    <StrictMode>
      <BrowserRouter>
        <AccountServiceProvider>
          <BankingServiceProvider>
            <LegalServiceProvider>
              <LiteAuthProvider>
                <Suspense fallback={<SuspenseLoader />}>
                  {!isLite && <MagicLogin />}
                  {!isLite && environment === ENVIRONMENTS?.development && (
                    <DevLogin />
                  )}
                  <InitializeUI>
                    <Suspense fallback={<SuspenseLoader />}>
                      {!isLite && <NavBar />}
                      {!isLite && <SessionExtensionModal />}
                      <PageRoutes />
                      <ToastContainer
                        position={isMobileOrTablet ? 'top-center' : 'top-right'}
                        autoClose={5_000}
                        theme="colored"
                      />
                      {!isLite && <FeedbackCard />}
                    </Suspense>
                  </InitializeUI>
                </Suspense>
              </LiteAuthProvider>
            </LegalServiceProvider>
          </BankingServiceProvider>
        </AccountServiceProvider>
      </BrowserRouter>
    </StrictMode>
  )
}
