import { useEffect, useRef, useState, useTransition } from 'react'
import { debounce } from '../utils/TSUtilFunctions'
import { useEventListener } from './useEventListener'

/**
 *
 * Controls graph resizing by listening for the window resize event. If a window
 * resize event happens the `draw` variables changes from false to true which
 * prompts D3 to redraw the graph  with new dimensions
 */
export const useGraphResize = () => {
  const [isPending, startTransition] = useTransition()
  const [draw, setDraw] = useState(true)
  const prevWidthRef = useRef(window.innerWidth) // Reference to store the previous window width

  //Renders an animation while the graph is being drawn in the background
  const handleResize = debounce(() => {
    //displays the SVG node when the graph has been redrawn
    startTransition(() => setDraw(true))
  }, 100)

  /**
   * Event handler for the `useEventListener` hook
   */
  const handleResizeEvent = () => {
    const currentWidth = window.innerWidth
    if (prevWidthRef.current !== currentWidth) {
      setDraw(false)
      handleResize()
      // Update the previous width after handling the resize
      prevWidthRef.current = currentWidth
    }
  }

  useEffect(() => {
    // Initialize the previous width reference on component mount
    prevWidthRef.current = window.innerWidth
  }, [])

  useEventListener({
    eventName: 'resize',
    handler: handleResizeEvent,
  })

  return draw && !isPending
}
