import PropTypes from 'prop-types'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { ACCOUNT_MENU, DASHBOARD_NAVIGATION, PRIVATE } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import MobileBarItem from './MobileBarItem'
import { ASSET } from '../../constants/Assets'
import { useLocation } from 'react-router-dom'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

/**
 * Mobile bar items that change styling depending on the
 * active route
 */
const MobileBarItems = ({
  completedKyc,
  isAuthenticated,
  className,
}: {
  completedKyc: boolean
  isAuthenticated: boolean
  className?: string
}) => {
  const t = useTranslate()
  const location = useLocation()
  const { isMobileOrTablet } = useDeviceScreen()

  const isDesktopAccountOverride = () =>
    !isMobileOrTablet && location.pathname.includes(PRIVATE.ACCOUNT)

  const isDesktopDashboardOverride = () =>
    !isMobileOrTablet && location.pathname.includes(PRIVATE.MYTT_DASHBOARD)

  return (
    <article className={className}>
      <MobileBarItem
        to={
          // On mobile this needs to lead to INDEX route
          isMobileOrTablet
            ? PRIVATE.MYTT_DASHBOARD
            : DASHBOARD_NAVIGATION.FUNDED_PROGRESS
        }
        buttonLabel={t('ACCOUNT.BUTTON_NAVIGATION_MYTONTINE')}
        iconName={ASSET.iconbottomnamt}
        activeIcon={ASSET.actieiconbottomnamt}
        dataTestID={UI_TEST_ID.rootTtDashboard}
        overrideActive={isDesktopDashboardOverride()}
      />

      {isAuthenticated && (
        <MobileBarItem
          //Opens personal details with Account navigation
          to={
            // On mobile this needs to lead to INDEX route
            isMobileOrTablet ? PRIVATE.ACCOUNT : ACCOUNT_MENU.PERSONAL_DETAILS
          }
          buttonLabel={t('ACCOUNT.BUTTON_NAV_2')}
          iconName={ASSET.iconbottomnaaccount}
          notification={!completedKyc}
          activeIcon={ASSET.actieiconbottomnaaccount}
          dataTestID={UI_TEST_ID.rootAccount}
          overrideActive={isDesktopAccountOverride()}
        />
      )}
    </article>
  )
}

MobileBarItems.propTypes = {
  completedKyc: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  className: PropTypes.string,
}

export default MobileBarItems
