import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/components/Card.module.scss'
import { CardBodyProps } from '../../types/Card.type'

/**
 * Renders the body section for the `Card` component.
 */
const CardBody = ({ title, subTitle, variant }: CardBodyProps) => (
  <article
    data-testid={UI_TEST_ID.cardBody}
    className={`${style[`card__body`]} ${style[`card__body${variant ? `--${variant}` : ''}`]}`}
  >
    {title && (
      <h3
        className={`${style[`card__title`]} ${style[`card__title${variant ? `--${variant}` : ''}`]}`}
      >
        {title}
      </h3>
    )}
    {subTitle && (
      <h4
        className={`${style[`card__subtitle`]} ${style[`card__subtitle${variant ? `--${variant}` : ''}`]}`}
      >
        {subTitle}
      </h4>
    )}
  </article>
)

export default CardBody
