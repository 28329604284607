import Button from '../buttons/Button'

type CalculatorButtonsProps = {
  firstButtonAction: () => void
  firstButtonLabel: string
  secondButtonAction: () => void
  secondButtonLabel: string
  secondButtonTestID: string
  firstButtonTestID: string
  className: string
  firstDisabled: boolean
  secondDisabled: boolean
}

/**
 * Renders two buttons with a container
 */
const CalculatorButtons = ({
  firstButtonAction,
  firstButtonLabel,
  secondButtonAction,
  secondButtonLabel,
  secondButtonTestID,
  firstButtonTestID,
  className,
  firstDisabled,
  secondDisabled,
}: CalculatorButtonsProps) => {
  return (
    <article className={`${className}`}>
      <Button
        disabled={secondDisabled}
        onClick={secondButtonAction}
        dataTestID={secondButtonTestID}
        variant="blue"
      >
        {secondButtonLabel}
      </Button>
      <Button
        onClick={firstButtonAction}
        disabled={firstDisabled}
        dataTestID={firstButtonTestID}
      >
        {firstButtonLabel}
      </Button>
    </article>
  )
}

export default CalculatorButtons
