import PropTypes from 'prop-types'
import { useState } from 'react'
import { CONSTANTS } from '../../../constants/ConstantValues'
import Divider from '../../data-display/Divider'
import { daysInMonth } from '../../../utils/UtilFunctions'
import Pickers from './modes/Pickers'
import PickerTopSection from './modes/PickerTopSection'
import {
  formatDateToDateString,
  isLeapYear,
} from '../../../utils/TSUtilFunctions'
import style from '../../../scss/components/DateInput.module.scss'

const { DECEMBER, JANUARY } = CONSTANTS

/**
 * @param {string} parentBlock CSS class of the parent component
 * @param {function} onChange Callback issued when the user changes the date
 * @param {string} dateStringISO Sets picker date values from ISO date string
 * @param {string} datePickerMode Default picker mode when the picker is open
 * @param {boolean} onlyMonths Renders only month selection with year
 * @param {string} locale Locale for date formatting
 *
 * @description Date picker rendered when the date input field is clicked, choosing a
 * day closes the picker or clicking anywhere outside the picker area
 */
const Picker = ({
  parentBlock,
  onChange,
  value,
  defaultPickerMode,
  onlyMonths,
  locale,
  disabledMonthsFrom,
  disabledMonthsTo,
  disabledYearFrom,
  disabledYearTo,
  disabledYearNext,
  disabledMonthNext,
  disabledYearBack,
  disabledMonthBack,
  disabledDayFrom,
  disabledDayTo,
  pickerMonthFormat,
}) => {
  //States
  const [pickerMode, setPickerMode] = useState(defaultPickerMode)

  /**
   * Updates the day value for the passed in `onChange` function and generates a
   * new `dateStringISO`
   */
  const setDay = (day) => {
    onChange((prev) => {
      const year = prev.year || value?.year
      const month = prev.month || value?.month
      return {
        ...prev,
        ...parsePickerData(year, month, day),
      }
    })
  }

  /**
   * Updates the month value for the passed in `onChange` function and generates a
   * new `dateStringISO`
   */
  const setMonth = (month) => {
    onChange((prev) => {
      const year = prev.year || value?.year
      const day = prev.day || value?.day
      return {
        ...prev,
        ...parsePickerData(year, month, day),
      }
    })
  }

  /**
   * Updates the year value for the passed in `onChange` function and generates a
   * new `dateStringISO`
   */
  const setYear = (year) => {
    onChange((prev) => {
      const month = prev.month || value?.month
      const day = prev.day || value?.day

      return {
        ...prev,
        ...parsePickerData(
          year,
          month,
          adjustDayFromLeapToNoneLeapYear(year, month, day)
        ),
      }
    })
  }

  /**
   * Makes sure the last day of the month is selected for the user no matter how
   * many days does a month have, covers edge cases if February 28th is selected
   * and the user selects a month with 30 or 31 days
   */
  const adjustLastDayOfTheMonth = (selectedMonth) => {
    const prevMonthDays = daysInMonth(value?.year, value?.month - 1)
    const currentMonthDays = daysInMonth(value?.year, selectedMonth)
    const nextMonthDays = daysInMonth(value?.year, value?.month + 1)

    if (currentMonthDays <= nextMonthDays) {
      setDay(currentMonthDays)
    }
    if (currentMonthDays >= prevMonthDays) {
      setDay(currentMonthDays)
    }
  }

  /**
   * Detects if the selected day is last day of the month, so in case the month
   * is switched the last day of the month will still be selected no matter how
   * many days does a month have
   */
  const detectAndAdjustLastDayOfTheMonth = (selectedMonth) => {
    //Only adjust if the selected day is also last day of the month
    if (value?.day === daysInMonth(value?.year, value?.month)) {
      adjustLastDayOfTheMonth(selectedMonth)
    }
  }

  /**
   * Sets selected month and adjusts the last day of the month, so if a user
   * switches from a 31 day month to a 30 day month, the last day of the month
   * will still be selected
   */
  const setMonthAndAdjustDay = (selectedMonth) => {
    detectAndAdjustLastDayOfTheMonth(selectedMonth)
    setMonth(selectedMonth)
  }

  /**
   * Keeps the previous month's value from going below one, if the previous month goes
   * below one that means the user has navigated back to the previous year and
   * the month value is set to 12 ( December )
   */
  const adjustPreviousMonth = (currentMonth) => {
    //Makes sure not to decrease current month's value below 1
    if (currentMonth > JANUARY) {
      detectAndAdjustLastDayOfTheMonth(currentMonth - 1)
      return currentMonth - 1
    }
    //Current month value is below 1 then navigate to previous year
    previousYear()
    // And set the month to 12 ( December )
    return DECEMBER
  }

  /**
   * Keeps the previous month's value from going above 12, if the previous month
   * goes above 12 that means the user has navigated to the next year and the
   * month value is set to 1 ( January )
   */
  const adjustNextMonth = (currentMonth) => {
    //Makes sure not to increase current month's value above 12
    if (currentMonth < DECEMBER) {
      detectAndAdjustLastDayOfTheMonth(currentMonth + 1)
      return currentMonth + 1
    }
    //Current month value is above 12 then navigate to next year
    nextYear()
    // And set the month to 1 ( January )
    return JANUARY
  }

  const adjustForNextYear = () => {
    //ADJUST MONTH SO IT DOES NOT GO OUT OF DISABLED BOUNDS
    if (
      disabledYearFrom - 1 === value?.year &&
      value?.month >= disabledMonthsFrom
    ) {
      setMonth(disabledMonthsFrom - 1)
    }

    return value?.year + 1
  }

  //Month control functions
  const previousMonth = () => setMonth(adjustPreviousMonth(value?.month))
  const nextMonth = () => setMonth(adjustNextMonth(value?.month))

  //Year control functions
  const previousYear = () => setYear(value?.year - 1)
  const nextYear = () => setYear(adjustForNextYear())

  return (
    <main className={style[`date-picker__picker`]}>
      <PickerTopSection
        locale={locale}
        parentBlock={parentBlock}
        date={{ year: value?.year, month: value?.month, day: value?.day }}
        nextMonth={nextMonth}
        previousMonth={previousMonth}
        nextYear={nextYear}
        previousYear={previousYear}
        setPickerMode={setPickerMode}
        disabledYearNext={disabledYearNext}
        disabledMonthNext={disabledMonthNext}
        disabledYearBack={disabledYearBack}
        disabledMonthBack={disabledMonthBack}
        pickerMonthFormat={pickerMonthFormat}
      />
      <Divider />
      <section className={style[`date-picker__middle-section`]}>
        <Pickers
          nextMonth={nextMonth}
          previousMonth={previousMonth}
          mode={pickerMode}
          setMode={setPickerMode}
          date={{ year: value?.year, month: value?.month, day: value?.day }}
          setYear={setYear}
          setMonth={setMonthAndAdjustDay}
          setDay={setDay}
          onlyMonths={onlyMonths}
          disabledMonthsFrom={disabledMonthsFrom}
          disabledMonthsTo={disabledMonthsTo}
          disabledYearFrom={disabledYearFrom}
          disabledYearTo={disabledYearTo}
          disabledDayFrom={disabledDayFrom}
          disabledDayTo={disabledDayTo}
        />
      </section>
    </main>
  )
}

/**
 * Parses the data from the picker into date value format
 */
const parsePickerData = (year, month, day) => {
  return {
    year,
    month,
    day,
    dateStringISO: formatDateToDateString({
      year,
      month,
      day,
    }),
  }
}

/**
 * Adjust the day in case user has 29th of Feb selected and a Leap year is
 * selected and they switch to a none leap year
 */
const adjustDayFromLeapToNoneLeapYear = (year, month, day) =>
  day === 29 && month === 2 && !isLeapYear(year) ? 28 : day

Picker.propTypes = {
  parentBlock: PropTypes.string.isRequired,
  value: PropTypes.any,
  defaultPickerMode: PropTypes.string,
  onChange: PropTypes.func,
  onlyMonths: PropTypes.bool,
  locale: PropTypes.string,
  disabledMonthsFrom: PropTypes.number,
  disabledMonthsTo: PropTypes.number,
  disabledYearFrom: PropTypes.number,
  disabledYearTo: PropTypes.number,
  disabledYearNext: PropTypes.bool,
  disabledMonthNext: PropTypes.bool,
  disabledYearBack: PropTypes.bool,
  disabledMonthBack: PropTypes.bool,
  disabledDayFrom: PropTypes.number,
  disabledDayTo: PropTypes.number,
  pickerMonthFormat: PropTypes.string,
}

export default Picker
