import {
  AgreementData,
  DynamicFormData,
  LegalMachineContext,
  LegalMachineEvent,
} from './LegalMachineTypes.types'

/**
 * Stores agreements in context on initial fetch
 */
const storeAgreements = {
  agreement: ({
    context,
    event,
  }: {
    context: LegalMachineContext
    event: LegalMachineEvent
  }) => {
    return { ...context?.agreement, ...event?.output } as AgreementData
  },
}

/**
 * Stores form data in context on initial fetch
 */
const storeForm = {
  form: ({ event }: { event: LegalMachineEvent }) => {
    return event?.output as DynamicFormData
  },
}

/**
 * Updates the context when a form is submitted
 */
const updateFormSubmitted = {
  form: ({
    context,
    event,
  }: {
    context: LegalMachineContext
    event: LegalMachineEvent
  }) => {
    if (context.form) {
      const formType = event?.output?.formType
      // Updates the form type to be submitted
      if (formType) {
        context.form[formType].submitted = true
      } else {
        console.warn(
          `Could not find the form type from event, got >>${event?.output?.formType}<< 
          is the form type present in form context?`
        )
      }
    }

    return context.form
  },
}

/**
 * Updates an agreement when a user agrees by adding a timestamp ISO to the
 * `userSigned` field
 */
const updateAgreement = {
  agreement: ({
    context,
    event,
  }: {
    context: LegalMachineContext
    event: LegalMachineEvent
  }) => {
    if (context?.agreement && event?.output?.agreementType) {
      // Time stamp when the agreement was signed
      // There will be a slight mismatch in mere seconds in case the user
      // refreshes the app and re-fetches the agreement but this is not na issue
      context.agreement[event?.output?.agreementType].userAgreed =
        new Date().toISOString()
    } else {
      console.warn(
        'Did not receive agreement type to update, returning original agreement context'
      )
    }

    return context.agreement
  },
}

/**
 * Updates the context form context by attaching a pdf to the form type
 */
const updateFormToPdf = {
  form: ({
    context,
    event,
  }: {
    context: LegalMachineContext
    event: LegalMachineEvent
  }) => {
    if (context.form) {
      const formType = event?.output?.formType
      const pdfBase64 = event?.output?.pdfBase64

      // Updates the form type to be submitted
      if (formType && pdfBase64) {
        context.form[formType] = {
          ...context.form[formType],
          pdfBase64,
        }
      } else {
        console.warn(
          `Did not get formType or pdfBase64 to update context. Returning original context`
        )
      }
    }

    return context.form
  },
}

export {
  storeAgreements,
  updateFormSubmitted,
  storeForm,
  updateAgreement,
  updateFormToPdf,
}
