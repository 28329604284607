import { assign, raise, setup } from 'xstate'
import {
  addUnverifiedPhoneNumber,
  authTokenService,
  cancelAccountClosing,
  changeCurrentPin,
  closeUserAccount,
  createNewPin,
  createReferralCode,
  deletePayoutDetails,
  devLogin,
  extendSession,
  fetchUserDetails,
  logout,
  redeemMagicToken,
  registerUser,
  resetPin,
  sendMagicLoginEmail,
  sendPinResetEmail,
  updatePayoutDetails,
  updateUserAccountInfo,
  verifyPhoneNumber,
  startFaceScan,
  refreshSession,
  resendVerificationEmailForLite,
  getReferralStats,
  liteAuth,
  updateLitePensionPlan,
} from './AuthMachineServices'
import {
  clearAuthContext,
  storeAuthData,
  storePayoutDetails,
  wipePayoutDetails,
  storeAuthTokenFromEvent,
  updateUserDetailsFromEvent,
  storeSessionAboutToExpire,
  storeExpiredSessionData,
  updateLiteDetails,
  clearLiteData,
} from './AuthMachineActions'
import {
  isAuthenticated,
  hasRefreshToken,
  isNotLiteBuild,
  shouldStartVerifying,
} from './AuthMachineGuards'
import {
  AuthMachineContext,
  AuthMachineEvent,
  UserDetails,
} from './AuthMachineTypes.type'
import { promiseFuncToPromiseActor, promiseActorInput } from '../StateUtils'
import { isLite } from '../../config/lite'

/*
General rules and good practices 
- Actions are FIRE and FORGET and should be used like that
- Assign is ASYNC, keep this in mind
- Invoking a service with timeout,interval and API call ALWAYS needs to have a
  clean up function
- All invoked services need to contain a unique ID
*/

//Initial context values
const initialContextValues: AuthMachineContext = {
  authToken: undefined,
  sessionExpired: undefined,
  error: undefined,
  remainingTime: undefined,
  // Needs to be an empty object to prevent destruction errors
  user_details: {} as UserDetails,
  permissions: undefined,
  extendedSessionTimes: 0,
  liteData: undefined,
  returns: undefined,
}

export const authMachine = setup({
  types: {
    context: {} as AuthMachineContext,
    events: {} as AuthMachineEvent,
  },
  actors: {
    sendMagicLoginEmail: promiseFuncToPromiseActor(sendMagicLoginEmail),
    addUnverifiedPhoneNumber: promiseFuncToPromiseActor(
      addUnverifiedPhoneNumber
    ),
    authTokenService: promiseFuncToPromiseActor(authTokenService),
    cancelAccountClosing: promiseFuncToPromiseActor(cancelAccountClosing),
    changeCurrentPin: promiseFuncToPromiseActor(changeCurrentPin),
    closeUserAccount: promiseFuncToPromiseActor(closeUserAccount),
    createNewPin: promiseFuncToPromiseActor(createNewPin),
    createReferralCode: promiseFuncToPromiseActor(createReferralCode),
    deletePayoutDetails: promiseFuncToPromiseActor(deletePayoutDetails),
    devLogin: promiseFuncToPromiseActor(devLogin),
    extendSession: promiseFuncToPromiseActor(extendSession),
    fetchUserDetails: promiseFuncToPromiseActor(fetchUserDetails),
    logout: promiseFuncToPromiseActor(logout),
    redeemMagicToken: promiseFuncToPromiseActor(redeemMagicToken),
    registerUser: promiseFuncToPromiseActor(registerUser),
    resetPin: promiseFuncToPromiseActor(resetPin),
    sendPinResetEmail: promiseFuncToPromiseActor(sendPinResetEmail),
    updatePayoutDetails: promiseFuncToPromiseActor(updatePayoutDetails),
    updateUserAccountInfo: promiseFuncToPromiseActor(updateUserAccountInfo),
    verifyPhoneNumber: promiseFuncToPromiseActor(verifyPhoneNumber),
    startFaceScan: promiseFuncToPromiseActor(startFaceScan),
    refreshSession: promiseFuncToPromiseActor(refreshSession),
    resendVerificationEmailForLite: promiseFuncToPromiseActor(
      resendVerificationEmailForLite
    ),
    getReferralStats: promiseFuncToPromiseActor(getReferralStats),
    verifyEmailAndGetData: promiseFuncToPromiseActor(liteAuth),
    updateLitePensionPlan: promiseFuncToPromiseActor(updateLitePensionPlan),
  },
  actions: {
    clearAuthContext: assign(clearAuthContext),
    storeAuthData: assign(storeAuthData),
    storePayoutDetails: assign(storePayoutDetails),
    wipePayoutDetails: assign(wipePayoutDetails),
    storeAuthTokenFromEvent: assign(storeAuthTokenFromEvent),
    updateUserDetailsFromEvent: assign(updateUserDetailsFromEvent),
    storeExpiredSessionData: assign(storeExpiredSessionData),
    storeSessionAboutToExpire: assign(storeSessionAboutToExpire),
    // Needs to have an delay in order to prevent double refresh in dev environment
    refreshSessionEvent: raise({ type: 'REFRESH_SESSION' }, { delay: 10 }),
    updateLiteDetails: assign(updateLiteDetails),
    clearLiteData: assign(clearLiteData),
  },
  guards: {
    /**
     * Sanity check if all the necessary info is present before transitioning
     * to a new state
     */
    isAuthenticated,
    hasRefreshToken,
    isNotLiteBuild,
    shouldStartVerifying,
  },
}).createMachine({
  id: 'AuthMachine',
  context: initialContextValues,
  initial: 'NO_AUTH_TOKEN',
  entry: [
    {
      type: 'refreshSessionEvent',
    },
  ],
  states: {
    NO_AUTH_TOKEN: {
      on: {
        SEND_NEW_TAB_EMAIL: {
          target: 'SENDING_NEW_TAB_EMAIL',
        },
        REDEEM_MAGIC_TOKEN: {
          target: 'REDEEMING_MAGIC_TOKEN',
        },
        DEV_LOGIN: {
          target: 'DEV_LOGGING_IN',
        },
        REGISTER_USER: {
          target: 'REGISTERING_USER',
        },
        RESET_PIN: {
          target: 'RESETTING_PIN',
        },
        START_FACE_SCAN: {
          target: 'ANON_FACE_SCAN_IN_PROGRESS',
        },
        CLOSE_SUCCESS_MODAL: {
          target: 'NO_AUTH_TOKEN',
        },
        MODIFY_EXPIRED_SESSION_DATA: {
          actions: ['storeExpiredSessionData'],
        },
        REFRESH_SESSION: {
          target: 'REFRESHING_SESSION',
          // Can only be issued if there is a refresh token
          // present in session storage
          guard: { type: 'hasRefreshToken' },
        },
        VERIFY_EMAIL_MTL: {
          target: 'VERIFYING_EMAIL_MTL',
          guard: { type: 'shouldStartVerifying' },
        },
        TERMINATE_MTL_SESSION: {
          target: 'TERMINATING_MTL_SESSION',
        },
        UPDATE_MTL_DRAFT_PLAN: {
          target: 'UPDATING_MTL_PENSION_PLAN',
        },
      },
      description:
        'There is no `auth_token` in context and it should **NOT** be there in this state',
    },

    UPDATING_MTL_PENSION_PLAN: {
      invoke: {
        id: 'updateLitePensionPlanID',
        src: 'updateLitePensionPlan',
        input: promiseActorInput,
        onDone: {
          target: 'NO_AUTH_TOKEN',
          actions: { type: 'updateLiteDetails' },
        },
        onError: {
          target: 'NO_AUTH_TOKEN',
        },
      },
    },

    // MTL ONLY
    VERIFYING_EMAIL_MTL: {
      invoke: {
        id: 'verifyEmailAndGetDataID',
        src: 'verifyEmailAndGetData',
        input: promiseActorInput,
        onDone: {
          // MTL does not DO AUTH!
          target: 'NO_AUTH_TOKEN',
          actions: { type: 'updateLiteDetails' },
        },
        onError: {
          target: 'NO_AUTH_TOKEN',
          actions: {
            type: 'updateLiteDetails',
          },
        },
      },
    },

    TERMINATING_MTL_SESSION: {
      // MTL does not DO AUTH!
      always: 'NO_AUTH_TOKEN',
      entry: { type: 'clearLiteData' },
    },

    //MTL ONLY ends
    REFRESHING_SESSION: {
      invoke: {
        id: 'refreshSessionID',
        src: 'refreshSession',
        input: promiseActorInput,
        onDone: {
          target: 'AUTH_TOKEN',
          guard: { type: 'isAuthenticated' },
          actions: ['storeAuthData'],
        },
        onError: {
          target: 'NO_AUTH_TOKEN',
          actions: ['clearAuthContext', 'storeExpiredSessionData'],
        },
      },
    },

    DEV_LOGGING_IN: {
      invoke: {
        id: 'devUserLoginID',
        src: 'devLogin',
        input: promiseActorInput,
        onDone: {
          target: 'AUTH_TOKEN',
          guard: { type: 'isAuthenticated' },
          actions: ['storeAuthData'],
        },
        onError: {
          target: 'NO_AUTH_TOKEN',
        },
      },
      description: `Only for developers! 
        Simulates organic magic login`,
    },

    RESETTING_PIN: {
      invoke: {
        id: 'resetPinID',
        src: 'resetPin',
        input: promiseActorInput,
        onDone: {
          target: 'NO_AUTH_TOKEN',
        },
        onError: {
          target: 'NO_AUTH_TOKEN',
        },
      },
      description: `Used for resetting a user's 
      PIN with a pin reset token`,
    },

    REGISTERING_USER: {
      invoke: {
        id: 'registerUserID',
        src: 'registerUser',
        input: promiseActorInput,
        onDone: [
          {
            target: 'SENDING_NEW_TAB_EMAIL',
            guard: { type: 'isNotLiteBuild' },
          },
          {
            target: 'SENT_NEW_TAB_EMAIL',
          },
        ],
        onError: {
          // Goes back to no auth state to allow the user to retry again
          target: 'NO_AUTH_TOKEN',
        },
      },
      description: 'Registers a user to the app',
    },

    READY_RESEND_EMAIL: {
      on: {
        SEND_NEW_TAB_EMAIL: {
          target: 'SENDING_NEW_TAB_EMAIL',
        },

        CLOSE_SUCCESS_MODAL: {
          target: 'NO_AUTH_TOKEN',
        },
      },
      description: `After a login email has been sent
        this state is used to re-send the same email`,
    },

    SENDING_NEW_TAB_EMAIL: {
      invoke: {
        id: 'sendMagicEmailNewTabServiceID',
        src: !isLite ? 'sendMagicLoginEmail' : 'resendVerificationEmailForLite',

        input: promiseActorInput,
        onDone: {
          target: 'SENT_NEW_TAB_EMAIL',
        },
        onError: {
          target: 'NO_AUTH_TOKEN',
        },
      },
      description: `Sends a login email to the user, the email can contain 
        forecast params if the user signs up via "HOW IT WORKS" flow`,
    },

    REDEEMING_MAGIC_TOKEN: {
      invoke: {
        id: 'redeemMagicTokenId',
        src: 'redeemMagicToken',
        input: promiseActorInput,
        onDone: {
          target: 'AUTH_TOKEN',
          //Transition to AUTH_TOKEN can ONLY happen if there is an
          //auth_token, permissions and user_account_info
          guard: { type: 'isAuthenticated' },
          actions: ['storeAuthData'],
        },
        onError: {
          target: 'NO_AUTH_TOKEN',
        },
      },
      description: `Redeems a magic token from UAS and in response gets
        an auth_token, permissions and user_account_info
        `,
    },

    ANON_FACE_SCAN_IN_PROGRESS: {
      invoke: {
        id: 'startFaceScanAnonID',
        src: 'startFaceScan',
        input: promiseActorInput,
        onDone: {
          target: 'AUTH_TOKEN',
          actions: ['storeAuthData'],
          guard: { type: 'isAuthenticated' },
        },
        onError: {
          target: 'NO_AUTH_TOKEN',
        },
      },
    },

    AUTH_FACE_SCAN_IN_PROGRESS: {
      invoke: {
        id: 'startFaceScanAuthID',
        src: 'startFaceScan',
        input: promiseActorInput,
        onDone: {
          target: 'AUTH_TOKEN',
          actions: ['storeAuthData'],
          guard: { type: 'isAuthenticated' },
        },
        // If an error has occurred no new auth token can be obtained, then go
        // back to AUTH_TOKEN state and use the auth_token that is already
        // there, in order for the session not to drop
        onError: {
          target: 'AUTH_TOKEN',
        },
      },
      on: {
        // Updates the context with a new auth token
        FACE_ENROLL_COMPLETED: {
          actions: ['storeAuthData'],
        },
      },
    },

    AUTH_TOKEN: {
      type: 'parallel',
      invoke: {
        id: 'authTokenStateServiceId',
        src: 'authTokenService',
        input: promiseActorInput,
      },
      //Main events
      on: {
        EXPIRED_SESSION: {
          target: 'NO_AUTH_TOKEN',
          //No need to call logout, server will terminate the session by
          //default since it has expired
          actions: ['clearAuthContext', 'storeExpiredSessionData'],
        },

        DELETE_AUTH_TOKEN: {
          target: 'DELETING_AUTH_TOKEN',
        },

        FETCH_USER_ACCOUNT: {
          target: 'FETCHING_USER_ACCOUNT',
        },

        EXTEND_SESSION: {
          target: 'EXTENDING_SESSION',
        },

        START_FACE_SCAN: {
          target: 'AUTH_FACE_SCAN_IN_PROGRESS',
        },
      },

      states: {
        AUTHENTICATED: {
          initial: 'IDLE',

          states: {
            IDLE: {
              on: {
                DELETE_PAYOUT_DETAILS: {
                  target: 'DELETING_PAYOUT_DETAILS',
                },
                UPDATE_PAYOUT_DETAILS: {
                  target: 'UPDATING_PAYOUT_DETAILS',
                },
                UPDATE_ACCOUNT_INFO: {
                  target: 'UPDATING_USER_ACCOUNT_INFO',
                },
                CREATE_NEW_PIN: {
                  target: 'CREATING_NEW_PIN',
                },
                CHANGE_CURRENT_PIN: {
                  target: 'CHANGING_CURRENT_PIN',
                },
                SEND_PIN_RESET_EMAIL: {
                  target: 'SENDING_PIN_RESET_EMAIL',
                },
                ADD_UNVERIFIED_PHONE: {
                  target: 'ADDING_UNVERIFIED_PHONE',
                },
                VERIFY_PHONE_NUMBER: {
                  target: 'VERIFYING_PHONE_NUMBER',
                },
                CLOSE_USER_ACCOUNT: {
                  target: 'CLOSING_USER_ACCOUNT',
                },
                CANCEL_CLOSING_ACCOUNT: {
                  target: 'CANCELLING_CLOSE_ACCOUNT',
                },
                CREATE_REFERRAL_CODE: {
                  target: 'CREATING_REFERRAL_CODE',
                },
                SESSION_ABOUT_TO_EXPIRE: {
                  actions: ['storeSessionAboutToExpire'],
                },
                GET_REFERRAL_STATS: {
                  target: 'FETCHING_REFERRAL_STATS',
                },
              },
            },

            FETCHING_REFERRAL_STATS: {
              invoke: {
                src: 'getReferralStats',
                id: 'getReferralStatsID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['updateUserDetailsFromEvent'],
                },
              },
            },

            DELETING_PAYOUT_DETAILS: {
              invoke: {
                src: 'deletePayoutDetails',
                id: 'deletePayoutDetailsID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['wipePayoutDetails'],
                },
              },
              description: 'Deletes user payout details',
            },

            UPDATING_PAYOUT_DETAILS: {
              invoke: {
                src: 'updatePayoutDetails',
                id: 'updatePayoutDetailsID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['storePayoutDetails'],
                },
              },
              description: 'Updates/adds user payout account details',
            },

            UPDATING_USER_ACCOUNT_INFO: {
              invoke: {
                src: 'updateUserAccountInfo',
                id: 'updateUserAccountInfoID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['updateUserDetailsFromEvent'],
                },
              },
              description: `Updates user's unverified account information`,
            },

            CREATING_NEW_PIN: {
              invoke: {
                src: 'createNewPin',
                id: 'createNewPinID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['updateUserDetailsFromEvent'],
                },
              },
            },

            CHANGING_CURRENT_PIN: {
              invoke: {
                src: 'changeCurrentPin',
                id: 'changeCurrentPinID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                },
              },
            },

            SENDING_PIN_RESET_EMAIL: {
              invoke: {
                src: 'sendPinResetEmail',
                id: 'sendPinResetEmailID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                },
              },
            },

            ADDING_UNVERIFIED_PHONE: {
              invoke: {
                src: 'addUnverifiedPhoneNumber',
                id: 'addUnverifiedPhoneNumberID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                },
              },
            },

            VERIFYING_PHONE_NUMBER: {
              invoke: {
                src: 'verifyPhoneNumber',
                id: 'verifyPhoneNumberID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['updateUserDetailsFromEvent'],
                },
              },
            },

            CLOSING_USER_ACCOUNT: {
              invoke: {
                src: 'closeUserAccount',
                id: 'closeUserAccountID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['updateUserDetailsFromEvent'],
                },
              },
            },

            CANCELLING_CLOSE_ACCOUNT: {
              invoke: {
                src: 'cancelAccountClosing',
                id: 'cancelAccountClosingID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['updateUserDetailsFromEvent'],
                },
              },
            },

            CREATING_REFERRAL_CODE: {
              invoke: {
                src: 'createReferralCode',
                id: 'createReferralCodeID',
                input: promiseActorInput,
                onError: {
                  target: 'IDLE',
                },
                onDone: {
                  target: 'IDLE',
                  actions: ['updateUserDetailsFromEvent'],
                },
              },
              description: `Edits user's referral code`,
            },
          },
        },
      },

      description: `Checks for session and opens a web socket
        connection with the UAS`,
    },

    FETCHING_USER_ACCOUNT: {
      invoke: {
        src: 'fetchUserDetails',
        id: 'fetchUserDetailsID',
        input: promiseActorInput,
        onError: {
          target: 'AUTH_TOKEN',
        },
        onDone: {
          target: 'AUTH_TOKEN',
          actions: ['updateUserDetailsFromEvent'],
        },
      },
      description: `Fetches fresh user account info from the backend`,
    },

    EXTENDING_SESSION: {
      invoke: {
        src: 'extendSession',
        id: 'extendSessionID',
        input: promiseActorInput,
        onError: {
          target: 'AUTH_TOKEN',
        },
        onDone: {
          target: 'AUTH_TOKEN',
          actions: ['storeAuthTokenFromEvent', 'storeSessionAboutToExpire'],
        },
      },
      description: `Extends the user's current session`,
    },

    DELETING_AUTH_TOKEN: {
      invoke: {
        id: 'logoutService',
        src: 'logout',
        input: promiseActorInput,
        onDone: {
          target: 'NO_AUTH_TOKEN',
          actions: ['clearAuthContext'],
        },
        onError: {
          //Log the user out anyway
          target: 'NO_AUTH_TOKEN',
          actions: ['clearAuthContext'],
        },
      },
      description: `Logs the user out of the app, and issues a delete
        request to the server to delete current auth token`,
    },

    SENT_NEW_TAB_EMAIL: {
      after: {
        //Check CONSTANTS.RESEND_EMAIL_TIMER_MILLISECONDS
        30_000: {
          target: 'READY_RESEND_EMAIL',
        },
      },
      on: {
        CLOSE_SUCCESS_MODAL: {
          target: 'NO_AUTH_TOKEN',
        },
      },
    },
  },
})
