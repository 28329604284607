import PropTypes from 'prop-types'
import { useTranslate } from '../../hooks/useTranslate'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import Button from '../../components/buttons/Button'
import { CONSTANTS } from '../../constants/ConstantValues'
import { PUBLIC } from '../../routes/Route'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { ASSET } from '../../constants/Assets'
import ConfirmationModal from '../overlay/ConfirmationModal'
import { useState } from 'react'

/**
 * Special buttons derived from `<Button />`, that serve only for
 * authentication purposes.
 */
const AuthButton = ({ type, className }) => {
  const t = useTranslate()
  const { send } = useAccountService()
  const navigate = useCustomNavigation()
  const [isOpen, setIsOpen] = useState(false)

  if (type === CONSTANTS.AUTH_BUTTON_TYPE_LOGIN) {
    const handleClick = () => navigate(PUBLIC.SIGN_IN)

    return (
      <Button variant="alternative" onClick={handleClick} className={className}>
        {t('BUTTON_LABEL.SIGN_IN')}
      </Button>
    )
  }

  if (type === CONSTANTS.AUTH_BUTTON_TYPE_REGISTER) {
    const handleClick = () => navigate(PUBLIC.SIGN_UP)

    return (
      <Button variant="primary" onClick={handleClick} className={className}>
        {t('BUTTON_LABEL.SIGN_UP')}
      </Button>
    )
  }

  if (type === CONSTANTS.AUTH_BUTTON_TYPE_LOGOUT) {
    const handleClick = () => {
      //Opens logout confirmation modal
      setIsOpen(true)
    }

    return (
      <>
        <ConfirmationModal
          isOpen={isOpen}
          title={t('LOGOUT_MODAL_TITLE')}
          content={t('LOGOUT_MODAL_CONTENT')}
          firstButtonAction={() =>
            send({
              type: 'DELETE_AUTH_TOKEN',
            })
          }
          firstButtonLabel={t('LOGOUT_MODAL.CONFIRM_BUTTON')}
          secondButtonLabel={t('CONFIRMATION_MODAL_BUTTONS.CANCEL')}
          secondButtonAction={() => setIsOpen(false)}
          confirmButtonTestID={UI_TEST_ID.modalLogoutButton}
        />
        <Button variant="secondary" onClick={handleClick} className={className}>
          {t('BUTTON_LABEL.LOGOUT')}
        </Button>
      </>
    )
  }

  if (type === CONSTANTS.AUTH_BUTTON_TYPE_LOGOUT_MOBILE) {
    const handleClick = () => {
      //Opens logout confirmation modal
      setIsOpen(true)
    }

    return (
      <>
        <ConfirmationModal
          isOpen={isOpen}
          title={t('LOGOUT_MODAL_TITLE')}
          content={t('LOGOUT_MODAL_CONTENT')}
          firstButtonAction={() =>
            send({
              type: 'DELETE_AUTH_TOKEN',
            })
          }
          firstButtonLabel={t('LOGOUT_MODAL.CONFIRM_BUTTON')}
          secondButtonLabel={t('CONFIRMATION_MODAL_BUTTONS.CANCEL')}
          secondButtonAction={() => setIsOpen(false)}
          confirmButtonTestID={UI_TEST_ID.modalLogoutButton}
        />
        <Button
          variant="logout"
          onClick={handleClick}
          className={className}
          icon={ASSET.iconaccountmenuloouticon}
          dataTestID={UI_TEST_ID.logoutButtonDesktop}
        >
          {t('BUTTON_LABEL.LOGOUT')}
        </Button>
      </>
    )
  }
}

AuthButton.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default AuthButton
