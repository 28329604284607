import Icon from '../media-and-icons/Icon'
import style from '../../scss/components/Card.module.scss'
import { CardArrowAndAlertProps, CardVariantType } from '../../types/Card.type'
import { ASSET } from '../../constants/Assets'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/**
 * CardArrow component renders an arrow icon with various styles based on the provided props.
 */
const CardArrow = ({
  variant,
  rotateArrow,
  arrowInvisible,
}: {
  variant?: CardVariantType
  rotateArrow?: CardArrowAndAlertProps['rotateArrow']
  arrowInvisible?: CardArrowAndAlertProps['arrowInvisible']
}) => {
  const arrowClasses = [
    style[`card__arrow`],
    !arrowInvisible &&
      variant &&
      style[`card__arrow${variant ? `--${variant}` : ''}`],
    !arrowInvisible &&
      rotateArrow &&
      style[`card__arrow${rotateArrow ? `--${rotateArrow}` : ''}`],
    arrowInvisible && style[`card__arrow--hidden`],
  ]
    .filter(Boolean)
    .join(' ')
  return (
    <Icon
      dataTestId={UI_TEST_ID?.cardArrow}
      className={arrowClasses}
      fileName={ASSET.icononboardinarrowforward}
    />
  )
}

export default CardArrow
