import ErrorBoundaryAndSuspense from '../components/error-handling/ErrorBoundaryAndSuspense'
import { PRIVATE, PUBLIC } from './Route'
import LoginForm from '../pages/authentication/LoginForm'
import RegisterForm from '../pages/authentication/RegisterForm'
import PinResetPage from '../pages/authorization/PinResetPage'
//MyTontine lite
import CalculatorForms from '../pages/calculator/CalculatorForms'
import Home from '../pages/onboarding/Home'
import PreRegisterForecast from '../pages/calculator/PreRegisterForecast'
import PublicStatsPage from '../pages/referral/PublicStatsPage'
import { isLite } from '../config/lite'
import LiteLogin from '../pages/authentication/LiteLogin'

const MyTontinePages = isLite
  ? [
      //MyTontine Lite only routes
      {
        // Default page
        path: PUBLIC.HOME,
        page: <PreRegisterForecast />,
      },
      {
        // Allows for the email to be verified on the homepage as well
        path: PUBLIC.VERIFY,
        page: <LiteLogin />,
      },
      {
        // Tontinator mapped to /u/referralCode
        // so the referral code is pre-filled in the user's sign up
        // form
        path: PUBLIC.SIGN_UP_REFERRAL,
        page: <PreRegisterForecast />,
      },
      {
        // It is private because it needs auth token to get results
        // from the API  looks like a contradiction, but these are all the pages
        // in MTL, so no need to add them to private
        path: PRIVATE.WEBSITE_REWARDS,
        page: <PublicStatsPage />,
      },
      {
        path: PUBLIC.NOT_FOUND_404,
        page: <PreRegisterForecast />,
      },
    ]
  : // MY TONTINE FULL APP BELOW
    [
      {
        path: PUBLIC.PIN_RESET,
        page: <PinResetPage />,
      },
      {
        path: PUBLIC.SIGN_IN,
        page: (
          <ErrorBoundaryAndSuspense>
            <LoginForm />
          </ErrorBoundaryAndSuspense>
        ),
      },
      {
        path: PUBLIC.SIGN_UP,
        page: <RegisterForm />,
      },
      {
        path: PUBLIC.SIGN_UP_REFERRAL,
        page: <RegisterForm />,
      },
      {
        path: PUBLIC.TONTINATOR,
        page: <CalculatorForms />,
      },
      {
        path: PUBLIC.HOME,
        page: <Home />,
      },
      {
        path: PUBLIC.NOT_FOUND_404,
        page: <Home />,
      },
    ]

/**
 * Accessed only by anon users
 * These pages are going to be included in the MyTontine Lite
 */
const PublicPages = [...MyTontinePages] as const

export { PublicPages }
