import PropTypes from 'prop-types'
import style from '../../scss/components/Carousel.module.scss'

/**
 * @param {array} slides Slides objects
 * @param {number} currentSlideIndex Index of the current slide that is active
 * @param {function():number} selectSlide - Callback that lifts the selected
 * slide index to the parent component
 *
 * Renders carrousel indicators that show the current active slide.
 * The indicators are clickable, so that the user can select the slide they want.
 */
const CarrouselIndicators = ({ slides, currentSlideIndex, selectSlide }) => {
  const renderSlidesIndicator = () =>
    slides?.map((slide, slideIndex) => (
      <div
        onClick={() => selectSlide(slideIndex)}
        key={`${slideIndex}${slide?.title}`}
        className={`${style[`carousel__indicator-item${slideIndex === currentSlideIndex ? '--active' : ''}`]}`}
      />
    ))

  return (
    <div className={style['carousel__indicator']}>
      {renderSlidesIndicator()}
    </div>
  )
}

CarrouselIndicators.propTypes = {
  slides: PropTypes.array.isRequired,
  currentSlideIndex: PropTypes.number.isRequired,
  selectSlide: PropTypes.func.isRequired,
}

export default CarrouselIndicators
