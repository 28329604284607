import { ASSET } from '../../constants/Assets'
import { useTranslate } from '../../hooks/useTranslate'
import Icon from '../media-and-icons/Icon'
import BrowsersIcons from './BrowsersIcons'
import style from '../../scss/components/SupportedBrowsers.module.scss'
import StoreIcons from './StoreIcons'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

/**
 * This component renders a modal that provides information about supported browsers
 * for FaceTec authentication. It includes a title, a description, and icons for
 * supported browsers and app stores.
 */
const SupportedBrowsers = ({
  setRenderunsupBrowserModal,
}: {
  setRenderunsupBrowserModal: (value: boolean) => void
}) => {
  const t = useTranslate()
  const { isMobileOrTablet } = useDeviceScreen()

  return (
    <div className={style['supported-browsers__container']}>
      <Icon
        onClick={() => setRenderunsupBrowserModal(false)}
        className={style['supported-browsers__back-btn']}
        fileName={ASSET.chevronBlueLeft}
      />
      <Icon
        fileName={ASSET.logoTontineTrust}
        className={style['supported-browsers__logo']}
      />

      <h1 className={style['supported-browsers__title']}>
        {t('LOGIN_FORM.FACETEC_UNSUPPORTED_BROWSER_TITLE')}
      </h1>
      <p className={style['supported-browsers__text']}>
        {t('LOGIN_FORM.FACETEC_UNSUPPORTED_BROWSER_QR_TEXT')}
      </p>
      {isMobileOrTablet ? (
        <StoreIcons />
      ) : (
        <div className={style['supported-browsers__qr-mockup']} />
      )}

      <p className={style['supported-browsers__text']}>
        {t('LOGIN_FORM.FACETEC_UNSUPPORTED_BROWSER_RECOMMENDED_TEXT')}
      </p>
      <BrowsersIcons />
    </div>
  )
}

export default SupportedBrowsers
