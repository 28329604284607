import { generateUniqueId } from '../../utils/UtilFunctions'
import PropTypes from 'prop-types'
import style from '../../scss/components/NextPayout.module.scss'

/**
 * @param {number=} maxProgress How many dots should be rendered
 * @param {number=} currentProgress What is the current dot
 *
 * @description Renders an array of dots to represent user's progress
 */
const NextPayoutLoadingBar = ({
  maxProgress,
  currentProgress,
  renderStartCircle = true,
  renderEndCircle = true,
}) => {
  /**
   *
   * @description Renders dots to represent user's progress
   */
  const renderProgressDots = () =>
    Array.from({ length: maxProgress }).map((_, index) => {
      //Don't render the first and last dot
      if (index !== 0 && index < maxProgress - 1) {
        return (
          <div
            key={generateUniqueId()}
            className={`${style[`nextPayout__dashes`]} 
            ${style[`nextPayout__dashes${index < currentProgress ? '--reached' : '--unreached'}`]} 
            ${style[`nextPayout__dashes${index === currentProgress - 1 ? '--current' : ''}`]}`}
          />
        )
      }
    })

  return (
    <div className={style[`nextPayout__prog-container`]}>
      {renderStartCircle && (
        <div className={style[`nextPayout__start-circle`]} />
      )}
      {renderProgressDots()}
      {renderEndCircle && (
        <div
          className={
            style[
              `nextPayout__end-circle${currentProgress === maxProgress ? '--payday' : ''}`
            ]
          }
        />
      )}
    </div>
  )
}

NextPayoutLoadingBar.propTypes = {
  maxProgress: PropTypes.number,
  currentProgress: PropTypes.number,
  renderStartCircle: PropTypes.bool,
  renderEndCircle: PropTypes.bool,
}

export default NextPayoutLoadingBar
