import { track } from '../../analytics/Analytics'
import { ReferralLinkEvent } from '../../analytics/EventData'
import InputLabel from '../../components/inputs/InputLabel'
import Icon from '../../components/media-and-icons/Icon'
import { ASSET } from '../../constants/Assets'
import { CONSTANTS } from '../../constants/ConstantValues'
import { useTranslate } from '../../hooks/useTranslate'
import style from '../../scss/components/ReferralCode.module.scss'
import { copyToClipboard } from '../../utils/UtilFunctions'
import { debounce } from '../../utils/TSUtilFunctions'

/**
 * Debounced copy to clipboard in order not to spam toast messages
 */
const debouncedCopy = debounce(
  copyToClipboard,
  CONSTANTS.SEARCH_DEBOUNCE_MS + 300
)

type ReferralCodeProps = {
  label: string
  referralCode: string
}

/**
 * Renders a referral code and allows the user to copy it if they click
 * on the referral code
 */
const ReferralCode = ({ label, referralCode }: ReferralCodeProps) => {
  const t = useTranslate()

  return (
    <article
      className={style['referral-code']}
      onClick={() => {
        debouncedCopy(referralCode, t('REFERRAL_LINK_TO_CLIPBOARD'))
        void track({
          event: ReferralLinkEvent.share,
          properties: {
            object_id: 'clipboard',
            object_value: referralCode,
          },
        })
      }}
    >
      <InputLabel label={label} />
      <div className={style['referral-code__text-container']}>
        <p className={style['referral-code__text']}>{referralCode}</p>
        <Icon fileName={ASSET.iconaccountcopy} />
      </div>
    </article>
  )
}

export default ReferralCode
