import axios from 'axios'
import { environment, envColor } from 'MyTontineConfig'
import {
  IncomeForecastParams,
  PromiseCallbacks,
} from '../../types/CommonTypes.types'
import {
  adjustAndConvertToAgeMonthString,
  parseIncomeForecastParams,
} from '../../utils/TSUtilFunctions'
import { axiosConfig } from '../RequestConfig'
import { CONSTANTS } from '../../constants/ConstantValues'
import { ForecastData } from '../../visualization/types/Visualization.types'
import { API } from '../API'
import { track } from '../../analytics/Analytics'
import { ForecastParamsEvent } from '../../analytics/EventData'
import { ChartId } from '../../analytics/ObjectId'

/**
 * Requests an income forecast, wether it is a plan based or a tontinator
 * forecast is decided by the `plan_id` property
 */
const requestIncomeForecast = (
  incomeForecastParams: Array<IncomeForecastParams & { paramsId: string }>,
  isAuthenticated: boolean,
  { onSuccess, onFailure, onFinally }: PromiseCallbacks,
  signal: AbortSignal,
  authToken: string
): void => {
  try {
    const paramsId =
      incomeForecastParams[0]?.paramsId ?? incomeForecastParams[1]?.paramsId

    const incomeForecastRequestBody = incomeForecastParams?.map(
      (incomeForecastParam: IncomeForecastParams) => {
        const {
          oneTimeContribution,
          monthlyContribution,
          sex,
          retirementAge,
          contributionAge,
          countryOfResidence,
          writeDraftPlan,
          pastContributions,
          planID,
        } = incomeForecastParam

        return {
          ...parseIncomeForecastParams({
            planID,
            oneTimeContribution: oneTimeContribution,
            monthlyContribution:
              //Monthly is disabled for USA so defaults to 0
              countryOfResidence === CONSTANTS.FALLBACK_COUNTRY_CODE
                ? 0
                : monthlyContribution,
            countryOfResidence,
            sex,
            payoutAge: adjustAndConvertToAgeMonthString(
              retirementAge,
              contributionAge
            )?.retirementAge,
            contributionAge: adjustAndConvertToAgeMonthString(
              retirementAge,
              contributionAge
            )?.contributionAge,
            isAuthenticated,
            writeDraftPlan: writeDraftPlan ?? false,
            pastContributions: pastContributions ?? false,
          }),
          // This is gonna get removed anyway when haskell model is integrated
          // atm no need to change the actual type and cause confusion
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          experimental_model: Boolean(incomeForecastParam?.experimental),
        }
      }
    )

    //Only here for logging purposes
    incomeForecastRequestBody.forEach((incomeForecastParam) => {
      if (incomeForecastParam?.future_contributions?.contribution_params) {
        console.log(`↓ Contribution params sent to tontinator ↓`)
        console.table(
          incomeForecastParam?.future_contributions?.contribution_params
        )
      }

      if (incomeForecastParam?.future_contributions?.plan_id) {
        console.log(`↓ Plan ID sent to tontinator ↓`)
        console.table(incomeForecastParam)
      }

      if (incomeForecastParam?.demographic_data?.full) {
        console.log('↓ Demographic params sent to tontinator ↓')
        console.table(incomeForecastParam?.demographic_data?.full)
      }

      if (incomeForecastParam?.demographic_data?.override_residency) {
        console.log(
          `
          ------------------------
          Residency override: ${incomeForecastParam?.demographic_data?.override_residency}
          ------------------------
          `
        )
      }
    })

    void track({
      event: ForecastParamsEvent.sent,
      properties: {
        object_id: paramsId as ChartId,
        // We need the params just, no need for the request format
        // TODO: Params are unparsed, we do not want this for user that has not
        // been auth.
        object_value: incomeForecastParams,
      },
    })

    axios
      .post(
        API.tontinatorForecast,
        incomeForecastRequestBody,
        axiosConfig({ signal, authToken })
      )
      .then(({ data }: { data: Array<ForecastData> }) => {
        data?.forEach((forecast) => {
          console.log(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
            `\n\nGot forecast response with view id:\n\n${forecast?.view_id}\n\n`
          )
        })
        console.log(`Environment: %c${environment}`, `color:${envColor}`)

        onSuccess(data)
      })
      .catch(onFailure)
      .finally(onFinally)
  } catch (error) {
    //Shows the error in console for good measure
    console.error(error)
    onFailure(error as object)
  }
}

export { requestIncomeForecast }
