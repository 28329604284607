import style from '../../scss/components/StaticToolTip.module.scss'

type StaticToolTipProps = {
  text: string
  /**
   * Whether the tip should be rendered on the right
   */
  rightTip?: boolean
  /**
   * Color is basically variant kind of...
   */
  color?: 'gold' | 'blue' | 'grey' | 'inactive'
  className?: string
  onClick?: () => void
}

/**
 * Static tool tip component
 */
const StaticToolTip = ({
  text,
  rightTip,
  color,
  className,
  onClick,
}: StaticToolTipProps) => {
  return (
    <section
      className={`${className ?? `${style[`static-tooltip`]}`} 
      ${style[`static-tooltip${color ? `--${color}` : ''}`]}`}
      onClick={onClick}
    >
      <div
        className={`
        ${style[`static-tooltip__tip`]} 
        ${
          style[
            `static-tooltip__tip${rightTip ? '--right' : '--center'}${color ? `--${color}` : ''}`
          ]
        }
        `}
      >
        {text}
      </div>
    </section>
  )
}

export default StaticToolTip
