import IncomeStats from './IncomeStats'
import StaticToolTip from '../feedback/StaticToolTip'
import style from '../../scss/components/IncomeStats.module.scss'
import { IncomeStatsExtendedProps } from './DataDisplay.types'

const variantToColor: {
  [key in 'blue-faint' | 'yellow-faint' | 'grey']: 'blue' | 'gold' | 'grey'
} = {
  'blue-faint': 'blue',
  'yellow-faint': 'gold',
  grey: 'grey',
}

/**
 * Adds a bottom text to the income stats component and a static tooltip
 */
const IncomeStatsExtended = ({
  bottomText,
  incomeStartAge,
  staticToolTipText,
  contributionAmount,
  contributionLabel,
  currency,
  incomeAmount,
  incomeLabel,
  incomePercentage,
  isLoading,
  variant = 'blue-faint',
  hideTooltip,
}: IncomeStatsExtendedProps) => {
  return (
    <div className={style['income-stats-extended']}>
      {!hideTooltip && (
        <StaticToolTip
          color={variantToColor[variant]}
          text={staticToolTipText}
          rightTip
        />
      )}

      <article
        className={
          style[
            `income-stats-extended__container${variant ? `--${variant}` : ''}`
          ]
        }
      >
        <IncomeStats
          contributionAmount={contributionAmount}
          currency={currency}
          contributionLabel={contributionLabel}
          incomeAmount={incomeAmount}
          incomeLabel={incomeLabel}
          incomePercentage={incomePercentage}
          isLoading={isLoading}
          variant={variant}
        />
        <div className={style['income-stats-extended__bottom-container']}>
          <p className={style['income-stats-extended__text-msg']}>
            {bottomText}
          </p>
          <p className={style['income-stats-extended__years-old']}>
            {incomeStartAge}
          </p>
        </div>
      </article>
    </div>
  )
}

export default IncomeStatsExtended
