import BoxDoubleIncrements from './BoxDoubleIncrements'
import DateBox from './DateBox'
import PropTypes from 'prop-types'
import style from '../../../../scss/components/DateBoxChunk.module.scss'

/**
 * @param {function} onClickIncrement Callback function when increment button is
 * clicked
 * @param {function} onClickDecrement Callback function when decrement button is
 * clicked
 * @param {number} dateNumber  Number representing a month or a year
 * @param {string} label Label to be displayed on top of the `dateNumber`
 * @param {function} monthNumberToString Function to convert month number to
 * string
 * @param {string} className className for styling
 * @param {boolean} renderRight Whether to render the increment buttons on the left or
 * on the right side
 *
 * @description Renders an increment and decrement buttons on top of each other
 * and a month or year
 */
const DateBoxChunk = ({
  onClickIncrement,
  onClickDecrement,
  dateNumber,
  label,
  monthNumberToString,
  className,
  renderRight,
  locale,
  disabledIncrement,
  disabledDecrement,
}) => {
  return (
    <div className={`${style['date-box-chunk']} ${className}`}>
      {!renderRight && (
        <BoxDoubleIncrements
          onClickDecrement={onClickDecrement}
          onClickIncrement={onClickIncrement}
          disabledIncrement={disabledIncrement}
          disabledDecrement={disabledDecrement}
        />
      )}
      <DateBox
        dateConvertFunction={monthNumberToString}
        locale={locale}
        formatting={'long'}
        dateNumber={dateNumber}
        dateLabel={label}
        className={style[`date-box-chunk__date-box`]}
      />
      {renderRight && (
        <BoxDoubleIncrements
          onClickDecrement={onClickDecrement}
          onClickIncrement={onClickIncrement}
          disabledIncrement={disabledIncrement}
          disabledDecrement={disabledDecrement}
          renderRight={renderRight}
        />
      )}
    </div>
  )
}

DateBoxChunk.propTypes = {
  onClickDecrement: PropTypes.func,
  onClickIncrement: PropTypes.func,
  dateNumber: PropTypes.number,
  label: PropTypes.string,
  monthNumberToString: PropTypes.func,
  className: PropTypes.string,
  renderRight: PropTypes.bool,
  locale: PropTypes.string,
  disabledIncrement: PropTypes.bool,
  disabledDecrement: PropTypes.bool,
}

export default DateBoxChunk
