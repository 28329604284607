import checkmark from '../lotties/check-mark.json'
import jarWithCoins from '../lotties/jar-with-coins.json'
import loadingWhiteDots from '../lotties/loading-white-dots.json'
import loadingLightBlueDots from '../lotties/loading-light-blue-dots.json'
import splashLogo from '../lotties/logo-splash.json'
import whiteTrend from '../lotties/white-trend.json'

/**
 * @description Lottie animations in JSON format.
 * All animations are exported as a single object.
 */
const ANIMATION = Object.freeze({
  checkmark,
  jarWithCoins,
  loadingWhiteDots,
  loadingLightBlueDots,
  splashLogo,
  whiteTrend,
})

export { ANIMATION }
