import { lazy } from 'react'
import { ANIMATION } from '../../constants/Animations'
import { useGraphResize } from '../../hooks/useGraphResize'
import { useTranslate } from '../../hooks/useTranslate'
import TextError from '../../components/typography/TextError'
import NoTransactions from '../../components/feedback/NoTransactions'
import PropTypes from 'prop-types'
import { generateLegendItem } from '../../visualization/utils/D3DataUtils'
import { useLocalization } from '../../hooks/useLocalization'
import style from '../../scss/pages/NominalBalanceDashboard.module.scss'
const BalanceLineChart = lazy(
  () => import('../../visualization/BalanceLineChart')
)

const X_AXIS_CLASS = 'x-axis-cnb-months'
const Y_AXIS_CLASS = 'y-axis-cnb-nominal'
//Contributions
const CONTRIBUTIONS_LINE_KEY = 'contributions'

//Nominal balance
const NOMINAL_BALANCE_LINE_KEY = 'nominal-balance-line'

const SVG_CONTAINER_ID = 'nominal-balance-svg-container'
const CONTAINER_CLASS = 'nominal-balance-container'

const contributionsStyling = {
  // Greenish
  color: '#22B573',
  stroke: '3px',
  fill: 'none',
  areaOpacity: 0.2,
  areaColor: '#22B573',
}
const nominalBalanceStyling = {
  // Yellow greenish
  color: '#C9D915',
  stroke: '3px',
  fill: 'none',
  areaOpacity: 0.2,
  areaColor: '#C9D915',
}

/**
 * Renders the Current Nominal balance graph with a chart lines
 * legend above it. This component also listens for a `resize` event to redraw
 * the graph if the `window` dimensions have changed
 */
const NominalBalanceDashboard = ({ bankContext, currentBankState, states }) => {
  const draw = useGraphResize()
  const t = useTranslate()
  const { formatAmount } = useLocalization()

  if (
    bankContext?.bankingInfo?.nominalBalance &&
    bankContext.bankingInfo.nominalBalance?.length === 0
  ) {
    return (
      <NoTransactions
        noTransactionsMessage={t('BANKING.CONTRIBUTIONS_HISTORY_PAGE_CONTENT')}
      />
    )
  }

  return (
    <section className={style['nominalBalanceDashboard']}>
      {bankContext?.bankingInfoError && (
        <TextError
          text={bankContext?.bankingInfoError}
          className="tontinatorDashboard__error-text"
        />
      )}
      <BalanceLineChart
        formatter={(amount) =>
          formatAmount({
            amount,
            currency:
              bankContext?.bankingInfo?.nominalBalance?.[0]?.nominalBalance
                ?.currency,
            style: 'currency',
            notation: 'compact',
          }).formattedAmountWithSymbol
        }
        //Sets the width and height of the graph
        //initially to follow the window's innerHeight and innerWidth
        //which means the graph will be 100% width of it's container
        showLegend
        dataToDraw={bankContext?.bankingInfo?.nominalBalance}
        height={innerHeight}
        width={innerWidth}
        redraw={draw}
        isLoading={currentBankState === states.FETCHING_BANK_INFO}
        drawingAnimation={ANIMATION.jarWithCoins}
        containerCssClass={CONTAINER_CLASS}
        contributionsStyleOptions={contributionsStyling}
        nominalBalanceStyling={nominalBalanceStyling}
        numOfXTicks={
          bankContext?.bankingInfo?.nominalBalance?.length < 6
            ? bankContext?.bankingInfo?.nominalBalance?.length
            : 6
        }
        numOfYTicks={5}
        axesDistanceFromGraph={5}
        mainSVGContainerID={SVG_CONTAINER_ID}
        xAxisCssClass={X_AXIS_CLASS}
        yAxisCssClass={Y_AXIS_CLASS}
        contributionLineKey={CONTRIBUTIONS_LINE_KEY}
        nominalBalanceLineKey={NOMINAL_BALANCE_LINE_KEY}
        legendData={[
          generateLegendItem({
            text: t('DASHBOARD.CHART_SUBTITLE_CNB'),
            itemColor: nominalBalanceStyling.color,
            renderLine: true,
          }),
          generateLegendItem({
            text: t('DASHBOARD.CURRENT_BALANCE_CHART_LEGEND'),
            itemColor: contributionsStyling.color,
            renderLine: true,
          }),
        ]}
      />
    </section>
  )
}
NominalBalanceDashboard.propTypes = {
  bankContext: PropTypes.any,
  currentBankState: PropTypes.any,
  states: PropTypes.any,
}

export default NominalBalanceDashboard
