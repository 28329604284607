import { ChangeEvent, MouseEvent } from 'react'
import { regex } from '../../constants/Regex'
import Icon from '../media-and-icons/Icon'
import TextError from '../typography/TextError'
import InputLabel from './InputLabel'
import { UserInputProps } from './InputTypes.type'
import { CONSTANTS } from '../../constants/ConstantValues'
import style from '../../scss/components/UserInput.module.scss'
import { track } from '../../analytics/Analytics'
import { InputFieldEvent } from '../../analytics/EventData'
import { EVENT_DESC } from '../../analytics/EventDescription'
import { debounce } from '../../utils/TSUtilFunctions'

const debouncedTrack = debounce(track, 1_000)

/**
 * Generic input wrapper for the native `<input>` JSX element, with limited props
 */
const UserInput = ({
  value,
  onChange,
  onClick,
  type,
  label,
  placeholder,
  maxLength,
  minLength,
  id,
  onKeyDown,
  readOnly,
  inputMode,
  max,
  min,
  pattern,
  className,
  suffixIcon,
  errorMessage,
  jsxValue,
  defaultValue,
  prefix,
  suffixText,
  dataTestID,
  autoComplete = 'off',
  labelInfoIcon,
  isTextArea,
  optional,
  tooltipText,
  height,
  trackActivity,
}: UserInputProps<string>) => {
  value === null || value === undefined ? (value = '') : value

  const defaultClassState =
    style[
      `userInput__input-element--${errorMessage?.message ? 'error' : 'default'}`
    ]
  const textAreaClass = isTextArea ? style['userInput--text-area'] : ''
  const prefixSpacing = prefix ? style[`userInput__input-element--prefix`] : ''

  // There needs to be spacing between the classes otherwise the styling is not
  // applied correctly
  const defaultClass = `${defaultClassState} ${textAreaClass} ${prefixSpacing} ${className ?? ''}`

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    let {
      target: { value },
    } = event

    if (inputMode === 'numeric') {
      value = value.replace(regex.stringDigits, '')
    }

    if (type === 'file') {
      //For file upload, but we don't have that requirement defined firmly yet
      //leave type as string not to cause any issues
      onChange && onChange(event as unknown as string)
    } else {
      onChange && onChange(value)
    }

    void debouncedTrack({
      event: InputFieldEvent.typed,
      properties: {
        object_id: trackActivity?.trackId,
        label,
        description: EVENT_DESC.inputFieldTyped,
      },
    })
  }

  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    void track({
      event: InputFieldEvent.clicked,
      properties: {
        object_id: trackActivity?.trackId,
        label,
        description: EVENT_DESC.inputFieldClicked,
      },
    })
    onClick?.(event)
  }

  const commonProps = {
    'data-testid': dataTestID,
    readOnly,
    defaultValue,
    maxLength,
    minLength,
    max,
    min,
    id,
    className: defaultClass,
    value,
    onChange: handleOnChange,
    placeholder,
    autoComplete,
    autoCorrect: 'off',
    autoCapitalize: 'off',
    spellCheck: false,
    onKeyDown,
    inputMode,
    pattern,
  }

  return (
    <article
      className={`${style[`userInput${isTextArea ? '--text-area' : ''}`]} ${style[`userInput${height ? `--${height}` : ''}`]}`}
    >
      {label && (
        <InputLabel
          label={label}
          tooltipText={tooltipText}
          infoIcon={labelInfoIcon}
          //readonly false, do not render asterisk
          renderAsterisk={optional ? false : !isReadOnly(className, readOnly)}
        />
      )}
      <div className={style['userInput__wrapper']} onClick={handleOnClick}>
        {!value && jsxValue && (
          <div className={style[`userInput__jsx`]}>{jsxValue}</div>
        )}
        {prefix && <p className={style['userInput__prefix']}>{prefix}</p>}
        {isTextArea ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <textarea {...commonProps} />
        ) : (
          <input {...commonProps} type={type} />
        )}
        {suffixIcon && (
          <Icon fileName={suffixIcon} className={style['userInput__suffix']} />
        )}
        {suffixText && (
          <p className={style['userInput__suffix-text']}>{suffixText}</p>
        )}
      </div>

      {errorMessage?.message && <TextError validationObject={errorMessage} />}
    </article>
  )
}

const isReadOnly = (className?: string, readOnly?: boolean): boolean =>
  (className === CONSTANTS.READONLY_FIELD || readOnly) ?? false

export default UserInput
