import { environment } from 'MyTontineConfig'
import { ENVIRONMENTS } from './ConstantValues'
import {
  TESTING_IDS,
  TestingIDsType,
} from '../../cypress/support/ui-component-ids'

const { production, staging } = ENVIRONMENTS

/**
 * @note **Use these ONLY in UI elements to assign test IDs!**
 */
const UI_TEST_ID: TestingIDsType =
  //If app is using production or staging config then do not include testing
  //custom testing attributes that are added in components for security reasons
  environment === production || environment === staging
    ? ({} as TestingIDsType)
    : TESTING_IDS

export { UI_TEST_ID }
