import style from '../../scss/components/CurrencyStat.module.scss'
import { useLocalization } from '../../hooks/useLocalization'
import { useCountUp } from '../../hooks/useCountUpAnimation'
import LottieAnimation from '../feedback/LottieAnimation'
import { ANIMATION } from '../../constants/Animations'
import { CONSTANTS } from '../../constants/ConstantValues'

type CurrencyStatProps = {
  currency: 'USD' | 'JPY'
  amount: number
  label: string
  disableAnimation?: boolean
  isLoading?: boolean
}

const compactFractionDigits = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}

const defaultDigits = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}

/**
 * Renders a label and formatted and animated currency amount. Only supported
 * currencies are `USD` and `JPY`.
 */
const CurrencyStat = ({
  currency,
  amount,
  label,
  disableAnimation,
  isLoading,
}: CurrencyStatProps) => {
  const { formatAmount } = useLocalization()

  const isVerySmallScreen =
    window?.innerWidth >= CONSTANTS.IPHONE5_WIDTH &&
    window?.innerWidth < CONSTANTS.IPHONEX_WIDTH

  const animatedAmount = useCountUp({
    targetValue: amount,
    durationInSeconds: 1_500,
    disableAnimation,
  })

  const isLargeDigit =
    animatedAmount?.toString()?.length > CONSTANTS.LARGE_DIGIT_LIMIT ||
    isVerySmallScreen

  return (
    <article className={style[`currency-stat`]}>
      <h3 className={style[`currency-stat__label`]}>{label}</h3>
      {isLoading ? (
        <LottieAnimation
          loop
          autoplay
          animationName={ANIMATION.loadingLightBlueDots}
          style={{
            height: '38px',
            scale: '4',
          }}
        />
      ) : (
        <p className={style[`currency-stat__amount`]}>
          {
            formatAmount({
              notation: isLargeDigit ? 'compact' : 'standard',
              style: 'currency',
              currency,
              amount: animatedAmount,
              digits: isLargeDigit ? compactFractionDigits : defaultDigits,
            })?.formattedAmountWithSymbol
          }
        </p>
      )}
    </article>
  )
}

export default CurrencyStat
