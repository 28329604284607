import style from '../../scss/layout/InputGroup.module.scss'

/**
 * Groups input fields into a two row grid. Passing in `noStyle` ignores the
 * styling nd just uses this component as a wrapper
 */
const InputGroup = ({
  children,
  dataTestID,
  noStyle,
  groupLabel,
  className,
}: {
  children?: React.JSX.Element | Array<React.JSX.Element>
  dataTestID?: string
  noStyle?: boolean
  groupLabel?: string
  className?: string
}) => {
  return (
    <>
      {groupLabel && (
        <h3 className={style[`inputGroup__label`]}>{groupLabel}</h3>
      )}

      <section
        className={noStyle ? '' : `${style[`inputGroup`]} ${className ?? ''}`}
        data-testid={dataTestID}
      >
        {children}
      </section>
    </>
  )
}

export default InputGroup
