const regex = {
  /**
   * Used for validation only in pin?
   */
  pinInputDigits: /[0-9]/,
  /**
   * Needed for SSN and PIN, or anything combined
   */
  stringDigits: /[^0-9]/g,
  /**
   * Catches duplicate spaces
   */
  duplicateSpaces: /\s+/g,
  usaCity: /^[a-zA-Z\s\-.'’]+$/,
  names: /^[a-zA-Z\s\-.'’]+$/,
  referralCodeFormat: /^[a-zA-Z0-9_]+$/,
  SSN: /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/,
  emailFormat:
    // eslint-disable-next-line security/detect-unsafe-regex
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  /**
   * Valid input characters for USA postal code field
   */
  // eslint-disable-next-line security/detect-unsafe-regex
  usaPostalCode: /^[0-9]{5}(?:-[0-9]{4})?$/,
}

/**
 * Allows only specified characters to be inputted to this field
 */
const inputRestrictionRegex = {
  /**
   * Valid input characters for City field
   */
  usaCity: /[^a-zA-Z\s\-.'']*$/g,
  /**
   * Only allows numbers to be inputted to this field
   */
  onlyDigits: /\D/g,
  usaAddress: /[^A-Za-z0-9\s\-'.,/&#]+/g,
  names: /[^a-zA-Z\s\-.'']*$/g,
  referralCodeFormat: /[^a-zA-Z0-9]+$/g,
  noWhiteSpace: /\s+/g,
}

export { regex, inputRestrictionRegex }
