import { useRef } from 'react'
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick'
import Icon from '../media-and-icons/Icon'
import style from '../../scss/components/Modal.module.scss'
import { ASSET } from '../../constants/Assets'
import { track } from '../../analytics/Analytics'
import { ButtonEvent } from '../../analytics/EventData'
import { TrackActivity } from '../Common.types'

type Backdrop = 'full-color'

type ModalProps = {
  children: React.ReactNode
  isOpen: boolean
  backdrop: boolean
  hasOnlyContent?: boolean
  onOutsideModalContentClick?: () => void
  showCloseButton?: boolean
  onClickCloseButton?: () => void
  wide?: boolean
  /**
   * Renders full color backdrop, default is with reduced opacity
   */
  backdropType?: Backdrop
  variant?: 'middle-content'
  dataTestID?: string
  trackActivityBackDrop?: TrackActivity
  trackActivityCloseButton?: TrackActivity
}

/**
 * Wrapper used for rendering a component as a modal
 */
const Modal = ({
  children,
  isOpen,
  backdrop,
  hasOnlyContent,
  onOutsideModalContentClick,
  showCloseButton,
  onClickCloseButton,
  wide,
  backdropType,
  variant,
  dataTestID,
  trackActivityBackDrop,
  trackActivityCloseButton,
}: ModalProps): JSX.Element => {
  const modalContentRef = useRef<null | HTMLDivElement>(null)

  const _onOutsideModalContentClick = () => {
    onOutsideModalContentClick?.()

    void track({
      event: ButtonEvent.clicked,
      properties: {
        object_id: trackActivityCloseButton?.trackId,
      },
    })
  }

  useDetectOutsideClick(modalContentRef, _onOutsideModalContentClick)

  return isOpen ? (
    <main
      className={
        style[
          `modal${backdrop ? `${createBackdrop(backdrop, backdropType)}` : ''}`
        ]
      }
      data-testid={dataTestID}
    >
      <section
        className={style[`modal__content${variant ? `--${variant}` : ''}`]}
      >
        <section className={style[`modal__inner`]}>
          {showCloseButton && onClickCloseButton && (
            <Icon
              fileName={ASSET.iconmtcloequare}
              className={style[`modal__close-icon`]}
              onClick={() => {
                onClickCloseButton()
                void track({
                  event: ButtonEvent.clicked,
                  properties: {
                    object_id: trackActivityBackDrop?.trackId,
                  },
                })
              }}
            />
          )}
          <div
            ref={modalContentRef}
            className={`${hasOnlyContent ? '' : style[`modal__content-wrapper`]} ${wide ? style['modal__content-wrapper--wide'] : ''}`}
          >
            {children}
          </div>
        </section>
      </section>
    </main>
  ) : (
    <></>
  )
}

/**
 * Enables a backdrop for the modal
 */
const createBackdrop = (backdrop: boolean, backdropType?: Backdrop) => {
  if (backdrop && backdropType) {
    return `--backdrop${backdropType ? `-${backdropType}` : ''}`
  }

  return '--backdrop'
}

export default Modal
