import { assign, setup } from 'xstate'
import {
  LegalMachineContext,
  LegalMachineEvent,
} from './LegalMachineTypes.types'
import {
  fetchAgreement,
  fetchForm,
  saveFormProgress,
  submitForm,
  signAgreement,
  getFormAsPdf,
} from './LegalMachineServices'
import {
  storeForm,
  updateFormSubmitted,
  storeAgreements,
  updateAgreement,
  updateFormToPdf,
} from './LegalMachineActions'
import { isSubmittedForm } from './LegalMachineGuards'
import { legalPromiseToPromiseActor, promiseActorInput } from '../StateUtils'

/**
 * Handles all of the legal parts of the app. Like signing agreements, fetching
 * legal forms and sending the data to the server
 */
export const legalMachine = setup({
  types: {
    context: {} as LegalMachineContext,
    events: {} as LegalMachineEvent,
  },
  actors: {
    fetchAgreement: legalPromiseToPromiseActor(fetchAgreement),
    fetchForm: legalPromiseToPromiseActor(fetchForm),
    saveFormProgress: legalPromiseToPromiseActor(saveFormProgress),
    submitForm: legalPromiseToPromiseActor(submitForm),
    signAgreement: legalPromiseToPromiseActor(signAgreement),
    getFormAsPdf: legalPromiseToPromiseActor(getFormAsPdf),
  },
  actions: {
    storeForm: assign(storeForm),
    updateFormSubmitted: assign(updateFormSubmitted),
    storeAgreements: assign(storeAgreements),
    updateAgreement: assign(updateAgreement),
    updateFormToPdf: assign(updateFormToPdf),
  },
  guards: {
    isSubmittedForm,
  },
}).createMachine({
  context: {
    form: undefined,
    agreement: undefined,
  },
  id: 'LegalMachine',
  initial: 'IDLE',
  states: {
    IDLE: {
      on: {
        FETCH_FORM: {
          target: 'FETCHING_FORM_FIELDS',
        },
        SAVE_FORM_PROGRESS: {
          target: 'SAVING_FORM_PROGRESS',
        },
        SUBMIT_FORM: {
          target: 'SUBMITTING_FORM',
        },
        FETCH_AGREEMENT: {
          target: 'FETCHING_AGREEMENT',
        },
        SIGN_AGREEMENT: {
          target: 'SIGNING_AGREEMENT',
        },
        GENERATE_FORM_AS_PDF: {
          target: 'GENERATING_FORM_AS_PDF',
        },
      },
    },

    GENERATING_FORM_AS_PDF: {
      invoke: {
        src: 'getFormAsPdf',
        id: 'getFormAsPdfID',
        input: promiseActorInput,
        onDone: [
          {
            target: 'IDLE',
            // Simply attach the pdf response in form context
            actions: ['updateFormToPdf'],
          },
        ],
        onError: [
          {
            target: 'IDLE',
          },
        ],
      },
    },

    SIGNING_AGREEMENT: {
      invoke: {
        src: 'signAgreement',
        id: 'signAgreementID',
        input: promiseActorInput,
        onDone: [
          {
            target: 'IDLE',
            actions: ['updateAgreement'],
          },
        ],
        onError: [
          {
            target: 'IDLE',
          },
        ],
      },
    },

    FETCHING_AGREEMENT: {
      invoke: {
        src: 'fetchAgreement',
        id: 'fetchAgreementID',
        input: promiseActorInput,
        onDone: [
          {
            target: 'IDLE',
            actions: ['storeAgreements'],
          },
        ],
        onError: [
          {
            target: 'IDLE',
          },
        ],
      },
    },

    FETCHING_FORM_FIELDS: {
      invoke: {
        src: 'fetchForm',
        id: 'fetchInvestmentFormID',
        input: promiseActorInput,
        onDone: [
          // Form is already submitted
          // generate PDF
          {
            target: 'GENERATING_FORM_AS_PDF',
            actions: ['storeForm'],
            guard: { type: 'isSubmittedForm' },
          },
          {
            target: 'IDLE',
            actions: ['storeForm'],
          },
        ],
        onError: [
          {
            target: 'IDLE',
          },
        ],
      },
    },

    SAVING_FORM_PROGRESS: {
      invoke: {
        src: 'saveFormProgress',
        id: 'saveFormProgressID',
        input: promiseActorInput,
        onDone: [
          {
            target: 'IDLE',
          },
        ],
        onError: [
          {
            target: 'IDLE',
          },
        ],
      },
    },

    SUBMITTING_FORM: {
      invoke: {
        src: 'submitForm',
        id: 'submitFormID',
        input: promiseActorInput,
        onDone: [
          {
            target: 'GENERATING_FORM_AS_PDF',
            actions: ['updateFormSubmitted'],
          },
        ],
        onError: [
          {
            target: 'IDLE',
          },
        ],
      },
    },
  },
})
