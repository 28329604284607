import {
  HTMLAttributes,
  ReactElement,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import style from '../../../scss/components/Dropdown.module.scss'
import { useDetectOutsideClick } from '../../../hooks/useDetectOutsideClick'
import { useTranslate } from '../../../hooks/useTranslate'
import { ValidationData } from '../../../types/CommonTypes.types'
import TextInput from '../TextInput'
import DropdownMenuItems from './DropdownMenuItems'
import DropdownMenu from './DropdownMenu'
import OptionView from './OptionView'
import { ItemKey, OptionValue } from './Dropdown.types'
import { useSearch } from '../../../hooks/useSearch'
import { ASSET } from '../../../constants/Assets'

type DropdownProps<T> = {
  options: Array<object>
  itemKey: ItemKey
  value: T
  onChange: (value: unknown) => void
  searchBy: Array<string>
  label?: string
  readOnly?: boolean
  className?: string
  errorMessage?: ValidationData
  validatorFunction?: () => void
  placeholder?: string
  optional?: boolean
  dataTestID?: string
}

/**
 * @note `searchBy` only supports searching in flat object `options`
 *
 * Renders a searchable dropdown component that takes in json options
 */
const Dropdown = <T,>({
  options,
  itemKey,
  value,
  onChange,
  label,
  searchBy,
  readOnly,
  className,
  errorMessage,
  validatorFunction,
  placeholder,
  optional,
  dataTestID,
}: DropdownProps<T>) => {
  const t = useTranslate()

  const { foundOptions, searchQuery, setSearchQuery } = useSearch({
    options,
    searchBy,
  })

  const { setSelectedOption, selectedOption } = useRenderVisualOption({
    options,
    value,
    itemKey,
  })

  const { mainContainerRef, isOpenMenu, setIsOpenMenu } =
    useCloseOnClickOutside(() => {
      setIsOpenMenu(false)
      // Resets the research query when the menu is closed
      setSearchQuery('')
    })

  /**
   * Handles the click event on an option and issues the onChange event
   */
  const handleOptionClick = (option: unknown) => {
    if (itemKey?.valueOnChange) {
      // this returns a string based on the itemKey
      onChange((option as OptionValue)[itemKey.valueOnChange] as T)
    } else {
      onChange(option)
    }

    /**
     * Extracts a value from the option object using the `displayKey`
     */
    const renderText = (option: unknown): string => {
      return (option as OptionValue)[itemKey.displayKey]
    }

    // Clear the query so the dropdown menu resets resets
    setSearchQuery('')
    // Set the Input to have the selected option as a component
    setSelectedOption(
      <OptionView
        text={renderText(option)}
        className={'option-view--no-spacing'}
        alpha3CountryCode={
          (option as OptionValue)?.[itemKey?.valueOnChange ?? '']
        }
      />
    )
    setIsOpenMenu(false)
  }

  return (
    <article
      className={`${style['dropdown']} ${className ?? ''}`}
      ref={mainContainerRef}
    >
      <TextInput
        // Prevents the jsx overlapping with a normal input placeholder
        placeholder={selectedOption ? '' : placeholder}
        jsxValue={applyOpacity({
          // Adds opacity to the selected element when dropdown menu is open,
          // looks cool
          selectedOption,
          isOpenMenu,
        })}
        label={label}
        value={searchQuery}
        onChange={setSearchQuery}
        inputMode="search"
        readOnly={readOnly}
        onClick={
          // Clicking the search input will only open the dropdown menu
          readOnly ? undefined : () => setIsOpenMenu(true)
        }
        suffixIcon={chevronPosition({
          readOnly,
          isOpenMenu,
        })}
        errorMessage={errorMessage}
        validatorFunction={validatorFunction}
        optional={optional}
        dataTestID={dataTestID}
      />

      <DropdownMenu isOpen={isOpenMenu}>
        {isOpenMenu && (
          <DropdownMenuItems
            handleOptionClick={handleOptionClick}
            options={foundOptions}
            itemKey={itemKey}
            value={value}
            noOptionFoundMessage={t('NO.DATA.FOR.THAT')}
            block={style['dropdown']}
          />
        )}
      </DropdownMenu>
    </article>
  )
}

/**
 * Renders a chevron position if up if menu is open, down if it is closed and
 * does not render a chevron if `readOnly`
 */
const chevronPosition = ({
  readOnly,
  isOpenMenu,
}: {
  readOnly?: boolean
  isOpenMenu: boolean
}): string => {
  if (readOnly) {
    return ''
  }

  return isOpenMenu ? ASSET.icononboardinarrowup : ASSET.icononboardinarrowdown
}

/**
 * Renders an option JSX component for passed in value so an Icon and other jsx
 * elements can be displayed inside the input component
 */
const useRenderVisualOption = <T,>({
  value,
  itemKey,
  options,
}: {
  options: Array<object>
  value: T
  itemKey: ItemKey
}) => {
  /**
   * Initializes the selected option with a jsx value based on the passed in
   * value for the dropdown
   */
  const initSelectedOptionIfValue = (
    value: T,
    itemKey: ItemKey,
    options: Array<object>
  ) => {
    // Find the the the options from the Array of options
    let foundOption = options.find((option) => {
      if (typeof value === 'string' && itemKey?.valueOnChange) {
        return value === (option as OptionValue)[itemKey.valueOnChange]
      }

      return undefined
    }) as OptionValue | undefined

    // If value is an object don't search for anything, just return it to init
    // the state
    if (!foundOption) {
      foundOption = value as OptionValue
    }

    if (foundOption) {
      return (
        <OptionView
          text={foundOption[itemKey.displayKey]}
          className={'option-view--no-spacing'}
          alpha3CountryCode={foundOption[itemKey.valueOnChange ?? '']}
        />
      )
    }

    return undefined
  }

  const [selectedOption, setSelectedOption] = useState<JSX.Element | undefined>(
    () => initSelectedOptionIfValue(value, itemKey, options)
  )
  useEffect(() => {
    if (!value) {
      setSelectedOption(undefined)
    }
  }, [value])

  return {
    selectedOption,
    setSelectedOption,
  }
}

/**
 * Controls the opening and closing of the dropdown menu
 */
const useCloseOnClickOutside = (onClickedOutsideArea: () => void) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const mainContainerRef = useRef(null)

  useDetectOutsideClick(mainContainerRef, onClickedOutsideArea)

  return {
    mainContainerRef,
    isOpenMenu,
    setIsOpenMenu,
  }
}

/**
 * Clones a react element and applies a new className based on the `isOpenMenu`
 */
const applyOpacity = ({
  selectedOption,
  isOpenMenu,
}: {
  selectedOption?: ReactElement<HTMLAttributes<HTMLElement>>
  isOpenMenu: boolean
}) => {
  if (selectedOption) {
    return cloneElement(selectedOption, {
      className: `${selectedOption.props.className} ${isOpenMenu ? 'option-view--back-option' : ''}`,
    })
  }

  return <></>
}

export default Dropdown
