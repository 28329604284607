import { useState, useEffect } from 'react'

/**
 * Counts up to a target value using `requestAnimationFrame` and returns the
 * animated counted up value.
 * Default value for `durationInSeconds` is 1_500 (1.5s).
 */
export const useCountUp = ({
  targetValue,
  durationInSeconds = 1_500,
  disableAnimation,
}: {
  targetValue: number
  durationInSeconds?: number
  disableAnimation?: boolean
}) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    let start = 0

    /**
     * Callback used in the `requestAnimationFrame` function
     */
    const animateCountUp = (timestamp: number) => {
      if (!start) {
        start = timestamp
      }

      const progress = timestamp - start

      /**
       * Rate in percent if the animation has finished
       * 1 = 100%
       */
      const rate = Math.min(progress / durationInSeconds, 1)

      // Sets the count every requested frame so it is animated
      setCount(Math.floor(rate * targetValue))

      if (rate < 1) {
        requestAnimationFrame(animateCountUp)
      }
    }

    // Does not request animation and cause re-renders if the target value is 0,
    // or if the animation is disabled
    if (!disableAnimation && targetValue !== 0) {
      // Request the very first frame
      const animationId = requestAnimationFrame(animateCountUp)
      return () => cancelAnimationFrame(animationId)
    }

    return undefined
  }, [targetValue, durationInSeconds, disableAnimation])

  if (disableAnimation) {
    return targetValue
  }

  return count
}
