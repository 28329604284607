import { useState } from 'react'
import { To } from 'react-router-dom'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { hasStrongAuth } from '../../utils/UtilFunctions'
import { useTranslate } from '../../hooks/useTranslate'
import FaceScan from '../../pages/authentication/FaceScan'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import ConfirmationModal from '../overlay/ConfirmationModal'
import { ASSET } from '../../constants/Assets'
import Card from './Card'
import { CardVariantType } from '../../types/Card.type'
import NavigationCard from './NavigationCard'

type MenuCardItemProps = {
  mainText?: string
  alertCount?: number
  to?: To
  icon?: string
  writeProtected?: boolean
  warningModalTitle?: string
  warningModalContent?: string
  warningModalCancelButtonLabel?: string
  showArrow?: boolean
  disabled?: boolean
  dataTestID?: string
  cardVariant?: CardVariantType
}

/**
 * Renders a menu card item that serves as a link to a route on mobile devices,
 * displays alerts next to the menu item if the `alertCount` is greater than 0
 */
const MenuCardItem = ({
  mainText,
  alertCount,
  to,
  icon,
  writeProtected,
  warningModalTitle,
  warningModalContent,
  warningModalCancelButtonLabel,
  showArrow = true,
  disabled,
  dataTestID,
  cardVariant,
}: MenuCardItemProps) => {
  const {
    context: { permissions, user_details },
  } = useAccountService()

  const t = useTranslate()
  const navigate = useCustomNavigation()
  const [faceScan, setFaceScan] = useState(false)
  const [isOpen, setOpen] = useState(false)

  /**
   * @description Checks if the user has strong authentication type before they
   * can proceed making changes to their account. If they have strong
   * authentication, they will be navigated to the route, otherwise it will
   * display a waning modal prompting the user to enroll or re-authenticate to
   * acquire strong auth type
   */
  const checkAuth = () => {
    if (permissions === 'read' && writeProtected) {
      /**
       * Open a warning modal, prompting the user to do biometric authentication
       * (scan their face if they don't have strong authentication)
       */
      setOpen(true)
    }
  }

  //If a user does not have strong auth token, then they are not navigated to
  //the write protected route, instead a warning modal renders prompting them to
  //do re-authentication to obtain a strong auth token, and we only return the
  //menu card body so the styling does not seem broken to the user
  return permissions && !hasStrongAuth(permissions) && writeProtected ? (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        icon={ASSET.yellowShield}
        title={warningModalTitle ?? t('FUND_PENSION.WARNING_MODAL_TITLE')}
        content={
          warningModalContent ?? t('ONBOARDING.MODAL_ERROR_LOGIN_MAGIC_LINK')
        }
        firstButtonAction={() => {
          setFaceScan(true)
        }}
        firstButtonLabel={t('PROMPT_BUTTON.ENROLL_FACE')}
        secondButtonAction={() => {
          setFaceScan(false)
          setOpen(false)
        }}
        secondButtonLabel={
          warningModalCancelButtonLabel ??
          t('CONFIRMATION_MODAL_BUTTONS.CANCEL')
        }
      />
      {faceScan && (
        <FaceScan
          asModal
          // Face already enrolled, start re-auth if not start an enroll face scan
          scanType={user_details?.face_enrolled ? 'auth-scan' : 'match-id'}
          onClickExitScan={() => setFaceScan(false)}
          onSuccessfulScan={() => {
            // After successful scan navigate the user to the page they wanted
            // to see
            navigate(to ?? '')
          }}
        />
      )}
      <Card
        dataTestID={dataTestID}
        title={mainText ?? ''}
        headerImage={icon}
        showArrow={showArrow}
        disabled={disabled}
        active={false}
        alert={alertCount}
        onClick={checkAuth}
        interactEnabled={!disabled}
        variant={cardVariant}
      />
    </>
  ) : (
    // Empty string means that the route is disabled, passing in undefined or null makes
    // the NavLink component crash
    <NavigationCard
      title={mainText ?? ''}
      headerImage={icon}
      showArrow={showArrow}
      disabled={disabled}
      alert={alertCount}
      interactEnabled={!disabled}
      variant={cardVariant}
      navigateTo={to ?? ''}
      dataTestID={dataTestID}
    />
  )
}

export default MenuCardItem
