import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import ErrorBoundaryAndSuspense from '../../components/error-handling/ErrorBoundaryAndSuspense'
import Layout from '../../components/layout/Layout'
import ConfirmationModal from '../../components/overlay/ConfirmationModal'
import { ANIMATION } from '../../constants/Animations'
import { CONSTANTS } from '../../constants/ConstantValues'
import { PUBLIC } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import {
  captureExceptionWithSentry,
  extractYearFromAgeMonthString,
} from '../../utils/TSUtilFunctions'
import { millisecondsToSeconds } from '../../utils/UtilFunctions'
import style from '../../scss/pages/Register.module.scss'
import SignUpFields from '../../pages/authentication/SignUpFields'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

/**
 * @param {boolean=} forecastPageRegisterModal Renders the sign up fields without
 * the `<PageLayout />` component
 * @param {object=} forecastUserData
 * @param {string=} registerResidency
 *
 * Renders a sign up form with or without the `<PageLayout />` so
 * the form fits most of the design use cases. This component is used for
 * creating an account for the MyTontine app.
 */
const RegisterForm = ({
  forecastPageRegisterModal = false,
  forecastUserData,
  registerResidency,
  backButtonAction,
  hideSmallTitle,
}) => {
  //Hooks
  const { isMobileOrTablet } = useDeviceScreen()
  const { send, currentState } = useAccountService()
  const t = useTranslate()

  //State
  const [modalTextContent, setModalTextContent] = useState(undefined)
  const [resendEmail, setResendEmail] = useState(undefined)

  const [registerState, setRegisterState] = useState(false)
  const [signUpError, setSignUpError] = useState(undefined)

  const dismissModal = () => {
    //Puts the app back to the initial NO_AUTH token, so the completed
    //registration modal does not show again on the login page
    send({
      type: 'CLOSE_SUCCESS_MODAL',
    })

    if (
      currentState === 'SENT_NEW_TAB_EMAIL' ||
      currentState === 'READY_RESEND_EMAIL'
    ) {
      setRegisterState(false)
    }
  }

  /**
   * @param {string} email
   */
  const sendEmailAgain = () => {
    send({
      type: 'SEND_NEW_TAB_EMAIL',
      payload: {
        email: resendEmail,
        forecastUserData,
      },
    })
  }

  /**
   * @typedef {object} SignUpFields
   * @property {string} email
   * @property {string} firstName
   * @property {string} lastName
   * @property {boolean=} emailUpdates
   * @property {boolean} checkPolicy
   * @property {string} sex
   * @property {string} country
   * @property {string=} referralCode
   *
   * @param {SignUpFields} signUpFields
   * Register a new user to the UAS, also this function is used to
   * redeem a referral code if provided
   */
  const handleRegister = (signUpFields) => {
    const {
      email,
      first_name,
      last_name,
      email_updates,
      terms_and_conditions,
      birth_year,
      sex,
      residency,
      referral_code,
      payout_start_year,
    } = parseRegisterBody(forecastUserData, signUpFields)

    setSignUpError(undefined)

    send({
      type: 'REGISTER_USER',
      payload: {
        email,
        first_name,
        last_name,
        email_updates,
        birth_year,
        sex,
        residency: registerResidency ?? residency,
        referral_code,
        marketing: email_updates,
        news: email_updates,
        terms_and_conditions,
        payout_start_year,
        forecastParams: forecastUserData,
        successCallback: (data) => {
          // A user account is created even if the /create endpoint returns 400 with
          // error id UAS-REFERRAL-CODE-4
          if (data === 'UAS-REFERRAL-CODE-4') {
            setModalTextContent(t('ERROR_REFERRAL_CODE_NOT_FOUND'))
          } else {
            setModalTextContent(t('MODAL.BODY.TEXT.VERIFICATION.SENT'))
          }

          setRegisterState(true)
          setResendEmail(email)
        },

        failureCallback: (error) => {
          setSignUpError(error)
          setRegisterState(false)
        },
        //Will be sent after the API request is successful
        forecastUserData,
      },
    })
  }

  //Closes the modal so it does not persist on the logins screen
  useEffect(() => {
    return () => dismissModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isRegisteredAndSentEmail = () =>
    currentState === 'SENT_NEW_TAB_EMAIL' ||
    ((currentState === 'READY_RESEND_EMAIL' ||
      currentState === 'SENDING_NEW_TAB_EMAIL') &&
      registerState)

  return (
    // &Important!
    // In order for the class to be present in the style object
    // there needs to be at least one css value, otherwise the
    // style object won't contain that class
    <main className={style.register}>
      {isRegisteredAndSentEmail() && (
        <ConfirmationModal
          isOpen
          title={'SUCCESS.HEADERTEXT.MODAL'}
          content={modalTextContent}
          contentValues={{
            email: resendEmail,
          }}
          animatedIcon={ANIMATION.checkmark}
          timerButtonAction={sendEmailAgain}
          timerButtonLabel={t('ACCOUNT.EMAIL_SEND_AGAIN_BUTTON')}
          timerButtonSeconds={millisecondsToSeconds(
            CONSTANTS.RESEND_EMAIL_TIMER_MILLISECONDS
          )}
          timerButtonDisabled={currentState !== 'READY_RESEND_EMAIL'}
          firstButtonAction={dismissModal}
          firstButtonLabel={t('SUCCESS.MODAL.DISMISS.BUTTON')}
          confirmationButtonTrackId={'register_dismiss'}
          timerButtonTrackId={'register_resend_email'}
        />
      )}

      <ErrorBoundaryAndSuspense>
        {forecastPageRegisterModal ? (
          <SignUpFields
            forecastPageRegisterModal={forecastPageRegisterModal}
            handleRegister={handleRegister}
            backButtonAction={backButtonAction}
            registerButtonLabel={t(
              'ONBOARDING.SEND_EMAIL_TO_SEE_RESULTS_BUTTON'
            )}
            hideAdditionalFields={Boolean(forecastUserData)}
            fieldsTitle={
              hideSmallTitle
                ? ''
                : t('ONBOARDING.SIGN_UP_MODAL_TITLE_GET_RESULTS')
            }
            registerState={registerState}
            signUpError={signUpError}
            authState={currentState}
            hideDivider
          />
        ) : (
          <Layout
            pageTitle={t('REGISTER_FORM.NO_MODAL_HEADER_TITLE')}
            headerTitle={
              isMobileOrTablet ? '' : t('REGISTER_FORM.NO_MODAL_HEADER_TITLE')
            }
            navigateTo={PUBLIC.GO_TO_PREV_PAGE}
            layoutVariant="sun-bg"
            containerWidth="medium"
            headerTextColor="blue"
            headerVariant="spaced"
            containerHeight="auto"
            containerMt="nomt"
            hideDividerHeader
          >
            <SignUpFields
              handleRegister={handleRegister}
              registerButtonLabel={t('BUTTON_LABEL.SIGN_UP')}
              registerState={registerState}
              signUpError={signUpError}
              authState={currentState}
            />
          </Layout>
        )}
      </ErrorBoundaryAndSuspense>
    </main>
  )
}

/**
 * @param {number} userCurrentAge
 *
 * Calculates the birth YYYY for an user that has provided their `currentAge`,
 * current age and contribution age are the same, just they are named differently
 * since contribution age comes from onboarding flow and current age comes from
 * sign up form. It was left like this for legacy reasons
 */
const calculateUserBirthYear = (userCurrentAge) => {
  try {
    if (userCurrentAge > 0) {
      return CONSTANTS.CURRENT_YEAR - userCurrentAge
    } else {
      throw new Error(
        `Got invalid params >>${userCurrentAge}<< should be a number or not null`
      )
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * @typedef {Object} DemographicDataFull
 * @property {string} country_of_residence - The country where the user resides.
 * @property {string} sex - The sex of the user.
 * @property {string} current_age - The current age of the user.
 */

/**
 * @typedef {object} SignUpFields
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {boolean=} emailUpdates
 * @property {boolean} checkPolicy
 * @property {string} sex
 * @property {string} country
 * @property {string=} referralCode
 * @property {number} termsVersion
 */

/**
 * @typedef {Object} DemographicData
 * @property {DemographicDataFull} full - The full demographic data of the user.
 */

/**
 * @typedef {Object} ForecastParams
 * @property {DemographicData} demographic_data - The demographic data of the user.
 */

/**
 * Parses the registration body.
 * @param {ForecastParams} forecastParams - The forecast parameters.
 * @param {SignUpFields} signUpData
 */
const parseRegisterBody = (forecastParams, signUpData) => {
  try {
    let forecastSex = undefined
    let forecastCurrentAgeMonth = undefined
    let forecastCountryOfResidence = undefined
    let forecastRetirementAge = undefined
    let payoutStartYear = undefined

    //If the forecast params are passed prioritize them
    if (forecastParams) {
      const {
        demographic_data: {
          full: { sex, current_age, country_of_residence },
        },
      } = forecastParams

      forecastSex = sex
      forecastCurrentAgeMonth = current_age
      forecastCountryOfResidence = country_of_residence
      forecastRetirementAge =
        forecastParams?.future_contributions?.contribution_params?.payout_age
    }

    const { age: forecastCurrentAgeNumber } = extractYearFromAgeMonthString(
      forecastCurrentAgeMonth
    )

    // Checks if the retirement age is present in the forscast
    if (forecastRetirementAge) {
      const { age: retirementAgeNumber } = extractYearFromAgeMonthString(
        forecastRetirementAge
      )

      // Calculate the payout start year
      payoutStartYear =
        CONSTANTS.CURRENT_YEAR +
        (retirementAgeNumber - forecastCurrentAgeNumber)
    }

    const {
      email,
      currentAge,
      firstName,
      lastName,
      emailUpdates,
      sex,
      country,
      referralCode,
      termsVersion,
    } = signUpData

    //Check for any undefined data
    if (
      !email ||
      currentAge < 0 ||
      !firstName ||
      !lastName ||
      !sex ||
      !country ||
      termsVersion === undefined ||
      termsVersion === null
    ) {
      throw new Error(
        `Invalid mandatory Sign up params got >>${email}<< >>${currentAge}<< >>${firstName}<< >>${lastName}<< >>${sex}<< >>${country}<< >>${termsVersion}<<`
      )
    }

    //0 just so javascript can type this as a number
    let birth_year = 0

    //If forecast year exists then use that
    if (forecastCurrentAgeNumber > 0) {
      birth_year = calculateUserBirthYear(forecastCurrentAgeNumber)
    } else {
      //Sign up params current_age is a number here
      birth_year = calculateUserBirthYear(currentAge)
    }

    let referralCodeToTier = undefined

    if (referralCode) {
      referralCodeToTier = `${CONSTANTS.REFERRAL_CODE_PREFIX}${referralCode}`
    }

    return {
      email: email?.trim()?.toLowerCase(),
      first_name: firstName?.trim(),
      last_name: lastName?.trim(),
      email_updates: emailUpdates,
      terms_and_conditions: termsVersion,
      birth_year,
      sex: forecastSex ?? sex,
      residency: forecastCountryOfResidence ?? country,
      referral_code: referralCodeToTier,
      payout_start_year: payoutStartYear,
    }
  } catch (error) {
    console.error(
      `Error occurred, while parsing register params error: ${error}`
    )
    captureExceptionWithSentry(
      new Error(`Error occurred API /create: ${JSON.stringify(error)}`)
    )
  }
}

RegisterForm.propTypes = {
  forecastPageRegisterModal: PropTypes.bool,
  forecastUserData: PropTypes.object,
  registerResidency: PropTypes.string,
  backButtonAction: PropTypes.func,
  hideSmallTitle: PropTypes.bool,
}

export default RegisterForm
