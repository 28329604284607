import Layout from '../../components/layout/Layout'
import { FUND_PENSION, PRIVATE } from '../../routes/Route'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useTranslate } from '../../hooks/useTranslate'
import NominalBalanceDashboard from './NominalBalanceDashboard'
import { useBankingService } from '../../state-management/banking/useBankingService'
import ErrorBoundaryAndSuspense from '../../components/error-handling/ErrorBoundaryAndSuspense'
import style from '../../scss/pages/NominalBalancePage.module.scss'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/**
 *  Renders the Current Nominal Balance page with the
 * `<NominalBalanceDashboard/>` component where the nominal balance graph is
 * displayed
 */
const NominalBalancePage = () => {
  const { bankContext, currentBankState, states } = useBankingService()
  //Hooks
  const t = useTranslate()

  const navigate = useCustomNavigation()
  const navigateToContributionHistory = () =>
    navigate(FUND_PENSION.CONTRIBUTION_HISTORY)

  return (
    <>
      <Layout
        pageTitle={t('NOMINAL_BALANCE_LABEL')}
        navigateTo={PRIVATE.MYTT_DASHBOARD}
        className={style['nominalBalancePage']}
        bottomSection={
          <NavigationButtons
            hideBackButton
            onClickSecond={() => navigateToContributionHistory()}
            secondButtonLabel={t('BANKING.PAGE_TITLE_CONTRIBUTION_HISTORY')}
            firstButtonTestID={UI_TEST_ID?.seeMyContributionHistory}
          />
        }
      >
        <ErrorBoundaryAndSuspense>
          <NominalBalanceDashboard
            bankContext={bankContext}
            states={states}
            currentBankState={currentBankState}
          />
        </ErrorBoundaryAndSuspense>
      </Layout>
    </>
  )
}

export default NominalBalancePage
