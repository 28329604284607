import style from '../../scss/components/ToggleButton.module.scss'
import { ToggleProps } from '../inputs/InputTypes.type'

/**
 * Renders a toggle button that contains active and inactive style
 */
const ToggleButton = ({ label, onChange, toggled, testID }: ToggleProps) => {
  return (
    <article
      onClick={onChange}
      className={style[`toggle-button`]}
      data-testid={testID}
    >
      <div
        className={
          style[`toggle-button__container${toggled ? '--active' : ''}`]
        }
      >
        <p className={style['toggle-button__label']}>{label}</p>
      </div>
    </article>
  )
}

export default ToggleButton
