import { Navigate } from 'react-router-dom'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { ReactNode } from 'react'

/**
 * Check is the user is not authenticated, if they are not authenticated the
 * page is rendered. If they are authenticated the user is redirected to the the
 * my tontine dashboard
 */
const PublicRoute = ({
  redirectPath,
  children,
}: {
  redirectPath: string
  children: ReactNode
}) => {
  const { isAuthenticated } = useAccountService()

  if (!isAuthenticated) {
    return children
  }

  return <Navigate to={redirectPath} />
}

export default PublicRoute
