import PropTypes from 'prop-types'
import { useRef, useState, useLayoutEffect } from 'react'
import { useDetectOutsideClick } from '../../../hooks/useDetectOutsideClick'
import TextInput from '../TextInput'
import Picker from './Picker'
import { formatDate, parseDateToNumbers } from '../../../utils/TSUtilFunctions'
import dayjs from 'dayjs'
import style from '../../../scss/components/DateInput.module.scss'

/**
 * @param {function():void} onChange Callback issued when the picker value
 * changes, contains a date object, not to be confused with Date object
 * @param {function():void} onClick Callback issued when the picker input is
 * clicked
 * @param {any} value Date values which can be a string or a date object
 * @param {boolean} readOnly Puts the component in read-only mode
 * @param {string} label Label for the date input
 * @param {string} errorMessage Renders an error message value validation does
 * not pass
 * @param {string} placeholder Placeholder for the date input
 * @param {string} suffixIcon Icon for the date input
 * @param {string} className CSS Icon targeting the main container element of
 * the component
 * @param {string} pickerMode Mode of the date picker, values are `months`,
 * `years`, `days`
 * @param {string} locale Locales used to format dates by, default value is
 * `en-US`
 * @param {object} dateFormat Object containing formatting options for the date
 * input displayed to the user
 * @param {boolean} openPickerByDefault If the date picker should be open by
 * default
 * @param {boolean} onlyMonths If the picker should be only in months mode
 * @param {boolean} alwaysOpen Date picker will always be open by default
 * @param {boolean=} optional
 *
 *
 *  Renders a date input with a date picker. The date picker opens
 * when the date input has been clicked
 */
const DateInput = ({
  onChange,
  onClick,
  value,
  readOnly,
  label,
  errorMessage,
  placeholder,
  suffixIcon,
  className,
  pickerMode = 'days',
  defaultValue,
  locale = 'en-US',
  openPickerByDefault,
  onlyMonths,
  validatorFunction,
  disabledMonthsFrom,
  disabledMonthsTo,
  disabledYearFrom,
  disabledYearTo,
  disabledDayFrom,
  disabledDayTo,
  extendedComponent,
  alwaysOpen,
  suffixText,
  dateFormat,
  pickerMonthFormat,
  optional,
}) => {
  const parsedValue = parseInitialDate(value)
  const pickerRef = useRef()
  const [showPicker, setShowPicker] = useState(openPickerByDefault)

  useDetectOutsideClick(pickerRef, () => setShowPicker(false || alwaysOpen))

  useLayoutEffect(() => {
    if (!readOnly) {
      pickerRef.current.style.display = showPicker ? '' : 'none'
    }
  }, [showPicker, readOnly])

  return (
    <article
      className={`${style[`date-picker`]} ${className}`}
      onClick={onClick}
    >
      <TextInput
        onChange={undefined}
        type="text"
        label={label}
        placeholder={placeholder}
        suffixIcon={(suffixIcon ?? suffixText) ? '' : suffixIcon}
        defaultValue={defaultValue}
        value={formatDate(
          parsedValue?.dateStringISO,
          dateFormat || 'MM/DD/YYYY'
        )}
        onClick={() => setShowPicker(true)}
        errorMessage={errorMessage}
        readOnly={readOnly}
        validatorFunction={validatorFunction}
        suffixText={suffixText}
        optional={optional}
        //TODO: Needed for testing!
        // because data test id is not being used
        className="date-picker__input"
      />

      {!readOnly && (
        <div ref={pickerRef}>
          <Picker
            pickerMonthFormat={pickerMonthFormat}
            locale={locale}
            parentBlock={style[`date-picker`]}
            setShowPicker={setShowPicker}
            defaultPickerMode={pickerMode}
            onChange={onChange}
            //Sets picker values
            value={parsedValue}
            onlyMonths={onlyMonths}
            disabledMonthsFrom={disabledMonthsFrom}
            disabledMonthsTo={disabledMonthsTo}
            disabledYearFrom={disabledYearFrom}
            disabledYearTo={disabledYearTo}
            // These props disable the chevrons on the top section
            disabledYearNext={
              //+1 means we disable the next year in order not to be clicked by
              //the chevrons
              disabledYearFrom === parsedValue?.year + 1
            }
            disabledMonthNext={
              disabledYearFrom === parsedValue?.year + 1 &&
              //+1 means we disable the next month in order not to be clicked by
              //the chevrons
              disabledMonthsFrom === parsedValue?.month + 1
            }
            disabledYearBack={
              //-1 means we disable currentYear-1 year in order not go below the
              //current year
              disabledYearTo === parsedValue?.year - 1
            }
            disabledMonthBack={
              disabledYearTo === parsedValue?.year - 1 &&
              disabledMonthsTo + 1 === parsedValue?.month
            }
            disabledDayFrom={disabledDayFrom}
            disabledDayTo={disabledDayTo}
          />
        </div>
      )}
      {extendedComponent && extendedComponent}
    </article>
  )
}

/**
 * Parses the value passed in to the `<DateInput />` component into
 * dateStringISO and date values as numbers and returns an object containing
 * both properties
 */
const parseInitialDate = (value) => {
  //Runs only when the dateStringISO is passed in as a string
  if (value?.dateStringISO && !value?.year && !value?.month && !value?.day) {
    return {
      ...parseDateToNumbers(value?.dateStringISO),
      dateStringISO: value?.dateStringISO,
    }
  }

  //Runs only when only date values are passed in as a number
  if (!value?.dateStringISO && value?.year && value?.month && value?.day) {
    //TODO: Check if this affects the timezone
    const nativeDateObject = new Date(value?.year, value?.month - 1, value?.day)

    return {
      ...value,
      dateStringISO: dayjs(nativeDateObject).format('YYYY-MM-DD'),
    }
  }

  //This is fine if all values are passed in
  return value
}

DateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.any,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.object,
  placeholder: PropTypes.string,
  suffixIcon: PropTypes.string,
  className: PropTypes.string,
  pickerMode: PropTypes.string,
  defaultValue: PropTypes.any,
  locale: PropTypes.string,
  dateFormat: PropTypes.string,
  openPickerByDefault: PropTypes.bool,
  onlyMonths: PropTypes.bool,
  validatorFunction: PropTypes.func,
  yearsOld: PropTypes.number,
  monthsOld: PropTypes.number,
  yearsMonthsOldLabel: PropTypes.string,
  futureYear: PropTypes.number,
  futureMonth: PropTypes.number,
  dob: PropTypes.string,
  disabledMonthsFrom: PropTypes.number,
  disabledMonthsTo: PropTypes.number,
  disabledYearFrom: PropTypes.number,
  disabledYearTo: PropTypes.number,
  extendedComponent: PropTypes.node,
  alwaysOpen: PropTypes.bool,
  disabledDayTo: PropTypes.number,
  disabledDayFrom: PropTypes.number,
  suffixText: PropTypes.any,
  pickerMonthFormat: PropTypes.string,
  optional: PropTypes.bool,
}

export default DateInput
