import { FaceScanProcessor } from './FaceScanProcessor'
import { getScanSessionToken } from './API'
import axios from 'axios'

// Public use only, do not import inside FaceTec Directory!
const scanType = {
  ENROLLMENT: 'ENROLLMENT',
  AUTHENTICATION: 'AUTHENTICATION',
  PHOTO_ID_SCAN: 'PHOTO_ID_SCAN',
}

/**
 * Checks if the passed in scan type is supported, before creating a
 * FaceScanSession
 *
 * @param {'AUTHENTICATION' | 'ENROLLMENT' | 'PHOTO_ID_SCAN'} scan
 */
const scanTypeSupported = (scan) => scanType?.[scan]

const initializeSDK = (config, onSDKinit) => {
  console.log(
    `FaceTecSDK version: ${FaceTecSDK.version()} \n Updated on 24/06/2024`
  )
  // Uses the path from web-build/public where the facetec dir is copied as-is
  FaceTecSDK.setResourceDirectory('./facetec/FaceTecSDK.js/resources')
  FaceTecSDK.setImagesDirectory('./facetec/FaceTecSDK.js/FaceTec_images')

  if (config.production) {
    FaceTecSDK.initializeInProductionMode(
      config.deviceKeyIdentifier,
      config.PublicFaceScanEncryptionKey,
      onSDKinit
    )
  } else {
    FaceTecSDK.initializeInDevelopmentMode(
      config.deviceKeyIdentifier,
      config.PublicFaceScanEncryptionKey,
      onSDKinit
    )
  }
}

// Starts a facescan
const startScan = ({
  scanType,
  email,
  onFaceScanStarted,
  signal,
  baseUrl,
  endpoint,
  authToken,
  onComplete,
  onEnrollmentOnlyDone,
}) => {
  if (scanTypeSupported(scanType)) {
    const handleSuccess = ({ session_token }) => {
      try {
        // Session token is obtained, instantiate FaceScanProcessor
        new FaceScanProcessor(
          session_token,
          scanType,
          email,
          baseUrl,
          endpoint,
          authToken,
          onComplete,
          onEnrollmentOnlyDone
        )
      } catch (error) {
        console.error(
          'Catastrophic error, could not instantiate FaceScanProcessor',
          error
        )
      }

      onFaceScanStarted?.()
    }

    const handleFailure = (error) => {
      if (!axios.isCancel(error)) {
        // If session token can't be fetched, issue complete callback with an
        // error, which will be handled where `startScan` is used
        onComplete?.(error)
        console.error(
          `Could not fetch session scan token, error from FaceBiometric:`,
          error
        )
      }
    }

    getScanSessionToken(`${baseUrl}${endpoint.sessionToken}`, signal)
      .then(handleSuccess)
      .catch(handleFailure)
  } else {
    console.error(
      `Processor scan type ${scanType} not supported or not provided`
    )
  }
}

export const FaceScanBiometrics = {
  initializeSDK,
  startScan,
  scanType,
}
