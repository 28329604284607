import { lazy } from 'react'
import {
  PRIVATE,
  MYTT_DASHBOARD,
  ACCOUNT,
  PERSONAL_DETAILS,
  FUND_PENSION,
  PAYOUT,
} from './Route'
import PayinAndPayoutHistory from '../pages/banking/PayinAndPayoutHistory'
import FundPensionMenu from '../components/navigation/FundPensionMenu'
// Already lazy loaded within the root component
import PayoutDetails from '../pages/banking/PayoutDetails'
import PayoutMenu from '../components/navigation/PayoutMenu'
import MyTontineDashboard from '../pages/mytt-dashboard/MyTontineMobileDashboard'
import NewsArticle from '../pages/mytt-dashboard/NewsArticle'
import NewsFeed from '../pages/mytt-dashboard/NewsFeed'
import NominalBalancePage from '../pages/mytt-dashboard/NominalBalancePage'
import TontinatorPage from '../pages/mytt-dashboard/TontinatorPage'
import Rewards from '../pages/referral/Rewards'
import RejectedID from '../pages/user-info/RejectedID'
import { PrivatePage } from './Route.type'
import Account from '../pages/user-info/Account'

const UpdatePhoneNumber = lazy(
  () => import('../pages/user-info/UpdatePhoneNumber')
)
const CloseAccount = lazy(() => import('../pages/user-info/CloseAccount'))
const PinSetupPage = lazy(() => import('../pages/authorization/PinSetupPage'))
const InvestmentAccountOpening = lazy(
  () => import('../pages/legal/InvestmentAccountOpening')
)
const ViewAgreements = lazy(() => import('../pages/legal/ViewAgreements'))
const PersonalDetails = lazy(() => import('../pages/user-info/PersonalDetails'))

/**
 * Pages that are meant to be accessed by authenticated users. Each index page
 * has child pages that are nested under it.
 *
 * You can specify if a certain private route requires some conditions to pass:
 *
 * - `writeProtected` - only allows the page to be accessed if the user has
 *   `write` permissions
 * - `l1KycRequired` - only allows the page to be accessed if the user has
 *   completed L1 KYC
 *
 * Both flags are allowed to be present at same time, or you can just specify
 * one flag
 */
const PrivatePages = [
  //MyTontine dashboard route route
  {
    rootPath: PRIVATE.MYTT_DASHBOARD,
    indexPage: <MyTontineDashboard />,
    childRoutes: [
      {
        path: MYTT_DASHBOARD.NOMINAL_BALANCE,
        page: <NominalBalancePage />,
      },
      {
        path: MYTT_DASHBOARD.FUNDED_PROGRESS,
        page: <MyTontineDashboard />,
      },
      {
        path: MYTT_DASHBOARD.NEWS_FEED,
        page: <NewsFeed />,
      },
      {
        path: `${MYTT_DASHBOARD.NEWS_FEED}/:news_id`,
        page: <NewsArticle />,
      },
      {
        path: MYTT_DASHBOARD.SANDBOX_TONTINATOR,
        page: <TontinatorPage />,
      },
      {
        path: MYTT_DASHBOARD.DASHBOARD_REWARDS,
        page: <Rewards />,
      },
    ],
  },
  //Account section ROOT Route
  {
    rootPath: PRIVATE.ACCOUNT,
    indexPage: <Account />,
    childRoutes: [
      {
        path: ACCOUNT.PERSONAL_DETAILS,
        page: <PersonalDetails />,
      },
      {
        path: PERSONAL_DETAILS.REJECTED_ID,
        page: <RejectedID />,
      },
      {
        path: PERSONAL_DETAILS.PHONE_NUMBER,
        writeProtected: true,
        page: <UpdatePhoneNumber />,
      },
      {
        path: ACCOUNT.REWARDS,
        page: <Rewards />,
      },
      {
        path: ACCOUNT.PIN,
        writeProtected: true,
        page: <PinSetupPage />,
      },
      {
        path: ACCOUNT.CLOSE_ACCOUNT,
        writeProtected: true,
        page: <CloseAccount />,
      },
      {
        path: ACCOUNT.VIEW_AGREEMENTS,
        page: <ViewAgreements />,
      },
      //FUND ROUTES
      {
        path: ACCOUNT.FUND_PENSION,
        l1KycRequired: true,
        page: <FundPensionMenu />,
      },
      {
        path: FUND_PENSION.CONTRIBUTION_HISTORY,
        l1KycRequired: true,
        page: <PayinAndPayoutHistory />,
      },
      {
        path: FUND_PENSION.INVESTMENT_ACCOUNT,
        l1KycRequired: true,
        writeProtected: true,
        page: <InvestmentAccountOpening />,
      },
      //PAYOUT ROUTES
      {
        path: ACCOUNT.PAYOUT,
        l1KycRequired: true,
        page: <PayoutMenu />,
      },
      {
        path: PAYOUT.HISTORY,
        l1KycRequired: true,
        page: <PayinAndPayoutHistory payoutHistory />,
      },
      {
        path: PAYOUT.SETUP,
        l1KycRequired: true,
        page: <PayoutDetails />,
      },
    ],
  },
] as Array<PrivatePage>

export { PrivatePages }
