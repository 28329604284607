import NavigationCard from '../../components/cards/NavigationCard'
import AccountSummary from '../../components/data-display/AccountSummary'
import NextPayoutCard from '../../components/feedback/NextPayoutCard'
import PageLayout from '../../components/layout/PageLayout'
import MobileAppBar from '../../components/navigation/MobileAppBar'
import UserFeedback from '../../components/other/UserFeedback'
import Header from '../../components/typography/Header'
import style from '../../scss/pages/MyTontineMobileDashboard.module.scss'
import { DASHBOARD_NAVIGATION, MYTT_DASHBOARD } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import FundedDashboard from './FundedDashboard'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { hasCompletedL1KYC } from '../../utils/UtilFunctions'
import { usePensionPlans } from '../../hooks/usePensionPlans'
import { useBankingService } from '../../state-management/banking/useBankingService'
import { useLocalization } from '../../hooks/useLocalization'
import { ASSET } from '../../constants/Assets'
import { renderNominalBalance } from '../../utils/TSUtilFunctions'

/**
 * Renders the MyTontine dashboard
 */
const MyTontineDashboard = () => {
  const t = useTranslate()
  const {
    context: { user_details },
  } = useAccountService()
  const { formatAmount } = useLocalization()
  const { bankContext } = useBankingService()
  const { data, isLoading, error } = usePensionPlans()

  return (
    <>
      <AccountSummary />
      <main className={style.myTontineDashboard}>
        <PageLayout containerHeight="auto-5" containerMt="nomt">
          <section className={style['myTontineDashboard__top-section']}>
            {hasCompletedL1KYC(user_details) && (
              <NextPayoutCard className={style['myTontineDashboard__card']} />
            )}

            <Header
              className={style['myTontineDashboard__header']}
              title={t('DASHBOARD.CHART.TITLE.EXPECTED.MONTHLY.INCOME')}
            />
          </section>

          <FundedDashboard data={data} error={error} isLoading={isLoading} />

          <section className={style['myTontineDashboard__bottom-section']}>
            <NavigationCard
              headerImage={ASSET.iconmtnominalbalanceUS}
              subTitle={t('NOMINAL_BALANCE_LABEL')}
              title={
                renderNominalBalance(formatAmount, bankContext, 'standard')
                  ?.formattedAmountWithSymbol ?? '-'
              }
              navigateTo={DASHBOARD_NAVIGATION.NOMINAL_BALANCE}
              showArrow
              variant="gray-dirty"
            />

            <NavigationCard
              title={t('MYTT_DASHBOARD.CARD_EDS_TITLE')}
              subTitle={t('EXPLORE.DIFFERENT.SCENARIOS.DASHBOARD.SUB')}
              headerImage={ASSET.iconmttontinator}
              navigateTo={DASHBOARD_NAVIGATION.TONTINATOR}
              showArrow
              variant="gray-dirty"
            />

            <NavigationCard
              title={t('INVITE_FRIENDS.GIVE_GET_STATEMENT')}
              subTitle={t('INVITE_FRIENDS.WITH_FRIENDS_CAPTION')}
              headerImage={ASSET.iconaccountrewardbicolor}
              navigateTo={DASHBOARD_NAVIGATION.DASHBOARD_REWARDS}
              showArrow
              variant="gray-dirty"
            />

            <NavigationCard
              headerImage={ASSET.blog}
              title={t('DASHBOARD.NEWS_FEED_TITLE')}
              subTitle={t('DASHBOARD.CARD_NEWS_FEED_TEXT')}
              navigateTo={MYTT_DASHBOARD.NEWS_FEED}
              showArrow
              variant="gray-dirty"
            />

            <UserFeedback />
          </section>
        </PageLayout>
      </main>
      {window.matchMedia('(max-width: 900px)').matches && (
        <MobileAppBar completedKyc={hasCompletedL1KYC(user_details)} />
      )}
    </>
  )
}

export default MyTontineDashboard
