import {
  IncomeForecastParams,
  PromiseCallbacks,
} from '../../types/CommonTypes.types'
import { requestIncomeForecast } from './ApiProvider'

/**
 * @legacy
 * Banking service API repository
 */
type TontineBanking = {
  /**
   * Makes a request to the tontinator API
   */
  requestIncomeForecast: (
    incomeForecastParams: Array<IncomeForecastParams & { paramsId: string }>,
    isAuthenticated: boolean,
    { onSuccess, onFailure, onFinally }: PromiseCallbacks,
    abortSignal: AbortSignal,
    authToken: string
  ) => void
}

export const tontineBanking: Readonly<TontineBanking> = {
  requestIncomeForecast,
}
