import lottie, { RendererType } from 'lottie-web'
import { useEffect } from 'react'
import { generateUniqueId, selectDomElement } from '../../utils/UtilFunctions'

type LottieAnimationProps = {
  loop: boolean
  autoplay: boolean
  animationName?: object
  style?: object
  className?: string
  containerID?: string
}

/**
 * @param  loop  Whether the animation should loop or not
 * @param  autoplay  Whether the animation should autoplay or not
 * @param  animationName  What the animation should be played, all of
 * the possible animations are located in `/constants/Animations` exported as
 * `ANIMATION` object
 * @param style Styling object
 * @param containerID Unique ID for the animation container generated every
 * render so animation collisions don't occur
 *
 * Plays an lottie animation from a provided JSON file, using the
 * `lottie-react` library. The animation will loop by default, and will autoplay.
 */
const LottieAnimation = ({
  loop,
  autoplay,
  animationName,
  style,
  className,
  containerID = `lottie-container${generateUniqueId()}`,
}: LottieAnimationProps): JSX.Element => {
  useLottieAnimation({
    animationData: animationName,
    container: `#${containerID}`,
    loop,
    autoplay,
    renderer: 'svg',
  })

  return <div id={containerID} className={className} style={style} />
}

type LottieWebProps = {
  animationData?: object
  container: string
  renderer: RendererType
  loop?: boolean
  autoplay?: boolean
}

/**
 * Creates an instance of lottie player and loads an animation. The player
 * instance gets destroyed when the component unmounts
 */
const useLottieAnimation = ({
  animationData,
  container,
  renderer,
  loop,
  autoplay,
}: LottieWebProps): void => {
  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      // DOM element where the animation will be contained
      container: selectDomElement(container) as Element,
      renderer,
      animationData,
      loop,
      autoplay,
    })

    return () => {
      if (animationInstance) {
        animationInstance.destroy()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default LottieAnimation
