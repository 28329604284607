import Icon from '../media-and-icons/Icon'
import PropTypes from 'prop-types'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/components/Carousel.module.scss'

/**
 * @param {function():void} previousSlide Navigates back to the previous slide
 * @param {function():void} nextSlide Navigates to the next slide
 * @param {boolean} hidePreviousControlArrow Hides the left control arrow
 * @param {boolean} hideNextControlArrow Hides the right control arrow
 *
 * @description Renders the carousel controls for navigating to the previous and next slide
 */
const CarouselControls = ({
  previousSlide,
  nextSlide,
  hidePreviousControlArrow,
  hideNextControlArrow,
}) => {
  return (
    <article className={style[`carousel__control-container`]}>
      {!hidePreviousControlArrow && (
        <span onClick={previousSlide}>
          <Icon
            className={`${style['carousel__control']} ${style['carousel__control-left']}`}
            fileName={ASSET.icononboardinarrowforward}
          />
        </span>
      )}
      {!hideNextControlArrow && (
        <span onClick={nextSlide}>
          <Icon
            className={`${style['carousel__control']} ${style['carousel__control-right']}`}
            fileName={ASSET.icononboardinarrowforward}
          />
        </span>
      )}
    </article>
  )
}

CarouselControls.propTypes = {
  previousSlide: PropTypes.func.isRequired,
  nextSlide: PropTypes.func.isRequired,
  hidePreviousControlArrow: PropTypes.bool,
  hideNextControlArrow: PropTypes.bool,
}

export default CarouselControls
