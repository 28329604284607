enum PageEvent {
  'viewed' = 'page_viewed',
}

enum LegendEvent {
  'toggled' = 'legend_item_toggled',
}

enum ErrorEvent {
  'encountered' = 'error_encountered',
}

enum CheckboxEvent {
  'toggled' = 'checkbox_toggled',
}

enum ButtonEvent {
  'clicked' = 'button_clicked',
}

enum CarouselEvent {
  'next_clicked' = 'carousel_next_clicked',
  'previous_clicked' = 'carousel_previous_clicked',
  'choose' = 'carousel_slide_choose',
}

enum SliderEvent {
  'moved' = 'slider_moved',
  'button_clicked' = 'slider_button_clicked',
}

enum ReferralLinkEvent {
  'share' = 'referral_link_share',
}

enum GraphEvent {
  'hover_start' = 'graph_hover_start',
  'hover_end' = 'graph_hover_end',
  'option_choose' = 'graph_option_choose',
}

enum RegisterFormEvent {
  'typed' = 'register_form_typed',
  'prefilled' = 'register_form_prefilled',
}

enum NavItemEvent {
  'clicked' = 'nav_item_clicked',
}

enum ForecastParamsEvent {
  sent = 'forecast_params_sent',
}

enum InputFieldEvent {
  typed = 'input_field_typed',
  clicked = 'input_field_clicked',
}

enum AuthEvent {
  'logged_in' = 'auth_logged_in',
  'logged_out' = 'auth_logged_out',
  'login_initiated' = 'auth_login_initiated',
  'signup' = 'auth_signed_up',
}

enum PensionPlan {
  'lite_plan_updated' = 'lite_plan_updated',
}

export {
  PageEvent,
  LegendEvent,
  ErrorEvent,
  CheckboxEvent,
  ButtonEvent,
  CarouselEvent,
  SliderEvent,
  ReferralLinkEvent,
  GraphEvent,
  RegisterFormEvent,
  NavItemEvent,
  ForecastParamsEvent,
  InputFieldEvent,
  AuthEvent,
  PensionPlan,
}
