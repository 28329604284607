import { useState } from 'react'
import PageContent from '../../components/data-display/PageContent'

import { useAccountService } from '../../state-management/authentication/useAccountService'
import { useTranslate } from '../../hooks/useTranslate'
import Layout from '../../components/layout/Layout'
import { idRejectionReason } from '../../utils/UtilFunctions'
import ListItems from '../../components/data-display/ListItems'
import FaceScan from '../authentication/FaceScan'
import { ACCOUNT_MENU } from '../../routes/Route'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { ASSET } from '../../constants/Assets'

/**
 * Renders reasons why an ID has been rejected and prompts the user
 * to try again
 */
const RejectedID = () => {
  const navigate = useCustomNavigation()
  const {
    context: { user_details },
  } = useAccountService()
  const t = useTranslate()

  const [startFaceScan, setStartFaceScan] = useState(false)

  return (
    <Layout
      containerWidth="small"
      pageTitle={t('PERSONAL_DETAILS.FORM_TITLE')}
      navigateTo={ACCOUNT_MENU.PERSONAL_DETAILS}
      bottomSection={
        <NavigationButtons
          onClickFirst={() => navigate(ACCOUNT_MENU.PERSONAL_DETAILS)}
          secondButtonLabel={t('COMMON.TRY_AGAIN')}
          onClickSecond={() => setStartFaceScan(true)}
        />
      }
    >
      {startFaceScan && (
        <FaceScan
          scanType="match-id"
          asModal
          onSuccessfulScan={() => setStartFaceScan(false)}
          onClickExitScan={() => setStartFaceScan(false)}
        />
      )}
      <PageContent
        icon={ASSET.iconaccountcloecirclemall}
        locizeKey={'REJECTION_EXPLANATION'}
        mainContent={
          <ListItems
            arrayOfItems={idRejectionReason(
              (user_details?.id_rejection_reason as unknown as Array<string>) ??
                []
            )}
          />
        }
      />
    </Layout>
  )
}

export default RejectedID
