import { useState } from 'react'
import { idVerificationStatusText } from '../../utils/UtilFunctions'
import style from '../../scss/components/MissingInformation.module.scss'
import { PERSONAL_DETAILS } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import FaceScan from '../authentication/FaceScan'
import Card from '../../components/cards/Card'
import NavigationCard from '../../components/cards/NavigationCard'
import { idVerificationAlertStatus } from '../../utils/TSUtilFunctions'

/**
 * Renders missing L1 KYC information
 */
const MissingInformation = () => {
  const [faceScan, setFaceScan] = useState(false)

  const t = useTranslate()
  const { context } = useAccountService()
  const { user_details } = context

  const showFaceScan = () => {
    setFaceScan(true)
  }

  const faceNotSubmitted =
    idVerificationAlertStatus(user_details?.id_review_status ?? '') !==
    'completed'

  return (
    <div className={style.missingInformation}>
      <div className={style['missingInformation__container']}>
        <div className={style['missingInformation__explainer']}>
          {t('MISSING_DETAILS.EXPLAINER_TEXT')}
        </div>
      </div>

      {faceScan && (
        <FaceScan
          scanType="match-id"
          asModal
          onClickExitScan={() => setFaceScan(false)}
        />
      )}
      <Card
        title={t('KYC.FACE_ENROLLED')}
        onClick={user_details?.face_enrolled ? undefined : showFaceScan}
        alert={user_details?.face_enrolled ? 'completed' : 'warn'}
        variant="gray-dirty"
        showArrow
        arrowInvisible={user_details?.face_enrolled}
        interactEnabled={!user_details?.face_enrolled}
      />
      <Card
        title={
          idVerificationStatusText(
            user_details?.id_review_status as string
          ) as string
        }
        onClick={faceNotSubmitted ? showFaceScan : undefined}
        alert={idVerificationAlertStatus(user_details?.id_review_status ?? '')}
        variant="gray-dirty"
        showArrow
        arrowInvisible={!faceNotSubmitted}
        interactEnabled={faceNotSubmitted}
      />
      <NavigationCard
        title={t('ACCOUNT.PHONE_NUMBER_INPUT_LABEL')}
        alert={user_details?.phone_number ? 'completed' : 'warn'}
        variant="gray-dirty"
        showArrow
        navigateTo={PERSONAL_DETAILS.PHONE_NUMBER}
        interactEnabled
      />
    </div>
  )
}

export default MissingInformation
