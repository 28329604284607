import PropTypes from 'prop-types'

import { useTranslate } from '../../hooks/useTranslate'
import Header from '../../components/typography/Header'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import FormProgress from '../../components/feedback/FormProgress'
import FormsLayout from '../../components/layout/FormsLayout'
import SelectSex from '../../components/buttons/SelectSex'
import CountryDropdown from '../../components/inputs/CountryDropdown'
import style from '../../scss/layout/OnboardingForm.module.scss'

const SexForm = ({
  setShowForms,
  formHeaderText,
  previousStep,
  progress,
  setFormData,
  formData,
}) => {
  const t = useTranslate()

  const nextStep = () => {
    setShowForms({
      sexForm: false,
      ageForm: Boolean(formData?.sex && formData?.countryOfResidence),
      contributionForm: false,
    })
  }

  const handleSexSetting = (sex) => {
    setFormData((previousData) => ({
      ...previousData,
      sex,
    }))
  }

  const handleCountrySetting = (countryOfResidence) => {
    setFormData((previousData) => ({
      ...previousData,
      countryOfResidence,
    }))
  }

  return (
    <main className={style['onboarding-form']}>
      <Header title={formHeaderText} variant="spaced" />

      <FormProgress progress={progress} skipSpacing />

      <FormsLayout>
        <SelectSex sex={formData?.sex} setSex={handleSexSetting} />

        <CountryDropdown
          value={formData?.countryOfResidence}
          onChange={handleCountrySetting}
          label={t('INPUT_LABEL.COUNTRY')}
          optional
        />
      </FormsLayout>
      <NavigationButtons
        disabledSecond={!(formData?.sex && formData?.countryOfResidence)}
        onClickSecond={nextStep}
        onClickFirst={previousStep}
        secondButtonLabel={t('BUTTON_LABEL.NEXT')}
        backButtonWhite
      />
    </main>
  )
}

SexForm.propTypes = {
  setShowForms: PropTypes.func,
  setFormData: PropTypes.func,
  formHeaderText: PropTypes.string,
  previousStep: PropTypes.func,
  progress: PropTypes.string,
  formData: PropTypes.object,
}

export default SexForm
