import axios from 'axios'
import { API_STATUS } from '../../constants/ApiErrors'
import { axiosConfig } from '../../api/RequestConfig'
import { writeToConsoleAndIssueAlert } from '../StateUtils'
import { FieldValue } from '../../pages/legal/CommonLegalTypes.types'
import { API } from '../../api/API'
import {
  mapResponseToRequest,
  parsePayloadToParams,
  processAndFlatAgreement,
} from './LegalUtils'
import {
  LegalMachineContext,
  LegalMachineEvent,
  InvestmentAccountFormType,
  TontineProduct,
  Agreement,
  AgreementContents,
} from './LegalMachineTypes.types'
import { Buffer } from 'buffer'

/**
 * Fetches form fields from the backend based on the `flow` and `formType`. Also
 * used for retrieving the form's saved values if they were saved by
 * `saveFormProgress`
 */
const fetchForm = async (_: LegalMachineContext, event: LegalMachineEvent) => {
  try {
    const { formTypes, product } = event?.payload as {
      formTypes: Array<InvestmentAccountFormType>
      product: TontineProduct
    }

    if (formTypes?.length <= 0 || !product) {
      throw new TypeError(
        `No form types specified got: >>${formTypes.toString()}<< or no product specified got >>${product}<<`
      )
    }

    const params = parsePayloadToParams(formTypes, product)

    const { status, data }: { status: number; data: Array<Array<unknown>> } =
      await axios.get(
        `${API.readInvestmentFormFields}/${params}`,
        axiosConfig({
          signal: event?.payload?.abortController?.signal,
          authToken: event?.payload?.authToken,
        })
      )

    const responseMatchedToType = mapResponseToRequest({
      requestArray: formTypes,
      responseArray: data,
    })

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback && event?.payload?.successCallback(data)
      return responseMatchedToType
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Saves a form's progress for the user, `flow` and `formType` need to be passed
 * in. Only saving editable fields is supported
 */
const saveFormProgress = async (
  _: LegalMachineContext,
  event: LegalMachineEvent
) => {
  try {
    const { formType, product, formData } = event?.payload as {
      product: TontineProduct
      formType: InvestmentAccountFormType
      formData: Array<[string, FieldValue]>
    }

    if (!product || !formType) {
      throw new TypeError(
        `Form type or product not specified  got product: >>${product}<< formType >>${formType}<<`
      )
    }

    const { status } = await axios.post(
      `${API.saveFormProgress}/${formType}/${product}`,
      formData,
      axiosConfig({ authToken: event?.payload?.authToken })
    )

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)
      return event?.payload
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Submits a form based on passed in `flow`, `formType` and `formData`.
 * `formData` must match the `formType`
 */
const submitForm = async (_: LegalMachineContext, event: LegalMachineEvent) => {
  try {
    const { formType, product, formData } = event?.payload as {
      product: TontineProduct
      formType: InvestmentAccountFormType
      formData: Array<[string, FieldValue]>
    }

    if (!product || !formType) {
      throw new TypeError(
        `Form type or product not specified  got product: >>${product}<< formType >>${formType}<<`
      )
    }

    const { status } = await axios.post(
      `${API.submitForm}/${formType}/${product}`,
      formData,
      axiosConfig({ authToken: event?.payload?.authToken })
    )

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)

      return event?.payload
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Fetches an agreement containing legal text
 */
const fetchAgreement = async (
  _: LegalMachineContext,
  event: LegalMachineEvent
) => {
  try {
    const { agreementTypes } = event?.payload as {
      agreementTypes: Array<Agreement>
    }

    if (agreementTypes?.length <= 0) {
      throw new TypeError(
        `At least 1 agreement type is needed in order to get an agreement`
      )
    }

    const response = await axios.get(
      `${API.getAgreement}/${parsePayloadToParams(agreementTypes)}/latest`,
      axiosConfig({
        signal: event?.payload?.abortController?.signal,
        authToken: event?.payload?.authToken,
      })
    )

    const { status, data } = response as {
      status: number
      data: Array<[Agreement, AgreementContents]>
    }

    const processedResponse = processAndFlatAgreement(data)

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(processedResponse)

      return processedResponse
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.abortController?.abort()
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

/**
 * Signs an agreement using an unique agreement type
 */
const signAgreement = async (
  _: LegalMachineContext,
  event: LegalMachineEvent
) => {
  try {
    if (
      !event?.payload ||
      !event?.payload?.signedAgreementData ||
      !event?.payload?.agreementType
    ) {
      throw new TypeError(
        `No agreementData or type found, check payload got >> ${JSON.stringify(event?.payload)} << `
      )
    }

    const { signedAgreementData, agreementType } = event.payload

    const version =
      signedAgreementData?.signedAgreementContents?.[agreementType]?.version

    const response = await axios.put(
      `${API.signAgreement}/${agreementType}/${version}`,
      null,
      axiosConfig({ authToken: event?.payload?.authToken })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)

      return event?.payload
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Fetches a PDF version of a submitted form with data
 */
const getFormAsPdf = async (
  _: LegalMachineContext,
  event: LegalMachineEvent
) => {
  try {
    if (!event?.output) {
      throw new TypeError(`No data received from previous event`)
    }

    // Different event data on different call. For the submit call we don't have this
    let formType = Object.keys(event.output)[0]

    // Only present when user submits the form
    if (event?.output?.formType) {
      formType = event?.output?.formType
    }

    // Ideally should be Crawford on both
    const translateAtm: { [key: string]: string } = {
      InvestmentAccountOpening: 'Crawford',
    }

    const response = await axios.get(
      `${API.readInvestmentFormFields}/${translateAtm[formType]}/generate`,
      {
        ...axiosConfig({ authToken: event?.output?.authToken }),
        // Needs to be in binary!, otherwise the encoding is messed up
        // and will be broken
        responseType: 'arraybuffer',
      }
    )
    const { status, data } = response as {
      status: number
      data: string
    }

    const jsonObject = {
      // Decodes the binary pdf data to base64 string so it can be used in an iframe
      pdfBase64: Buffer.from(data, 'binary').toString('base64'),
      formType,
    }

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback?.(jsonObject)

      return jsonObject
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

export {
  fetchForm,
  saveFormProgress,
  submitForm,
  fetchAgreement,
  signAgreement,
  getFormAsPdf,
}
