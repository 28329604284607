import { useState, useEffect } from 'react'
import Rating from './Rating'
import Button from '../buttons/Button'
import { useTranslate } from '../../hooks/useTranslate'
import Icon from '../media-and-icons/Icon'
import { tontineFeedback } from '../../api/legacy/Feedback'
import { toast } from 'react-toastify'
import ToastMessage from '../feedback/ToastMessage'
import PropTypes from 'prop-types'
import TextInput from '../inputs/TextInput'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/components/UserFeedback.module.scss'

/**
 * @description Provides rating and text feedback modal when the rating has been saved
 * The text feedback is **optional**
 */
const UserFeedback = ({
  ratingOpenDefault = false,
  closeModal,
  onSuccessfulSubmit,
}) => {
  const {
    context: { authToken },
  } = useAccountService()
  const t = useTranslate()
  const [openRating, setOpenRating] = useState(ratingOpenDefault)
  const [loading, setLoading] = useState(false)

  const [feedbackId, setFeedbackId] = useState('')

  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState(0)
  //Needs to be null string so the API does not fail if the user does not submit
  //any text feedback
  const [textFeedback, setTextFeedback] = useState('')
  const [submittedFeedback, setSubmittedFeedback] = useState(false)

  const submitFeedback = () => {
    setLoading(true)
    const handleSuccessfulSubmit = () => {
      toast.success(
        <ToastMessage
          title={t('USER_FEEDBACK.SUBMITTED_TITLE')}
          content={t('USER_FEEDBACK.SUBMITTED_CONTENT')}
        />
      )
      window.scrollTo(0, 0)
      setSubmittedFeedback(true)
      onSuccessfulSubmit && onSuccessfulSubmit()
      setLoading(false)
    }

    const handleFailedSubmit = () => {
      toast.error(
        <ToastMessage
          title={t('USER_FEEDBACK.FAILED_SUBMIT_TITLE')}
          content={t('USER_FEEDBACK.FAILED_SUBMIT_CONTENT')}
        />
      )
      setLoading(false)
    }

    tontineFeedback.saveTextFeedback(textFeedback, feedbackId, authToken, {
      onSuccess: handleSuccessfulSubmit,
      onFailure: handleFailedSubmit,
    })
  }

  //TODO: Do not do it like this or maybe this is a good solution?
  useEffect(() => {
    if (feedbackId && openRating) {
      window.scrollTo(0, 1300)
    }
  }, [feedbackId, openRating])

  return submittedFeedback ? (
    <></>
  ) : (
    <article className={style.userFeedback}>
      <div
        className={style['userFeedback__container']}
        onClick={
          ratingOpenDefault
            ? undefined
            : () => setOpenRating((prevState) => !prevState)
        }
      >
        <h3 className={style['userFeedback__title']}>
          {t('USER_FEEDBACK.TITLE')}
        </h3>
        {!ratingOpenDefault && (
          <Icon
            fileName={ASSET.icononboardinarrowforward}
            className={style[`userFeedback--${openRating ? 'open' : 'closed'}`]}
          />
        )}
        {ratingOpenDefault && (
          //Only renders on desktop if the UserFeedback component is a modal
          <Icon
            className={style[`userFeedback__close-button`]}
            fileName={ASSET.iconaccountcloetoatmeae}
            onClick={closeModal}
          />
        )}
      </div>

      {openRating && (
        <Rating setFeedbackId={setFeedbackId} setRating={setRating} />
      )}
      {feedbackId && openRating && (
        <>
          <TextInput
            value={textFeedback}
            onChange={setTextFeedback}
            isTextArea
            placeholder={t('FEEDBACK_MODAL.PLACEHOLDER_MESSAGE')}
          />
          <Button
            className={style[`userFeedback__submit-btn`]}
            onClick={submitFeedback}
            loading={loading}
            textOnLoading={t('LOADING_TEXT')}
          >
            {t('FEEDBACK_MODAL.SUBMIT_BUTTON')}
          </Button>
        </>
      )}
    </article>
  )
}

UserFeedback.propTypes = {
  ratingOpenDefault: PropTypes.bool,
  closeModal: PropTypes.func,
  onSuccessfulSubmit: PropTypes.func,
}

export default UserFeedback
