import { useMemo } from 'react'
import { getSupportedTontinatorParams } from '../utils/TSUtilFunctions'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { CONSTANTS } from '../constants/ConstantValues'
/**
 * Returns memorized supported country information
 */
export const useSupportedCountries = (
  alpha3CountryCode?: string,
  onCountryChanged?: (supportedCountry?: object) => void
) => {
  const {
    context: { user_details },
  } = useAccountService()

  const supportedCountry = useMemo(() => {
    onCountryChanged &&
      onCountryChanged(
        getSupportedTontinatorParams(
          alpha3CountryCode ??
            user_details?.residency ??
            CONSTANTS.FALLBACK_COUNTRY_CODE
        )
      )

    return getSupportedTontinatorParams(
      alpha3CountryCode ??
        user_details?.residency ??
        CONSTANTS.FALLBACK_COUNTRY_CODE
    )
  }, [alpha3CountryCode, user_details?.residency, onCountryChanged])

  return {
    supportedCountry,
  }
}
