import { useUserInfo } from '../hooks/InputHandlers'
import { useInputValidation } from '../hooks/useInputValidation'
import { InputValidationProps } from '../components/inputs/InputTypes.type'

/**
 * Custom hook for handling email-related functionalities.
 */
const useEmailHandling = () => {
  const { userEmail, setUserEmail } = useUserInfo()
  const { emailValidated, validateEmail } =
    useInputValidation() as unknown as InputValidationProps

  const handleTyping = (email: string) => setUserEmail(email.trim())

  return {
    userEmail,
    setUserEmail,
    emailValidated,
    validateEmail,
    handleTyping,
  }
}

export default useEmailHandling
