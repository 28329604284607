import DateSliderBox from './date-box/DateSliderBox'
import Range from './Range'
import PropTypes from 'prop-types'
import { CONSTANTS } from '../../../constants/ConstantValues'
import MonthAndYearBubble from './MonthAndYearBubble'
import InputLabel from '../InputLabel'
import {
  extractYearFromAgeMonthString,
  numberToAgeMonthString,
  calculateRetirementValues,
} from '../../../utils/TSUtilFunctions'
import style from '../../../scss/components/DateSliderBox.module.scss'

const { DECEMBER, JANUARY } = CONSTANTS
/**
 * @param {function=} onChangeRange A callback function that is called when the
 * range thumb is dragged
 * @param {array=} steps Array of steps for the slider, `steps` contains certain
 * values that the slider can take
 * @param {string=} yearHeadLabel An label for the year heading of the slider.
 * @param {string=} monthHeadLabel An label for the month heading of the slider.
 * @param {object=} value An object that describes the current range of the
 * slider, 'from' and 'to' properties should be instance of Date.
 * @param {function=} setValue A function that updates the value of the slider.
 * @param {string=} locale Locale for the months to be formatted
 * @param {string | number=} yearsOldOnRetirementDate Value displayed for the first
 * slider thumb bubble
 * @param {string | number=} monthsOldOnRetirementDate Value displayed for the second
 * slider thumb bubble
 * @param {string=} yearsOldOnRetirementDateLabel Label for the first bubble value
 * @param {string=} monthsOldOnRetirementDateLabel Label for the second bubble value
 * @param {string=} label Label that is rendered on top of the component
 * @param {string=} caption Used for writing a caption in the middle of the range
 * slider and the top
 * @param {function=} setSteps Sets additional steps in the steps array used by
 * the `Range` component
 *
 * @description Date slider that renders a range input to select retirement year
 * and header component where you can adjust the years or months. Adjusting the
 * months does not affect the range slider.
 */
const DateSlider = ({
  sliderSteps,
  yearHeadLabel,
  monthHeadLabel,
  value,
  setValue,
  locale,
  yearsOldOnRetirementDateLabel,
  yearsOldOnRetirementDate,
  monthsOldOnRetirementDateLabel,
  monthsOldOnRetirementDate,
  label,
  caption,
  userDetails,
  ageThresholds,
}) => {
  //Maximum AGE and YEAR thresholds
  const { age: maxThresholdYearsOld, month: maxThresholdMonthsOld } =
    extractYearFromAgeMonthString(ageThresholds?.payout_start?.max_age)
  const { age: maxThresholdYear, month: maxThresholdMonth } =
    extractYearFromAgeMonthString(ageThresholds?.payout_start?.max_year_month)

  //Minimum AGE and YEAR thresholds
  const { age: minThresholdYearOld, month: minThresholdMonthsOld } =
    extractYearFromAgeMonthString(ageThresholds?.payout_start?.min_age)
  const { age: minThresholdYear, month: minThresholdMonth } =
    extractYearFromAgeMonthString(ageThresholds?.payout_start?.min_year_month)

  //Disables the UI increment MONTH chevrons
  const maxLimitsReached = checkMaxLimits(
    value?.year,
    maxThresholdYear,
    value?.month,
    maxThresholdMonth
  )

  //Disables the UI decrement MONTH chevrons
  const minLimitsReached = checkMinLimits(
    value?.year,
    minThresholdYear,
    value?.month,
    minThresholdMonth
  )

  /**
   * Does not allow the months to go out of range, always returns a value
   * between 1 and 12
   */
  const adjustMonth = (month) => ((month + 11) % DECEMBER) + 1

  /**
   * Does not allow the year to go bellow the `sliderSteps` first step, because
   * the first step is the minimum year the user can retire
   */
  const adjustYear = (year, month) => {
    // Ensure that year does not go beyond the maximum slider step value
    const maxYear = sliderSteps[sliderSteps.length - 1]
    if (year > maxYear) {
      year = maxYear
    }

    // Ensure that year does not go below the minimum slider step value
    const minYear = sliderSteps[0]
    if (year < minYear) {
      year = minYear
    }
    //If user keeps clicking on the increment month chevron, and reaches january
    //then update the year +1
    if (month === JANUARY) {
      year += 1
    }

    return year
  }

  /**
   * Increment month value by one calling the adjustMonth function to adjust the
   * value and then updating the month in the state
   */
  const handleMonthsIncrementByOne = () =>
    setValue((prevValueObject) => {
      const adjustedMonth = adjustMonth(prevValueObject?.month + 1)
      const adjustedYear = adjustYear(prevValueObject?.year, adjustedMonth)

      const {
        age: yearsOldOnRetirementDate,
        months: monthsOldOnRetirementDate,
      } = calculateRetirementValues(userDetails, {
        year: adjustedYear,
        month: adjustedMonth,
      })

      return {
        ...prevValueObject,
        month: adjustedMonth,
        year: adjustedYear,
        yearsOld: yearsOldOnRetirementDate,
        monthsOld: monthsOldOnRetirementDate,
        retirementAge: numberToAgeMonthString(
          yearsOldOnRetirementDate,
          monthsOldOnRetirementDate
        ),
      }
    })

  /**
   * Decrement month value by one calling the adjustMonth function to adjust the
   * value and then updating the month in the state
   */
  const handleMonthsDecrementByOne = () =>
    setValue((prevValueObject) => {
      let adjustedMonth = adjustMonth(prevValueObject?.month - 1)
      let adjustedYear = prevValueObject?.year

      // Decrease the year by 1 if the adjusted month is December
      if (adjustedMonth === DECEMBER) {
        adjustedYear -= 1
      }

      const { age: ageOnYear, months: monthsOnYear } =
        calculateRetirementValues(userDetails, {
          year: adjustedYear,
          month: adjustedMonth,
        })

      //Does not allow month decrement to be below limits
      return {
        ...prevValueObject,
        month: adjustedMonth,
        year: adjustedYear,
        yearsOld: ageOnYear,
        monthsOld: monthsOnYear,
        retirementAge: numberToAgeMonthString(ageOnYear, monthsOnYear),
      }
    })

  /**
   * Increments of the year by one, by using a setState function
   */
  const handleYearsIncrement = () => {
    setValue((prevValueObject) => {
      let year = prevValueObject?.year

      const maxLimitsReachedHere = checkMaxLimits(
        //We need to use the "next" year in order to prevent
        //the year going out of threshold limits, before it is committed
        //to the state
        year + 1,
        maxThresholdYear,
        value?.month,
        maxThresholdMonth
      )

      if (maxLimitsReachedHere) {
        return {
          ...prevValueObject,
          year: maxThresholdYear,
          month: maxThresholdMonth,
          yearsOld: maxThresholdYearsOld,
          monthsOld: maxThresholdMonthsOld,
          retirementAge: numberToAgeMonthString(
            maxThresholdYearsOld,
            maxThresholdMonthsOld
          ),
        }
      } else {
        //No thresholds limits  have been reached,
        //safely increment the year
        year++
      }

      const {
        age: yearsOldOnRetirementDate,
        months: monthsOldOnRetirementDate,
      } = calculateRetirementValues(userDetails, {
        year,
        month: prevValueObject?.month,
      })

      return {
        ...prevValueObject,
        year,
        yearsOld: yearsOldOnRetirementDate,
        monthsOld: monthsOldOnRetirementDate,
        retirementAge: numberToAgeMonthString(
          yearsOldOnRetirementDate,
          monthsOldOnRetirementDate
        ),
      }
    })
  }

  /**
   * Decrements of the year by one, uses the `onChangeRangeSlider` function to
   * update the the year state
   */
  const handleYearsDecrement = () => onChangeRangeSlider(value?.year - 1)

  /**
   * Handler when the range thumb is dragged
   */
  const onChangeRangeSlider = (year) => {
    //Sets the retirement year by dragging the range thumb
    setValue((prevValueObject) => {
      const {
        age: yearsOldOnRetirementDate,
        months: monthsOldOnRetirementDate,
      } = calculateRetirementValues(userDetails, {
        year,
        month: prevValueObject?.month,
      })

      // Max limits reached while dragging the range slider
      if (
        checkMaxLimits(
          year,
          maxThresholdYear,
          prevValueObject?.month,
          maxThresholdMonth
        )
      ) {
        return {
          ...prevValueObject,
          year: maxThresholdYear,
          month: maxThresholdMonth,
          yearsOld: maxThresholdYearsOld,
          monthsOld: maxThresholdMonthsOld,
          retirementAge: numberToAgeMonthString(
            maxThresholdYearsOld,
            maxThresholdMonthsOld
          ),
        }
      }

      //Min limits reached while dragging the range slider
      if (
        checkMinLimits(
          year,
          minThresholdYear,
          prevValueObject?.month,
          minThresholdMonth
        )
      ) {
        return {
          ...prevValueObject,
          year: minThresholdYear,
          month: minThresholdMonth,
          yearsOld: minThresholdYearOld,
          monthsOld: minThresholdMonthsOld,
          retirementAge: numberToAgeMonthString(
            minThresholdYearOld,
            minThresholdMonthsOld
          ),
        }
      }

      return {
        ...prevValueObject,
        year,
        yearsOld: yearsOldOnRetirementDate,
        retirementAge: numberToAgeMonthString(
          yearsOldOnRetirementDate,
          monthsOldOnRetirementDate
        ),
      }
    })
  }

  return (
    <article>
      <InputLabel label={label} />
      <div className={style['date-slider-box__bg']}>
        <DateSliderBox
          year={value?.year}
          month={value?.month}
          onClickIncrementMonths={handleMonthsIncrementByOne}
          onClickDecrementMonths={handleMonthsDecrementByOne}
          onClickIncrementYears={handleYearsIncrement}
          onClickDecrementYears={handleYearsDecrement}
          yearLabel={yearHeadLabel}
          monthLabel={monthHeadLabel}
          locale={locale}
          disabledIncrementYear={value?.year >= maxThresholdYear}
          disabledIncrementMonth={maxLimitsReached}
          disabledDecrementYear={value?.year <= minThresholdYear}
          disabledDecrementMonth={minLimitsReached}
        >
          <InputLabel
            label={caption}
            className={style['date-slider-box__caption']}
          />
          <Range
            steps={sliderSteps}
            onChange={onChangeRangeSlider}
            value={value?.year}
            thumbBubble={
              <MonthAndYearBubble
                year={yearsOldOnRetirementDate}
                month={monthsOldOnRetirementDate}
                yearLabel={yearsOldOnRetirementDateLabel}
                monthLabel={monthsOldOnRetirementDateLabel}
              />
            }
            className={style[`date-slider-box__range`]}
          />
        </DateSliderBox>
      </div>
    </article>
  )
}

/**
 * Checks if the threshold month and year are withing MAX limits
 */
const checkMaxLimits = (
  currentYear,
  thresholdYear,
  currentMonth,
  thresholdMonth
) => currentYear >= thresholdYear && currentMonth >= thresholdMonth

/**
 * Checks if the threshold month and year are withing MIN limits
 */
const checkMinLimits = (
  currentYear,
  thresholdYear,
  currentMonth,
  thresholdMonth
) => currentYear <= thresholdYear && currentMonth <= thresholdMonth

DateSlider.propTypes = {
  sliderSteps: PropTypes.array.isRequired,
  yearHeadLabel: PropTypes.string,
  monthHeadLabel: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  locale: PropTypes.string,
  yearsOldOnRetirementDateLabel: PropTypes.string,
  yearsOldOnRetirementDate: PropTypes.any,
  monthsOldOnRetirementDateLabel: PropTypes.string,
  monthsOldOnRetirementDate: PropTypes.any,
  caption: PropTypes.string,
  label: PropTypes.string,
  setSteps: PropTypes.func,
  userDetails: PropTypes.any.isRequired,
  ageThresholds: PropTypes.object,
}

export default DateSlider
