import { CONSTANTS } from '../../constants/ConstantValues'
import { AuthMachineEvent } from './AuthMachineTypes.type'
import { isLite } from '../../config/lite'

/**
 * Prevents the machine from attempting to refresh the session if there is no
 * refresh token present in session storage
 */
const hasRefreshToken = () =>
  Boolean(sessionStorage.getItem(CONSTANTS.AUTH_MACHINE_KEY))

/**
 * Makes sure all the needed auth data is present in order to allow a state transition
 */
const isAuthenticated = ({ event }: { event: AuthMachineEvent }) => {
  const hasAllAuthData =
    Boolean(event?.output?.authTokenInfo?.authToken) &&
    Boolean(event?.output?.authTokenInfo?.permissions) &&
    Boolean(event?.output?.authTokenInfo?.refreshToken) &&
    Boolean(event?.output?.userAccountInfo)

  if (hasAllAuthData) {
    return true
  }

  // Do NOT log the missing data because it is considered very sensitive data !!
  console.error(
    `Did not get all necessary data from executing event ${event?.type}, state transition will be prevented by guard`
  )
  return false
}

/**
 * Sends a magic login email if the app is not in Lite build
 */
const isNotLiteBuild = () => !isLite

/**
 * Starts the verification flow if the user has a verification token in order
 * not to spam missing key error
 */
const shouldStartVerifying = ({ event }: { event: AuthMachineEvent }) => {
  const verifyToken = localStorage?.getItem(CONSTANTS.LITE_TOKEN) as string

  return Boolean(event?.payload?.verifyToken) || Boolean(verifyToken)
}

export {
  isAuthenticated,
  hasRefreshToken,
  isNotLiteBuild,
  shouldStartVerifying,
}
