import Icon from '../media-and-icons/Icon'
import PropTypes from 'prop-types'
import style from '../../scss/layout/MobileHeader.module.scss'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { getGreeting } from '../../utils/TSUtilFunctions'
import { To } from 'react-router-dom'
import { ASSET } from '../../constants/Assets'

type MobileHeaderType = {
  pageTitle?: string
  to?: To
  onClick?: () => void
  className?: string
  hideBackButton?: boolean
  rootScreenHeader?: boolean
}

/**
 * Mobile header rendered only on Mobile devices. Renders a back button that
 * takes the user to the previous page the came from, also renders the page
 * title in the middle and a logo on the most right side
 */
const MobileHeader = ({
  pageTitle,
  to,
  onClick,
  className,
  hideBackButton,
  rootScreenHeader,
}: MobileHeaderType) => {
  const navigate = useCustomNavigation()
  //Click action checks if an additional action is passed in so the
  //back button can be used for navigation or for other actions
  const clickAction = () =>
    onClick ? onClick() : navigate(to ?? '', { replace: true })

  return (
    <div className={`${style.mobileHeader} ${className}`}>
      {!hideBackButton && (
        <Icon
          onClick={clickAction}
          className={style['mobileHeader__back-icon']}
          fileName={ASSET.icononboardinwhitearrowback}
        />
      )}

      <div
        className={`${style[`mobileHeader__${rootScreenHeader ? 'root' : 'page'}-title`]}`}
      >
        {rootScreenTitle(rootScreenHeader, pageTitle)}
      </div>

      {/* Will be removed with the notification update,  
      for now it just aligns the title and serves as a placeholder */}
      <div className={style['mobileHeader__back-icon']} />
    </div>
  )
}

/**
 * Renders specific root screen header content if `rootScreenHeader` is passed in
 */
const rootScreenTitle = (rootScreenHeader?: boolean, pageTitle?: string) => {
  if (rootScreenHeader) {
    return pageTitle ?? getGreeting()
  }

  if (pageTitle && !rootScreenHeader) {
    return pageTitle
  }

  //Some screens don't have page titles
  return ''
}

MobileHeader.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageTitle: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  hideBackButton: PropTypes.bool,
  rootScreenHeader: PropTypes.bool,
}

export default MobileHeader
