import { NavigateFunction, useLocation } from 'react-router-dom'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { DASHBOARD_NAVIGATION } from '../../routes/Route'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { IncomeForecastParams } from '../../types/CommonTypes.types'
import { useState, useLayoutEffect } from 'react'
import TontineModal from '../../components/overlay/Modal'
import GeneralError from '../../components/error-handling/GeneralError'
import { useTranslate } from '../../hooks/useTranslate'
import { parseMagicLink } from '../../utils/TSUtilFunctions'
import SuspenseLoader from '../../components/feedback/SuspenseLoader'

const MAGIC_LOGIN_PARAM = 'magic_login'

/**
 * @note **Do not wrap this component as a child under `<InitializeUI />`!**
 *
 * Exchanges a magic link token for an auth token. If the magic token is valid,
 * the UAS responds with an auth token that is stored in the AuthMachine's
 * context, so the auth token can be used globally to make authenticated API
 * requests
 */
const MagicLogin = () => {
  //HOOKS
  const location = useLocation()
  const navigate = useCustomNavigation()
  const t = useTranslate()
  const [apiError, setApiError] = useState<undefined | string>(undefined)
  const { send, isAuthenticated } = useAccountService()

  useLayoutEffect(() => {
    // Close error modal if user navigates from the page
    return () => setApiError(undefined)
  }, [location?.pathname])

  useLayoutEffect(() => {
    if (
      !isAuthenticated &&
      parseMagicLink(location?.pathname, MAGIC_LOGIN_PARAM)?.param ===
        MAGIC_LOGIN_PARAM
    ) {
      send({
        type: 'REDEEM_MAGIC_TOKEN',
        payload: {
          magic_login_token: parseMagicLink(
            location?.pathname,
            MAGIC_LOGIN_PARAM
          )?.token,
          successCallback: (data) => {
            const forecastParams = data as {
              forecastParams?: IncomeForecastParams
            }

            navigateUser(
              navigate,
              DASHBOARD_NAVIGATION,
              forecastParams?.forecastParams
            )
          },
          failureCallback: (error) => setApiError(error?.translatedError),
        },
      })
    }
  }, [location?.pathname, send, navigate, isAuthenticated])

  if (
    !isAuthenticated &&
    Boolean(parseMagicLink(location?.pathname, MAGIC_LOGIN_PARAM)) &&
    !apiError
  ) {
    // Renders a suspense loader until the user redeems the magic token
    return (
      <main
        style={{
          backgroundColor: 'white',
          position: 'fixed',
          height: '100vh',
          width: '100%',
          // 1 more 9 than navbar
          zIndex: '99999999',
        }}
      >
        <SuspenseLoader />
      </main>
    )
  }

  if (apiError) {
    return (
      <TontineModal isOpen backdrop>
        <GeneralError
          hideErrorTitle
          errorMessage={t('ERROR_MAGIC_LINK_TEXT')}
          hideNavButton
        />
      </TontineModal>
    )
  }

  return <></>
}

/**
 * Navigates the user to the tontinator page if there are forecast params
 * available, otherwise the user is redirected to the dashboard home page
 */
const navigateUser = (
  navigate: NavigateFunction,
  DASHBOARD_NAVIGATION: {
    TONTINATOR: '/mytt-dashboard/sandbox-tontinator'
    FUNDED_PROGRESS: '/mytt-dashboard/funded-progress'
  },
  forecastParams?: IncomeForecastParams
) => {
  //If user has forecast params navigate them to tontinator to do a forecast
  if (forecastParams) {
    navigate(DASHBOARD_NAVIGATION.TONTINATOR, {
      state: forecastParams,
      //Don't allow the user to go back to the magic_login route, because the
      //app will try to redeem the token again and render an error
      replace: true,
    })
  } else {
    //No forecast params, navigate to the dashboard home page
    navigate(DASHBOARD_NAVIGATION.FUNDED_PROGRESS, { replace: true })
  }
}

export default MagicLogin
