import axios from 'axios'

const API_OK = 200
// 3 mins request timeout
const REQUEST_TIMEOUT_IN_MS = 180_000

/**
 * @param {string} api
 * @param {AbortSignal=} signal
 *
 * Fetches a scan session token which is necessary for a facescan
 *
 * @returns {Promise<string | undefined>}
 */
const getScanSessionToken = async (api, signal) => {
  try {
    if (!api) {
      throw new Error(`No api provided`)
    }

    const { status, data } = await axios.get(api, {
      signal: signal,
      timeout: REQUEST_TIMEOUT_IN_MS,
    })

    if (status === API_OK) {
      return data
    }
  } catch (error) {
    logClientError(error)
    throw error?.response
  }

  return undefined
}

/**
 * @typedef {{api:string, scanResultBody:object, email:string}} AuthenticateParams
 *
 * @param {AuthenticateParams}
 *
 * Authenticates the user by doing a facescan. The scan result is sent to the
 * passed in API in exchange for an auth token
 *
 * @returns {Promise<{scan_result_blob:string,
 * user_account_info:object, auth_token_info:AuthTokenInfo}>}
 */
const authenticateWithFace = async ({ api, scanResultBody, email }) => {
  try {
    if (!scanResultBody) {
      throw new Error('FATAL: No scan result body from face scan')
    }

    if (!api || !email) {
      throw new TypeError(
        `No API or email for auth provided got >>${api}<<  >>${email}<<`
      )
    }

    const { status, data } = await axios.post(
      api,
      {
        scan_result: scanResultBody,
        email,
      },
      {
        timeout: REQUEST_TIMEOUT_IN_MS,
      }
    )

    if (status === API_OK) {
      return data
    }
  } catch (error) {
    logClientError(error)
    throw error?.response
  }
}

/**
 * @typedef {{api:string, scanResultBody:object, email:string, authToken?:string,
 * mockResponse?:boolean}} EnrollParams
 * @typedef {{authToken:string, permissions:string, refreshToken:string,
 * remainingTime:number}} AuthTokenInfo
 *
 * @param {EnrollParams}
 *
 * Enrolls the user by doing a facescan. The scan result is sent to the
 * passed in API
 *
 * @returns {Promise<{scan_result_blob:string,enrollment_complete:boolean,
 * auth_token_info: AuthTokenInfo, user_account_info:object,face_scan_reference_id:string}>}
 */
const enrollFaceToFaceAuth = async ({
  api,
  scanResultBody,
  email,
  authToken,
  mockResponse = false,
}) => {
  try {
    if (!authToken) {
      throw new Error('No auth token provided for face enrollment')
    }

    if (!api || !email) {
      throw new TypeError(
        `No API or email for auth provided got >>${api}<<  >>${email}<<`
      )
    }

    if (!scanResultBody) {
      throw new Error('FATAL: No scan result body from face scan')
    }

    const { status, data } = await axios.post(
      api,
      {
        mock: mockResponse,
        scanResult: scanResultBody,
        email,
      },
      {
        headers: {
          'X-Auth-Token': authToken,
        },
        timeout: REQUEST_TIMEOUT_IN_MS,
      }
    )

    if (status === API_OK) {
      return data
    }
  } catch (error) {
    logClientError(error)
    throw error?.response
  }
}

/**
 * @typedef {{api:string, idScanResultBody:object, faceScanRefId:string, authToken:string}} IDScanParams
 * @param {IDScanParams}
 *
 * Matches the user's scanned face with their ID.
 *
 * @returns {Promise<{scan_result_blob:string, completed:boolean}>}
 */
const scanId = async ({ api, idScanResultBody, faceScanRefId, authToken }) => {
  try {
    if (!authToken) {
      throw new Error('No auth token provided for ID match to face scan')
    }

    if (!api) {
      throw new TypeError(`No API or email for auth provided got >>${api}<< `)
    }

    if (!idScanResultBody) {
      throw new Error('FATAL: No scan result body from face scan')
    }

    if (!faceScanRefId) {
      throw new Error('No face scan ref id, something went wrong')
    }

    const { status, data } = await axios.post(
      api,
      {
        scan_result: idScanResultBody,
        face_scan_reference_id: faceScanRefId,
      },
      {
        headers: {
          'X-Auth-Token': authToken,
        },
        timeout: REQUEST_TIMEOUT_IN_MS,
      }
    )

    if (status === API_OK) {
      return data
    }
  } catch (error) {
    logClientError(error)
    throw error?.response
  }
}

const logClientError = (error) => {
  if (!error?.response) {
    console.error('Client error:', error)
  }
}

export {
  getScanSessionToken,
  authenticateWithFace,
  enrollFaceToFaceAuth,
  scanId,
}
