import MenuCard from '../cards/MenuCard'
import MenuCardItem from '../cards/MenuCardItem'
import Layout from '../layout/Layout'
import SecondaryMenuContainer from './SecondaryMenuContainer'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { FUND_PENSION, PRIVATE } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import { ASSET } from '../../constants/Assets'

/**
 * Displays all the available options for the user to choose how
 * they will fund their pension. KYC check is also being done here in order to
 * display a check list to the user that they need to do before proceeding
 * further with any of the fund options.
 */
const FundPensionMenu = () => {
  //Hooks
  const t = useTranslate()

  return (
    <Layout
      navigateTo={PRIVATE.ACCOUNT}
      pageTitle={t('BANKING.PAGE_TITLE_FUND_YOUR_PLAN')}
    >
      <SecondaryMenuContainer>
        <MenuCard
          title={t('BANKING.CARD_TITLE_BANK_DETAILS')}
          variant="alternative"
        >
          <MenuCardItem
            mainText={t('BANKING.FUND_PLAN_CARD_LABEL_CONTRIBUTION_ACCOUNT')}
            to={FUND_PENSION.INVESTMENT_ACCOUNT}
            icon={ASSET.iconaccountaddbank}
            writeProtected
            cardVariant="gray-dirty"
          />
        </MenuCard>

        <MenuCard
          title={t('BANKING.FUND_PLAN_INPUT_LABEL_FUTURE_FEATURE')}
          variant="alternative"
          comingSoon
        >
          <MenuCardItem
            mainText={t('BANKING.CARD_DIRECT_DEBIT_LABEL')}
            to={'NONE'}
            icon={ASSET.iconaccountfundbank}
            cardVariant="gray-dirty"
            disabled
          />
        </MenuCard>
        <MenuCard
          title={t('FUND_PENSION.OPEN_BANKING_MENU_TEXT')}
          variant="alternative"
          comingSoon
        >
          <MenuCardItem
            mainText={t('FUND_PENSION.OPEN_BANKING_MENU_TEXT')}
            to={'NONE'}
            icon={ASSET.iconaccountfundbank}
            cardVariant="gray-dirty"
            disabled
          />
        </MenuCard>

        <MenuCard
          title={t('TRANSFER_AND_CONTRIBUTIONS.LEGEND_ITEM')}
          variant="alternative"
        >
          <MenuCardItem
            to={FUND_PENSION.CONTRIBUTION_HISTORY}
            mainText={t('BANKING.PAGE_TITLE_CONTRIBUTION_HISTORY')}
            icon={ASSET.iconaccountmenucontributionhitory}
            dataTestID={UI_TEST_ID.subMenuItemFundsTrans}
            cardVariant="gray-dirty"
          />
        </MenuCard>
      </SecondaryMenuContainer>
    </Layout>
  )
}

export default FundPensionMenu
