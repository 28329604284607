import ConfirmationModal from '../components/overlay/ConfirmationModal'
import { useState } from 'react'

/**
 * @typedef {Object} params
 * @property {boolean} isOpen if the modal is open or not
 * @property {string=} title Title of the modal
 * @property {string=} content Short description that explains the purpose of the modal
 * @param {object=} contentValues Variables for i18n translations
 * @property {function=} firstButtonAction Adds `onClick` event on the first button rendered in the modal
 * @property {string=} firstButtonLabel Label of the first button
 * @property {boolean=} firstButtonLoading  Indicates if the first button is in loading state
 * @property {string=} firstButtonTextOnLoading  Text to display on the first button when it's loading
 * @property {function=} secondButtonAction Adds `onClick` event on the second button
 * @property {string=} secondButtonLabel Label of the second button
 * @property {function=} timerButtonAction Adds `onClick` event on the third button
 * @property {string=} timerButtonLabel Label of the third button
 * @property {boolean=} timerButtonDisabled  Disables the timer button
 * @property {number=} timerButtonSeconds  Seconds to start the countdown from
 * @property {function():boolean=} onCountdownFinishedTimerButton  Callback when the countdown has reached 0
 * @property {string=} errorMessage  Error message to display in the modal
 * @property {function=} dismissButtonAction Adds `onClick` event on the dismiss button
 * @property {string=} dismissButtonLabel Label of the dismiss button
 * @property {ReactNode=} icon  Icon to display in the modal
 * @property {ReactNode=} animatedIcon  Animated icon to display in the modal
 * @property {string=} firstButtonType Type of the first button
 * @property {string=} confirmButtonTestID ID for testing the confirm button
 * @property {string=} cancelButtonTestID ID for testing the cancel button
 *
 * @param {params} params - The parameters for the modal
 * General purpose modal with a backdrop that provides three call to action buttons.
 * @deprecated
 * Warning: This hook has issues with derived state and should not be used.
 */
export const useConfirmModal = ({
  isOpen,
  title,
  content,
  contentValues,
  icon,
  animatedIcon,
  firstButtonAction,
  firstButtonLabel,
  firstButtonLoading,
  firstButtonTextOnLoading,
  secondButtonAction,
  secondButtonLabel,
  timerButtonAction,
  timerButtonLabel,
  timerButtonDisabled,
  timerButtonSeconds,
  onCountdownFinishedTimerButton,
  errorMessage,
  dismissButtonAction,
  dismissButtonLabel,
  firstButtonType,
  confirmButtonTestID,
  cancelButtonTestID,
}) => {
  const [open, setOpen] = useState(isOpen || false)

  return {
    confirmationModal: (
      <ConfirmationModal
        isOpen={open}
        icon={icon}
        animatedIcon={animatedIcon}
        title={title}
        content={content}
        contentValues={contentValues}
        firstButtonLabel={firstButtonLabel}
        firstButtonAction={firstButtonAction}
        firstButtonLoading={firstButtonLoading}
        firstButtonTextOnLoading={firstButtonTextOnLoading}
        secondButtonAction={secondButtonAction}
        secondButtonLabel={secondButtonLabel}
        timerButtonAction={timerButtonAction}
        timerButtonLabel={timerButtonLabel}
        timerButtonDisabled={timerButtonDisabled}
        timerButtonSeconds={timerButtonSeconds}
        onCountdownFinishedTimerButton={onCountdownFinishedTimerButton}
        errorMessage={errorMessage}
        dismissButtonAction={dismissButtonAction}
        dismissButtonLabel={dismissButtonLabel}
        firstButtonType={firstButtonType}
        confirmButtonTestID={confirmButtonTestID}
        cancelButtonTestID={cancelButtonTestID}
      />
    ),
    /**
     * Changes the modal's state to open
     */
    setOpen,
  }
}
