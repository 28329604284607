import { useEffect, useRef } from 'react'
import Checkbox from '../inputs/Checkbox'
import PageContent from './PageContent'
import { useIntersectObserver } from '../../hooks/useIntersectObserver'
import Icon from '../media-and-icons/Icon'
import TextError from '../typography/TextError'
import style from '../../scss/components/AgreementContent.module.scss'

type AgreementContentProps = {
  onUserReadAgreement?: (hasRead: boolean) => void
  onAgreed?: ({
    checkboxChecked,
    agreementData,
  }: {
    checkboxChecked: boolean
    agreementData: unknown
  }) => void
  backgroundColor?: 'white'
  agreementData?: unknown
  agreementSigned?: boolean
  agreementImage: string
  agreementContent: string
  agreementHeading?: string
  checkboxLabel?: string
  agreementSigningError?: string
  readOnly?: boolean
}

/**
 * Renders agreement content with the following order
 * 1. Agreement heading
 * 2. Agreement image if present
 * 3. Agreement text
 * 4. Checkbox to sing an agreement
 *
 * When an the `onAgree` callback is issued the checkbox is checked and the
 * agreement data is also issued
 */
const AgreementContent = ({
  onUserReadAgreement,
  onAgreed,
  backgroundColor,
  agreementData,
  checkboxLabel,
  agreementSigned,
  agreementImage,
  agreementContent,
  agreementHeading,
  agreementSigningError,
  readOnly,
}: AgreementContentProps) => {
  const checkboxRef = useRef(null)

  const { setElement } = useIntersectObserver({
    onIntersect: () => onUserReadAgreement?.(true),
    elementToAttachObserverTo: checkboxRef?.current,
  })

  useEffect(() => {
    if (checkboxRef?.current) {
      setElement(checkboxRef?.current)
    }
  }, [setElement])

  return (
    <section className={style.agreementContent}>
      {agreementHeading && (
        <h2 className={style[`agreementContent__heading`]}>
          {agreementHeading}
        </h2>
      )}
      {agreementImage && (
        <div className={style[`agreementContent__image-container`]}>
          <Icon
            fileName={agreementImage}
            className={style[`agreementContent__image`]}
          />
        </div>
      )}
      <PageContent
        className={style[`agreementContent__content`]}
        locizeKey={agreementContent}
        backgroundColor={backgroundColor}
      />
      {!readOnly && (
        <Checkbox
          label={checkboxLabel}
          checked={agreementSigned}
          onChange={(agreed: boolean) => {
            onAgreed?.({
              checkboxChecked: agreed,
              agreementData,
            })
          }}
        />
      )}
      {agreementSigningError && (
        <TextError
          validationObject={{
            i18nKey: 'ERROR_SIGN_AGREEMENT',
            values: {
              agreementSigningError,
            },
            valid: false,
          }}
        />
      )}
      <div
        ref={checkboxRef}
        style={{
          height: '50px',
        }}
      />
    </section>
  )
}

export default AgreementContent
