import { useTranslate } from '../../hooks/useTranslate'
import { convertDateToClientLocale } from '../../utils/UtilFunctions'
import { ASSET } from '../../constants/Assets'
import ExtendedContent from '../../pages/banking/ExtendedContent'
import { CONSTANTS } from '../../constants/ConstantValues'
import { useLocalization } from '../../hooks/useLocalization'
import { TransactionContribution } from '../../types/CommonTypes.types'
import ExtendedContentCard from './ExtendedContentCard'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/** Renders payouts using the `<Card />` component
 * - `payouts` Array of objects containing payouts
 */
const PayoutCards = ({ payouts }: { payouts: TransactionContribution }) => {
  const t = useTranslate()
  const { formatAmount } = useLocalization()

  return (
    payouts.map((payout) => (
      <ExtendedContentCard
        key={payout?.transaction?.time}
        headerImage={ASSET.iconaccountcontributionbicolorUS}
        title={
          formatAmount({
            // Payouts are negative from the backend, to render them in the UI
            // better we need to take the absolute value
            amount: Math.abs(payout?.transaction?.amount?.amount ?? 0),
            currency: payout?.nominalBalance?.currency,
            style: 'currency',
          })?.formattedAmountWithSymbol ?? ''
        }
        autoExpand
        variant="gray-dirty"
        expandClickDisabled
        dataTestID={UI_TEST_ID?.payoutCard}
      >
        <ExtendedContent
          date={
            convertDateToClientLocale(
              payout?.transaction?.time,
              CONSTANTS.CLOSURE_ACCOUNT_DATE_FORMAT
            )?.formattedLocaleDate
          }
          firstLineLabel={t('PAYOUT_EXTENDED.FIRST_LINE_LABEL')}
          variant="gray-dirty"
        />
      </ExtendedContentCard>
    )) ?? []
  )
}
export default PayoutCards
