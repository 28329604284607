import { useEffect } from 'react'
import { advancedDashboardURL, environment } from 'MyTontineConfig'
import { ENVIRONMENTS } from '../constants/ConstantValues'
import PropTypes from 'prop-types'
import { isLite } from '../config/lite'

/**
 * Used for debugging the FM, only present on local dev and staging
 */
const ForecastID = ({ view_id, isLoading }) => {
  //This is temporary and only on staging, for debugging purposes
  useEffect(() => {
    if (view_id && !isLoading) {
      //Appends the view id as link hash, just for visual purposes
      window.location.hash = view_id
    }
    if (isLoading) {
      //When forecasting, append nothing
      window.location.hash = ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (
    !isLoading &&
    environment !== ENVIRONMENTS.production &&
    !isLite &&
    view_id
  ) {
    return (
      <article
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        onClick={() => window.open(`${advancedDashboardURL}#${view_id}`)}
      >
        <p
          style={{
            textAlign: 'center',
          }}
        >
          Click to see in AD → {view_id}
        </p>
      </article>
    )
  }

  return <></>
}

ForecastID.propTypes = {
  view_id: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default ForecastID
