import { ANIMATION } from '../../constants/Animations'
import LottieAnimation from './LottieAnimation'
import style from '../../scss/components/Suspense.module.scss'

/**
 * @description Used for lazy loading as a fallback component, displays a
 * loading animation while the lazy loaded component loads
 */
const SuspenseLoader = () => {
  return (
    <div className={style[`spinner-centered`]}>
      <LottieAnimation
        animationName={ANIMATION?.splashLogo}
        autoplay
        loop
        className="splash-image"
      />
    </div>
  )
}

export default SuspenseLoader
