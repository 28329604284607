import { useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import { IncomeStatsExtendedProps } from './DataDisplay.types'
import IncomeStatsExtended from './IncomeStatsExtended'
import MobileIncomeStatTip from './MobileIncomeStatTip'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

type RequiredProps = Pick<
  IncomeStatsExtendedProps,
  | 'contributionAmount'
  | 'incomeAmount'
  | 'incomePercentage'
  | 'isLoading'
  | 'incomeStartAge'
>

type CompareIncomeStatsProps = {
  plan1: RequiredProps
  plan2: RequiredProps
}

/**
 * Renders two IncomeStatsExtended components, one for each plan.
 */
const CompareIncomeStats = ({ plan1, plan2 }: CompareIncomeStatsProps) => {
  const t = useTranslate()

  const [plan1Active, setPlan1Active] = useState(true)
  const [plan2Active, setPlan2Active] = useState(
    // Disable when init in mobile view
    !window?.matchMedia('(max-width: 900px)')?.matches
  )

  const { isMobileOrTablet } = useDeviceScreen((isMobileOrTablet) => {
    if (isMobileOrTablet) {
      setPlan1Active(true)
      setPlan2Active(false)
    } else {
      setPlan2Active(true)
    }
  })

  const sharedProps = {
    bottomText: 'Your income starting age is',
    incomeLabel: t('FORECAST_PAGE.PAYOUTS_BY_100_LABEL'),
    contributionLabel: t('FORECAST_PAGE.TOTAL_CONTRIBUTION_LABEL'),
    // Yeah you know typescript is made from microsoft with
    // bangers like these
    currency: 'USD' as 'USD' | 'JPY',
  }

  return (
    <>
      <section
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        {isMobileOrTablet && (
          <MobileIncomeStatTip
            tooltipText1={t('PLAN1_INPUT_GROUP')}
            tooltipText2={t('PLAN2_INPUT_GROUP')}
            onClickTip1={() => {
              setPlan2Active(false)
              setPlan1Active(true)
            }}
            onClickTip2={() => {
              setPlan1Active(false)
              setPlan2Active(true)
            }}
            tip1Active={plan1Active}
            tip2Active={plan2Active}
          />
        )}

        {plan1Active && (
          <IncomeStatsExtended
            variant="blue-faint"
            staticToolTipText={t('PLAN1_INPUT_GROUP')}
            hideTooltip={isMobileOrTablet}
            {...sharedProps}
            {...plan1}
          />
        )}
        {plan2Active && (
          <IncomeStatsExtended
            variant="yellow-faint"
            staticToolTipText={t('PLAN2_INPUT_GROUP')}
            hideTooltip={isMobileOrTablet}
            {...sharedProps}
            {...plan2}
          />
        )}
      </section>
    </>
  )
}

export default CompareIncomeStats
