import PropTypes from 'prop-types'
import Icon from '../../media-and-icons/Icon'
import InputLabel from '../InputLabel'
import { ASSET } from '../../../constants/Assets'
import style from '../../../scss/components/SliderInput.module.scss'
import { track } from '../../../analytics/Analytics'
import { SliderEvent } from '../../../analytics/EventData'

/**
 * @param {function} formatter Function to format the value of the slider
 * @param {string} icon Icon to display on the slider
 * @param {function} onChange Function to call when the slider value changes
 * @param {array} steps Array of values to use as the slider steps
 * @param {string} title Title to display on the slider
 * @param {number} value Value of the slider
 * @param {boolean} disabled Whether the slider is disabled or not
 * @param {string} prefix Renders a string prefix to the slider
 * @param {string} className CSS class targeting the container of the element
 *
 * @description Renders a box with a draggable slider below it, the box displays
 * the slider's value. The slider box is wrapped in a tooltip, which displays
 * text information when the slider is disabled when hovered over. Clicking the
 * box drags the slider by one step.
 */
const SliderBox = ({
  formatter,
  prefix,
  onChange,
  steps,
  title,
  value = 0,
  disabled = false,
  children,
  disabledIncrement,
  disabledDecrement,
  boxValueDataTestID,
  incrementButtonDataTestID,
  decrementButtonDataTestID,
  sliderTestID,
  trackActivity,
}) => {
  /**
   * Tracks the slider click events
   */
  const trackSliderClick = ({ trackId, eventDescription, value }) => {
    void track({
      event: SliderEvent.button_clicked,
      properties: {
        object_id: trackId,
        description: eventDescription,
        label: title,
        // See what to do with value, it comes from other elements
        object_value: value,
      },
    })
  }

  /**
   * @param {boolean} increase  Indicates whether the value should be increased
   * otherwise it is decremented by default
   *
   * Generates an array index that is used by the `steps` array to
   * update the slider value
   *
   * @returns {number} Returns the value from the `steps` array, if that value
   * does not exist it returns the min value if `increase` is true, otherwise
   * the max value of the array
   */
  const updateSliderValue = (increase) => {
    const currentIndex = steps?.findIndex(
      //value as in the current slider value
      (stepsArrayElement) => stepsArrayElement === value
    )

    if (increase) {
      return steps[(currentIndex + 1) % steps?.length]
    }

    return currentIndex === 0
      ? steps[steps?.length - 1]
      : steps[currentIndex - 1]
  }

  const increaseSliderValueByOne = () => {
    onChange(updateSliderValue(true))
    trackSliderClick({
      trackId: trackActivity?.incBtnId,
      eventDescription: trackActivity?.incBtnDesc,
      value: updateSliderValue(true),
    })
  }
  const decreaseSliderValueByOne = () => {
    onChange(updateSliderValue())
    trackSliderClick({
      trackId: trackActivity?.decBtnId,
      eventDescription: trackActivity?.decBtnDesc,
      value: updateSliderValue(),
    })
  }

  const updateSliderValueByOneBox = () => {
    onChange(updateSliderValue(true))
    trackSliderClick({
      trackId: trackActivity?.incBtnBoxId,
      eventDescription: trackActivity?.incBtnBoxDesc,
      value: updateSliderValue(true),
    })
  }

  return (
    <section className={style[`sliderInput__main-container`]}>
      <InputLabel label={title} optional />
      <article
        className={`${style[`sliderInput${disabled ? '--disabled' : ''}`]}`}
        data-testid={sliderTestID}
      >
        <div className={style['sliderInput__container']}>
          <div
            className={`${style[`sliderInput__controls`]} 
            ${style[`sliderInput__controls--${disabledDecrement || disabled ? 'disabled' : ''}`]}`}
            onClick={decreaseSliderValueByOne}
            data-testid={decrementButtonDataTestID}
          >
            <Icon
              className={style['sliderInput__icon']}
              fileName={ASSET.icononboardinarrowdownray}
            />
          </div>
          <div className={style['sliderInput__inner']}>
            <p className={style['sliderInput__prefix']}>{prefix}</p>
            <p
              className={
                style[`sliderInput__value${disabled ? '--disabled' : ''}`]
              }
              onClick={disabled ? undefined : updateSliderValueByOneBox}
              data-testid={boxValueDataTestID}
            >
              {formatter ? formatter(value) : value}
            </p>
          </div>
          <div
            className={`${style[`sliderInput__controls`]}  
            ${style[`sliderInput__controls--${disabledIncrement || disabled ? 'disabled' : ''}`]}`}
            onClick={increaseSliderValueByOne}
            data-testid={incrementButtonDataTestID}
          >
            <Icon
              className={style['sliderInput__icon']}
              fileName={ASSET.icononboardinarrowupray}
            />
          </div>
        </div>
        {children}
      </article>
    </section>
  )
}

SliderBox.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  formatter: PropTypes.func,
  icon: PropTypes.any,
  title: PropTypes.string,
  steps: PropTypes.array,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  className: PropTypes.string,
  disabledIncrement: PropTypes.bool,
  disabledDecrement: PropTypes.bool,
  boxValueDataTestID: PropTypes.string,
  incrementButtonDataTestID: PropTypes.string,
  decrementButtonDataTestID: PropTypes.string,
  sliderTestID: PropTypes.string,
  trackActivity: PropTypes.object,
}

export default SliderBox
