import { useTranslate } from '../../hooks/useTranslate'
import { useAccountService } from '../../state-management/authentication/useAccountService'

import DateSlider from './slider/DateSlider'
import PropTypes from 'prop-types'

/**
 * @param {Object} retirementData Object containing `month` and `year`
 * properties.
 * @param {Function} setRetirementData Used for updating the `retirementData`
 * properties
 * @param {Array} sliderSteps  An array of steps for the slider
 * @param {Function} setSliderSteps Used for adding additional steps to the
 * `sliderSteps` array
 * @param {object} anonUserDetails Fake user details, that can be used for
 * integration testing, if this component is used in authenticated state and
 * this is not passed in, it will default to authenticated real user details
 *
 * Scheduler used for picking user's retirement month/year
 */

const IncomeScheduler = ({
  retirementData,
  setRetirementData,
  sliderSteps,
  ageThresholds,
  anonUserDetails,
}) => {
  const t = useTranslate()
  const {
    context: { user_details },
  } = useAccountService()

  return (
    <DateSlider
      ageThresholds={ageThresholds}
      userDetails={anonUserDetails ?? user_details}
      setSteps={undefined}
      label={t('INCOME_SLIDER_LABEL')}
      sliderSteps={sliderSteps}
      monthHeadLabel={t('MONTH_HEAD_LABEL')}
      yearHeadLabel={t('YEAR_HEAD_LABEL')}
      value={retirementData}
      yearsOldOnRetirementDateLabel={t('BUBBLE_YEAR_VALUE_LABEL')}
      yearsOldOnRetirementDate={retirementData?.yearsOld}
      monthsOldOnRetirementDateLabel={t('BUBBLE_MONTH_VALUE_LABEL')}
      monthsOldOnRetirementDate={retirementData?.monthsOld}
      caption={t('RETIRE_USER_INCOME_CAPTION')}
      setValue={setRetirementData}
    />
  )
}

IncomeScheduler.propTypes = {
  retirementData: PropTypes.object.isRequired,
  setRetirementData: PropTypes.func.isRequired,
  sliderSteps: PropTypes.array.isRequired,
  ageThresholds: PropTypes.object,
  anonUserDetails: PropTypes.object,
}

export default IncomeScheduler
