import { addHyphenAt } from './TSUtilFunctions'

/**
 * Formats a ZIP code to ZIP+4 format if more than 5 zip code digits are
 * detected.
 */
const formatToZipPlusFourFormat = (
  postalCode: string,
  postalCodeDefaultLength: number
): string => {
  //More than 5 zip code digits, that means postal code is in zip+4 format, add
  //hyphen
  if (
    postalCode.length >= postalCodeDefaultLength + 1 &&
    !postalCode.includes('-')
  ) {
    return addHyphenAt(postalCode, postalCodeDefaultLength)
    //User deleted the 6th digits, go back to 5 digits zip code by removing the
    //hyphen
  } else if (postalCode.length === postalCodeDefaultLength + 1) {
    return postalCode.replaceAll('-', '')
  }
  //Zip code 5 digits
  return postalCode
}

export { formatToZipPlusFourFormat }
