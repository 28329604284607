import { Routes } from 'react-router-dom'
import { isLite } from '../config/lite'
import {
  buildDevPages,
  buildPrivatePages,
  buildPublicPages,
} from './RouteBuilders'

const publicPages = buildPublicPages()
const privatePages = !isLite ? buildPrivatePages() : undefined
const devPages = !isLite ? buildDevPages() : undefined

/**
 * Renders the generated page routes
 */
const PageRoutes = () => {
  return (
    <Routes>
      {publicPages}
      {privatePages}
      {devPages}
    </Routes>
  )
}

export default PageRoutes
