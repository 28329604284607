import { To } from 'react-router-dom'

/**
 * Root routes that don't require authentication. The user can
 * access these routes anytime.
 */
const PUBLIC = {
  HOME: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  SIGN_UP_REFERRAL: '/u/:referral_code',
  NOT_FOUND_404: '*',
  TONTINATOR: '/tontinator',
  GO_TO_PREV_PAGE: -1 as To,
  PIN_RESET: '/pin_reset/:reset_token',
  VERIFY: '/verify/:verify_token',
} as const

/**
 * Root routes that require authentication. The user must have weak or strong
 * authentication in order to access these routes.
 */
const PRIVATE = {
  ACCOUNT: '/account',
  REDEEM_REF_CODE: '/redeem-code',
  BIOMETRICS: '/biometrics/:scan_type',
  PAYOUT: '/payout',
  SANDBOX_TONTINATOR: '/sandbox-tontinator',
  MYTT_DASHBOARD: '/mytt-dashboard',
  WEBSITE_REWARDS: '/rewards',
} as const

////////////////////////ACCOUNT ROUTES//////////////////////////////////
/**
 * Child routes for the account section of the app, these routes are **NOT**
 * meant to be used with the `navigate` provided by `useCustomNavigation`. The
 * only use case for this object is to map components (Check `<PageRoutes />`)
 * to routes and to build routes with other object like the `ACCOUNT_MENU`
 * object
 */
const ACCOUNT = {
  PERSONAL_DETAILS: 'personal-details',
  PIN: 'pin',
  CLOSE_ACCOUNT: 'close-account',
  REWARDS: 'rewards',
  FUND_PENSION: 'fund-plan-menu',
  CONTRIBUTION_HISTORY: 'contribution-history',
  PAYOUT: 'payouts-menu',
  VIEW_AGREEMENTS: 'agreements',
} as const

/**
 * Child routes for the account section that `<AccountMenu />` component uses.
 * These routes are meant to be used with the `navigate` function provided by
 * `useCustomNavigation` hook. If you want to redirect a user to a specific
 * section of the app you would use this object.
 */
const ACCOUNT_MENU = {
  PERSONAL_DETAILS: `${PRIVATE.ACCOUNT}/${ACCOUNT.PERSONAL_DETAILS}`,
  REWARDS: `${PRIVATE.ACCOUNT}/${ACCOUNT.REWARDS}`,
  PIN: `${PRIVATE.ACCOUNT}/${ACCOUNT.PIN}`,
  CLOSE_ACCOUNT: `${PRIVATE.ACCOUNT}/${ACCOUNT.CLOSE_ACCOUNT}`,
  FUND_PLAN_MENU: `${PRIVATE.ACCOUNT}/${ACCOUNT.FUND_PENSION}`,
  PAYOUTS_MENU: `${PRIVATE.ACCOUNT}/${ACCOUNT.PAYOUT}`,
  VIEW_AGREEMENTS: `${PRIVATE.ACCOUNT}/${ACCOUNT.VIEW_AGREEMENTS}`,
} as const

/**
 * Nested routes that are specific to a page, for example these routes are used
 * for a page that acts as a menu. This can be considered as tertiary nesting
 */
const PERSONAL_DETAILS = {
  REJECTED_ID: `${ACCOUNT_MENU.PERSONAL_DETAILS}/id-rejected`,
  PHONE_NUMBER: `${ACCOUNT_MENU.PERSONAL_DETAILS}/phone-number`,
} as const

/**
 * Nested routes that are specific to a page, for example these routes are used
 * for a page that acts as a menu. This can be considered as tertiary nesting
 */
const FUND_PENSION = {
  INVESTMENT_ACCOUNT: `${ACCOUNT_MENU.FUND_PLAN_MENU}/investment-account`,
  CONTRIBUTION_HISTORY: `${ACCOUNT_MENU.FUND_PLAN_MENU}/contribution-history`,
} as const

/**
 * Nested routes that are specific to a page, for example these routes are used
 * for a page that acts as a menu. This can be considered as tertiary nesting
 */
const PAYOUT = {
  SETUP: `${ACCOUNT_MENU.PAYOUTS_MENU}/setup`,
  HISTORY: `${ACCOUNT_MENU.PAYOUTS_MENU}/history`,
} as const

/////////////////////////////// DASHBOARD ROUTES
////////////////////////////////////

/**
 * Child routes for the dashboard section of the app, these routes are **NOT**
 * meant to be used with the `navigate` function provided by
 * `useCustomNavigation`. The only use case for this object is to map components
 * (Check `<PageRoutes />`) to routes and to build routes with other object like
 * the `DASHBOARD_NAVIGATION` object
 */
const MYTT_DASHBOARD = {
  NOMINAL_BALANCE: 'nominal-balance',
  NEWS_FEED: 'news',
  PROGRESS_DASHBOARD: 'progress-dashboard',
  SANDBOX_TONTINATOR: 'sandbox-tontinator',
  FUNDED_PROGRESS: 'funded-progress',
  DASHBOARD_REWARDS: 'dashboard-rewards',
} as const

/**
 * Child routes for the dashboard section that `<DashboardNavigation />` component
 * uses. These routes are meant to be used with the `navigate` function provided
 * by `useCustomNavigation` hook. If you want to redirect a user to a specific
 * section of the app you would use this object.
 */
const DASHBOARD_NAVIGATION = {
  FUNDED_PROGRESS: `${PRIVATE.MYTT_DASHBOARD}/${MYTT_DASHBOARD.FUNDED_PROGRESS}`,
  NOMINAL_BALANCE: `${PRIVATE.MYTT_DASHBOARD}/${MYTT_DASHBOARD.NOMINAL_BALANCE}`,
  TONTINATOR: `${PRIVATE.MYTT_DASHBOARD}/${MYTT_DASHBOARD.SANDBOX_TONTINATOR}`,
  DASHBOARD_REWARDS: `${PRIVATE.MYTT_DASHBOARD}/${MYTT_DASHBOARD.DASHBOARD_REWARDS}`,
} as const

/**
 * Nested routes that are specific to a page, for example these routes are used
 * for a page that acts as a menu. This can be considered as tertiary nesting
 */
const FUNDED_PROGRESS = {
  PENSION_PLAN: `${DASHBOARD_NAVIGATION.FUNDED_PROGRESS}/pension-plan`,
} as const

/**
 * Public root routes for development purposes. These routes are
 * are only available in staging or development environments.
 */
const DEVELOPER = {
  LOGIN: '/dev/login/:auth_token',
  PLAYGROUND: '/dev/playground',
  LOGIN_ONLY: '/dev/login',
} as const

export {
  PRIVATE,
  PUBLIC,
  DEVELOPER,
  FUND_PENSION,
  PAYOUT,
  PERSONAL_DETAILS,
  MYTT_DASHBOARD,
  ACCOUNT,
  ACCOUNT_MENU,
  DASHBOARD_NAVIGATION,
  FUNDED_PROGRESS,
}
