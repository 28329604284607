import style from '../../../scss/layout/DropdownMenu.module.scss'

type DropdownMenuProps = {
  children: React.ReactNode
  isOpen: boolean
  className?: string
}
/**
 * Renders a dropdown menu
 */
const DropdownMenu = ({
  children,
  isOpen,
  className = '',
}: DropdownMenuProps) => {
  return (
    <main
      className={`${style['dropdown-menu']} ${className ?? ''} ${style[`dropdown-menu--${isOpen ? 'open' : ''}`]}`}
    >
      <section className={style[`dropdown-menu__inner`]}>{children}</section>
    </main>
  )
}

export default DropdownMenu
