import CountDownTimer from '../../hooks/CountDownTimer'
import { toMinutesAndRemainingSeconds } from '../../utils/UtilFunctions'
import style from '../../scss/components/SessionExtensionModal.module.scss'
import { ReactNode } from 'react'

type SessionExtensionTimerAndTitleProps = {
  title?: string
  content: string
  serverTime?: number
  children?: ReactNode
}

/**
 * Renders the title and content of the session extension modal
 */
const SessionExtensionTimerAndTitle = ({
  title,
  content,
  serverTime,
  children,
}: SessionExtensionTimerAndTitleProps) => {
  return (
    <div className={style['sessionExtensionModal']}>
      <div className={style['sessionExtensionModal__explainer-text']}>
        {content}
      </div>
      {children}
      {title && (
        <div className={style['sessionExtensionModal__title']}>{title}</div>
      )}
      <div className={style['sessionExtensionModal__countdown-timer']}>
        <div>
          {serverTime && (
            <CountDownTimer
              minutes={toMinutesAndRemainingSeconds(serverTime)?.minutes}
              seconds={
                toMinutesAndRemainingSeconds(serverTime)?.remainingSeconds
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SessionExtensionTimerAndTitle
