import { useTranslate } from '../../hooks/useTranslate'
import style from '../../scss/components/ExtendedTransaction.module.scss'
import { CardVariantType } from '../../types/Card.type'

type ExtendedContentProps = {
  date?: string
  time?: string
  secondLineData?: string
  firstLineLabel?: string
  secondLineLabel?: string
  variant?: CardVariantType
}

/**
 * Renders extended content for `extendedContent` attribute in
 * the `<Card />` component
 */
const ExtendedContent = ({
  date,
  time,
  secondLineData,
  firstLineLabel,
  secondLineLabel,
  variant,
}: ExtendedContentProps) => {
  const t = useTranslate()
  return (
    <article
      className={`${style[`extended-transaction`]} ${style[`extended-transaction${variant ? `--${variant}` : ''}`]}`}
    >
      <div className={style[`extended-transaction__container`]}>
        <p className={style[`extended-transaction__date-label`]}>
          {firstLineLabel}
        </p>
        <p className={style[`extended-transaction__date`]}>
          {date}
          {time && (
            <>
              {` ${t('AT')} `}
              {time}
            </>
          )}
        </p>
      </div>
      {secondLineData && (
        <div className={style['extended-transaction__id-container']}>
          <p className={style['extended-transaction__id-label']}>
            {secondLineLabel}
          </p>
          <p className={style['extended-transaction__id']}>{secondLineData}</p>
        </div>
      )}
    </article>
  )
}

export default ExtendedContent
