import PropTypes from 'prop-types'
import { useState } from 'react'
import style from '../../scss/pages/PublicTontinatorPage.module.scss'
import TontinatorDashboard from '../mytt-dashboard/TontinatorDashboard'
import PublicTontinatorInputLayout from './PublicTontinatorInputLayout'
import PensionPlanDashboard from '../mytt-dashboard/PensionPlanDashboard'
import BottomCtaLiteLayout from '../../components/layout/BottomCtaLiteLayout'
import LiteReferralLayout from '../../components/layout/LiteReferralLayout'
import Icon from '../../components/media-and-icons/Icon'
import { ASSET } from '../../constants/Assets'

/**
 * Tontinator page for public users
 */
const PublicTontinator = ({
  incomeForecastParams,
  setIncomeForecastParams,
  setRegisterForm,
  blueForecastParams,
  setBlueForecastParams,
  yellowForecastParams,
  setYellowForecastParams,
  setIsOpenSignInModal,
}) => {
  const [isCompareOpen, setIsCompareOpen] = useState(false)
  const [openSliderPage, setOpenSliderPage] = useState(false)

  return (
    <main className={style['public-tontinator-page']}>
      {isCompareOpen && (
        <Icon
          // Top back button only on mobile
          fileName={ASSET.icononboardinarrowback}
          className={style['public-tontinator-page__back-mobile']}
          onClick={() => setIsCompareOpen(false)}
        />
      )}
      <section
        className={style['public-tontinator-page__tontinator-container']}
      >
        <section className={style['public-tontinator-page__chart-layout']}>
          {isCompareOpen ? (
            <PensionPlanDashboard
              dataToDraw={[blueForecastParams, yellowForecastParams]}
            />
          ) : (
            <TontinatorDashboard
              incomeForecastParams={incomeForecastParams}
              hideDivider
            />
          )}
        </section>

        <PublicTontinatorInputLayout
          isSliderPageOpen={openSliderPage}
          setOpenSliderPage={setOpenSliderPage}
          setRegisterForm={setRegisterForm}
          incomeForecastParams={incomeForecastParams}
          setIncomeForecastParams={setIncomeForecastParams}
          comparison={isCompareOpen}
          setIsCompareOpen={setIsCompareOpen}
          blueForecastParams={blueForecastParams}
          setBlueForecastParams={setBlueForecastParams}
          yellowForecastParams={yellowForecastParams}
          setYellowForecastParams={setYellowForecastParams}
          setIsOpenSignInModal={setIsOpenSignInModal}
          isCompareOpen={isCompareOpen}
        />
      </section>

      <section
        className={style['public-tontinator-page__bottom-cta-container']}
      >
        {!openSliderPage && (
          // Prevents a double render of bottom layout if the slider page is open
          <BottomCtaLiteLayout
            incomeForecastParams={incomeForecastParams}
            isSliderPageOpen={openSliderPage}
            onClickBack={() => setIsCompareOpen(false)}
            setIsOpenSignInModal={setIsOpenSignInModal}
            onClickSignUpButton={() => setRegisterForm(true)}
            onClickMobileTopButtonOnly={() =>
              setOpenSliderPage((prev) => !prev)
            }
            onClickComparison={() => setIsCompareOpen(true)}
            isCompareOpen={isCompareOpen}
            onClickPlan1={() => {
              setIncomeForecastParams(undefined, blueForecastParams)
              setIsCompareOpen(false)
            }}
            onClickPlan2={() => {
              setIncomeForecastParams(undefined, yellowForecastParams)
              setIsCompareOpen(false)
            }}
            blueForecastParams={blueForecastParams}
            yellowForecastParams={yellowForecastParams}
          />
        )}

        {!isCompareOpen && <LiteReferralLayout />}
      </section>
    </main>
  )
}

PublicTontinator.propTypes = {
  incomeForecastParams: PropTypes.object,
  setIncomeForecastParams: PropTypes.func,
  setRegisterForm: PropTypes.func,
  blueForecastParams: PropTypes.object,
  setBlueForecastParams: PropTypes.func,
  yellowForecastParams: PropTypes.object,
  setYellowForecastParams: PropTypes.func,
  isOpenSignInModal: PropTypes.bool,
  setIsOpenSignInModal: PropTypes.func,
}

export default PublicTontinator
