import style from '../../scss/layout/FormsLayout.module.scss'

/**
 * Contains height that spaces out child elements
 */
const FormsLayout = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return <section className={style['forms-layout']}>{children}</section>
}

export default FormsLayout
