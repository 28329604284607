import {
  DynamicFormData,
  InvestmentAccountFormType,
  LegalMachineEvent,
} from './LegalMachineTypes.types'

/**
 * Checks if the form has been submitted in order to transition to new state
 */
const isSubmittedForm = ({ event }: { event: LegalMachineEvent }) => {
  if (!event.output) {
    console.warn(
      'Did not receive data to check if submitted, returning false not to issue state transition'
    )
    return false
  }

  const formType = Object.keys(event?.output)[0] as InvestmentAccountFormType

  if (formType) {
    const { output } = event as {
      output: DynamicFormData
    }

    return output[formType].submitted
  } else {
    console.warn(
      'Did not receive form type to check if submitted, returning false not to issue state transition'
    )
  }

  return false
}

export { isSubmittedForm }
