import { ErrorBoundary } from '@sentry/react'
import GeneralError from './GeneralError'

type SentryErrorBoundaryProps = {
  children: React.ReactNode
  fallbackErrorComponent?: JSX.Element
  hideNavButton?: boolean
}

/**
 * Catches an error if a component fails to render and displays a fallback UI.
 * In short words `try/catch` block but for UI elements using Sentry for error tracking
 */
const SentryErrorBoundary = ({
  children,
  fallbackErrorComponent,
  hideNavButton,
}: SentryErrorBoundaryProps) => {
  return (
    <ErrorBoundary
      fallback={
        fallbackErrorComponent ?? <GeneralError hideNavButton={hideNavButton} />
      }
    >
      {children}
    </ErrorBoundary>
  )
}

export default SentryErrorBoundary
