import { useState } from 'react'
import { useTranslate } from './useTranslate'
import { CONSTANTS } from '../constants/ConstantValues'
import { getCurrentAge } from '../utils/UtilFunctions'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { regex } from '../constants/Regex'
import { formatSSN, validateInputWithError } from '../utils/TSUtilFunctions'
import { INPUT_LIMIT } from '../constants/InputLimits'

/**
 * Validates input fields and returns an object containing `valid`
 * property which can be `true` or `false`, and `message` containing a user
 * friendly message to the user why that input is invalid.
 */
export const useInputValidation = (
  initialFirstNameValidated = {},
  initialLastNameValidated = {},
  initialEmailValidated = {},
  initialSSNValidated = {},
  initialDateOfBirthValidated = {},
  initialGenericTextValidated = {},
  initialReferralCodeValidated = {},
  initialTextFeedbackValidated = {}
) => {
  const t = useTranslate()

  //Validation states
  const [firstNameValidated, setFirstNameValidated] = useState(
    initialFirstNameValidated
  )
  const [lastNameValidated, setLastNameValidated] = useState(
    initialLastNameValidated
  )
  const [emailValidated, setEmailValidated] = useState(initialEmailValidated)
  const [secondaryEmailValidated, setSecondaryEmailValidated] = useState(
    initialEmailValidated
  )
  const [dateOfBirthValidated, setDateOfBirthValidated] = useState(
    initialDateOfBirthValidated
  )
  const [genericTextValidated, setGenericTextValidated] = useState(
    initialGenericTextValidated
  )
  const [referralCodeValidated, setReferralCodeValidated] = useState(
    initialReferralCodeValidated
  )
  const [textFeedbackValidated, setTextFeedbackValidated] = useState(
    initialTextFeedbackValidated
  )
  const [phoneNumberValidated, setPhoneNumberValidated] = useState({})
  const [secondPhoneNumValidated, setSecondPhoneNumValidated] = useState({})
  const [ssnValidated, setSsnValidated] = useState(initialSSNValidated)
  const [accountAssetsValidated, setAccountAssetsValidated] = useState({})
  const [spousalNameValidated, setSpousalNameValidated] = useState({})
  const [maritalStatusValidated, setMaritalStatusValidated] = useState({})
  const [trustedPersonNameValidated, setTrustedPersonNameValidated] = useState(
    {}
  )
  const [trustedPersonEmailValidated, setTrustedPersonEmailValidated] =
    useState({})
  const [trustedPersonPhoneValidated, setTrustedPersonPhoneValidated] =
    useState({})

  /**
   *
   * @param {string} email
   * Validates email if it is in RFC 5322 format
   */
  const validateEmail = (email) => {
    validateInputWithError({
      input: email?.toLowerCase()?.trim(),
      emptyInputErrorI18nKey: 'ERROR_EMPTY_EMAIL',
      validateFormat: (email) => !regex.emailFormat.test(email),
      invalidInputErrorI18nKey: 'ERROR_EMAIL_WRONG_FORMAT',
      setStateAction: setEmailValidated,
    })
  }

  /**
   * @param {string} firstName Entered by the user
   *
   * Validates a first name input field, by checking if the first
   * name variable has a value and if the length of string matches the server's
   * max and min lengths
   */
  const validateFirstName = (firstName) =>
    validateInputWithError({
      input: firstName,
      emptyInputErrorI18nKey: 'ERROR_EMPTY_FIRST_NAME',
      validateFormat: (firstName) => !regex.names.test(firstName),
      invalidInputErrorI18nKey: 'ERROR_INVALID_CHARACTER',
      extendedValidator: (firstName) =>
        firstName?.length > INPUT_LIMIT.GENERIC_MAX,
      extendedValidationErrorI18nKey: 'ERROR_LONG_FIRSTNAME',
      setStateAction: setFirstNameValidated,
    })

  /**
   * @param {string} lastName Entered by the user
   *
   * Validates a last name input field, by checking if the last
   * name variable has a value and if the length of string matches the server's
   * max and min lengths
   */
  const validateLastName = (lastName) => {
    validateInputWithError({
      input: lastName,
      emptyInputErrorI18nKey: 'ERROR_EMPTY_LASTNAME',
      validateFormat: (firstName) => !regex.names.test(firstName),
      invalidInputErrorI18nKey: 'ERROR_INVALID_CHARACTER',
      extendedValidator: (lastName) =>
        lastName?.length > INPUT_LIMIT.GENERIC_MAX,
      extendedValidationErrorI18nKey: 'ERROR_LONG_LASTNAME',
      setStateAction: setLastNameValidated,
    })
  }

  /**
   * @param {string} text Entered by the user
   *
   * Validated if text area is empty or not
   */
  const validateFeedback = (text) => {
    //Initial check for empty values
    if (text?.length > CONSTANTS.MAX_FEEDBACK_LENGTH) {
      setTextFeedbackValidated({
        message: t('ERROR_FEEDBACK_TOO_LONG'),
        valid: false,
      })
    } else {
      setTextFeedbackValidated({ message: null, valid: true })
    }
  }

  /**
   * @param {string} referralCode Without referral prefix
   * @param {boolean=} optionalField
   *
   * Validates a referral code input field, format and length
   */
  const validateReferralCode = (referralCode, optionalField) => {
    validateInputWithError({
      optionalField: Boolean(optionalField),
      input: referralCode,
      emptyInputErrorI18nKey: 'ERROR_REFERRAL_CODE_EMPTY',
      validateFormat: (referralCode) =>
        !regex.referralCodeFormat.test(referralCode),
      invalidInputErrorI18nKey: 'ERROR_REFERRAL_INVALID_FORMAT',
      extendedValidator: (referralCode) =>
        referralCode?.length < INPUT_LIMIT.REF_CODE_MIN,
      extendedValidationErrorI18nKey: 'ERROR_REFERRAL_CODE_SHORT',
      setStateAction: setReferralCodeValidated,
      //We do not validate if code it too long, because the Input is already
      //capped to MAX characters
    })
  }

  /**
   * @param {string} text String to be validated
   *
   * Check if a string is empty or contains a value
   */
  const validateGenericText = (text) => {
    if (text === null || text === undefined) {
      setGenericTextValidated({
        message: undefined,
        valid: false,
      })
    } else {
      setGenericTextValidated({ message: null, valid: true })
    }
  }

  /**
   * @param {string} date  String date in format `2005-02-03`
   *
   * Validates if the input date is between the current year and
   * 1900 also uses `getCurrentAge()` to validate if the user is over 18 years
   * of age
   */
  const validateDateOfBirth = (date) => {
    if (date) {
      if (getCurrentAge(date) < CONSTANTS.CURRENT_AGE_MIN) {
        setDateOfBirthValidated({
          message: t('ERROR_USER_NOT_18'),
          valid: false,
        })
      } else setDateOfBirthValidated({ message: null, valid: true })
    }
  }

  /**
   * @param {string} phoneNumber  Format `+12015512355` with the dial code
   *
   * The phone number must contain the dial code!
   * Validate a phone number input by checking if it is empty and
   * if the entered phone number matches the user's country formatting.
   */
  const validatePhoneNumber = (phoneNumber) => {
    validateInputWithError({
      input: phoneNumber,
      emptyInputErrorI18nKey: 'ERROR_EMPTY_PHONE',
      validateFormat: (phoneNumber) => !isValidPhoneNumber(phoneNumber),
      invalidInputErrorI18nKey: 'ERROR_PHONE_NUMBER_FORMAT',
      setStateAction: setPhoneNumberValidated,
    })
  }

  /**
   * @param {string} phoneNumber
   */
  const validateSecondaryPhoneNumber = (phoneNumber) => {
    validateInputWithError({
      input: phoneNumber,
      optionalField: true,
      validateFormat: (phoneNumber) => !isValidPhoneNumber(phoneNumber),
      invalidInputErrorI18nKey: 'ERROR_PHONE_NUMBER_FORMAT',
      setStateAction: setSecondPhoneNumValidated,
    })
  }

  /**
   *
   * @param {string} email
   */
  const validateSecondaryEmail = (email) => {
    validateInputWithError({
      input: email,
      optionalField: true,
      validateFormat: (email) => !regex.emailFormat.test(email),
      invalidInputErrorI18nKey: 'ERROR_EMAIL_WRONG_FORMAT',
      setStateAction: setSecondaryEmailValidated,
    })
  }

  /**
   * @param {string} ssn
   *
   * Validates if the entered SSN is in XXX-XX-XXXX format where X represents
   * a digit from the SSN
   */
  const validateSSN = (ssn) => {
    validateInputWithError({
      input: ssn,
      emptyInputErrorI18nKey: 'ERROR_EMPTY_SSN',
      //HACK: For now will be removed, format ssn then validate. maybe it is not
      //a hack not sure
      validateFormat: (ssn) => !regex.SSN.test(formatSSN(ssn)),
      invalidInputErrorI18nKey: 'ERROR_INVALID_SSN_FORMAT',
      setStateAction: setSsnValidated,
    })
  }

  /**
   * @param {string} assets
   */
  const validateAccountAssets = (assets) => {
    validateInputWithError({
      input: assets,
      emptyInputErrorI18nKey: 'ERROR_EMPTY_INPUT',
      validateFormat: (assets) => assets?.length > 130,
      invalidInputErrorI18nKey: 'ERROR__LONG_FIELD',
      setStateAction: setAccountAssetsValidated,
    })
  }

  /**
   * @param {string} spousalName
   * @param {boolean} optionalField
   */
  const validateSpousalName = (spousalName, optionalField) => {
    validateInputWithError({
      input: spousalName,
      optionalField,
      emptyInputErrorI18nKey: 'ERROR_EMPTY_INPUT',
      validateFormat: (spousalName) => !regex.names.test(spousalName),
      invalidInputErrorI18nKey: 'ERROR_INVALID_FIELD',
      setStateAction: setSpousalNameValidated,
    })
  }

  /**
   * @param {'Married' | 'Not Married'=} maritalStatus
   */
  const validateMaritalStatus = (maritalStatus) => {
    validateInputWithError({
      input: maritalStatus,
      emptyInputErrorI18nKey: 'ERROR_EMPTY_INPUT',
      setStateAction: setMaritalStatusValidated,
    })
  }

  /**
   * @param {string} trustedPersonName
   */
  const validateTrustedPersonName = (trustedPersonName) => {
    validateInputWithError({
      input: trustedPersonName,
      emptyInputErrorI18nKey: 'ERROR_EMPTY_INPUT',
      validateFormat: (trustedPersonName) =>
        !regex.names.test(trustedPersonName),
      invalidInputErrorI18nKey: 'ERROR_INVALID_FIELD',
      setStateAction: setTrustedPersonNameValidated,
    })
  }

  /**
   * @param {string} email
   */
  const validateTrustedPersonEmail = (email) => {
    validateInputWithError({
      input: email,
      optionalField: true,
      validateFormat: (email) => !regex.emailFormat.test(email),
      invalidInputErrorI18nKey: 'ERROR_EMAIL_WRONG_FORMAT',
      setStateAction: setTrustedPersonEmailValidated,
    })
  }

  /**
   * @param {string} phoneNumber
   */
  const validateTrustedPersonPhone = (phoneNumber) => {
    validateInputWithError({
      input: phoneNumber,
      optionalField: true,
      validateFormat: (phoneNumber) => !isValidPhoneNumber(phoneNumber),
      invalidInputErrorI18nKey: 'ERROR_PHONE_NUMBER_FORMAT',
      setStateAction: setTrustedPersonPhoneValidated,
    })
  }

  /**
   * @note Careful with this function
   * @deprecated
   * Returns a boolean the following fields have been validated:
   * - `email`
   * - `firstName`
   * - `lastName`
   */
  const allInputFieldsValidated = () =>
    emailValidated?.valid &&
    firstNameValidated?.valid &&
    lastNameValidated?.valid

  return {
    emailValidated,
    firstNameValidated,
    lastNameValidated,
    dateOfBirthValidated,
    genericTextValidated,
    textFeedbackValidated,
    referralCodeValidated,
    phoneNumberValidated,
    ssnValidated,
    secondPhoneNumValidated,
    secondaryEmailValidated,
    accountAssetsValidated,
    spousalNameValidated,
    maritalStatusValidated,
    trustedPersonNameValidated,
    trustedPersonEmailValidated,
    trustedPersonPhoneValidated,
    allInputFieldsValidated,
    validateEmail,
    validateFirstName,
    validateLastName,
    validateFeedback,
    validateReferralCode,
    validateGenericText,
    validateDateOfBirth,
    validatePhoneNumber,
    validateSSN,
    validateSecondaryPhoneNumber,
    validateSecondaryEmail,
    validateAccountAssets,
    validateSpousalName,
    validateMaritalStatus,
    validateTrustedPersonName,
    validateTrustedPersonEmail,
    validateTrustedPersonPhone,
  }
}
