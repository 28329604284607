import { Milliseconds } from '../types/CommonTypes.types'
import { RequestConfigParams, RequestConfig } from './API.types'

/**
 * Default request settings
 */
const defaultErrorParams = {
  timeout: 900_000 as Milliseconds,
  timeoutError: 'Request timed out, server could not be contacted',
} as const

/**
 * Returns a custom header that can be read from the backend services.
 * Client side API requests are authorized with this header.
 * Request settings can be found here like timing out requests.
 *
 * The header includes:
 *
 * - Authorization via `X-Auth-Token`, which is added automatically if the user
 *   is authenticated. Also can be passed in as well with certain configurations
 * - Client side pin authorization if one provided, the `User-Pin` header is a
 *   added to the request header as well to authorize the request
 * - Request cancellation with `AbortSignal` from `AbortController`
 */
const axiosConfig = (
  params: RequestConfigParams = defaultErrorParams
): RequestConfig => {
  const {
    authToken,
    rawBodyRequest,
    signal,
    userPin,
    timeout,
    timeoutErrorMessage,
  } = params

  let customHeader: RequestConfig = {
    signal,
    //Milliseconds
    timeout,
    timeoutErrorMessage,
  }

  //Prioritizes using the passed in auth token, if none is provided
  //then the on saved in AuthMachine's context is used (state hydration)
  if (authToken) {
    customHeader = {
      ...customHeader,
      headers: {
        'X-Auth-Token': authToken,
      },
    }
  }

  if (rawBodyRequest) {
    customHeader = {
      ...customHeader,
      headers: {
        ...customHeader.headers,
        'Content-Type': 'application/json',
      },
    }
  }

  //Adds a custom `User-Pin` header if a pin is provided
  if (userPin) {
    customHeader = {
      ...customHeader,
      headers: {
        ...customHeader.headers,
        'User-Pin': userPin,
      },
    }
  }

  return customHeader
}

export { axiosConfig }
