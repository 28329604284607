import PropTypes from 'prop-types'

import { CONSTANTS } from '../../../../constants/ConstantValues'
import {
  generateRange,
  generateUniqueId,
  monthNumberToString,
} from '../../../../utils/UtilFunctions'
import { disabledPickerValues } from '../../../../utils/TSUtilFunctions'
import style from '../../../../scss/components/DateInput.module.scss'

/**
 * @param {function():void} setMode Sets the mode of the date picker to days
 * after a month has been selected
 * @param {function():void} setMonth Sets the month that the user has selected
 * @param {number} selectedMonth Used for adding the `--selected` style modifier
 *
 * @description Renders all months from 1 to 12 in a calendar year and converts
 * the numbers to localized month strings
 */
const Months = ({
  setMode,
  setMonth,
  selectedMonth,
  disabledFrom,
  disabledTo,
}) => {
  return (
    <div
      onClick={() => setMode?.(CONSTANTS.DATE_PICKER_MODES.DAYS)}
      className={style[`date-picker__months`]}
    >
      {generateRange(CONSTANTS.JANUARY, CONSTANTS.DECEMBER)?.map(
        (monthNumber) => (
          <div
            key={generateUniqueId()}
            onClick={() => setMonth(monthNumber)}
            className={`${style[`date-picker${selectedMonth === monthNumber ? '--selected' : ''}`]}
            ${style[`date-picker__month-element`]}
            ${style[`${disabledPickerValues({ value: monthNumber, disabledFrom, disabledTo })}`]}
          `}
          >
            {monthNumberToString(monthNumber)}
          </div>
        )
      )}
    </div>
  )
}

Months.propTypes = {
  setMode: PropTypes.func,
  setMonth: PropTypes.func.isRequired,
  selectedMonth: PropTypes.number.isRequired,
  disabledFrom: PropTypes.number,
  disabledTo: PropTypes.number,
}

export default Months
