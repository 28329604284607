import PropTypes from 'prop-types'
import { lazy } from 'react'
import CompareIncomeStats from '../../components/data-display/CompareIncomeStats'
import Divider from '../../components/data-display/Divider'
import IncomeStats from '../../components/data-display/IncomeStats'
import TextError from '../../components/typography/TextError'
import { ANIMATION } from '../../constants/Animations'
import { useLocalization } from '../../hooks/useLocalization'
import { useRequestIncomeForecast } from '../../hooks/useRequestIncomeForecast'
import { useTranslate } from '../../hooks/useTranslate'
import style from '../../scss/pages/PensionPlanSetup.module.scss'
import { generateLegendItem } from '../../visualization/utils/D3DataUtils'
import { track } from '../../analytics/Analytics'
import { GraphEvent } from '../../analytics/EventData'
import { EVENT_DESC } from '../../analytics/EventDescription'
const TontinatorLineChart = lazy(
  () => import('../../visualization/TontinatorLineChart')
)

const X_AXIS_CSS_CLASS = 'x-axis-progress-graph-age'
const Y_AXIS_CSS_CLASS = 'y-axis-progress-graph-balance'
const CONTAINER_CSS_CLASS = 'progress-graph-container'

const MAIN_SVG_ID = 'pensionPlanSetup-svg-container'

const LINE_KEYS = Object.freeze({
  tontineLineKey: 'line-tontine',
})

const NEW_PLAN_COLOR = '#FFCC50'
const NEW_PLAN_FONT_COLOR = '#4D5155'
const CURRENT_PLAN_COLOR = '#2975bf'
const CURRENT_PLAN_FONT_COLOR = 'white'

const PLAN1_LINE_STYLE = {
  stroke: '3px',
  fill: 'none',
  areaOpacity: 0.1,
  dashArray: '0, 0',
  showAnnotations: true,
  arrowHead: true,
  color: CURRENT_PLAN_COLOR,
  areaColor: CURRENT_PLAN_COLOR,
  textColor: CURRENT_PLAN_FONT_COLOR,
}

const PLAN2_LINE_STYLE = {
  ...PLAN1_LINE_STYLE,
  color: NEW_PLAN_COLOR,
  areaColor: NEW_PLAN_COLOR,
  textColor: NEW_PLAN_FONT_COLOR,
}

const multipleLineStyle = [PLAN1_LINE_STYLE, PLAN2_LINE_STYLE]

/**
 * Draws the user's pension plan as a line chart. The component supports
 * up to 3 plan lines for now.
 */
const PensionPlanDashboard = ({ className, dataToDraw, currencySymbol }) => {
  const t = useTranslate()
  const { formatAmount } = useLocalization()

  const { error, isLoading, forecastData } = useRequestIncomeForecast({
    incomeForecastParams: appendParamsIdToPlans({
      forecastArray: dataToDraw,
      paramsId: 'compare_plan',
    }),
  })

  const legendInitialData = [
    generateLegendItem({
      itemColor: CURRENT_PLAN_COLOR,
      text: t('PLAN1_INPUT_GROUP'),
      renderLine: true,
    }),
    generateLegendItem({
      itemColor: NEW_PLAN_COLOR,
      text: t('PLAN2_INPUT_GROUP'),
      renderLine: true,
    }),
  ]

  //Dynamically renders 1 or 2 legend items depending how many response items are received
  const legendData = Array.from(
    { length: forecastData?.length },
    (_, legendItemIndex) => {
      return legendInitialData[legendItemIndex]
    }
  )

  return (
    <main className={`${className}`}>
      {error && <TextError errorText={error} />}
      <section className={style['pension-plan-setup__section']}>
        <TontinatorLineChart
          onHoverOrTapLeave={() =>
            trackChartHover({ event: GraphEvent.hover_end })
          }
          onHoverOrTapStart={() =>
            trackChartHover({ event: GraphEvent.hover_start })
          }
          isLoading={isLoading}
          forecastData={forecastData}
          currencySymbol={currencySymbol}
          formatter={(number) => {
            //Prevents from formatting `null` or `undefined` values
            if (number) {
              return formatAmount({
                amount: number,
                style: 'currency',
                currency: forecastData?.[0]?.results?.currency,
                digits: {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                },
              }).formattedAmountWithSymbol
            }
          }}
          numOfTicksForX={8}
          numOfTicksForY={8}
          xAxisCssClass={X_AXIS_CSS_CLASS}
          yAxisCssClass={Y_AXIS_CSS_CLASS}
          mainSVGContainerID={MAIN_SVG_ID}
          containerCssClass={CONTAINER_CSS_CLASS}
          axisDistanceFromGraph={12}
          tontineLineKey={LINE_KEYS.tontineLineKey}
          multipleTontineLineStyles={shouldRenderOneLine({
            forecastArray: dataToDraw,
            multipleLineStyle,
          })}
          toggles={{
            inflation: false,
            breakeven: false,
            percent: false,
          }}
          showVerticalHoveringLine
          drawingAnimation={ANIMATION.jarWithCoins}
          showFocusCircleOnPath
          showHoveringMouseAnnotation
          legendData={legendData}
        />
      </section>

      <Divider className={style[`pension-plan-setup__dashboard-divider`]} />

      {shouldRenderPreviousAndCurrentPlan(dataToDraw) ? (
        <div
          className={style['pension-plan-setup__dashboard-payouts-container']}
        >
          <CompareIncomeStats
            plan1={{
              contributionAmount:
                forecastData?.[0]?.stats?.total_contributions ?? 0,
              incomeAmount: forecastData?.[0]?.stats?.total_payouts ?? 0,
              incomePercentage:
                forecastData?.[0]?.stats?.payout_percentage ?? 0,
              incomeStartAge: dataToDraw[0]?.retirementAge ?? 0,
              isLoading,
            }}
            plan2={{
              contributionAmount:
                forecastData?.[1]?.stats?.total_contributions ?? 0,
              incomeAmount: forecastData?.[1]?.stats?.total_payouts ?? 0,
              incomePercentage:
                forecastData?.[1]?.stats?.payout_percentage ?? 0,
              incomeStartAge: dataToDraw[1]?.retirementAge ?? 0,
              isLoading,
            }}
          />
        </div>
      ) : (
        <div className={style[`pension-plan-setup__payout-container`]}>
          <div className={style[`pension-plan-setup__payout-center`]}>
            <IncomeStats
              variant="blue-faint"
              isLoading={isLoading}
              contributionAmount={
                forecastData?.[0]?.stats?.total_contributions ?? 0
              }
              contributionLabel={t('FORECAST_PAGE.TOTAL_CONTRIBUTION_LABEL')}
              incomeAmount={forecastData?.[0]?.stats?.total_payouts ?? 0}
              incomeLabel={t('FORECAST_PAGE.PAYOUTS_BY_100_LABEL')}
              incomePercentage={
                forecastData?.[0]?.stats?.payout_percentage ?? 0
              }
              currency={forecastData?.[0]?.results?.currency ?? 'USD'}
            />
          </div>
        </div>
      )}
    </main>
  )
}

/**
 * Checks if the forecast response array contains more than one forecast data
 * object
 */
const shouldRenderPreviousAndCurrentPlan = (forecastResponse) =>
  forecastResponse?.length > 1

const trackChartHover = ({ event }) => {
  void track({
    event,
    properties: {
      object_id: 'compare_plan',
      description: EVENT_DESC.chartHoverOrTap,
    },
  })
}

/**
 * @param {Array<unknown>} forecastArray
 *
 * Appends an paramsId to the forecast params. The id is used for analytic purposes
 */
const appendParamsIdToPlans = ({ forecastArray, paramsId }) => {
  if (forecastArray && forecastArray?.length > 1) {
    return [
      {
        ...forecastArray[0],
        paramsId,
      },
      {
        ...forecastArray[1],
        paramsId,
      },
    ]
  }

  return forecastArray
}

/**
 * If the forecast params are both equal renders only one line in the chart to
 * prevent overlap so the styling does not look bad
 */
const shouldRenderOneLine = ({ forecastArray, multipleLineStyle }) => {
  if (forecastArray && forecastArray?.length > 1) {
    if (
      forecastArray[0].retirementAge === forecastArray[1].retirementAge &&
      forecastArray[0].oneTimeContribution ===
        forecastArray[1].oneTimeContribution
    ) {
      return [
        multipleLineStyle[0],
        {
          ...multipleLineStyle[1],
          color: 'transparent',
          textColor: 'transparent',
          areaColor: 'transparent',
        },
      ]
    }
  }
  return multipleLineStyle
}
PensionPlanDashboard.propTypes = {
  className: PropTypes.string,
  dataToDraw: PropTypes.array,
  currencySymbol: PropTypes.string,
}

export default PensionPlanDashboard
