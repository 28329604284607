import PropTypes from 'prop-types'
import DividerHeader from '../headers/DividerHeader'
import MobileHeader from '../navigation/MobileHeader'
import Divider from '../data-display/Divider'
import PageLayout from './PageLayout'
import WarningMessage from '../feedback/BannerMessage'
import { ReactNode } from 'react'
import { To } from 'react-router-dom'
import style from '../../scss/layout/Layout.module.scss'

type LayoutProps = {
  children?: ReactNode
  pageTitle?: string
  navigateTo?: To
  onClickAction?: () => void
  headerTitle?: string
  hideMobileHeader?: boolean
  className?: string
  card?: JSX.Element
  bottomSection?: JSX.Element
  infoBannerText?: string
  topSection?: JSX.Element
  hideBottomSectionDivider?: boolean
  hideDividerHeader?: boolean
  dividersClass?: string
  textNextToHeader?: string
  headerTextColor?: 'default' | 'blue'
  containerWidth?: 'small' | 'medium'
  headerVariant?: 'spaced'
  layoutVariant?: 'sun-bg' | 'sun-blue-bg'
  containerHeight?: 'sh' | 'lh' | 'xlh' | 'auto' | 'lite-build'
  containerMt?: 'nomt' | 'mt-20'
}

//FIXME : Top section

/**
 * Layout wrapper for page's content, mobile and desktop. Some of
 * common components that appear above the main page content can be rendered
 * here by just passing the needed arguments, or you can pass in any component
 * to sit above or below the page's content
 */
const Layout = ({
  children,
  pageTitle,
  navigateTo,
  onClickAction,
  headerTitle,
  hideMobileHeader,
  className,
  hideDividerHeader,
  card,
  bottomSection,
  infoBannerText,
  dividersClass,
  topSection,
  hideBottomSectionDivider,
  textNextToHeader,
  headerTextColor,
  containerWidth,
  headerVariant,
  layoutVariant,
  containerHeight,
  containerMt,
}: LayoutProps) => {
  return (
    <main className={className}>
      <div className={style[`layout__top-section-mobile`]}>
        {topSection && topSection}
      </div>

      {!hideDividerHeader && (
        <DividerHeader
          headerText={pageTitle}
          className={dividersClass}
          additionalText={textNextToHeader}
        />
      )}

      {!hideMobileHeader && (
        <MobileHeader
          pageTitle={pageTitle}
          to={navigateTo}
          onClick={onClickAction}
          className={className}
        />
      )}

      {/* Mobile specific case where we need a top element to be below the mobile header */}
      <div className={style[`layout__top-section-desktop`]}>
        {topSection && topSection}
      </div>

      {infoBannerText && <WarningMessage message={infoBannerText} />}

      {card && (
        <>
          {card}
          <Divider className={`${dividersClass}`} />
        </>
      )}

      <PageLayout
        containerWidth={containerWidth}
        headerTitle={headerTitle}
        headerTextColor={headerTextColor}
        headerVariant={headerVariant}
        layoutVariant={layoutVariant}
        containerHeight={containerHeight}
        containerMt={containerMt}
      >
        {children}
      </PageLayout>

      {bottomSection && (
        <>
          {!hideBottomSectionDivider && (
            //TODO: THIS GOTTA BE HIDDEN ON MOBILE EVERY PAGE
            <Divider
              className={`${style[`layout__div-bottom`]} ${dividersClass}`}
            />
          )}
          {bottomSection}
        </>
      )}
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  topSection: PropTypes.node,
  pageTitle: PropTypes.string,
  actionLabel: PropTypes.string,
  navigateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.func,
  onClickAction: PropTypes.func,
  headerTitle: PropTypes.string,
  hideMobileHeader: PropTypes.bool,
  className: PropTypes.string,
  hideDividerHeader: PropTypes.bool,
  card: PropTypes.node,
  bottomSection: PropTypes.any,
  infoBannerText: PropTypes.any,
  hideMobileSecondaryIcon: PropTypes.bool,
  dividersClass: PropTypes.string,
  hideBottomSectionDivider: PropTypes.bool,
  textNextToHeader: PropTypes.string,
}

export default Layout
