import { NavLink } from 'react-router-dom'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/components/StoreIcons.module.scss'
import Icon from '../media-and-icons/Icon'

const APP_MARKET_LINKS = {
  GOOGLE_PLAY: 'https://play.google.com/store/apps/',
  APPLE_STORE: 'https://www.apple.com/sg/app-store/',
}

/**
 * Renders a container with google play and apple store badges.
 */
const StoreIcons = () => {
  const stores = [
    { icon: ASSET.badgeGooglePlay, href: APP_MARKET_LINKS.GOOGLE_PLAY },
    { icon: ASSET.badgeAppleStore, href: APP_MARKET_LINKS.APPLE_STORE },
  ]

  return (
    <div className={style['store-icons__icons-container']}>
      {stores.map((store, index) => (
        <NavLink to={store.href} key={index}>
          <Icon fileName={store.icon} className={style['store-icons__icon']} />
        </NavLink>
      ))}
    </div>
  )
}

export default StoreIcons
