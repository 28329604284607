import { useState, lazy } from 'react'
import Layout from '../../components/layout/Layout'
import { PUBLIC } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import ReferralLinkView from './ReferralLinkView'
import Card from '../../components/cards/Card'
import style from '../../scss/pages/ReferralDetails.module.scss'
import ViewRewards from './ViewRewards'
import { ASSET } from '../../constants/Assets'
import { useAccountService } from '../../state-management/authentication/useAccountService'
const ReferralLinkModal = lazy(() => import('./ReferralLinkModal'))

/**
 * Renders user referral details, also allows the user to edit their referral
 * code
 */
const ReferralDetails = () => {
  //Hooks
  const t = useTranslate()

  const {
    referralCode,
    editingLimitReached,
    editReferralCodeModal,
    setEditReferralCodeModal,
    renderViewRewards,
    setRenderViewRewards,
  } = useReferralDetailsController()

  if (renderViewRewards) {
    return <ViewRewards onClickBack={() => setRenderViewRewards(false)} />
  }

  return (
    <Layout
      className={style.referral}
      containerHeight="lh"
      pageTitle={t('REWARDS.INVITE_FRIENDS_PAGE_TITLE')}
      navigateTo={PUBLIC.GO_TO_PREV_PAGE}
      containerWidth="small"
      containerMt="nomt"
    >
      <ReferralLinkView
        editingLimitReached={editingLimitReached}
        openEditReferralCodeModal={setEditReferralCodeModal}
        referralLink={referralCode}
        label={t('INVITE_FRIENDS.UNIQUE_LINK_LABEL')}
        hideDescription={editingLimitReached}
      />

      <div onClick={() => setRenderViewRewards(true)}>
        <Card
          headerImage={ASSET.iconaccountrewardbicolor}
          title={t('VIEW.EARNED.REWARDS')}
          variant="gray-dirty"
          headerImageSize="large"
          interactEnabled
          showArrow
        />
      </div>

      {editReferralCodeModal && (
        <ReferralLinkModal
          referralLink={referralCode}
          setOpenEditModal={setEditReferralCodeModal}
          isOpen={editReferralCodeModal}
        />
      )}
    </Layout>
  )
}

const useReferralDetailsController = () => {
  const { context, states } = useAccountService()
  //State
  const [editReferralCodeModal, setEditReferralCodeModal] = useState(false)
  const [renderViewRewards, setRenderViewRewards] = useState(false)

  return {
    editingLimitReached:
      context?.user_details?.referralDetails?.editingLimitReached ?? false,
    referralCode: context?.user_details?.referralDetails?.referral_code ?? '',
    context,
    states,
    editReferralCodeModal,
    setEditReferralCodeModal,
    renderViewRewards,
    setRenderViewRewards,
  }
}

export default ReferralDetails
