import { useCallback, useRef } from 'react'
import { useAccountService } from '../authentication/useAccountService'
import { BankServiceContext } from './BankingServiceContext'
import { generateStatesObject } from '../StateUtils'
import { States } from './BankMachineTypes.type'
import { bankMachine } from './BankMachine'

type EventType = 'FETCH_BANKING_INFO' | 'GET_RETURNS'

const { useSelector, useActorRef } = BankServiceContext

/**
 * Consumes the BankServiceProvider (subscribed to all states)
 *
 * An `AbortController` is automatically sent with every event, and the request
 * is aborted in the `finally` block of some services.
 *
 * Provided functionalities:
 * 1. `send` sends an event to the bank machine
 * 2. `currentBankState` the state the bank machine is currently in
 * 3. `bankContext` data stored in the bank machine, fetched data and errors
 * 4. `states` all the possible states that the bank machine can be in
 */
export const useBankingService = () => {
  //Uses the auth machine to only make API requests on mount if the user is
  //authenticated,otherwise the request might fail since there is no
  //`auth_token` in place
  const {
    isAuthenticated,
    context: { authToken },
  } = useAccountService()

  const abortController = useRef<AbortController>(new AbortController())

  const { currentBankState, bankContext } = useSelector((state) => {
    return {
      currentBankState: state.value,
      bankContext: state.context,
    }
  })
  const actorRef = useActorRef()

  //Send from xstate function wrapped in a callback
  const callbackSend = useCallback(
    ({ type, payload }: { type: EventType; payload?: object }) => {
      actorRef.send({
        type,
        payload: {
          abortController: abortController.current,
          finallyCallback: () => {
            // Assign new abort controller for next possible event
            abortController.current = new AbortController()
          },
          authToken,
          ...payload,
        },
      })
    },
    // Actor ref should not change, it is a static reference!
    [actorRef, authToken]
  )

  return {
    /**
     * Sends an event to the bank machine.
     */
    sendBankEvent: callbackSend,
    /**
     * Current state the bank machine is in.
     */
    currentBankState,
    /**
     * Data stored in the bank machine, including errors.
     */
    bankContext,
    /**
     * All possible states the bank machine can be in, usually used to compare
     * with `currentBankState`.
     */
    states: generateStatesObject(bankMachine.states) as States,
    /**
     * Sourced form the AuthMachine, to check if user is authenticated for
     * convenience in order not to call the auth machine again
     */
    isAuthenticated,
  }
}
