import Layout from '../../components/layout/Layout'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import { useTranslate } from '../../hooks/useTranslate'
import style from '../../scss/pages/ViewRewards.module.scss'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { ReferredCount } from '../../state-management/banking/BankTypes.type'
import ReferralStats from './ReferralStats'

type ViewRewardsProps = {
  referralRewards?: Array<ReferredCount>
  onClickBack: () => void
}

/**
 * @note Feature not fully done from product design
 *
 * Renders cards of referral rewards
 */
const ViewRewards = ({ onClickBack }: ViewRewardsProps) => {
  const t = useTranslate()
  const { context } = useAccountService()

  return (
    <Layout
      onClickAction={onClickBack}
      containerMt="nomt"
      className={style['view-rewards']}
      pageTitle={t('REWARDS.INVITE_FRIENDS_PAGE_TITLE')}
      bottomSection={
        <NavigationButtons hideActionButton onClickFirst={onClickBack} />
      }
    >
      <ReferralStats
        redeemCount={
          context?.user_details?.referralDetails?.count_redeemed ?? 0
        }
      />
    </Layout>
  )
}

export default ViewRewards
