import { lazy } from 'react'
import AccountMenu from '../../components/navigation/AccountMenu'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'
const PersonalDetails = lazy(() => import('../user-info/PersonalDetails'))

/**
 * Renders only account menu on mobile or personal details only on desktop on
 * the same route /account
 */
const Account = () => {
  const { isMobileOrTablet } = useDeviceScreen()

  if (isMobileOrTablet) {
    return <AccountMenu />
  }

  return <PersonalDetails />
}

export default Account
