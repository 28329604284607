import { Outlet, Route } from 'react-router-dom'
import { PRIVATE } from './Route'
import PublicRoute from './PublicRoute'
import { PublicPages } from './PublicPages'
import { DevPages } from './DeveloperPages'
import { PrivatePages } from './PrivatePages'
import PrivateRoute from './PrivateRoute'
import AccountLayout from '../components/layout/AccountLayout'
import TontineDashboardLayout from '../components/layout/TontineDashboardLayout'
import KYCGuard from './KYCGuard'
import PermissionGuard from './PermissionGuard'
import { FC, ReactNode } from 'react'
import { environment } from 'MyTontineConfig'

/**
 * Builds all public page routes by returning an array of `<Route />`. Every
 * page is wrapped in a `<PublicRoute />` not allowing an authenticated user to
 * access public pages
 */
const buildPublicPages = () => {
  return PublicPages.map((entry) => {
    const modifiedPage = (
      <PublicRoute redirectPath={PRIVATE.MYTT_DASHBOARD}>
        {entry.page}
      </PublicRoute>
    )
    return <Route key={entry.path} path={entry.path} element={modifiedPage} />
  })
}

/**
 * Builds private page routes by returning an array of `<Route />`. Every page
 * is wrapped in the following component order:
 * 1. Private route, only allows the page to be accessed if the user is authenticated
 * 2. Desktop layout based on the path
 * 3. KYC guard, only allows the page to be accessed if the user has completed
 *    L1 KYC, if not a `<MissingDetails />` page is shown
 * 4. Permission guard, only allows the page to be accessed if the user has
 *    `write` permissions. Renders a modal and requests from the user to
 *    complete a face scan
 */
const buildPrivatePages = () => {
  const desktopLayout: {
    [key: string]: FC<{ children: ReactNode }>
  } = {
    [PRIVATE.ACCOUNT]: AccountLayout,
    [PRIVATE.MYTT_DASHBOARD]: TontineDashboardLayout,
  }

  return PrivatePages.map((entry) => {
    const DesktopLayout = desktopLayout[entry.rootPath]

    const modifiedIndex = (
      <PrivateRoute>
        <DesktopLayout>{entry.indexPage}</DesktopLayout>
      </PrivateRoute>
    )

    return (
      <Route
        key={entry.rootPath}
        path={entry.rootPath}
        element={
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        }
      >
        <Route index element={modifiedIndex} />
        {entry.childRoutes.map((childEntry) => {
          const modifiedChildPage = (
            <PrivateRoute>
              <DesktopLayout>
                <KYCGuard requestKyc={childEntry?.l1KycRequired}>
                  <PermissionGuard
                    requestFaceScan={childEntry?.writeProtected}
                    path={childEntry.path}
                  >
                    {childEntry.page}
                  </PermissionGuard>
                </KYCGuard>
              </DesktopLayout>
            </PrivateRoute>
          )
          return (
            <Route
              key={childEntry.path}
              path={childEntry.path}
              element={modifiedChildPage}
            />
          )
        })}
      </Route>
    )
  })
}

/**
 * Builds all dev pages used for debugging and testing
 */
const buildDevPages = () => {
  if (environment !== 'production') {
    return DevPages.map((entry) => {
      return <Route key={entry.path} path={entry.path} element={entry.page} />
    })
  }
  return []
}

export { buildPublicPages, buildDevPages, buildPrivatePages }
