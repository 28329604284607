import { lazy } from 'react'
const Playground = lazy(() => import('../dev/Playground'))
const HaskellVsPython = lazy(() => import('../dev/HaskellVsPython'))

/**
 * Pages only rendered in development env
 */
const DevPages = [
  {
    path: '/dev/playground',
    page: <Playground />,
  },
  {
    path: '/haskell-vs-python',
    page: <HaskellVsPython />,
  },
] as const

export { DevPages }
