interface OptionProps<T> {
  value: T
  children?: React.ReactNode | string
  className?: string
  dataTestID?: string
  onClick?: (value: T) => void
}

/**
 * Used for dropdown menus to render option items
 */
const Option = <T,>({
  value,
  children,
  className,
  dataTestID,
  onClick,
}: OptionProps<T>) => {
  return (
    <li
      onClick={() => onClick?.(value)}
      data-testid={dataTestID}
      className={`${className ?? ''}`}
    >
      {children}
    </li>
  )
}

export default Option
