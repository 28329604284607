/**
 * Contains API error responses as keys and localize keys as
 *  `API_ERROR` Object values, if an API error is returned with an ID from any
 *  API then it will be mapped to this object to display a translated error to
 *  the user
 */
const API_ERROR = {
  'UAS-ALT-DEVICE-AUTH-1': 'ERROR_GENERIC',
  'UAS-ALT-DEVICE-AUTH-2': 'ALT_DEVICE_LOGIN.ERROR_MESSAGE',
  'UAS-ALT-DEVICE-AUTH-3': 'ERROR_GENERIC',
  'UAS-ALT-DEVICE-AUTH-4': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-1': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-2': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-3': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-4': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-5': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-6': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-7': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-8': 'ERROR_GENERIC',
  'UAS-AUTH-TOKEN-9': 'ERROR_GENERIC',
  'UAS-FACE-SCAN-1': 'ERROR_GENERIC',
  'UAS-FACE-SCAN-2': 'ERROR_GENERIC',
  'UAS-FACE-SCAN-3': 'ERROR_GENERIC',
  'UAS-FACE-SCAN-4': 'ERROR_GENERIC',
  'UAS-FACE-SCAN-5': 'ERROR_GENERIC',
  'UAS-FEEDBACK-1': 'ERROR_GENERIC',
  'UAS-FEEDBACK-2': 'ERROR_GENERIC',
  'UAS-ID-SCAN-1': 'ERROR_GENERIC',
  'UAS-ID-SCAN-2': 'ERROR_GENERIC',
  'UAS-ID-SCAN-3': 'ERROR_GENERIC',
  'UAS-ID-VERIFICATION-1': 'ERROR_GENERIC',
  'UAS-ID-VERIFICATION-2': 'ERROR_GENERIC',
  'UAS-MAGIC-LOGIN-1': 'ERROR_MAGIC_LINK_TEXT',
  'UAS-MAGIC-LOGIN-2': 'ERROR_GENERIC',
  'UAS-MAGIC-LOGIN-3': 'ERROR_GENERIC',
  'UAS-MAGIC-LOGIN-4': 'ERROR_MAGIC_LINK_TEXT',
  'UAS-MAGIC-LOGIN-5': 'ERROR_GENERIC',
  'UAS-MAGIC-LOGIN-6': 'ERROR_GENERIC',
  'UAS-PAYOUT-FORECAST-VIEW-1': 'ERROR_GENERIC',
  'UAS-PAYOUT-FORECAST-VIEW-2': 'ERROR_GENERIC',
  'UAS-PHONE-NUMBER-1': 'ERROR_ADDING_PHONE_NUM',
  'UAS-PHONE-NUMBER-3': 'ERROR_VERIFICATION_CODE',
  'UAS-PHONE-NUMBER-4': 'ERROR_EXPIRED_SMS_CODE_OR_INVALID_PHONE_NUMBER',
  'UAS-PHONE-NUMBER-5': 'ERROR_TRY_AGAIN_PHONE_NUM',
  'UAS-PIN-1': 'ERROR_GENERIC',
  'UAS-PIN-2': 'ERROR_GENERIC',
  'UAS-PIN-3': 'ERROR_GENERIC',
  'UAS-PIN-4': 'ERROR_GENERIC',
  'UAS-PIN-5': 'ERROR_GENERIC',
  'UAS-PIN-6': 'ERROR_GENERIC',
  'UAS-PIN-7': 'ERROR_WRONG_PIN',
  'UAS-PIN-8': 'ERROR_GENERIC',
  'UAS-PIN-9': 'ERROR_GENERIC',
  'UAS-REFERRAL-CODE-1': 'ERROR_VULGAR_WORD',
  'UAS-REFERRAL-CODE-2': 'ERROR_DUPLICATE_CODE',
  'UAS-REFERRAL-CODE-3': 'ERROR_GENERIC',
  'UAS-REFERRAL-CODE-4': 'ERROR_REFERRAL_CODE_NOT_FOUND',
  'UAS-REFERRAL-CODE-5': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-1': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-2': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-3': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-4': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-5': 'SUCCESSFUL_REGISTRATION_BUT_INVALID_REFERRAL_CODE',
  'UAS-USER-ACCOUNT-6': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-7': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-8': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-9': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-10': 'ERROR_GENERIC',
  'UAS-USER-ACCOUNT-11': 'ERROR_GENERIC',
  'UAS-USER-ADDRESS-1': 'ERROR_GENERIC',
  'UAS-USER-ADDRESS-2': 'ERROR_GENERIC',
  'UAS-USER-ADDRESS-3': 'ERROR_GENERIC',
  'UAS-USER-ADDRESS-4': 'ERROR_GENERIC',
  'BS-AUTH-TOKEN-1': 'ERROR_GENERIC',
  'BS-AUTH-TOKEN-2': 'ERROR_GENERIC',
  'BS-AUTH-TOKEN-3': 'ERROR_GENERIC',
  'BS-AUTH-TOKEN-4': 'ERROR_GENERIC',
  'BS-AUTH-TOKEN-5': 'ERROR_GENERIC',
  'BS-AUTH-TOKEN-6': 'ERROR_GENERIC',
  'BS-JOB-1': 'ERROR_GENERIC',
  'BS-JOB-2': 'ERROR_GENERIC',
  'BS-JOB-3': 'ERROR_GENERIC',
  'BS-VALIDATE-BAN-1': 'ERROR_GENERIC',
  'BS-VALIDATE-BAN-2': 'ERROR_GENERIC',
  'BS-PAYOUTS-ON-OFF-1': 'ERROR_GENERIC',
  'BS-PAYOUTS-ON-OFF-2': 'ERROR_GENERIC',
  'BS-PAYOUTS-ON-OFF-3': 'ERROR_GENERIC',
  'BS-PAYOUTS-1': 'ERROR_GENERIC',
  'BS-PAYOUTS-2': 'ERROR_CALCULATIONS_INPROGRESS',
  'BS-PAYOUT-ACCOUNT-1': 'ERROR_GENERIC',
  'BS-PAYOUT-ACCOUNT-2': 'ERROR_GENERIC',
  'BS-PAYOUT-ACCOUNT-3': 'ERROR_GENERIC',
  'BS-PAYOUT-ACCOUNT-4': 'ERROR_GENERIC',
  'BS-FORECAST-1': 'ERROR_GENERIC',
  'BS-FORECAST-2': 'ERROR_GENERIC',
  'BS-FORECAST-3': 'ERROR_GENERIC',
  'BS-FORECAST-4': 'ERROR_GENERIC',
  'BS-FORECAST-5': 'ERROR_GENERIC',
  'BS-FORECAST-6': 'ERROR_GENERIC',
  'BS-FORECAST-7': 'ERROR_GENERIC',
  'BS-FORECAST-8': 'ERROR_GENERIC',
  'BS-FORECAST-9': 'ERROR_GENERIC',
  'BS-FORECAST-10': 'ERROR_GENERIC',
  'BS-FORECAST-11': 'ERROR_GENERIC',
  'BS-FORECAST-12': 'ERROR_GENERIC',
  'BS-FORECAST-13': 'ERROR_GENERIC',
  'BS-FORECAST-14': 'ERROR_GENERIC',
  'BS-FORECAST-15': 'ERROR_GENERIC',
  'BS-FORECAST-16': 'ERROR_GENERIC',
  'BS-FORECAST-17': 'ERROR_GENERIC',
  'BS-FORECAST-18': 'ERROR_GENERIC',
  'BS-FORECAST-19': 'ERROR_GENERIC',
  'BS-FORECAST-20': 'ERROR_GENERIC',
  'BS-FORECAST-21': 'ERROR_GENERIC',
  // Axios error codes
  ECONNABORTED: 'ERROR_REQUEST_TIMEOUT',
  'UAS-USER-ACCOUNT-13': 'EMAIL_VERIFICATION_ERROR',
}

/**
 * API status codes
 */
const API_STATUS = {
  OK: 200,
  JOB_CREATED: 201,
  UNAUTHORIZED: 401,
  SERVER_ERROR: 500,
  CONFLICT: 409,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
}

export { API_STATUS, API_ERROR }
