import { getCountryInformation } from '../../../utils/UtilFunctions'
import Icon from '../../media-and-icons/Icon'
import ReactCountryFlag from 'react-country-flag'
import style from '../../../scss/components/OptionView.module.scss'

/**
 * CSS does not have any effect, because the flags are SVGs
 */
const styleForFlag = {
  width: 30,
  height: 30,
}

type OptionViewProps = {
  icon?: string
  text: string
  className?: string
  alpha3CountryCode?: string
  noData?: boolean
  isBackgroundOption?: boolean
}

/**
 * Renders a dropdown view with an icon and text, if `icon` is passed in renders
 * an icon. Passing in a country code alpha2 will render a flag
 */
const OptionView = ({
  icon,
  text,
  className,
  alpha3CountryCode,
  noData,
  isBackgroundOption,
}: OptionViewProps) => {
  return (
    <div
      className={` ${style[`option-view`]} ${className ?? ''} ${noData ? style[`option-view--no-data`] : ''} ${
        isBackgroundOption ? style[`option-view--back-option`] : ''
      }`}
    >
      {hackForCountryFlag(alpha3CountryCode) && !icon && (
        <ReactCountryFlag
          className={style[`option-view__icon`]}
          svg
          countryCode={hackForCountryFlag(alpha3CountryCode)}
          style={styleForFlag}
        />
      )}
      {icon && !alpha3CountryCode && (
        <Icon className={style[`option-view__icon`]} fileName={icon} />
      )}
      <p className={style[`option-view__text`]}>{text}</p>
    </div>
  )
}

/**
 * If the function name does not explain it, it is a hack to turn a dial code
 * and alpha 3 code to alpha2 which the flag component uses. For now it will
 * hold, might not even change.
 */
const hackForCountryFlag = (alpha3CountryCode?: string) => {
  // Regular expression to match a string that starts with a plus sign followed by digits
  const dialCode = /^\+\d+$/
  // HACK:
  if (dialCode.test(alpha3CountryCode ?? '')) {
    return getCountryInformation('dial_code', alpha3CountryCode)?.alpha2 ?? ''
  }

  return getCountryInformation('alpha3', alpha3CountryCode)?.alpha2 ?? ''
}

export default OptionView
