import Icon from '../media-and-icons/Icon'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/components/BannerMessage.module.scss'

/**
 * Renders a message with specified props
 */
const BannerMessage = ({
  message,
  className,
  hideIcon,
  variant,
}: {
  message: string
  className?: string
  hideIcon?: boolean
  variant?: 'info'
}) => {
  return (
    <article className={`${style['banner-message']} ${className ?? ''}`}>
      <div
        className={
          style[`banner-message__container${variant ? `--${variant}` : ''}`]
        }
      >
        {!hideIcon && (
          <Icon
            className={style[`banner-message__icon`]}
            fileName={ASSET.infoCircle}
          />
        )}
        <p className={style[`banner-message__text`]}>{message}</p>
      </div>
    </article>
  )
}

export default BannerMessage
