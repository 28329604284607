import AccountSummaryBody from './AccountSummaryBody'
import MobileHeader from '../navigation/MobileHeader'
import PropTypes from 'prop-types'
import style from '../../scss/layout/AccountSummary.module.scss'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

/**
 * Displays's user's first, last name and email with the user's
 * initials on desktop devices, for mobile it renders the page title or if
 * `pageTitle` is not passed in renders greeting messages depending on which
 * part of the day it is
 */
const AccountSummary = ({ pageTitle }: { pageTitle?: string }) => {
  const { isMobileOrTablet } = useDeviceScreen()

  if (isMobileOrTablet) {
    return (
      <MobileHeader rootScreenHeader hideBackButton pageTitle={pageTitle} />
    )
  }

  return (
    <article className={style.accountSummary}>
      <AccountSummaryBody />
    </article>
  )
}

AccountSummary.propTypes = {
  pageTitle: PropTypes.string,
}

export default AccountSummary
