import { writeToConsoleAndIssueAlert } from '../StateUtils'
import {
  Agreement,
  AgreementContents,
  AgreementData,
  DynamicFormData,
  InvestmentAccountFormType,
  TontineProduct,
} from './LegalMachineTypes.types'

/**
 * Converts an array of form types to a string that can be used as GET params
 */
const parsePayloadToParams = (
  formTypes: Array<Agreement | InvestmentAccountFormType>,
  product?: TontineProduct
) => {
  const delimiterForBackendParsing = ';'

  const withProduct = product ? `,${product}` : ''

  const productParams = formTypes.map(
    (InvestmentAccountFormType) => `${InvestmentAccountFormType}${withProduct}`
  )

  return `${productParams.join(delimiterForBackendParsing)}`
}

/**
 * Matches the request param with a response from the backend. For example if
 * `["RequestKey"]` is the request, the server responds with an array of
 * objects, for convenience the array is converted into an `object`
 *
 * Example output:
 * `{
 * "RequestKey": { serverResponse }
 *  }`
 */
const mapResponseToRequest = ({
  requestArray,
  responseArray,
}: {
  requestArray: Array<Agreement | InvestmentAccountFormType>
  responseArray: Array<unknown>
}) => {
  let responseMatchedToType = {} as DynamicFormData | AgreementData

  try {
    if (responseArray?.length === 0) {
      throw new Error(`No response from the server, cannot map. `)
    }

    requestArray.forEach((request, index) => {
      responseMatchedToType = {
        ...responseMatchedToType,
        [request]: responseArray[index],
      }
    })

    return responseMatchedToType
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      skipRethrow: true,
    })
  }

  return responseMatchedToType
}

/**
 * Processes the response data for agreements and parses the response into a
 * flat object
 */
const processAndFlatAgreement = (
  data: Array<[Agreement, AgreementContents]>
): AgreementData | object => {
  let processedResponse: AgreementData | object = {}

  data?.forEach((agreement) => {
    let text = ''
    let image = ''

    agreement[1]?.content.forEach((content) => {
      if (content?.tag === 'Text') {
        text = content?.contents
      }

      if (content?.tag === 'Image') {
        image = content?.contents
      }
    })

    const title = agreement[1].title
    const version = agreement[1].version
    const userAgreed = agreement[1].userAgreed

    processedResponse = {
      ...processedResponse,
      // 0 is the agreement type
      // 1 is the contents
      [agreement[0]]: {
        text,
        image,
        title,
        version,
        userAgreed,
      },
    }
  })

  return processedResponse
}

export { parsePayloadToParams, mapResponseToRequest, processAndFlatAgreement }
