import PropTypes from 'prop-types'
import { useState, lazy } from 'react'
import { useLocation } from 'react-router-dom'
import { useIncomeScheduler } from '../../hooks/IncomeScheduler'
import Layout from '../../components/layout/Layout'
import CalculatorButtons from '../../components/mytontine-dashboard/CalculatorButtons'
import { FUNDED_PROGRESS, PRIVATE } from '../../routes/Route'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useTranslate } from '../../hooks/useTranslate'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import {
  adjustAndConvertToAgeMonthString,
  extractYearFromAgeMonthString,
  numberToAgeMonthString,
  dobToYearsAndMonthsOld,
  initialForecastParams,
  validateRetirementAge,
  getSupportedTontinatorParams,
} from '../../utils/TSUtilFunctions'
import TontinatorDashboard from './TontinatorDashboard'
import { calculateYearForRetirementAge } from '../../utils/TSUtilFunctions'
import { useGetRequest } from '../../hooks/useGetRequest'
import { API } from '../../api/API'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/pages/TontinatorPage.module.scss'

const SliderPage = lazy(
  () => import('../../components/mytontine-dashboard/SliderPage')
)

/**
 * Renders the tontine calculator graph with the slider page where the user can
 * input different parameters to get a forecast. If the user is unauthenticated,
 * a registration modal is rendered.
 */
const TontinatorPage = ({ onboardingForecastParams }) => {
  //States
  const [showSliderPage, setShowSliderPage] = useState(false)

  //Navigation
  const navigate = useCustomNavigation()
  const navigateToMyTontine = () => navigate(FUNDED_PROGRESS.PENSION_PLAN)
  const openSliderPage = () => setShowSliderPage(true)

  //Hooks
  const t = useTranslate()
  const { isAuthenticated, context } = useAccountService()

  const {
    incomeForecastParamsNumber,
    sliderSteps,
    setSliderSteps,
    ageThreshold,
    setIncomeForecastParamsNumber,
    renderUI,
  } = useUserIncomeForecastParams({
    isAuthenticated,
    context,
    onboardingForecastParams,
  })

  return (
    <Layout
      containerHeight="xlh"
      containerMt="mt-20"
      navigateTo={PRIVATE.MYTT_DASHBOARD}
      pageTitle={
        showSliderPage
          ? t('SLIDER_PAGE.HEADER')
          : t('MYTT_DASHBOARD.CARD_EDS_TITLE')
      }
      className={style['tontinator-page']}
      // On Safari it is possible for both of the headers to overlap for some
      // reason so this makes sure only one header is present when the slider
      // page is open
      hideMobileHeader={showSliderPage}
    >
      {showSliderPage && (
        <SliderPage
          pageTitle={t('MYTT_DASHBOARD.CARD_EDS_TITLE')}
          retirementSliders
          contributionSliders
          formsParams={incomeForecastParamsNumber}
          setShowSliderPage={setShowSliderPage}
          setNewFormData={setIncomeForecastParamsNumber}
          sliderSteps={sliderSteps}
          setSliderSteps={setSliderSteps}
          loadingAgeThreshold={renderUI}
          ageThreshold={ageThreshold}
        />
      )}

      <TontinatorDashboard
        incomeForecastParams={incomeForecastParamsNumber}
        isAuthenticated={isAuthenticated}
        renderUI={renderUI}
      />

      <p className={style['tontinator-page__disclaimer']}>
        {t('FORECAST.PAGE.DISCLAIMER')}
      </p>

      <CalculatorButtons
        className={style['tontinator-page__bottom-buttons']}
        firstButtonAction={navigateToMyTontine}
        firstButtonLabel={t('CALCULATOR_BUTTON_TO_DASHBOARD')}
        secondButtonAction={openSliderPage}
        secondButtonLabel={t('BUTTON_LABEL.CALCULATOR')}
        secondButtonTestID={UI_TEST_ID.openSliderPageButton}
      />
    </Layout>
  )
}

TontinatorPage.propTypes = {
  onboardingForecastParams: PropTypes.any,
}

export default TontinatorPage

/**
 * Chooses which income forecast params the tontinator needs to forecast with
 */
const useUserIncomeForecastParams = ({
  isAuthenticated,
  context,
  onboardingForecastParams,
}) => {
  //Get the forecast params that were retrieved in `<MagicLogin />` response
  //when the user redeemed their magic token
  const { state: signUpForecastParams } = useLocation()
  // Init state with location state
  const [incomeForecastParamsNumber, setIncomeForecastParamsNumber] =
    useState(signUpForecastParams)

  /**
   * Callback executed after draft plan call is successful
   */
  const onSuccessfulDraftPlanResponse = ({ data: planData }) => {
    setIncomeForecastParamsNumber(
      initializeStateWithIncomeParams({
        planData,
        // HACK: This fixes an issue where the location state gets reset as soon
        // as this callback executes.
        // This whole flow of data will be fixed as soon as pension plans
        // requirements are done and ready for developers
        //https://3.basecamp.com/5235135/buckets/24897644/card_tables/cards/6901774739
        signUpForecastParams: incomeForecastParamsNumber,
        onboardingForecastParams,
        context,
      })
    )
  }

  const {
    sliderSteps,
    setSliderSteps,
    isLoading: loadingAgeThreshold,
    ageThreshold,
  } = useIncomeScheduler(incomeForecastParamsNumber)

  const { isLoading } = useGetRequest(
    //Do not call if not authenticated
    //Call draft plan to update income scheduler
    isAuthenticated && !loadingAgeThreshold ? API.readDraftPlan : undefined,
    onSuccessfulDraftPlanResponse
  )

  //Returns data needed for components
  return {
    incomeForecastParamsNumber:
      //Onboarding forecast params are ONLY present in ANON STATE
      //they will ALWAYS be undefined in auth state!
      incomeForecastParamsNumber ?? onboardingForecastParams,
    sliderSteps,
    setSliderSteps,
    ageThreshold,
    setIncomeForecastParamsNumber,
    renderUI:
      uiShouldRender(
        isAuthenticated,
        isLoading,
        loadingAgeThreshold,
        validateRetirementAge(incomeForecastParamsNumber?.retirementAge)
        //Puts the UI in loading state in case it is needed to fetch data from
        //an API This would be done in more elegant way when we have a strong
        //definition of what we want to do with pensions plans
      ) && Boolean(incomeForecastParamsNumber),
  }
}

/**
 * @param {boolean} isAuthenticated
 * @param {boolean} isLoading Whether the PENSION plan is being fetched
 * @param {boolean} loadingAgeThreshold Fetching age thresholds
 * @param {boolean} validateRetirementAge If retirement age received from the
 * API or frontend is valid
 *
 * In authenticated state waits for draft plan and age threshold requests to be
 * resolved, in anon state just does a normal forecast
 */
const uiShouldRender = (
  isAuthenticated,
  isLoading,
  loadingAgeThreshold,
  validRetirementAge
) => {
  if (isAuthenticated) {
    return !isLoading && !loadingAgeThreshold && validRetirementAge
  }

  return true
}

/**
 * Initializes the income forecast params state when the `<TontinatorPage />` is
 * mounted. Draft plan data is prioritized. Specified order is followed:
 *
 * 1. Checks if there is a draft plan, if `true` use that data
 * 2. Checks if there is sign up params (fresh user signed up via "HOW IT
 *    WORKS"), if yes use that and write a draft plan
 * 3. 1 and 2 don't pass that means user is ANON and they are just going trough
 *    the "HOW IT WORKS FLOW"
 *
 * **DEFAULT** -> No 1,2,3 just generates default params for the user using the
 *    `generateDefaultParams`, the values are the same default values as in the
 *    "HOW IT WORKS" flow
 */
const initializeStateWithIncomeParams = ({
  planData,
  signUpForecastParams,
  onboardingForecastParams,
  context,
}) => {
  //AUTHENTICATED If there is plan data always the plan data to initialize the
  //tontinator and the slider page
  if (planData?.payout_start_age) {
    //Destruct plan data
    const {
      payout_start_age,
      plan: { payout_start_month, monthly_contribution, onetime_contribution },
    } = planData

    const { age: ageOnRetirementYear, month: monthsOldOnRetirementYear } =
      extractYearFromAgeMonthString(payout_start_age)

    const { age: retirementYear, month: retirementMonth } =
      extractYearFromAgeMonthString(payout_start_month)

    return initialForecastParams({
      retirementAge: payout_start_age,
      yearsOld: ageOnRetirementYear,
      monthsOld: monthsOldOnRetirementYear,
      year: retirementYear,
      month: retirementMonth,
      monthlyContribution: monthly_contribution,
      oneTimeContribution: onetime_contribution,
      countryOfResidence: context?.user_details?.residency,
    })
  }

  //AUTHENTICATED These params only become available if the user has registered
  //with the tontinator onboarding flow.
  if (signUpForecastParams) {
    const {
      demographic_data: { full },
      future_contributions: { contribution_params },
    } = signUpForecastParams

    const { current_age } = full
    const { monthly_amount, onetime_amount, payout_age } = contribution_params

    const {
      yearsOldOnRetirement,
      monthsOldOnRetirement,
      retirementYear,
      retirementMonth,
    } = calculateYearForRetirementAge(current_age, payout_age)

    return {
      contributionAge: current_age,
      retirementAge: payout_age,
      yearsOld: yearsOldOnRetirement,
      monthsOld: monthsOldOnRetirement,
      year: retirementYear,
      month: retirementMonth,
      monthlyContribution: monthly_amount,
      oneTimeContribution: onetime_amount,
      countryOfResidence: context?.user_details?.residency,
      writeDraftPlan: true,
    }
  }

  //UNAUTHENTICATED Params from "HOW IT WORKS" flow also known as tontinator
  //flow and onboarding flow
  if (onboardingForecastParams) {
    let {
      contributionAge: adjustedContributionAge,
      retirementAge: adjustedPayoutAge,
    } = adjustAndConvertToAgeMonthString(
      onboardingForecastParams?.retirementAge,
      onboardingForecastParams?.contributionAge
    )

    //Max retirement age for the tontinator simulation
    const maxSimRetirementAge = getSupportedTontinatorParams(
      onboardingForecastParams?.countryOfResidence
    )?.tontinatorParams?.maxRetirementAgeMonthSim

    //After the overflow has been handled we can have a scenario where the
    //payout age can exceed the max for a specific country, so this make sure
    //that does not happen, at least for now. Scenario: USA retirement age max:
    //84-11 Today is December, the output from
    //`adjustAndConvertToAgeMonthString` is contribution age: 84-11 and
    //retirement age: 85-0. This goes above the max thresholds. So we send to
    //the tontinator retirement age: 84-11 and contribution age: 84-10
    if (adjustedContributionAge === maxSimRetirementAge) {
      const minMonthContributionAgeOld = 10
      adjustedPayoutAge = maxSimRetirementAge
      adjustedContributionAge = numberToAgeMonthString(
        onboardingForecastParams?.contributionAge,
        minMonthContributionAgeOld
      )
    }

    return {
      ...onboardingForecastParams,
      contributionAge: adjustedContributionAge,
      retirementAge: adjustedPayoutAge,
      countryOfResidence: onboardingForecastParams?.countryOfResidence,
    }
  }

  //AUTHENTICATED If no params from onboarding flow, onboarding flow
  //registration and no plan, use default params
  return generateDefaultForecastParams(context?.user_details)
}

/**
 * Generates default forecast params for a user that has signed up without going
 * through the tontinator flow
 */
const generateDefaultForecastParams = (user_details) => {
  const { yearsOld, monthsOld } = dobToYearsAndMonthsOld(
    user_details?.date_of_birth
  )

  const supportedCountries = getSupportedTontinatorParams(
    user_details?.residency
  )?.tontinatorParams

  const {
    defaultRetirementAgeSlider,
    defaultOneTimeSliderValue,
    defaultMonthlySliderValue,
  } = supportedCountries

  //Age month overflow, age month max values [0,11]
  const monthsOldOverflow = 12

  //Pick max retirement age from years old or default
  const defaultRetirementAge = Math.max(yearsOld, defaultRetirementAgeSlider)

  //User retirement age and contribution age is same, add +1 month to retirement
  //age
  let monthsOldRetirement =
    defaultRetirementAge === yearsOld ? monthsOld + 1 : 0

  //Handles overflow in case months old is 12
  let interMonthsOldOnRetirement =
    monthsOldRetirement > monthsOldOverflow ? 0 : monthsOldRetirement

  //Adds year + 1 in case of month overflow
  let inetrYearsOld =
    monthsOld > monthsOldOverflow
      ? defaultRetirementAgeSlider + 1
      : defaultRetirementAge

  const retirementAge = numberToAgeMonthString(
    inetrYearsOld,
    interMonthsOldOnRetirement
  )

  const {
    retirementYear,
    retirementMonth,
    yearsOldOnRetirement,
    monthsOldOnRetirement,
  } = calculateYearForRetirementAge(
    numberToAgeMonthString(yearsOld, monthsOld),
    retirementAge
  )

  return {
    retirementAge,
    monthlyContribution: defaultMonthlySliderValue,
    oneTimeContribution: defaultOneTimeSliderValue,
    year: retirementYear,
    month: retirementMonth,
    yearsOld: yearsOldOnRetirement,
    monthsOld: monthsOldOnRetirement,
    countryOfResidence: user_details?.residency,
    writeDraftPlan: true,
  }
}
