import Button from '../buttons/Button'
import { useTranslate } from '../../hooks/useTranslate'
import { ReactNode } from 'react'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/layout/NavigationBtns.module.scss'
import { ButtonVariant } from '../../types/Button.type'
import { TrackActivity } from '../Common.types'

type NavigationButtonsProps = {
  disabledFirst?: boolean
  disabledSecond?: boolean
  onClickFirst?: () => void
  onClickSecond?: () => void
  secondButtonType?: ButtonVariant
  className?: string
  secondButtonLoading?: boolean
  textOnLoading?: string
  secondButtonLabel?: string
  firstButtonLabel?: string
  hideBackButton?: boolean
  hideActionButton?: boolean
  customButton?: ReactNode
  infrontCustomButton?: ReactNode
  dataTestIDFirstBtn?: string
  dataTestIDSecondBtn?: string
  backButtonWhite?: boolean
  disabledFixedOnMobile?: boolean
  showOnMobile?: boolean
  trackActivityBackButton?: TrackActivity
  trackActivityActionButton?: TrackActivity
}

/**
 * Renders two buttons that serve as a navigation between the
 * pages, two buttons are only rendered on desktop. If the user is in mobile
 * view only the second button is rendered that takes 100% width
 */
const NavigationButtons = ({
  disabledFirst,
  disabledSecond,
  onClickFirst,
  onClickSecond,
  secondButtonType,
  className,
  secondButtonLoading,
  textOnLoading,
  secondButtonLabel,
  firstButtonLabel,
  hideBackButton,
  hideActionButton,
  customButton,
  infrontCustomButton,
  dataTestIDFirstBtn,
  dataTestIDSecondBtn,
  disabledFixedOnMobile,
  showOnMobile,
  trackActivityBackButton,
  trackActivityActionButton,
}: NavigationButtonsProps) => {
  const t = useTranslate()
  return (
    <article
      className={`${style[`navigation-btns`]} ${
        disabledFixedOnMobile ? '' : style['navigation-btns__fixed']
      }`}
    >
      <div className={`${style[`navigation-btns__container`]} ${className}`}>
        {infrontCustomButton && infrontCustomButton}
        {!hideBackButton && (
          <Button
            variant={'back--light'}
            disabled={disabledFirst}
            onClick={onClickFirst}
            className={
              style[
                `navigation-btns__back${showOnMobile ? '--show-mobile' : ''}`
              ]
            }
            icon={ASSET.chevronBlueLeft}
            dataTestID={dataTestIDFirstBtn}
            trackActivity={trackActivityBackButton}
          >
            {firstButtonLabel ?? t('BACK_BUTTON_LABEL')}
          </Button>
        )}

        {!hideActionButton && (
          <Button
            className={style[`navigation-btns__action`]}
            disabled={disabledSecond}
            onClick={onClickSecond}
            variant={secondButtonType}
            loading={secondButtonLoading}
            textOnLoading={textOnLoading}
            dataTestID={dataTestIDSecondBtn}
            trackActivity={trackActivityActionButton}
          >
            {secondButtonLabel ?? ''}
          </Button>
        )}
        {customButton && customButton}
      </div>
    </article>
  )
}

export default NavigationButtons
