type SmsShareProps = {
  /**
   * To wrap an icon or a component
   */
  children: React.ReactNode
  contentToShare: string
  onClick?: () => void
}

/**
 * Shares text via sms, works for Android and iOS
 */
const SmsShare = ({ children, contentToShare, onClick }: SmsShareProps) => {
  return (
    <a href={`sms://;?&body=${contentToShare}`} onClick={onClick}>
      {children}
    </a>
  )
}

export default SmsShare
