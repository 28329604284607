/**
 * Description of tracking events to further clarify what the event means
 */
const EVENT_DESC = {
  comparePlanBtn:
    'Bottom at the bottom of the frame, only present in tontine lite that leads to compare screen',
  tontinatorSex: 'Sex has been selected from the tontinator screen',
  comparePlanBack:
    'Button on the plan comparison screen that returns to the user to the tontinator',
  plan1Choose:
    'User has chosen plan 1 and gets redirected back to the tontinator screen',
  plan2Choose:
    'User has chosen plan 2 and gets redirected back to the tontinator screen',
  tontinatorOpenSliderPage: 'Clicked the open slider page on Mobile',
  compareOpenSliderPage:
    'Clicked the open slider (compare plan screen) page on Mobile',
  tontinatorOpenRegisterScreen:
    'Clicked the open register screen on MyTontine Lite',
  tontinatorLoginText:
    'User opened the login modal on MyTontine Lite by clicking the clickable text',
  tontinatorPercentToggle: 'Income as percentage bottom graph toggle buttons',
  tontinatorInflationToggle: 'Income as inflation bottom graph toggle buttons',
  tontinatorBreakevenToggle: 'Income as breakeven bottom graph toggle buttons',
  chartHoverOrTap:
    'Drag/tap event on the chart itself, depending on the device if it is hover or drag',
  inputFieldClicked:
    'Input field has been clicked or tapped and it is in focus so the user can start typing',
  inputFieldTyped: 'Something has been typed in the input field',
  referralCodeWithParams:
    'User has signed up with a referral code and forecast params',
} as const

export { EVENT_DESC }
