import { useParams } from 'react-router-dom'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useTranslate } from '../../hooks/useTranslate'
import PinSetup from '../../components/inputs/PinSetup'
import ConfirmationModal from '../../components/overlay/ConfirmationModal'
import style from '../../scss/pages/PinSetupPage.module.scss'
import Layout from '../../components/layout/Layout'
import { useState } from 'react'
import { ANIMATION } from '../../constants/Animations'
import { ErrorStorage } from '../../state-management/CommonState.type'
import { ASSET } from '../../constants/Assets'

/**
 * Renders a pin reset page with error or success modal depending on the state
 * of the pin verification
 */
const PinResetPage = () => {
  const {
    t,
    reset_token,
    error,
    onSuccessfulPinSubmit,
    onFailedPinReset,
    shouldRenderSuccessModal,
  } = usePinReset()

  return (
    <Layout pageTitle={t('PIN_RESET.PAGE_TITLE')} hideDividerHeader>
      {error && (
        <ConfirmationModal
          isOpen
          icon={ASSET.infoamber}
          title={t('PIN_RESET.LINK_EXPIRED_TITLE')}
          content={t('PIN_RESET.LINK_EXPIRED_CONTENT')}
        />
      )}
      {shouldRenderSuccessModal && (
        <ConfirmationModal
          isOpen
          animatedIcon={ANIMATION.checkmark}
          title={t('PIN_RESET.SUCCESS_MODAL_TITLE')}
          content={t('PIN_RESET.SUCCESS_MODAL_CONTENT')}
          firstButtonLabel={t('THANK_FORM.DISMISS_BUTTON')}
          firstButtonAction={() => window?.close()}
        />
      )}

      <article className={style['pin-setup-page']}>
        <PinSetup
          authData={reset_token}
          authMachineEvent={'RESET_PIN'}
          headerTitle={t('PIN_RESET.HEADER_TITLE')}
          focusPinSetup={false}
          onSuccessfulPinSubmit={onSuccessfulPinSubmit}
          onFailedPinSubmit={onFailedPinReset}
        />
      </article>
    </Layout>
  )
}

/**
 *
 * Provides the data needed to render a pin reset page
 */
const usePinReset = () => {
  const t = useTranslate()
  const { reset_token } = useParams()
  const navigate = useCustomNavigation()

  // states
  const [error, setError] = useState<string | null>(null)
  const [shouldRenderSuccessModal, setShouldRenderSuccessModal] =
    useState(false)

  const onSuccessfulPinSubmit = () => setShouldRenderSuccessModal(true)

  const onFailedPinReset = (error: ErrorStorage) => {
    // Show error modal only if there is an API error
    if (error?.translatedError) {
      setError(error?.translatedError)
    }
    // Otherwise it is just pins don't match error...
  }

  return {
    t,
    navigate,
    reset_token,
    error,
    onSuccessfulPinSubmit,
    onFailedPinReset,
    shouldRenderSuccessModal,
  }
}

export default PinResetPage
