import CountDownTimer from '../../hooks/CountDownTimer'
import PropTypes from 'prop-types'
import Button from './Button'

/**
 * @param {number} hours  Starts countdown from hours
 * @param {number} minutes  Starts countdown from minutes
 * @param {number} seconds  Starts countdown from seconds
 * @param {boolean} disabled Disables the button
 * @param {JSX} children Children of the button
 * @param {string} type Type of button like alternative, primary, secondary
 * @param {function} onClick Adds `onClick` event on the button
 * @param {function():boolean} onCountdownFinished Callback when the countdown has reached 0
 * @param {function} trackActivity Tracks the user's click if provided
 *
 * @description Button that displays a countdown timer next to the button, only
 * one parameter of `hours`, `minutes` or `seconds needs to be passed to start
 * the countdown
 */
const TimerButton = ({
  hours,
  minutes,
  seconds,
  disabled,
  children,

  onClick,
  onCountdownFinished,
  trackActivity,
  variant,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      trackActivity={trackActivity}
    >
      {!disabled ? (
        children
      ) : (
        <>
          {`${children} `}
          (
          <CountDownTimer
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            onCountdownFinished={onCountdownFinished}
          />
          )
        </>
      )}
    </Button>
  )
}

TimerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onCountdownFinished: PropTypes.func,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.string,
  trackActivity: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default TimerButton
