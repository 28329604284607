import { useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import TontineModal from '../overlay/Modal'
import UserFeedback from '../other/UserFeedback'
import { ASSET } from '../../constants/Assets'
import Card from './Card'
import { useAccountService } from '../../state-management/authentication/useAccountService'

/** Feedback card only rendered when the user is in desktop mode and
 * authenticated. When clicked opens a modal used to provide feedback
 * about the user experience with the app
 */
const FeedbackCard = (): JSX.Element => {
  const t = useTranslate()
  const { isAuthenticated } = useAccountService()
  const [openModal, setOpenModal] = useState(false)

  if (isAuthenticated) {
    return (
      <>
        <Card
          headerImage={ASSET.iconmileureymodal}
          title={t('FEEDBACK_CARD.TITLE')}
          showArrow
          onClick={() => setOpenModal(true)}
          interactEnabled
          variant="feedback"
        />

        {openModal && (
          <TontineModal
            // Renders the `<UserFeedback />` component as a modal
            isOpen={openModal}
            backdrop
            hasOnlyContent
            onOutsideModalContentClick={() => setOpenModal(false)}
          >
            <UserFeedback
              ratingOpenDefault
              closeModal={() => setOpenModal(false)}
              onSuccessfulSubmit={() => setOpenModal(false)}
            />
          </TontineModal>
        )}
      </>
    )
  }

  return <></>
}

export default FeedbackCard
