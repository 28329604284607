import { useCallback, useRef } from 'react'
import { AccountServiceContext } from './AccountServiceContext'
import { AuthMachineEvent, StateType, States } from './AuthMachineTypes.type'
import { authMachine } from './AuthMachine'
import { generateStatesObject } from '../StateUtils'
import { environment } from '../../config/development'
import { CONSTANTS } from '../../constants/ConstantValues'

const { useSelector, useActorRef } = AccountServiceContext

/**
 * Consumes the AccountServiceProvider (subscribed to all states)
 *
 * An `AbortController` is automatically sent with every event, and the request
 * is aborted in the `finally` block of some services.
 *
 * Provided functionalities:
 * 1. `send` sends an event to the auth machine
 * 2. `currentState` the state the auth machine is currently in
 * 3. `context` global data stored in the auth machine
 * 4. `states` all the possible states that the auth machine can be in
 */
export const useAccountService = () => {
  const abortController = useRef<AbortController>(new AbortController())

  const actorRef = useActorRef()

  // Using the auth machine and subscribing to the state
  const { context, currentState, isAuthenticated, snapshot } = useSelector(
    (snapshot) => {
      let AUTH_TOKEN_STATE = '' as StateType
      let currentState = null

      const AUTH_TOKEN: StateType = 'AUTH_TOKEN'

      // Flattens the nested state into just a state value
      if (typeof snapshot.value === 'object') {
        if (AUTH_TOKEN in snapshot.value) {
          AUTH_TOKEN_STATE = AUTH_TOKEN

          currentState = snapshot.value?.['AUTH_TOKEN']?.['AUTHENTICATED']
        }
      }

      if (snapshot.value === AUTH_TOKEN_STATE) {
        // Saves the refresh token in session storage
        sessionStorage.setItem(
          CONSTANTS.AUTH_MACHINE_KEY,
          JSON.stringify(snapshot.context.refreshToken)
        )
      }

      return {
        currentState: currentState ?? snapshot.value,
        //If there is a auth token in context then user is authenticated
        isAuthenticated: Boolean(snapshot.context.authToken),
        context: snapshot.context,
        snapshot,
      }
    }
  )

  //Send from xstate function wrapped in a callback
  //TODO: Remove callback in react19
  const callbackSend = useCallback(
    ({ type, payload }: AuthMachineEvent) => {
      actorRef.send({
        type,
        payload: {
          abortController: abortController.current,
          finallyCallback: () => {
            abortController.current = new AbortController()
          },
          ...payload,
        },
      })
    },

    // Actor ref should not change, it is a static reference!
    [actorRef]
  )

  // Only used in developer env to directly send events to the auth machine
  // for testing. Also used in Cypress test as a hack for now until real model
  // based tests are added for xstate
  if (environment === 'development') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.sendDevEvent = actorRef.send.bind(actorRef)
  }

  return {
    send: callbackSend,
    currentState,
    context,
    isAuthenticated,
    states: generateStatesObject(authMachine.states) as States,
    snapshot,
  }
}
