import { DASHBOARD_NAVIGATION } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import { useBankingService } from '../../state-management/banking/useBankingService'
import { useLocalization } from '../../hooks/useLocalization'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { ASSET } from '../../constants/Assets'
import { renderNominalBalance } from '../../utils/TSUtilFunctions'
import NavigationCard from '../cards/NavigationCard'

/**
 * Renders a **desktop** only navigation component for the MyTontine dashboard
 */
const DashboardNavigation = ({ className }: { className?: string }) => {
  const { bankContext } = useBankingService()
  const { formatAmount } = useLocalization()

  const t = useTranslate()

  return (
    <nav className={`${className}`}>
      <NavigationCard
        title={t('DASHBOARD.CHART.TITLE.EXPECTED.MONTHLY.INCOME')}
        subTitle={t('DASHBOARD.SUBTITLE.PLAN')}
        headerImage={ASSET.iconmtprojectedincome}
        interactEnabled
        variant="box"
        showArrow
        alertAndArrowPosition="start"
        navigateTo={DASHBOARD_NAVIGATION.FUNDED_PROGRESS}
      />
      <NavigationCard
        title={t('NOMINAL_BALANCE_LABEL')}
        subTitle={
          renderNominalBalance(formatAmount, bankContext)
            ?.formattedAmountWithSymbol ?? '-'
        }
        headerImage={ASSET.iconaccountmenufundyourpenionUS}
        interactEnabled
        variant="box-alt"
        showArrow
        alertAndArrowPosition="start"
        navigateTo={DASHBOARD_NAVIGATION.NOMINAL_BALANCE}
        dataTestID={UI_TEST_ID.menuItemNominalBalance}
      />
      <NavigationCard
        title={t('INVITE_FRIENDS.GIVE_GET_STATEMENT')}
        subTitle={t('INVITE_FRIENDS.WITH_FRIENDS_CAPTION')}
        headerImage={ASSET.iconaccountrewardbicolor}
        interactEnabled
        variant="box"
        showArrow
        alertAndArrowPosition="start"
        navigateTo={DASHBOARD_NAVIGATION.DASHBOARD_REWARDS}
        dataTestID={UI_TEST_ID.menuItemDashboardRewards}
      />
      <NavigationCard
        title={t('MYTT_DASHBOARD.CARD_EDS_TITLE')}
        subTitle={t('EXPLORE.DIFFERENT.SCENARIOS.DASHBOARD.SUB')}
        headerImage={ASSET.iconmttontinator}
        interactEnabled
        variant="box"
        showArrow
        alertAndArrowPosition="start"
        navigateTo={DASHBOARD_NAVIGATION.TONTINATOR}
        dataTestID={UI_TEST_ID.menuItemTontinator}
      />
    </nav>
  )
}

export default DashboardNavigation
