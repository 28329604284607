import { NavLink, To } from 'react-router-dom'
import { CardProps } from '../../types/Card.type'
import Card from './Card'
import style from '../../scss/components/Card.module.scss'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/** NavLink wrapper for the `Card` component */
const NavigationCard = ({
  title,
  subTitle,
  onClick,
  variant,
  headerImage,
  headerImageSize,
  alertAndArrowPosition = 'end',
  alert,
  ctaButtonLabel,
  ctaButtonVariant,
  roundness = 'rounded',
  interactEnabled,
  rotateArrow,
  showArrow = false,
  arrowInvisible,
  disabled,
  navigateTo,
  dataTestID = UI_TEST_ID?.commonCardNavigation,
}: CardProps & { navigateTo: To }) => {
  const activeLink = ({ isActive }: { isActive: boolean }) => {
    return (
      <Card
        title={title}
        subTitle={subTitle}
        onClick={onClick}
        variant={variant}
        headerImage={headerImage}
        headerImageSize={headerImageSize}
        alertAndArrowPosition={alertAndArrowPosition}
        alert={alert}
        ctaButtonLabel={ctaButtonLabel}
        ctaButtonVariant={ctaButtonVariant}
        roundness={roundness}
        interactEnabled={interactEnabled}
        rotateArrow={rotateArrow}
        active={isActive}
        showArrow={showArrow}
        arrowInvisible={arrowInvisible}
        disabled={disabled}
      />
    )
  }

  return (
    <NavLink
      to={navigateTo}
      data-testid={dataTestID ?? UI_TEST_ID?.commonCardNavigation}
      className={style['navigation-card']}
    >
      {activeLink}
    </NavLink>
  )
}

export default NavigationCard
