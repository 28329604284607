import { useState } from 'react'

/**
 * Used for debouncing validator functions. All text input components are built
 * with this hook.
 */
export const useDebouncedValidation = <T extends string>(
  debounceTime: number,
  validatorFunction?: (value: T, isOptional?: boolean) => void
) => {
  const [timer, setTimer] = useState<number | undefined | NodeJS.Timeout>(
    undefined
  )

  const validateDebounced = (inputValue: T, isOptional?: boolean) => {
    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      validatorFunction && validatorFunction(inputValue, isOptional)
    }, debounceTime)

    setTimer(newTimer)
  }

  return validateDebounced
}
