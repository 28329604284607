import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import CarouselControls from './CarouselControls'
import CarouselIndicators from './CarouselIndicators'
import CarouselItem from './CarouselItem'
import style from '../../scss/components/Carousel.module.scss'

/**
 * @param {array} slides Slides objects to be displayed in the carousel
 * @param {number} startSlide Index of the slide to be displayed first
 * @param {boolean} autoSlide Whether the carousel should auto slide or not,
 * turned off by default
 * @param {number} slideChangeIntervalMilliseconds Auto slide change interval,
 * it is set to `5_000` milliseconds by default
 * @param {boolean} hideControls Hides the carousel controls
 * @param {boolean} hideIndicators Hides the carousel indicators
 *
 * @description Carousel component that displays a list of slides. Supports
 * automatic slide change and slide change via carousel controls. Carousel
 * controls and indicators are optional and can be hidden.
 */
const Carousel = ({
  slides,
  startSlide = 0,
  autoSlide,
  slideChangeIntervalMilliseconds = 5_000,
  hideControls,
  hideIndicators,
}) => {
  //State
  const [currentSlideIndex, setCurrentSlideIndex] = useState(startSlide)
  const slideInterval = useRef()

  //Slide indexes
  const firstSlide = 0
  const lastSlide = slides.length - 1
  //Slide index changing
  const goToNextSlide =
    currentSlideIndex < lastSlide ? currentSlideIndex + 1 : firstSlide
  const gotoPreviousSlide =
    currentSlideIndex > firstSlide ? currentSlideIndex - 1 : lastSlide

  /**
   * @description Stops the slider from auto-sliding
   */
  const stopSliderTimer = () => {
    if (slideInterval.current) {
      clearInterval(slideInterval.current)
    }
  }

  /**
   * @description Starts the slider so it can automatically switch slides on
   * interval
   */
  const startSlideTimer = () => {
    stopSliderTimer()
    slideInterval.current = setInterval(() => {
      setCurrentSlideIndex((currentSlideIndex) =>
        //if the slider reaches the end then start from the first one
        currentSlideIndex < lastSlide ? currentSlideIndex + 1 : firstSlide
      )
    }, slideChangeIntervalMilliseconds)
  }

  /**
   * @description Checks if the current slide is the last one
   */
  const isLastSlide = () => currentSlideIndex === lastSlide

  /**
   * @description Checks if the current slide is the first one
   */
  const isFirstSlide = () => currentSlideIndex === firstSlide

  /**
   * @description Navigates to the previous slide
   */
  const previousSlide = () => {
    if (autoSlide) {
      //Restarts the timer when the user clicks the previous slide buttons
      startSlideTimer()
    }
    //When the first slide is reached and the user clicks the previous button,
    //the last slide is displayed
    setCurrentSlideIndex(gotoPreviousSlide)
  }

  /**
   * @description Navigates to the next slide
   */
  const nextSlide = () => {
    if (autoSlide) {
      //Restarts the timer when the user clicks the next slide buttons
      startSlideTimer()
    }
    //When the last slide is reached and the user clicks the next button,
    //the slideshow starts from the beginning
    setCurrentSlideIndex(goToNextSlide)
  }

  const selectSlide = (index) => {
    if (autoSlide) {
      startSlideTimer()
    }
    //Selects a slide and makes it current
    setCurrentSlideIndex(index)
  }

  const renderCarouselItems = () =>
    slides?.map((slide, index) => (
      <CarouselItem
        key={index}
        webpImageName={slide?.imageWebp}
        backupImageName={slide?.imagePng}
        title={slide?.title}
        content={slide?.content}
        animationName={slide?.animation}
      />
    ))

  /**
   * @param {number} currentSlideIndex Slider's index to be multiplied by 100
   *
   * @description Uses CSS transform to move the slider to the left or right by
   * multiplying the current number of the slide by 100
   */
  const moveSlider = (currentSlideIndex) => {
    return { transform: `translate(${-currentSlideIndex * 100}%)` }
  }

  useEffect(() => {
    if (autoSlide) {
      startSlideTimer()
    }
    return () => stopSliderTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSlide])

  return (
    <section className={style.carousel}>
      <div
        className={style['carousel__inner']}
        style={moveSlider(currentSlideIndex)}
      >
        {renderCarouselItems()}
      </div>
      {!hideIndicators && (
        <CarouselIndicators
          slides={slides}
          currentSlideIndex={currentSlideIndex}
          selectSlide={selectSlide}
        />
      )}
      {!hideControls && (
        <CarouselControls
          previousSlide={previousSlide}
          nextSlide={nextSlide}
          hideNextControlArrow={isLastSlide()}
          hidePreviousControlArrow={isFirstSlide()}
        />
      )}
    </section>
  )
}

Carousel.propTypes = {
  slides: PropTypes.array,
  slideChangeIntervalMilliseconds: PropTypes.number,
  hideControls: PropTypes.bool,
  hideIndicators: PropTypes.bool,
  startSlide: PropTypes.number,
  autoSlide: PropTypes.bool,
}

export default Carousel
