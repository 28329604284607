/* eslint-disable @typescript-eslint/ban-ts-comment */
import parse from 'html-react-parser'
import { marked } from 'marked'
//@ts-ignore
import { gfmHeadingId } from 'marked-gfm-heading-id'
import { generateUniqueId } from '../../utils/UtilFunctions'

//Have to ignore TS because the new library gfmHeadingId does not have any
//TS support so it causes linting to fail

type MarkdownParserProps = {
  markdownContent: string
}

const markedHeadingIDOptions = {
  prefix: `${generateUniqueId()}-`,
}

//@ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
marked.use(gfmHeadingId(markedHeadingIDOptions))

/**
 * Parses markdown content from sanity cms markdown field
 */
export const MarkdownParser = ({
  markdownContent,
}: MarkdownParserProps): JSX.Element => {
  if (markdownContent) {
    const html: string = marked(
      markdownContent.replace(/\n/g, '<br>')
    ) as unknown as string

    return <>{parse(html)}</>
  }
  return <></>
}
