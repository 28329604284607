import { useTranslate } from '../../hooks/useTranslate'
import { ValidationData } from '../../types/CommonTypes.types'

type TextErrorProps = {
  validationObject?: ValidationData
  className?: string
  dataTestID?: string
  errorText?: string
}

/**
 * Renders a text with red color to serve as
 */
const TextError = ({
  className = '',
  dataTestID,
  validationObject,
  errorText,
}: TextErrorProps) => {
  const t = useTranslate()
  return (
    <p
      // Do not change the "error-text" class
      className={`error-text ${className}`}
      data-testid={dataTestID}
      style={{
        color: 'red',
        position: 'absolute',
      }}
    >
      {errorText
        ? errorText
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          t(validationObject?.i18nKey, validationObject?.values)}
    </p>
  )
}

export default TextError
