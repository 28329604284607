import axios, { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { CONSTANTS } from '../constants/ConstantValues'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { tontineBanking } from '../api/legacy/ApiRepository'
import {
  IncomeForecastParams,
  PromiseCallbacks,
} from '../types/CommonTypes.types'
import { generateApiError } from '../utils/UtilFunctions'
import { ForecastData } from '../visualization/types/Visualization.types'
import { captureExceptionWithSentry } from '../utils/TSUtilFunctions'

/**
 * Calls the `tontinator` API to make a payout forecast with the provided form
 * data. Pending request cancelling is supported by default and is written in
 * the hook itself. Also supports saving forecast params for authenticated
 * users.
 */
export const useRequestIncomeForecast = ({
  incomeForecastParams,
}: {
  incomeForecastParams: Array<IncomeForecastParams & { paramsId: string }>
}) => {
  const {
    isAuthenticated,
    context: { user_details, authToken },
  } = useAccountService()
  ///useEffect dependencies ////////////
  const userDateOfBirth = user_details?.date_of_birth

  const useEffectHash = generateHashFromForecastParams(incomeForecastParams)
  ///////////////

  //Axios cancel token
  const abortController = useRef<AbortController>(new AbortController())

  const [forecastData, setForecastData] = useState<
    Array<ForecastData> | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [error, setError] = useState<string | undefined | object>()

  useEffect(() => {
    //Start loading when forecast data changes
    setIsLoading(true)

    //Cancels the previous pending api request
    if (abortController.current) {
      abortController.current.abort()
    }
    //Assign new request source reference something like new request ID
    abortController.current = new AbortController()

    const handleSuccessfulForecast = (forecast: Array<ForecastData>) =>
      setForecastData(forecast)

    const handleFailedForecast = (error: AxiosError) => {
      //Skips setting the error state if it is an error of type cancelled from
      //axios
      if (!axios.isCancel(error)) {
        setError(generateApiError(error).translatedError)
        //Sends a sentry alert that request failed

        captureExceptionWithSentry(
          new Error(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            `Failed /tontinator API call. Error name:${error?.name} Error code:${
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              error.code
            } Error message:${
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              error.message
            } raw \n parsed error: ${JSON.stringify(generateApiError(error))}`
          ),
          error
        )
      }
    }

    let debounceRequests: NodeJS.Timeout | undefined = undefined

    //Only start a forecast if there is data available
    //This block also avoids premature forecasts if there is no data available or
    //if it is being fetched from an API
    if (incomeForecastParams) {
      debounceRequests = setTimeout(() => {
        //Check if there is retirement age before sending the request
        //if determined there is data, then check the format
        if (incomeForecastParams?.length > 0) {
          setError(undefined)

          tontineBanking.requestIncomeForecast(
            incomeForecastParams,
            isAuthenticated,
            {
              onSuccess: handleSuccessfulForecast,
              onFailure: handleFailedForecast,
              onFinally: () => setIsLoading(false),
            } as PromiseCallbacks,
            abortController.current.signal,
            authToken ?? ''
          )
        } else {
          console.error(
            'Array of params does not contain any params cannot make forecast,got'
          )
          console.table(incomeForecastParams)
          captureExceptionWithSentry(
            new TypeError(
              `Array of params does not contain any params, cannot make forecast ${JSON.stringify(
                incomeForecastParams
              )}`
            )
          )
        }
      }, CONSTANTS.INCOME_FORECAST_DEBOUNCE_TIME)
    }
    return () => {
      if (debounceRequests) {
        clearTimeout(debounceRequests)
      }
      abortController.current.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    incomeForecastParams?.length,
    useEffectHash,
    isAuthenticated,
    userDateOfBirth,
  ])

  return {
    error,
    isLoading,
    forecastData,
  }
}

/**
 * Generates a hash from the income forecast params in order to determine if
 * params have changed in the UI so a new API request can be made
 */
const generateHashFromForecastParams = (
  incomeForecastParams: Array<IncomeForecastParams>
): string => {
  if (incomeForecastParams) {
    const changeHash = incomeForecastParams
      .map((param) => Object.values(param).join(''))
      .join('')

    return changeHash
  }
  return ''
}
