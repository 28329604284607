import { Navigate } from 'react-router-dom'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { ReactNode } from 'react'
import { PUBLIC } from './Route'

/**
 * Checks if user is authenticated, if not, redirects to `/signin`
 */
const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAccountService()

  if (isAuthenticated) {
    return children
  }

  return <Navigate to={PUBLIC.SIGN_IN} />
}

export default PrivateRoute
