import Divider from '../../data-display/Divider'
import OptionView from './OptionView'
import Option from './Option'
import { deepEqual } from '../../../utils/UtilFunctions'
import { ItemKey, OptionValue } from './Dropdown.types'
import { ASSET } from '../../../constants/Assets'

type DropDownMenuItems = {
  options: Array<object>
  itemKey: ItemKey
  value: unknown
  handleOptionClick: (value: unknown) => void
  noOptionFoundMessage?: string
  block?: string
}

/**
 * Renders dropdown items if there is data otherwise a no data found message is rendered
 */
const DropdownMenuItems = ({
  options,
  handleOptionClick,
  itemKey,
  noOptionFoundMessage,
  block,
  value,
}: DropDownMenuItems) => {
  const optionsToRender = options.map((option, index) => (
    <Option
      value={option}
      key={`option-${(option as OptionValue)?.[itemKey?.displayKey]}${index}`}
      onClick={handleOptionClick}
    >
      <OptionView
        text={(option as OptionValue)?.[itemKey?.displayKey]}
        alpha3CountryCode={
          (option as OptionValue)?.[itemKey?.valueOnChange ?? '']
        }
        className={
          isSelectedItem(value, option, itemKey?.valueOnChange ?? '')
            ? //FIXME: This will be broken
              'option-view--selected'
            : ''
        }
      />

      {index !== options.length - 1 && (
        // Don't render a divider on the last option
        <Divider className={`${block}__divider`} />
      )}
    </Option>
  ))

  return (
    <>
      {optionsToRender?.length === 0 && (
        // Do data found for search query message
        <OptionView
          text={noOptionFoundMessage ?? ''}
          icon={ASSET.iconnotlikelyemojiurey}
          noData
        />
      )}

      {optionsToRender}
    </>
  )
}

/**
 * Checks if a value has been selected from the options list
 */
const isSelectedItem = (
  selectedValue: unknown,
  option: object,
  itemKey: string
): boolean => {
  // We are doing an object comparison if not string comparison
  if (selectedValue && typeof selectedValue === 'object') {
    return deepEqual(selectedValue, option) as boolean
  }

  if (selectedValue && option) {
    return selectedValue === (option as OptionValue)?.[itemKey]
  }

  return false
}

export default DropdownMenuItems
