import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

/**
 * Custom hook that provides access to the `t` function from the `useTranslation` hook.
 * The `t` function is wrapped in a `useCallback` hook to avoid red
 */
export const useTranslate = () => {
  const { t } = useTranslation()
  return useCallback(t, [t])
}
