import { max, min, timeParse } from 'd3'
import {
  AmountToPercentParams,
  AnnotationValueParams,
  BreakevenAges,
  ForecastPayout,
  FormatterFunction,
  TontinatorToggles,
} from '../types/Visualization.types'
import { CONSTANTS } from '../../constants/ConstantValues'
import { BreakevenAdjustments } from './Utils.types'
import { differenceInPercentage } from '../../utils/TSUtilFunctions'

/**
 * Parses a date string to a `Date` type so D3 can use it. D3 cannot use date
 * strings
 */
const dateParser = (date: string): Date => timeParse('%Y-%m')(date) as Date

/**
 * Returns the max payouts for each forecast data.
 */
const maxAmounts = (multiplePayouts: Array<Array<ForecastPayout>>): number => {
  //Finds the max values from multiple payouts
  const maxValuesForPayout = multiplePayouts?.map((payouts) => {
    return {
      tontine: max(
        payouts,
        (payout: ForecastPayout) => payout?.tontine?.amount
      ) as number,
      annuity: max(
        payouts,
        (payout: ForecastPayout) => payout?.annuity?.amount
      ) as number,
      depositAmount: max(
        payouts,
        (payout: ForecastPayout) => payout?.deposit_account?.amount
      ) as number,
    }
  })

  // Flatten the array of objects into an array of numbers
  const allMaxValues: Array<number> = maxValuesForPayout.flatMap(
    ({ tontine, annuity, depositAmount }) => [tontine, annuity, depositAmount]
  )

  // Find and return the maximum value in the array of numbers
  return Math.max(...allMaxValues)
}

/**
 * Returns the array with the minimum payout start date from the passed-in array
 * of arrays.
 */
const minDateRange = (
  multiplePayouts: Array<Array<ForecastPayout>>
): Array<ForecastPayout> | undefined => {
  // Finds the array with the minimum date
  const minTontineDate = multiplePayouts?.reduce(
    (
      minArray: Array<ForecastPayout> | undefined,
      payouts: Array<ForecastPayout>
    ) => {
      const minDate = min(payouts, (payout: ForecastPayout) => payout.date)

      if (!minArray || (minDate && minDate < minArray[0]?.date)) {
        return payouts
      }
      return minArray
    },

    undefined
  )

  return minTontineDate
}

/**
 * Renders annotation income in percent. The percent values is formatted to
 * `fixed(1)`
 */
const amountToPercent = ({
  forecastData,
  inflationToggle,
  dataKey,
  payoutOnAnnotation,
  formatter,
}: AmountToPercentParams): string | number => {
  //Destruct forecast data, in order to iterate trough the payouts array
  const {
    results: { payouts },
  } = forecastData

  let percentOnAnnotation = 0

  payouts.forEach((allPayout: ForecastPayout) => {
    //Data key choose which payout line
    const payoutForLine = allPayout[dataKey] as ForecastPayout

    //Check if there is a payout with year_end_percent_of_total_contributions
    //key
    if (payoutForLine[CONSTANTS.D3_PERCENT_KEY]) {
      //Check if that payout matches the current placed annotation on that year
      if (allPayout?.age?.years === payoutOnAnnotation?.age?.years) {
        percentOnAnnotation = payoutForLine[
          dataSetPercent(inflationToggle)
        ] as number
      }
    }
  })

  //Returns income in percent for an annotation, using toLocaleString,
  //style:percent formatting messes up the numbers
  return formatter ? formatter(percentOnAnnotation) : percentOnAnnotation
}

/**
 * Displays annotation value either as formatted localized string or as percent
 * string
 */
const annotationValue = ({
  percentage,
  value,
  inflation,
  inflationAmount,
  forecastData,
  dataKey,
  formatter,
  payoutOnAnnotation,
}: AnnotationValueParams): string | number => {
  if (percentage) {
    return amountToPercent({
      forecastData,
      inflationToggle: inflation,
      dataKey,
      payoutOnAnnotation,
      formatter,
    })
  }

  //If inflation toggles is enabled this value will be with inflation applied
  const adjustedValue: number = adjustForInflation(
    value,
    inflationAmount,
    inflation
  )

  return formatter ? formatter(adjustedValue) : adjustedValue
}
/**
 * If inflation toggle is `true` then adjusted for inflation amount is returned.
 * Otherwise just the normal amount is returned.
 */
const adjustForInflation = (
  amount: number,
  amountAdjustedForInflation: number,
  inflationToggle?: boolean
): number => {
  if (inflationToggle) {
    return amountAdjustedForInflation
  }
  return amount
}

/**
 * Iterates troughs the `payouts` array and returns the breakeven data for each
 * line
 */
const calculateBreakevenAges = (
  payouts: Array<ForecastPayout>
): BreakevenAges => {
  let breakevenAges: BreakevenAges = {
    tontine: {
      breakevenAge: 0,
      breakevenDate: new Date(),
      breakevenAmount: 0,
    },
    annuities: {
      breakevenAge: 0,
      breakevenDate: new Date(),
      breakevenAmount: 0,
    },
    depositAccount: {
      breakevenAge: 0,
      breakevenDate: new Date(),
      breakevenAmount: 0,
    },
  }

  payouts.forEach((payout: ForecastPayout) => {
    if (payout.tontine.breakeven) {
      breakevenAges = {
        ...breakevenAges,
        tontine: {
          breakevenAge: payout.age.years,
          breakevenDate: dateParser(payout.date),
          breakevenAmount: payout.tontine.amount,
        },
      }
    }
    if (payout.annuity.breakeven) {
      breakevenAges = {
        ...breakevenAges,
        annuities: {
          breakevenAge: payout.age.years,
          breakevenDate: dateParser(payout.date),
          breakevenAmount: payout.annuity.amount,
        },
      }
    }
    if (payout.deposit_account.breakeven) {
      breakevenAges = {
        ...breakevenAges,
        depositAccount: {
          breakevenAge: payout.age.years,
          breakevenDate: dateParser(payout.date),
          breakevenAmount: payout.deposit_account.amount,
        },
      }
    }
  })

  return breakevenAges
}

/**
 * Uses the annotation percent value with inflation if inflation toggle is
 * enabled
 */
const dataSetPercent = (inflationToggle: boolean): string =>
  inflationToggle ? CONSTANTS.D3_PERCENT_KEY : CONSTANTS.D3_PERCENT_KEY

/**
 * Returns an amount with symbols if symbol is passed and `percentToggle` is
 * false. If `percentToggle` is `true` then the amount is formatted `toFixed(1)`
 * and a `%` symbol is added
 *
 * @note Real localization will be added in the future
 */
const amountSymbols = ({
  amount,
  formatter,
}: {
  amount: number
  formatter?: FormatterFunction
}): string | number => {
  if (formatter) {
    return formatter(amount)
  }

  return amount
}

/**
 * Adjusts the breakeven circles Y position in order to prevent them from
 * overlapping and hiding data. If there is no deposit line or annuity line,
 * skip adjusting
 */
const adjustBreakevenCircleOverlap = (
  tontineCircleAge: number,
  depositAccountCircleAge: number,
  annuityCircleAge: number,
  toggles: TontinatorToggles
): BreakevenAdjustments => {
  //If annotations are 2 years different then they will overlap, but this might
  //not cover every scenario, can't test since annuities are broken when
  //annuities are fixed this will be adjusted not to only check age
  const ageOverlapThreshold = 1

  const NO_ADJUSTMENT = 0

  const annuityYAdjustmentInPixels = 15
  const depositAccountYAdjustmentInPixels = 0
  const genericYAdjustmentInPixels = -15
  const firstAnnotationThresholdCover = 80

  const adjustments: BreakevenAdjustments = {
    tontineAdjustment: NO_ADJUSTMENT,
    depositAccountAdjustment: NO_ADJUSTMENT,
    annuityAdjustment: NO_ADJUSTMENT,
  }

  //Adjust only if there are other lines
  if (toggles?.annuityLine && toggles?.depositLine) {
    // Check pair tontineCircleAge and depositAccountCircleAge
    if (
      Math.abs(tontineCircleAge - depositAccountCircleAge) <=
      ageOverlapThreshold
    ) {
      //Sorts out an edge case where the very first annotation is covered by
      //generic adjustment
      if (tontineCircleAge > firstAnnotationThresholdCover) {
        return {
          ...adjustments,
          tontineAdjustment:
            genericYAdjustmentInPixels - genericYAdjustmentInPixels,
          depositAccountAdjustment: depositAccountYAdjustmentInPixels + 15,
          annuityAdjustment: annuityYAdjustmentInPixels,
        }
      }

      return {
        ...adjustments,
        tontineAdjustment: genericYAdjustmentInPixels,
        depositAccountAdjustment: depositAccountYAdjustmentInPixels,
        annuityAdjustment: annuityYAdjustmentInPixels,
      }
    }

    // Check pair tontineCircleAge and annuityCircleAge
    if (Math.abs(tontineCircleAge - annuityCircleAge) <= ageOverlapThreshold) {
      return {
        ...adjustments,
        tontineAdjustment: genericYAdjustmentInPixels,
        annuityAdjustment: annuityYAdjustmentInPixels,
      }
    }

    // Check pair depositAccountCircleAge and annuityCircleAge
    if (
      Math.abs(depositAccountCircleAge - annuityCircleAge) <=
      ageOverlapThreshold
    ) {
      return {
        ...adjustments,
        depositAccountAdjustment: genericYAdjustmentInPixels,
        annuityAdjustment: annuityYAdjustmentInPixels,
      }
    }
  }

  return adjustments
}

/**
 * Returns the array with the longest length and all of it's elements from an
 * array of arrays
 */
const findLongestArray = (
  array: Array<Array<ForecastPayout>>
): Array<ForecastPayout> =>
  array.reduce(
    (longest: Array<ForecastPayout>, current: Array<ForecastPayout>) =>
      current.length > longest.length ? current : longest
  )

/**
 * Generates an array of X axis values
 */
const generateAgeXAxis = (
  start: number,
  max: number,
  step: number,
  lastElementToDisplay: number
): Array<number> => {
  const sequence = []
  const thresholdDistance = 8

  //Current element
  let current = Math.ceil(start / step) * step

  // Generate the sequence of X axis values without start and end
  while (current < max) {
    sequence.push(Math.round(current / step) * step)
    current += step
  }

  // Remove the first element from the sequence
  sequence.splice(0, 1)

  // Check if the distance between the first and start elements is greater than
  // or equal to the threshold distance
  if (sequence[0] - start >= thresholdDistance) {
    //Return the array with placing a 2nd element so in some cases the
    //ticks look nice
    return [
      start,
      // Make sure to all take the ceil value of the 2nd element
      // because it can become a float
      Math.ceil(start + step - 1),
      ...sequence,
      lastElementToDisplay,
    ]
  }

  // Return the array with the start, sequence, and lastElementToDisplay
  return [start, ...sequence, lastElementToDisplay]
}

/**
 * Generates a legend item for the chart legend
 */
const generateLegendItem = ({
  itemColor,
  text,
  renderLine,
  onClick,
  id,
}: {
  itemColor: string
  text: string
  renderLine?: boolean
  onClick?: () => void
  id?: string
}) => {
  try {
    if (itemColor && text) {
      return {
        color: itemColor,
        text,
        onClick: onClick,
        lineToggled: Boolean(renderLine),
        id,
      }
    } else {
      throw new TypeError(
        `Missing required arguments, provided: ${itemColor} and ${text}`
      )
    }
  } catch (error) {
    console.error(error)
  }

  return {
    color: itemColor,
    text: '',
    onClick: undefined,
    lineToggled: true,
  }
}

const alphaStringId = () =>
  (Math.random() + 1).toString(36).substring(7).replaceAll(/\d/g, '')

/**
 * Centers text in the hovering line box
 */
const centerText = ({
  mousePointerPositionX,
  ageOnLine,
  adjustByIfAbove3Digits,
  adjustBy,
  xAxisLastAgeTick,
}: {
  mousePointerPositionX: number
  ageOnLine: number
  adjustByIfAbove3Digits: number
  adjustBy: number
  xAxisLastAgeTick: number
}) => {
  if (ageOnLine >= xAxisLastAgeTick) {
    return mousePointerPositionX - adjustByIfAbove3Digits
  }

  return mousePointerPositionX - adjustBy
}

/**
 * Adjusts the hovering line box if it reaches the edges of the chart Moves
 * the box back 20 pixes if it reaches the edge of the chart
 */
const adjustHoveringLineBox = ({
  mousePointerPositionX,
  xAxisFirstAgeTick,
  xAxisLastAgeTick,
  currentAgeData,
}: {
  mousePointerPositionX: number
  xAxisFirstAgeTick: number
  xAxisLastAgeTick: number
  currentAgeData: number
}) => {
  if (currentAgeData === xAxisFirstAgeTick) {
    return mousePointerPositionX + 20
  }

  if (currentAgeData >= xAxisLastAgeTick) {
    return mousePointerPositionX - 20
  }
  return mousePointerPositionX
}

/**
 * Prevents hovering annotation overlap when two hovering annotation amounts
 * have small difference between them
 */
const preventSameLineTypeOverlap = ({
  previousClosestData,
  closestData,
  thresholdPercentToCompareBy,
  adjustYForLowestPayoutLine,
  adjustYForHighestPayoutLine,
  xAxisLastAgeTick,
}: {
  previousClosestData: {
    tontine: {
      amount: number
    }
  }
  closestData: {
    age: {
      years: number
    }
    tontine: {
      amount: number
    }
  }
  thresholdPercentToCompareBy: number
  adjustYForLowestPayoutLine: number
  adjustYForHighestPayoutLine: number
  xAxisLastAgeTick: number
}) => {
  //Takes the previous line amount and rounds it
  const previousAmount = previousClosestData?.tontine?.amount
  //Takes the current line amount and rounds it
  const currentAmount = closestData?.tontine?.amount

  // Makes sure no adjustments are applied if the last age tick is reached
  // because the line hits the edge of the chart and the hovering callout will
  // not be visible
  if (closestData?.age?.years === xAxisLastAgeTick) {
    return 0
  }

  //Calculates the difference between the amounts, the returned percentage
  //number is from 0 to 100
  const differenceInPercentageBetweenAnnotations = Math.abs(
    differenceInPercentage(
      previousClosestData?.tontine?.amount,
      closestData?.tontine?.amount
    )
  )
  //Find the lowest amount, because we need a relative line to compare with
  //so we know which line need to go -Y or +Y
  const lowestAmount = Math.min(currentAmount, previousAmount)

  if (differenceInPercentageBetweenAnnotations < thresholdPercentToCompareBy) {
    if (lowestAmount === previousAmount) {
      return adjustYForLowestPayoutLine
    }
    return adjustYForHighestPayoutLine
  }

  return 0
}

/**
 * Calculates the perfect X distance for a callout from the crosshair
 */
const hoveringCalloutDistanceFromCrosshair = (rectWidth: number) => {
  if (rectWidth > 0) {
    return Math.ceil(rectWidth / 2) + 4
  }
  return 0
}

export {
  dateParser,
  maxAmounts,
  annotationValue,
  adjustForInflation,
  calculateBreakevenAges,
  amountSymbols,
  adjustBreakevenCircleOverlap,
  minDateRange,
  findLongestArray,
  generateAgeXAxis,
  generateLegendItem,
  alphaStringId,
  centerText,
  adjustHoveringLineBox,
  preventSameLineTypeOverlap,
  hoveringCalloutDistanceFromCrosshair,
}
