import style from '../../scss/components/Card.module.scss'
import { ASSET } from '../../constants/Assets'
import Icon from '../media-and-icons/Icon'
import { CardAlertType } from '../../types/Card.type'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

const alertIcons: Record<CardAlertType, string> = {
  completed: ASSET.iconaccountcheckedreencirclemall,
  warn: ASSET.infoamber,
  error: ASSET.icononboardininfocirclemall,
  pending: ASSET.yellowClock,
}

/**
 * Renders an alert component based on the provided alert type.
 */
const CardAlert = ({ alert }: { alert: CardAlertType }) => {
  const iconFileName = alertIcons[alert]
  if (iconFileName) {
    return (
      <Icon
        dataTestId={UI_TEST_ID?.cardAlert}
        className={`${style[`card__alert`]} ${style[`card__alert--${alert}`]}`}
        fileName={iconFileName}
      />
    )
  }

  if (typeof alert === 'number' && alert > 0) {
    return (
      <div
        data-testid={UI_TEST_ID?.cardAlert}
        className={`${style[`card__alert`]}`}
      >
        {alert}
      </div>
    )
  }

  return <></>
}

export default CardAlert
