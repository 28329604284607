import axios from 'axios'
import { axiosConfig } from '../RequestConfig'
import { API } from '../API'

/**
 * @param {object} callbacks - Contains `onSuccess` and `onFailure` functions
 * @param {AbortSignal} signal- Cancels the previous
 * request if it has not been completed
 *
 * @description Retirement age threshold for retirement
 */
const readAgeThresholds = (
  params,
  { onSuccess, onFailure, onFinally },
  signal,
  authToken
) => {
  axios
    .post(
      API.ageThresholds,
      //Supports: sex, current_age and residency as well, but that is only
      //for anon users and it is not used at the moment for JPN demo
      {
        residency: params?.residency,
      },
      axiosConfig({ signal, authToken })
    )
    .then(({ data }) => onSuccess(data))
    .catch(onFailure)
    .finally(onFinally)
}

/**
 * @legacy Will be removed in favour of `BankMachine`
 * Check `/state-management/banking/BankMachineServices.ts`
 */
export const tontineBanking = {
  readAgeThresholds,
}
