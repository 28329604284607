import { useState } from 'react'
import PropTypes from 'prop-types'
import { tontineFeedback } from '../../api/legacy/Feedback'
import { useTranslate } from '../../hooks/useTranslate'
import Icon from '../media-and-icons/Icon'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/components/Rating.module.scss'

const NET_PROMOTER_SCORE = 10

const Rating = ({ setFeedbackId, setRating }) => {
  const {
    context: { authToken },
  } = useAccountService()
  const t = useTranslate()

  const [activeToggleButton, setActiveToggleButton] = useState({
    activeButton: null,
    buttons: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
      { id: 7 },
      { id: 8 },
      { id: 9 },
      { id: 10 },
    ],
  })

  const toggleActive = (index) => {
    setActiveToggleButton((prevState) => ({
      ...prevState,
      activeButton: prevState.buttons[index].id,
    }))

    tontineFeedback.saveRating(
      activeToggleButton.buttons[index].id,
      authToken,
      {
        onSuccess: (feedbackId) => {
          //sets the feedback id
          setFeedbackId(feedbackId)
          //returns the saved rating on the server
          setRating(activeToggleButton.buttons[index].id)
        },
        onFailure: (error) => {
          console.error(error)
        },
      }
    )
  }

  /**
   * @param {number} index
   */
  const toggleActiveStyle = (index) => {
    return style[
      `rating__buttons--${
        activeToggleButton.buttons[index].id === activeToggleButton.activeButton
          ? 'active'
          : 'inactive'
      }`
    ]
  }

  const generateButtons = () => {
    let buttons = []

    Array.from({ length: NET_PROMOTER_SCORE }, (_, index) => {
      buttons = [
        ...buttons,
        <div
          onClick={() => toggleActive(index)}
          className={toggleActiveStyle(index)}
          key={index}
        >
          {index + 1}
        </div>,
      ]
    })

    return buttons
  }

  return (
    <div className={style.rating}>
      <div className={style['rating__buttons']}>{generateButtons()}</div>
      <div className={style['rating__emojis']}>
        <div className={style['rating__emojis-text-emoji']}>
          <Icon
            fileName={ASSET.iconnotlikelyemojiurey}
            className={style['rating__emoji-icon']}
          />
          <div>{t('FEEDBACK.RATING_LOW_TEXT')}</div>
        </div>
        <div className={style['rating__emojis-text-emoji']}>
          <Icon
            fileName={ASSET.iconerylikelyemojiurey}
            className={style['rating__emoji-icon']}
          />
          <div>{t('FEEDBACK.RATING_HIGH_TEXT')}</div>
        </div>
      </div>
    </div>
  )
}

Rating.propTypes = {
  setRating: PropTypes.func,
  setOpenModal: PropTypes.func,
  setFeedbackId: PropTypes.func,
}

export default Rating
