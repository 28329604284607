import PropTypes from 'prop-types'
import style from '../../scss/components/Header.module.scss'

/**
 * Renders a header with a title text
 */
const Header = ({
  title,
  className,
  containerClassName,
  textColor = 'default',
  variant,
}: {
  title?: string
  className?: string
  containerClassName?: string
  variant?: 'spaced'
  textColor?: 'blue' | 'default'
}) => {
  return (
    <header
      className={`${style[`header${variant ? `--${variant}` : ''}`]} ${containerClassName}`}
    >
      <h1
        className={`${style[`header__text--${textColor}`]}  ${className ?? ''}`}
      >
        {title}
      </h1>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
}

export default Header
