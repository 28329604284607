import { generateRange } from '../utils/UtilFunctions'
import { useGetRetirementAgeThresholds } from './useGetRetirementAgeThreshold'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { useState, useEffect } from 'react'
import { extractYearFromAgeMonthString } from '../utils/TSUtilFunctions'

/**
 * Takes in `user_details` and `ageThreshold` and calculates the retirement data
 * that is used by the UI inputs so the user can set their retirement month and
 * year
 */
export const useIncomeScheduler = (retirementData) => {
  const {
    context: { user_details },
  } = useAccountService()

  const { ageThreshold, loading } = useGetRetirementAgeThresholds({
    residency: user_details?.residency,
  })

  //Income scheduling state
  const [scheduleRetirement, setScheduleRetirement] = useState(retirementData)
  //Generates slider steps, sliders steps are in state so when the user reaches
  //the range limit they can click the increment button so a new step is added
  //to the range. The range start if from age threshold, always. Age threshold
  //contains the minimum date that the user can set the slider to
  const [sliderSteps, setSliderSteps] = useState([])

  //Initializes the income scheduler with retirement data or if there is no
  //retirement data uses the age threshold
  useEffect(() => {
    if (ageThreshold) {
      setScheduleRetirement(presetRetirementData(retirementData, ageThreshold))
      setSliderSteps(presetAgeSlider(ageThreshold))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //HACK: For japan demo to refetch thresholds depending on country
    user_details?.residency,
    ///////////////////
    user_details?.date_of_birth,
    retirementData?.year,
    retirementData?.month,
    ageThreshold?.payout_start?.max_age,
    ageThreshold?.payout_start?.max_year_month,
    ageThreshold?.payout_start?.min_age,
    ageThreshold?.payout_start?.min_year_month,
  ])

  return {
    sliderSteps,
    setSliderSteps,
    scheduleRetirement,
    setScheduleRetirement,
    isLoading: loading,
    ageThreshold,
  }
}

//Presets the income scheduler data by checking if the user has already
//set their retirement month/year, if they have not then the age threshold
//data is used to preset the income scheduler
const presetRetirementData = (retirementData, ageThreshold) => {
  //Parses the age threshold values to number format
  const { year, month } = extractYearFromAgeMonthString(
    ageThreshold?.payout_start?.min_year_month
  )
  //Parses the age threshold values to number format
  const { age: yearsOld, month: monthsOld } = extractYearFromAgeMonthString(
    ageThreshold?.payout_start?.min_age
  )

  //TODO: Add https://github.com/TontineTrust/tontine-webapp/issues/2388

  //Prioritize using the retirement data passed in from props, the reason being
  //because those values can be pension plan values
  //Retirement data MONTH and YEAR are in number format
  if (retirementData?.year > 0 && retirementData?.month >= 0) {
    return {
      //If no values are provided then use age threshold values
      month: retirementData?.month,
      year: retirementData?.year,
      monthsOld: retirementData?.monthsOld,
      yearsOld: retirementData?.yearsOld,
    }
  }

  //Just returns the age thresholds
  return {
    month,
    year,
    yearsOld,
    monthsOld,
  }
}

/**
 * Needs only the age threshold, because we want all MIN and MAX values, so the
 * user can stay within thresholds
 * Returns an array of numbers with all the possible ranges the slider can go up to.
 * @returns {Array<number>}
 */
const presetAgeSlider = (ageThreshold) => {
  //Sets age slider year to minimum from threshold age
  const { age: minStartYear } = extractYearFromAgeMonthString(
    ageThreshold?.payout_start?.min_year_month
  )

  //Sets age slider year to maximum
  const { age: maxStartYear } = extractYearFromAgeMonthString(
    ageThreshold?.payout_start?.max_year_month
  )

  return generateRange(minStartYear, maxStartYear)
}

/**
 * Does not do an api request to `age_thresholds`, it sets the values based on
 * passed in props
 */
export const useIncomeSchedulerWithoutAgeThresholds = ({
  initialRetirementData = {},
  initialSliderSteps = [],
}) => {
  const [scheduleRetirement, setScheduleRetirement] = useState(
    initialRetirementData
  )
  //Generates slider steps, sliders steps are in state so when the user reaches
  //the range limit they can click the increment button so a new step is added
  //to the range. The range start if from age threshold, always. Age threshold
  //contains the minimum date that the user can set the slider to
  const [sliderSteps, setSliderSteps] = useState(initialSliderSteps)

  return {
    sliderSteps,
    setSliderSteps,
    scheduleRetirement,
    setScheduleRetirement,
  }
}
