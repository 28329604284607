import NextPayoutLoadingBar from './NextPayoutLoadingBar'
import { useTranslate } from '../../hooks/useTranslate'
import style from '../../scss/components/NextPayout.module.scss'
import { CardVariantType } from '../../types/Card.type'

type NextPayoutProps = {
  previousAmount: string
  nextAmount: string
  maxProgress: number
  currentProgress: number
  variant: CardVariantType
}

/**
 * Renders a progress bar that displays the days until the user
 * gets paid visually, also the previous paid out amount and the next amount to
 * be paid out is displayed.
 */
const NextPayout = ({
  previousAmount,
  nextAmount,
  maxProgress,
  currentProgress,
  variant,
}: NextPayoutProps) => {
  const t = useTranslate()
  return (
    <article
      className={`${style.nextPayout} ${style[`nextPayout${variant ? `--${variant}` : ''}`]}`}
    >
      <NextPayoutLoadingBar
        maxProgress={maxProgress}
        currentProgress={currentProgress}
      />
      <div className={style['nextPayout__amount-container']}>
        <p className={style['nextPayout__previous-amount']}>{previousAmount}</p>
        <div className={style['nextPayout__next-amount-container']}>
          <div className={style['nextPayout__info-amount']}>
            {t('NEXT_PAYOUT_INFO')}
          </div>
          <p className={style['nextPayout__next-amount']}>{nextAmount}</p>
        </div>
      </div>
    </article>
  )
}

export default NextPayout
