import { track } from '../../analytics/Analytics'
import { CheckboxEvent } from '../../analytics/EventData'
import AgreementContent from '../../components/data-display/AgreementContent'
import Modal from '../../components/overlay/Modal'
import { useTranslate } from '../../hooks/useTranslate'
import { AgreementContents } from '../../state-management/legal/LegalMachineTypes.types'
import { useLegalMachine } from '../../state-management/legal/useLegalMachine'
import { Signature } from './CommonLegalTypes.types'

type TermsAndConditionsProps = {
  isOpen: boolean
  signature: Signature
  onClickCloseModalButton: () => void
  onSigned: (signature: Signature) => void
}

/**
 * Renders the Terms and Conditions modal and allows the user to accept the
 * terms and conditions. When accepting the T&C the modal closes
 */
const TermsAndConditions = ({
  isOpen,
  signature,
  onClickCloseModalButton,
  onSigned,
}: TermsAndConditionsProps) => {
  const t = useTranslate()

  const {
    legalContext: { agreement },
  } = useLegalMachine()

  return (
    <Modal
      isOpen={isOpen}
      showCloseButton
      backdrop
      wide
      onClickCloseButton={onClickCloseModalButton}
      onOutsideModalContentClick={onClickCloseModalButton}
      trackActivityBackDrop={{
        trackId: 'terms_and_conditions_modal_close_via_backdrop',
      }}
      trackActivityCloseButton={{
        trackId: 'terms_and_conditions_modal_close',
      }}
    >
      <AgreementContent
        backgroundColor="white"
        agreementHeading={
          agreement?.TermsAndConditions?.heading ??
          agreement?.TermsAndConditions?.title ??
          ''
        }
        agreementImage={agreement?.TermsAndConditions?.image ?? ''}
        agreementContent={agreement?.TermsAndConditions?.text ?? ''}
        agreementData={agreement?.TermsAndConditions}
        onAgreed={(signedAgreementData) => {
          const signedData = signedAgreementData as {
            checkboxChecked: boolean
            agreementData: AgreementContents
          }
          onSigned({
            checkboxChecked: signedData?.checkboxChecked,
            agreementVersion: signedData?.agreementData?.version,
          })

          if (!signature?.checkboxChecked) {
            onClickCloseModalButton()
          }

          track({
            event: CheckboxEvent.toggled,
            properties: {
              object_id: 'terms_and_conditions_modal_tac',
              object_value: signedData?.checkboxChecked,
            },
          })
        }}
        checkboxLabel={t('ONBOARDING.CHECKBOX_TEXT')}
        agreementSigned={signature?.checkboxChecked}
      />
    </Modal>
  )
}

export default TermsAndConditions
