import { useState } from 'react'
import { sanitizeInputValue } from '../utils/TSUtilFunctions'
import { CONSTANTS } from '../constants/ConstantValues'
import { formatToZipPlusFourFormat } from '../utils/Formatters'
import { MarriedValues, Sex, StateData } from '../types/CommonTypes.types'

/**
 * Provides extended `useState` dispatch functions, which contain input
 * restriction based on the input value.
 *
 * Hooks built with:
 * - `useState` hook
 */
const useUserInfo = (
  params: {
    first_name?: string
    last_name?: string
    additional_names?: string
    dob?: string
    ssn?: string
    primary_phone_number?: string
    primary_email?: string
    default_sex?: Sex
  } = {}
) => {
  const {
    first_name,
    last_name,
    additional_names,
    dob,
    ssn,
    primary_phone_number,
    primary_email,
    default_sex,
  } = params

  const [userIsTyping, setUserIsTyping] = useState(false)
  const userTypedData = () => setUserIsTyping(true)

  const [firstName, _setFirstName] = useState<string | undefined>(first_name)
  const [lastName, _setLastName] = useState<string | undefined>(last_name)
  const [additionalName, _setAdditionalName] = useState<string | undefined>(
    additional_names
  )
  const [userDob, _setUserDob] = useState<string | Date | undefined>(dob)
  const [userSsn, _setUserSsn] = useState<string | undefined>(ssn)
  const [primaryPhoneNumber, _setPrimaryPhoneNumber] = useState<
    string | undefined
  >(primary_phone_number)
  const [userEmail, _setUserEmail] = useState<string | undefined>(primary_email)
  const [sex, _setSex] = useState<Sex | undefined>(default_sex)

  const setFirstName = (firstName: string) => {
    _setFirstName(
      sanitizeInputValue({ inputValue: firstName, onlySpaces: true })
    )
    userTypedData()
  }

  const setLastName = (lastName: string) => {
    _setLastName(sanitizeInputValue({ inputValue: lastName, onlySpaces: true }))
    userTypedData()
  }

  const setAdditionalName = (additionalName: string) => {
    _setAdditionalName(additionalName)
    userTypedData()
  }

  const setUserDob = (userDob: Date | string) => {
    _setUserDob(userDob)
    userTypedData()
  }

  const setUserSsn = (value: { value: string; formattedValue: string }) => {
    _setUserSsn(value?.formattedValue)
    userTypedData()
  }

  const setPrimaryPhoneNumber = (primaryPhoneNumber: string) => {
    _setPrimaryPhoneNumber(primaryPhoneNumber)
    userTypedData()
  }

  const setUserEmail = (userEmail: string) => {
    _setUserEmail(userEmail)
    userTypedData()
  }

  const setSex = (sex: Sex) => {
    _setSex(sex)
    userTypedData()
  }

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    additionalName,
    setAdditionalName,
    userDob,
    setUserDob,
    userSsn,
    setUserSsn,
    primaryPhoneNumber,
    setPrimaryPhoneNumber,
    userEmail,
    setUserEmail,
    userIsTyping,
    sex,
    setSex,
  }
}

/**
 * User secondary info, like 2nd phone number or 2nd email. Default values are
 * USA based
 */
const useUserSecondaryInfo = ({
  secondary_email,
  secondary_phone_number,
}: {
  secondary_email?: string
  secondary_phone_number?: string
}) => {
  const [userIsTyping, setUserIsTyping] = useState(false)
  const userTypedData = () => setUserIsTyping(true)

  const [userSecondaryEmail, _setSecondaryUserEmail] = useState<
    string | undefined
  >(secondary_email)
  const [secondaryPhoneNumber, _setSecondaryPhoneNumber] = useState<
    string | undefined
  >(secondary_phone_number)

  const setSecondaryUserEmail = (userEmail: string) => {
    _setSecondaryUserEmail(userEmail)
    userTypedData()
  }

  const setSecondaryPhoneNumber = (secondaryPhoneNumber: string) => {
    _setSecondaryPhoneNumber(secondaryPhoneNumber)
    userTypedData()
  }

  return {
    userSecondaryEmail,
    setSecondaryUserEmail,
    secondaryPhoneNumber,
    setSecondaryPhoneNumber,
    userIsTyping,
  }
}

/**
 * Provides handlers for extended `useState` dispatch functions for handling
 * residency information.
 */
const useResidency = ({
  address_line_1,
  address_line_2,
  city,
  state,
  postal_code,
  country,
}: {
  address_line_1?: string
  city?: string
  state?: string | StateData
  postal_code?: string
  country?: string
  address_line_2?: string
}) => {
  const [userIsTyping, setUserIsTyping] = useState(false)
  const userTypedData = () => setUserIsTyping(true)

  const [addressLine1, _setAddressLine1] = useState<string | undefined>(
    address_line_1
  )

  const [addressLine2, _setAddressLine2] = useState<string | undefined>(
    address_line_2
  )

  const [userCity, _setUserCity] = useState<string | undefined>(city)
  const [userUSAState, _setUserUSAState] = useState<
    string | undefined | StateData
  >(state)
  const [postalCode, _setPostalCode] = useState<string | undefined>(postal_code)
  const [userCountry, _setUserCountry] = useState<string | undefined>(country)

  const setAddressLine1 = (streetAddress: string) => {
    _setAddressLine1(
      sanitizeInputValue({ inputValue: streetAddress, onlySpaces: true })
    )
    userTypedData()
  }

  const setAddressLine2 = (streetAddress: string) => {
    _setAddressLine2(
      sanitizeInputValue({ inputValue: streetAddress, onlySpaces: true })
    )
    userTypedData()
  }

  const setUserCity = (userCity: string) => {
    _setUserCity(sanitizeInputValue({ inputValue: userCity, onlySpaces: true }))
    userTypedData()
  }

  const setUserUSAState = (userUSAState: StateData) => {
    _setUserUSAState(userUSAState)
    userTypedData()
  }

  const setPostalCode = (postalCode: string) => {
    postalCode = formatToZipPlusFourFormat(
      postalCode,
      CONSTANTS.USA_ZIP_DEFAULT_LENGTH
    )

    _setPostalCode(sanitizeInputValue({ inputValue: postalCode }))
    userTypedData()
  }

  const setUserCountry = (stateObject: string) => {
    _setUserCountry(stateObject)
    userTypedData()
  }

  return {
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    userCity,
    setUserCity,
    userUSAState,
    setUserUSAState,
    postalCode,
    setPostalCode,
    userCountry,
    setUserCountry,
    userIsTyping,
  }
}

/**
 * Provides handlers for extended `useState` dispatch functions for handling
 * information about a trust fund
 */
const useTrustInfo = ({
  full_trust_name,
  beneficiary_dob,
  trust_residential_address,
  share_percentage,
  beneficiary_type,
  beneficiary_acknowledgement,
  trust_tin,
  account_assets,
}: {
  full_trust_name?: string
  beneficiary_dob?: string
  trust_residential_address?: string
  share_percentage?: number
  beneficiary_type?: string
  beneficiary_acknowledgement?: boolean
  trust_tin?: string
  account_assets?: string
}) => {
  const [userIsTyping, setUserIsTyping] = useState(false)
  const userTypedData = () => setUserIsTyping(true)

  const [fullTrustName, _setFullTrustName] = useState<string | undefined>(
    full_trust_name
  )
  const [beneficiaryDob, _setBeneficiaryDob] = useState<string | undefined>(
    beneficiary_dob
  )
  const [trustTin, _setTrustTin] = useState<string | undefined>(trust_tin)
  const [trustResidentialAddress, _setTrustResidentialAddress] = useState<
    string | undefined
  >(trust_residential_address)
  const [sharePercentage, _setSharePercentage] = useState<number | undefined>(
    share_percentage
  )
  const [beneficiaryType, _setBeneficiaryType] = useState<string | undefined>(
    beneficiary_type
  )
  const [beneficiaryAcknowledgement, _setBeneficiaryAcknowledgement] = useState<
    boolean | undefined
  >(beneficiary_acknowledgement)

  const [accountAssets, _setAccountAssets] = useState<string | undefined>(
    account_assets
  )

  const setAccountAssets = (accountAssets: string) => {
    _setAccountAssets(accountAssets)
    userTypedData()
  }

  const setFullTrustName = (fullTrustName: string) => {
    _setFullTrustName(fullTrustName)
    userTypedData()
  }

  const setBeneficiaryDob = (beneficiaryDob: string) => {
    _setBeneficiaryDob(beneficiaryDob)
    userTypedData()
  }

  const setTrustTin = (trustTin: string) => {
    _setTrustTin(trustTin)
    userTypedData()
  }

  const setTrustResidentialAddress = (trustResidentialAddress: string) => {
    _setTrustResidentialAddress(trustResidentialAddress)
    userTypedData()
  }

  const setSharePercentage = (sharePercentage: number) => {
    _setSharePercentage(sharePercentage)
    userTypedData()
  }

  const setBeneficiaryType = (beneficiaryType: string) => {
    _setBeneficiaryType(beneficiaryType)
    userTypedData()
  }

  const setBeneficiaryAcknowledgement = (
    beneficiaryAcknowledgement: boolean
  ) => {
    _setBeneficiaryAcknowledgement(beneficiaryAcknowledgement)
    userTypedData()
  }

  return {
    fullTrustName,
    setFullTrustName,
    beneficiaryDob,
    setBeneficiaryDob,
    trustTin,
    setTrustTin,
    trustResidentialAddress,
    setTrustResidentialAddress,
    sharePercentage,
    setSharePercentage,
    beneficiaryType,
    setBeneficiaryType,
    beneficiaryAcknowledgement,
    setBeneficiaryAcknowledgement,
    setAccountAssets,
    accountAssets,
    userIsTyping,
  }
}

/**
 * Provides handlers for extended `useState` dispatch functions for spousal
 * information and spousal consent
 */
const useSpousalInfo = ({
  married,
  spousal_name,
  spousal_consent_day,
  account_holder_name,
  account_holder_day,
}: {
  married?: MarriedValues
  spousal_name?: string
  spousal_consent_day?: string
  account_holder_name?: string
  account_holder_day?: string
}) => {
  const [userIsTyping, setUserIsTyping] = useState(false)
  const userTypedData = () => setUserIsTyping(true)

  const [userMarried, _setMarried] = useState<MarriedValues | undefined>(
    married
  )

  const [spousalName, _setSpousalName] = useState<string | undefined>(
    spousal_name
  )
  const [spousalConsentDay, _setSpousalConsentDay] = useState<
    string | undefined
  >(spousal_consent_day)
  const [accountHolderName, _setAccountHolderName] = useState<
    string | undefined
  >(account_holder_name)
  const [accountHolderDay, _setAccountHolderDay] = useState<string | undefined>(
    account_holder_day
  )

  const setMarried = (userMarried: MarriedValues) => {
    _setMarried(userMarried)
    userTypedData()
  }

  const setSpousalName = (spousalName: string) => {
    _setSpousalName(
      sanitizeInputValue({ inputValue: spousalName, onlySpaces: true })
    )
    userTypedData()
  }

  const setSpousalConsentDay = (spousalConsentDay: string) => {
    _setSpousalConsentDay(spousalConsentDay)
    userTypedData()
  }

  const setAccountHolderName = (accountHolderName: string) => {
    _setAccountHolderName(
      sanitizeInputValue({ inputValue: accountHolderName, onlySpaces: true })
    )
    userTypedData()
  }

  const setAccountHolderDay = (accountHolderDay: string) => {
    _setAccountHolderDay(accountHolderDay)
    userTypedData()
  }

  return {
    userMarried,
    setMarried,
    spousalName,
    setSpousalName,
    spousalConsentDay,
    setSpousalConsentDay,
    accountHolderName,
    setAccountHolderName,
    accountHolderDay,
    setAccountHolderDay,
    userIsTyping,
  }
}

const useTrustedPersonInfo = ({
  trusted_person_name,
  trusted_person_email,
  trusted_person_phone,
}: {
  trusted_person_name?: string
  trusted_person_email?: string
  trusted_person_phone?: string
}) => {
  const [userIsTyping, setUserIsTyping] = useState(false)
  const userTypedData = () => setUserIsTyping(true)

  const [trustedPersonName, _setTrustedPersonName] =
    useState(trusted_person_name)
  const [trustedPersonEmail, _setTrustedPersonEmail] =
    useState(trusted_person_email)
  const [trustedPersonPhone, _setTrustedPersonPhone] =
    useState(trusted_person_phone)

  const setTrustedPersonName = (trustedPersonName: string) => {
    _setTrustedPersonName(
      sanitizeInputValue({ inputValue: trustedPersonName, onlySpaces: true })
    )
    userTypedData()
  }
  const setTrustedPersonEmail = (trustedPersonEmail: string) => {
    _setTrustedPersonEmail(trustedPersonEmail)
    userTypedData()
  }
  const setTrustedPersonPhone = (trustedPersonPhone: string) => {
    _setTrustedPersonPhone(trustedPersonPhone)
    userTypedData()
  }

  return {
    trustedPersonName,
    setTrustedPersonName,
    trustedPersonEmail,
    setTrustedPersonEmail,
    trustedPersonPhone,
    setTrustedPersonPhone,
    userIsTyping,
  }
}

export {
  useUserInfo,
  useUserSecondaryInfo,
  useResidency,
  useTrustInfo,
  useSpousalInfo,
  useTrustedPersonInfo,
}
