const INPUT_LIMIT = {
  GENERIC_MAX: 40,
  GENERIC_MIN: 2,
  REF_CODE_MIN: 4,
  FULL_NAME_MAX: 60,
  USA_BANK_ACCOUNT_MAX: 17,
  USA_ACH_ROUTING_NUM_MAX: 9,
  USA_ZIP_CODE_MAX: 10,
  USA_ZIP_DEFAULT_LENGTH: 5,
  MAX_FEEDBACK_LENGTH: 1_200,
  REFERRAL_CODE_MAX: 30,
  TEXTAREA_MAX_LENGTH: 130,
} as const

export { INPUT_LIMIT }
