import { useState } from 'react'
import DividerHeader from '../../components/headers/DividerHeader'
import Layout from '../../components/layout/Layout'
import { CONSTANTS } from '../../constants/ConstantValues'
import { MYTT_DASHBOARD, PRIVATE } from '../../routes/Route'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useGetRequest } from '../../hooks/useGetRequest'
import { useTranslate } from '../../hooks/useTranslate'
import { API } from '../../api/API'
import NewsCards from './NewsCards'
import LottieAnimation from '../../components/feedback/LottieAnimation'
import { ANIMATION } from '../../constants/Animations'
import TextError from '../../components/typography/TextError'
import ErrorBoundaryAndSuspense from '../../components/error-handling/ErrorBoundaryAndSuspense'
import { environment } from 'MyTontineConfig'
import style from '../../scss/pages/NewsFeed.module.scss'

/**
 *  Fetches data for the news feed using the `newsFeed` API
 */
const NewsFeed = () => {
  //Hooks
  const navigate = useCustomNavigation()
  const t = useTranslate()
  const { isLoading, error } = useGetRequest(
    // Not to spam logs with CORS error in local dev
    environment === 'development' ? undefined : API.getAllSanityArticles,
    ({ data: { result } }) => handleSuccessfulFetching(result),
    {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_SANITY_ACCESS_TOKEN}`,
      },
    }
  )

  //State
  const [news, setNews] = useState({ items: [] })
  const [newsArticleId, setNewsArticleId] = useState('')
  const [limit] = useState(CONSTANTS.MAX_FETCH_COUNT)
  const [offset, setOffset] = useState(0)

  /**
   * Checks if there is more data to be fetched, if there is keep fetching more
   */
  const reachedEndOfScrolling = () => {
    if (news?.total > limit + offset) {
      setOffset((prev) => prev + limit)
    }
  }

  const handleArticleCardClick = (articleId) => {
    //Absolute route needs to be passed in here
    //so it does not causes refresh when a new article
    //appears in the params
    navigate(
      `${PRIVATE.MYTT_DASHBOARD}/${MYTT_DASHBOARD.NEWS_FEED}/${articleId}`
    )
  }

  const handleSuccessfulFetching = (news) => {
    setNews((prev) => {
      //Appends to the already fetched items array
      return {
        ...prev,
        items: [...prev.items, ...news],
        total: news?.length,
      }
    })
  }

  return (
    <main className={style.newsFeed}>
      <DividerHeader
        headerText={t('DASHBOARD.NEWS_FEED_TITLE')}
        className={style[`newsFeed__header`]}
      />
      <Layout
        containerMt="mt-20"
        containerHeight="xlh"
        navigateTo={PRIVATE.MYTT_DASHBOARD}
        //Wipes the article id when back button is pressed
        onClickAction={newsArticleId ? () => setNewsArticleId('') : null}
        hideDividerHeader
        extraSpacing
      >
        <ErrorBoundaryAndSuspense hideNavButton>
          {error && <TextError errorText={t('ERROR_GENERIC')} />}
          {environment === 'development' ? (
            <p>Not available in dev mode</p>
          ) : (
            <>
              {!isLoading ? (
                <NewsCards
                  total={news?.total}
                  newsData={news}
                  onClickNewsArticleId={handleArticleCardClick}
                  onReachedEnd={reachedEndOfScrolling}
                />
              ) : (
                <LottieAnimation
                  animationName={ANIMATION.loadingLightBlueDots}
                  loop
                  autoplay
                />
              )}
            </>
          )}
        </ErrorBoundaryAndSuspense>
      </Layout>
    </main>
  )
}

export default NewsFeed
