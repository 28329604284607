import axios, { AxiosResponse } from 'axios'
import { API } from '../../api/API'
import { axiosConfig } from '../../api/RequestConfig'
import {
  BankMachineContext,
  BankMachineEvent,
  BankingInfo,
} from './BankMachineTypes.type'
import { writeToConsoleAndIssueAlert } from '../StateUtils'
import { API_STATUS } from '../../constants/ApiErrors'
import { Returns } from '../authentication/AuthMachineTypes.type'

/**
 * Gets the the Fed rate and data
 */
const getReturns = async (_: BankMachineContext, event: BankMachineEvent) => {
  if (!event?.payload?.currencyParamForRate) {
    console.warn(`No currency param provided, defaulting to USD`)
  }

  try {
    const response = await axios.get(API.getReturns, {
      ...axiosConfig({
        signal: event?.payload?.abortController?.signal,
      }),
      params: {
        currency: event?.payload?.currencyParamForRate ?? 'USD',
      },
    })

    const { status, data } = response as { status: number; data: Returns }

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback?.(data)
      return data
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.abortController?.abort()
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

type SettledPromise = PromiseSettledResult<AxiosResponse<unknown, unknown>>

/**
 * Fetches all possible banking info. Currently multiple APIs are called in
 * parallel using `Promise.allSettled` until this call is merged into one API from the backend.
 */
const readUserBankingInfo = async (
  _: BankMachineContext,
  event: BankMachineEvent
): Promise<BankingInfo> => {
  /**
   * Check if promise status if fulfilled and returns the data, if the promise
   * is rejected then returns undefined
   */
  const getData = (statusObject: SettledPromise) =>
    statusObject.status === 'fulfilled' ? statusObject.value.data : []

  /**
   * Binds all promise response into a return object that is used by the
   * bank machine context
   */
  const bindResponsesToObject = (
    nominalBalance: SettledPromise,
    nextPayout: SettledPromise
  ) => ({
    nominalBalance: getData(nominalBalance) as Array<unknown>,
    payoutHistory: getData(nominalBalance) as Array<unknown>,
    payinHistory: getData(nominalBalance),
    nextPayout: getData(nextPayout),
  })

  try {
    if (!event?.payload?.authToken) {
      throw new TypeError(`No auth token found to fetch banking data`)
    }

    const controller = event?.payload?.abortController
    //The array destructing follows the order of the API calls in `allSettled`!
    const [
      nominalBalance,
      nextPayout,
      //All settled promise does not care if promise has been accepted or
      //rejected, as long as it has been fulfilled it will continue execution.
      //We want to use it like this in order to ensure for now if one API call
      //fails, not to stop execution for all other API calls
    ] = await Promise.allSettled([
      axios.get(
        API.readUserNominalBalance,
        axiosConfig({
          signal: controller?.signal,
          authToken: event?.payload?.authToken,
        })
      ),
      axios.get(
        API.readNextPayout,
        axiosConfig({
          signal: controller?.signal,
          authToken: event?.payload?.authToken,
        })
      ),
    ])

    return bindResponsesToObject(nominalBalance, nextPayout)
  } catch (error) {
    // to skips spam alerts until other APIs are done in order to prevent client
    // errors
    writeToConsoleAndIssueAlert({
      error,
    })
  } finally {
    event?.payload?.abortController?.abort()
    event?.payload?.finallyCallback?.()
  }

  return {} as BankingInfo
}

export { readUserBankingInfo, getReturns }
