import Divider from '../../data-display/Divider'
import PropTypes from 'prop-types'
import style from '../../../scss/components/MonthYearBubble.module.scss'

/**
 * @param {number} year  The year value to display
 * @param {string} yearLabel  The label for the year value
 * @param {number} month  The month value to display
 * @param {string} monthLabel  The label for the month value
 * @param {boolean} renderOnlyYear Renders only the year value
 *
 * @description MonthAndYearBubble component is used to display the month and
 * year value in a bubble format for the slider thumb, usually
 */
const MonthAndYearBubble = ({ year, yearLabel, month, monthLabel }) => {
  return (
    <article className={style[`month-year-bubble`]}>
      <div className={style[`month-year-bubble__year-container`]}>
        <p className={style[`month-year-bubble__year-value`]}>{year}</p>
        <p className={style[`month-year-bubble__year-label`]}>{yearLabel}</p>
      </div>
      {month > 0 && (
        <>
          <Divider className={style[`month-year-bubble__divider`]} />
          <div className={style[`month-year-bubble__month-container`]}>
            <p className={style[`month-year-bubble__month-value`]}>{month}</p>
            <p className={style[`month-year-bubble__month-label`]}>
              {monthLabel}
            </p>
          </div>
        </>
      )}
    </article>
  )
}

MonthAndYearBubble.propTypes = {
  year: PropTypes.number.isRequired,
  yearLabel: PropTypes.string.isRequired,
  month: PropTypes.number.isRequired,
  monthLabel: PropTypes.string.isRequired,
  renderOnlyYear: PropTypes.bool,
}

export default MonthAndYearBubble
