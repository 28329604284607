import { useState } from 'react'
import { useEventListener } from './useEventListener'

/**
 * Listens for screen resize event and updates the `isMobileOrTablet` state to
 * `true` if mobile or tablet is detected based on `(max-width: 900px)` breakpoint
 */
export const useDeviceScreen = (
  onResize?: (isMobileOrTablet: boolean) => void
) => {
  const [isMobileOrTablet, setSetIsMobileOrTablet] = useState(
    window.matchMedia('(max-width: 900px)')?.matches
  )
  const handleResizeEvent = () => {
    setSetIsMobileOrTablet(window.matchMedia('(max-width: 900px)')?.matches)
    onResize?.(window.matchMedia('(max-width: 900px)')?.matches)
  }

  useEventListener({
    eventName: 'resize',
    handler: handleResizeEvent,
  })

  return {
    isMobileOrTablet,
  }
}
