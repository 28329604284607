import { useEffect } from 'react'
import LottieAnimation from '../../components/feedback/LottieAnimation'
import { ANIMATION } from '../../constants/Animations'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import ReferralDetails from './ReferralDetails'

// Not sure what is going on here, might be needed might not be, waiting on
// product. Still waiting on product
/**
 * Renders a referral component where a first time user can join a
 * referral program or renders referral details for a user that has already joined
 */
const Rewards = () => {
  const { send, currentState } = useAccountService()

  useEffect(() => {
    send({
      type: 'GET_REFERRAL_STATS',
    })
  }, [send])

  if (currentState === 'FETCHING_REFERRAL_STATS') {
    return (
      <LottieAnimation
        autoplay
        loop
        animationName={ANIMATION.loadingLightBlueDots}
      />
    )
  }

  return <ReferralDetails />
}

export default Rewards
