import { lazy } from 'react'
const USAPayoutDetails = lazy(() => import('./USAPayoutDetails'))

/**
 * For only renders payout details for USA bank accounts, in the future there
 * will be multiple components here and render different fields depending on the country
 */
const PayoutDetails = () => {
  return <USAPayoutDetails />
}

export default PayoutDetails
