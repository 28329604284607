import { ReactNode } from 'react'
import { LegalServiceContext } from './LegalServiceContext'

/**
 * Legal service provider that interprets the `bankMachine` from xstate and
 * exposes the global LegalServiceContext with state management functionalities
 * to be used globally
 */
const LegalServiceProvider = ({ children }: { children: ReactNode }) => {
  return <LegalServiceContext.Provider>{children}</LegalServiceContext.Provider>
}

export { LegalServiceProvider }
