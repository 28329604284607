import { createContext } from 'react'
import { ReferralDetails } from '../../pages/referral/ReferralTypes.type'
import { LitePensionPlan } from '../authentication/AuthMachineTypes.type'
import { TranslatedError } from '../../types/CommonTypes.types'

type LiteAuthContext = {
  isAuth: boolean
  referralDetails?: ReferralDetails
  litePensionPlan?: LitePensionPlan
  error?: TranslatedError
  isLoading: boolean
  verifyToken?: string
  currentState?: unknown
}

const LiteContext = createContext({} as LiteAuthContext)

export { LiteContext }
