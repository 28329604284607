import PropTypes from 'prop-types'
import style from '../../scss/components/ToastMessage.module.scss'

type ToastMessageProps = {
  title: string
  content?: string | null
  textColor?: string
}

/**
 * @param {string} title Renders a title for the toast message
 * @param {string} content Renders the content of the toast message
 * @param {string} textColor Color of the toast message text, default is white
 *
 * Used for rendering content for react-toastify toast message
 */
const ToastMessage = ({
  title,
  content,
  textColor = '',
}: ToastMessageProps) => {
  return (
    <article className={style[`toast-message--${textColor}`]}>
      {title && <p className={style['toast-message__title']}>{title}</p>}
      {content && <p className={style['toast-message__content']}>{content}</p>}
    </article>
  )
}

ToastMessage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  textColor: PropTypes.string,
}

export default ToastMessage
