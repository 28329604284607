import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DEVELOPER, PRIVATE } from '../routes/Route'
import { useCustomNavigation } from '../hooks/useCustomNavigation'
import GeneralError from '../components/error-handling/GeneralError'
import TontineModal from '../components/overlay/Modal'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { useLegalMachine } from '../state-management/legal/useLegalMachine'

/**
 * @note **Do not wrap this component as a child for `<InitializeUI />`!**
 *
 * Used for mocking `MagicLogin.js` for developers to easily login without going
 * trough the whole user flow also used by Cypress for testing. An `auth_token`
 * is passed in directly as an url param and stored in the `AuthMachine.js`
 * context where it is sent to the `seconds_remaining` API as every other
 * `auth_token`, to check for server session
 *
 * If the backend simply would have static magic tokens instead of auth tokens,
 * there won't be a need for this abomination!
 */
const DevLogin = () => {
  const location = useLocation()
  const auth_token = location?.pathname?.split('/')[3]
  const { send, isAuthenticated } = useAccountService()
  const { sendLegalEvent } = useLegalMachine()

  const checkIfTokenIsRead = (auth_token: string) => {
    if (auth_token?.includes('read')) {
      return 'read'
    }

    return 'write'
  }

  const navigate = useCustomNavigation()
  const [error, setError] = useState<undefined | string>(undefined)

  useEffect(() => {
    // Close error modal if user navigates from the page
    return () => setError(undefined)
  }, [location?.pathname])

  useEffect(() => {
    // auth token from the route
    if (
      !isAuthenticated &&
      auth_token &&
      location.pathname.includes(DEVELOPER.LOGIN_ONLY)
    ) {
      sendLegalEvent({
        type: 'FETCH_AGREEMENT',
        payload: {
          authToken: auth_token,
          agreementTypes: [
            'TontinesAreForLife',
            'YourHealthIsYourWealth',
            'Variability',
            'TermsAndConditions',
          ],
          successCallback: () =>
            send({
              type: 'DEV_LOGIN',
              payload: {
                authToken: auth_token,
                permissions: checkIfTokenIsRead(auth_token),
                successCallback: () =>
                  navigate(PRIVATE.MYTT_DASHBOARD, { replace: true }),
                failureCallback: () =>
                  setError(
                    `Cannot dev login, expired or nonexisting fake auth token check the following URL param >> ${auth_token} <<`
                  ),
              },
            }),
        },
      })
    }
  }, [
    send,
    isAuthenticated,
    auth_token,
    navigate,
    location?.pathname,
    sendLegalEvent,
  ])

  if (error) {
    return (
      <TontineModal isOpen backdrop>
        <GeneralError errorMessage={error} hideErrorTitle hideNavButton />
      </TontineModal>
    )
  }

  return <></>
}

export default DevLogin
