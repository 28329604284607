import { CONSTANTS } from '../../constants/ConstantValues'
import UserInput from './UserInput'
import { TextInputProps } from '../../types/CommonInterfaces.interfaces'
import { useDebouncedValidation } from '../../hooks/useDebouncedValidation'

/**
 * Generic text input component that has debounced input, error message and input
 * formatting. Extension of the generic `<UserInput />` component
 */
const TextInput = <T extends string>({
  onChange,
  value,
  onClick,
  onKeyDown,
  errorMessage,
  label,
  readOnly,
  placeholder,
  validatorFunction,
  type = 'text',
  inputMode,
  pattern,
  className,
  formatterFunction,
  suffixIcon,
  jsxValue,
  debounceTime = CONSTANTS.DEBOUNCE_TIME,
  prefix,
  dataTestID,
  suffixText,
  autoComplete,
  maxLength,
  labelInfoIcon,
  isTextArea,
  restrictionRegex,
  optional,
  defaultValue,
  tooltipText,
  height,
  trackActivity,
}: TextInputProps<T>) => {
  const validateDebounced = useDebouncedValidation(
    debounceTime,
    validatorFunction
  )

  /**
   * If a formatter function is passed in it will be used to format
   * the value, otherwise the value will not be formatted
   */
  const onTextValue = (value: string) => {
    if (restrictionRegex) {
      //Does not allow the user to enter any character that is not in the
      //regex
      value = value.replaceAll(restrictionRegex ?? '', '')
    }

    if (formatterFunction) {
      const formatterObject = {
        formattedValue: formatterFunction(value as unknown as T),
        value,
      }
      onChange && onChange(formatterObject as unknown as T)
    } else {
      onChange && onChange((value as unknown as T) ?? '')
    }

    //Invalidate input as soon as the user is typing
    validatorFunction && validatorFunction(undefined as unknown as T, optional)

    // Validate input only if not in readonly mode
    !readOnly && validateDebounced(value as unknown as T, optional)
  }

  return (
    <UserInput
      trackActivity={trackActivity}
      height={height}
      readOnly={readOnly}
      label={label}
      type={type}
      //The reason there are two values is to switch between readonly value and
      //edit value
      value={value}
      defaultValue={defaultValue}
      onChange={onTextValue}
      onKeyDown={onKeyDown}
      onClick={onClick}
      placeholder={placeholder}
      inputMode={inputMode}
      pattern={pattern}
      className={className}
      suffixIcon={suffixIcon}
      //Don't render messages on readonly mode
      errorMessage={readOnly ? undefined : errorMessage}
      jsxValue={jsxValue}
      prefix={prefix}
      suffixText={suffixText}
      dataTestID={dataTestID}
      autoComplete={autoComplete}
      maxLength={maxLength}
      labelInfoIcon={labelInfoIcon}
      isTextArea={isTextArea}
      optional={optional}
      tooltipText={tooltipText}
    />
  )
}

export default TextInput
