import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/components/Card.module.scss'
import {
  cardBodyFooterVariants,
  CardBodyFooterVariantType,
  cardHeaderVariants,
  CardHeaderVariantType,
  CardProps,
} from '../../types/Card.type'
import { getCardVariant } from '../../utils/TSUtilFunctions'
import CardBody from './CardBody'
import CardFooter from './CardFooter'
import CardHeader from './CardHeader'

/**
 * Generic card component.
 */
const Card = ({
  title,
  subTitle,
  onClick,
  variant,
  headerImage,
  headerImageSize,
  alertAndArrowPosition = 'end',
  alert,
  ctaButtonLabel,
  ctaButtonVariant,
  roundness = 'rounded',
  active,
  interactEnabled,
  rotateArrow,
  showArrow = false,
  arrowInvisible,
  disabled,
  dataTestID,
}: CardProps) => {
  const cardClasses = [
    style.card,
    interactEnabled && style['card--enable-interact'],
    style[`card--${roundness}`],
    variant && style[`card--${variant}`],
    variant && interactEnabled && style[`card--${variant}--enable-interact`],
    !disabled && active && style['card--active'],
    !disabled && onClick && !ctaButtonLabel && style['card--pointer'],
    disabled && style['card--disabled'],
  ]
    .filter(Boolean)
    .join(' ')

  const showHeader = Boolean(
    headerImage || ((showArrow || alert) && alertAndArrowPosition === 'start')
  )

  const showFooter = Boolean(
    ctaButtonLabel || ((showArrow || alert) && alertAndArrowPosition === 'end')
  )

  const headerVariant = getCardVariant<CardHeaderVariantType>({
    variants: cardHeaderVariants,
    variant,
  })
  const bodyFooterVariant = getCardVariant<CardBodyFooterVariantType>({
    variants: cardBodyFooterVariants,
    variant,
  })

  return (
    <article
      className={cardClasses}
      onClick={ctaButtonLabel || disabled ? undefined : onClick}
      data-testid={dataTestID ?? UI_TEST_ID?.commonCard}
    >
      {showHeader && (
        <CardHeader
          headerImage={headerImage}
          headerImageSize={headerImageSize}
          alertAndArrowPosition={alertAndArrowPosition}
          alert={alert}
          variant={headerVariant}
          rotateArrow={rotateArrow}
          showArrow={showArrow}
          arrowInvisible={arrowInvisible}
        />
      )}
      <CardBody title={title} subTitle={subTitle} variant={bodyFooterVariant} />
      {showFooter && (
        <CardFooter
          alertAndArrowPosition={alertAndArrowPosition}
          alert={alert}
          variant={bodyFooterVariant}
          ctaButtonLabel={ctaButtonLabel}
          ctaButtonVariant={ctaButtonVariant}
          onClick={disabled ? undefined : onClick}
          rotateArrow={rotateArrow}
          showArrow={showArrow}
          arrowInvisible={arrowInvisible}
        />
      )}
    </article>
  )
}

export default Card
