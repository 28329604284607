import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CONSTANTS } from '../../../constants/ConstantValues'
import { selectDomElement } from '../../../utils/UtilFunctions'
import style from '../../../scss/components/SliderInput.module.scss'
import { track } from '../../../analytics/Analytics'
import { SliderEvent } from '../../../analytics/EventData'

const variantToColor = {
  yellow: '#ffcc50',
}

const disabledAreaColor = '#dddd'

/**
 * @param {Array=} steps  The array of steps to search.
 * @param {*} value  The value to search for in the array of steps.
 *
 * Finds the index of a given value in an array of steps that are passed in for
 * the range input
 */
const findStepIndex = (steps, value) =>
  steps?.findIndex((step) => step === value)

/**
 * @param {function=} onChange Function to be called when the slider is changed
 * @param {array=} steps Array of steps for the slider, steps contains certain
 * values that the slider can take
 * @param {number=} value Range value, default is 0, the value is used as a index
 * in the passed in `steps` array
 * @param {boolean=} disabled Whether the slider is disabled or not
 * @param {JSX=} thumbBubble Whether the thumb should have a bubble component
 * that displays the value of the range slider
 * @param {string=} className The name of the class targeting the main range
 * container
 *
 * @description Renders a draggable slider using the `input` default html5
 * component
 */
const Range = ({
  onChange = null,
  steps = [],
  value = 0,
  disabled,
  thumbBubble,
  className,
  variant,
  trackActivity,
  disabledIncrement,
  disabledDecrement,
  enabledSteps,
}) => {
  const trackMovement = ({ objectValue }) => {
    void track({
      event: SliderEvent.moved,
      properties: {
        object_id: trackActivity?.sliderId,
        object_value: objectValue,
      },
    })
  }

  const sliderRef = useRef(null)
  const color = variantToColor[variant] ?? CONSTANTS.SLIDER_STYLE_CHROME

  /**
   * Moves the bubble that is above the slider thumb, it follow the slider thumb
   * and adjusts the bubble accordingly so it does not go past the Range.jsx's
   * width
   */
  const moveThumbBubble = (steps, value) => {
    // Select the slider thumb bubble and the static tooltip elements
    const tooltip = selectDomElement(`.${style[`sliderInput__bubble`]}`)
    const tip = selectDomElement(`.${style['sliderInput__tip']}`)

    if (tooltip && tip) {
      // Set the position of the tooltip to be absolute and above the slider thumb
      // bubble
      tip.style.position = 'absolute'
      tip.style.top = '-20px'
      // 2.5px
      const initialTipPosition = 2.5

      // Get the width of the slider and the slider thumb bubble
      const sliderWidth = sliderRef.current.getBoundingClientRect().width
      const tooltipWidth = tooltip.getBoundingClientRect().width
      const thumbWidth = 31

      // Find the index of the current value in the steps array and calculate its percentage position
      const currentIndex = findStepIndex(steps, value)
      const percent = currentIndex / (steps.length - 1)
      // Slider width scaled with the width of the thumb
      const scaledSliderWidth = Math.floor(sliderWidth * percent)
      const scaledThumbWidth = Math.floor(thumbWidth * percent)

      // Calculate the position of the slider thumb bubble based on the percentage position
      const thumbPosition = (percent * 100 * (sliderWidth - tooltipWidth)) / 100
      tooltip.style.left = `${thumbPosition}px`
      //Positions the tip exactly on top and middle of the thumb
      const currentTipPosition = scaledSliderWidth - scaledThumbWidth
      tip.style.left = `${currentTipPosition > 0 ? currentTipPosition : initialTipPosition}px`
    }
  }

  //After every render adjust the gradient on the slider.
  useEffect(() => {
    let areaBefore = 0
    let areaAfter = 100
    let cutTinyPixel = disabled ? 2 : 0

    if (enabledSteps && enabledSteps?.length > 0) {
      // Find start and end index of the enabled steps
      const startIndex = steps.indexOf(enabledSteps?.[0])
      const endIndex = steps.indexOf(enabledSteps?.[enabledSteps?.length - 1])
      // Create a sub array from steps disabled from left side
      const elementsBeforeEnabledSteps = steps.slice(0, startIndex)
      areaBefore =
        (elementsBeforeEnabledSteps?.length / steps?.length) * 100 +
        cutTinyPixel
      // Calculate area after ENABLED range
      const elementsAfterEnabledSteps = steps.slice(endIndex, steps?.length - 1)
      areaAfter = Math.ceil(
        (1 - elementsAfterEnabledSteps?.length / steps?.length) * 100
      )
    }
    sliderRef.current.style.backgroundImage = `linear-gradient(to right, ${disabledAreaColor} 0 ${areaBefore}%, 
                                               ${color} ${areaBefore}% ${areaAfter}%, 
                                               ${disabledAreaColor} ${areaAfter}% 100%)
                                          `

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledSteps?.length, steps?.length, color])

  useEffect(() => {
    if (thumbBubble) {
      moveThumbBubble(steps, value)
    }
  })

  const handleInput = (event) => {
    const sliderValue = steps[event?.target?.value]

    if (thumbBubble) {
      moveThumbBubble(steps, sliderValue)
    }
    onChange(sliderValue)

    // Tracking needs to be delayed because the
    // value handling min,max is handled in the state
    // and not in the input
    // otherwise if the user is very fast enough they can send
    // retirement age 18 because the value handling is in the state and not here
    let trackTimer
    if (!disabledDecrement && !disabledIncrement) {
      clearTimeout(trackTimer)
      setTimeout(() => {
        trackMovement({ objectValue: steps[event?.target?.value] })
      }, 500)
    }
  }

  //NOTE: the input uses the INDICES of the steps, not the values.
  return (
    <div className={`${style[`sliderInput__range-container`]} ${className}`}>
      <input
        ref={sliderRef}
        className={`${style[`sliderInput__range${disabled ? '--disabled' : ''}`]} ${style[`sliderInput__range${variant ? `--${variant}` : ''}`]} `}
        max={steps?.length - 1}
        min={0}
        onChange={handleInput}
        step={1}
        type="range"
        value={findStepIndex(steps, value)}
        disabled={disabled}
      />
      {thumbBubble && (
        <div className={`${style[`sliderInput__bubble`]}`}>{thumbBubble}</div>
      )}
      {thumbBubble && <div className={style['sliderInput__tip']} />}
    </div>
  )
}

Range.propTypes = {
  onChange: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  thumbBubble: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
  trackActivity: PropTypes.object,
  disabledIncrement: PropTypes.bool,
  disabledDecrement: PropTypes.bool,
  enabledSteps: PropTypes.array,
}

export default Range
