import { createRoot } from 'react-dom/client'
import App from './App'

import { browserStorageEnabled } from '../web-build/utils/UtilFunctions'

import { BrowserOptions, init } from '@sentry/react'
import { envColor, environment, sentry } from 'MyTontineConfig'
import { CONSTANTS, ENVIRONMENTS } from '../web-build/constants/ConstantValues'
import {
  consoleWarningMessage,
  getIpGeoLocation,
} from '../web-build/utils/TSUtilFunctions'

init(sentry as BrowserOptions)

// Won't work on localhost or local dev env
if (environment !== ENVIRONMENTS.development) {
  getIpGeoLocation()
}

if (browserStorageEnabled()) {
  // Just to be sure remove the FaceTec initialization flag when the user refreshes
  // the app
  sessionStorage.removeItem(CONSTANTS.FACETEC_INIT_KEY)
}
if (environment !== ENVIRONMENTS.development) {
  consoleWarningMessage()
}

console.log(`Using config: %c${environment}`, `color:${envColor}`)

const container = document.querySelector('#root')
const root = createRoot(container!)

root.render(<App />)
