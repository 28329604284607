import SecondaryMenuContainer from './SecondaryMenuContainer'
import MenuCard from '../cards/MenuCard'
import MenuCardItem from '../cards/MenuCardItem'
import { useTranslate } from '../../hooks/useTranslate'
import { ASSET } from '../../constants/Assets'
import { PAYOUT } from '../../routes/Route'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import Layout from '../layout/Layout'
import { PRIVATE } from '../../routes/Route'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/**
 *  Payout menu for different payout sections in the app
 */
const PayoutMenu = () => {
  const {
    context: { user_details },
  } = useAccountService()
  const t = useTranslate()

  return (
    <Layout
      navigateTo={PRIVATE.ACCOUNT}
      pageTitle={t('ACCOUNT.MENU_ITEM_PAYOUT_SETUP')}
    >
      <SecondaryMenuContainer>
        <MenuCard
          title={t('BANKING.PAYOUT_ACC_COUNTAINER_TITLE')}
          variant="alternative"
        >
          <MenuCardItem
            mainText={t('BANKING.CARD_WHERE_TO_SEND_YOUR_INCOME_LABEL')}
            to={PAYOUT.SETUP}
            icon={ASSET.iconaccountaddbank}
            writeProtected={!user_details?.payout_account}
            dataTestID={UI_TEST_ID.incomeSubMenuPayoutDetails}
            cardVariant="gray-dirty"
          />
        </MenuCard>

        <MenuCard
          title={t('BANKING.CONTAINER_SET_PAYOUT_START_MONTH')}
          variant="alternative"
          comingSoon
        >
          <MenuCardItem
            disabled={true}
            mainText={t('PAYOUT_SETTINGS.PAGE_TITLE')}
            writeProtected
            icon={ASSET.iconaccountaddbank}
            cardVariant="gray-dirty"
          />
        </MenuCard>

        <MenuCard
          title={t('BANKING.PAYOUT_HISTORY_PAGE_TITLE')}
          variant="alternative"
        >
          <MenuCardItem
            mainText={t('BANKING.PAYOUT_HISTORY_PAGE_TITLE')}
            to={PAYOUT.HISTORY}
            icon={ASSET.iconaccountmenucontributionhitory}
            dataTestID={UI_TEST_ID.subMenuItemIncomeTrans}
            cardVariant="gray-dirty"
          />
        </MenuCard>
      </SecondaryMenuContainer>
    </Layout>
  )
}

export default PayoutMenu
