import PropTypes from 'prop-types'
import { useTranslate } from '../../hooks/useTranslate'
import Header from '../../components/typography/Header'
import FormProgress from '../../components/feedback/FormProgress'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import FormsLayout from '../../components/layout/FormsLayout'
import style from '../../scss/layout/OnboardingForm.module.scss'
import TontinatorInputs from '../../components/mytontine-dashboard/TontinatorInputs'

/**
 * @param {function} setShowForms  Controls the flow of the calculator forms
 * @param {function} setAgeFormCurrentAge Sets the state of the parent component
 * for current age
 * @param {function} setAgeFormRetirementAge Sets the state of the parent
 * component for retirement age
 * @param {string} formHeaderText Text to be displayed above the sliders
 * @param {object} formData Contains the data for the sliders, this data is
 * meant for the calculator
 * @param {function} previousStep On click callbacks that goes to the previous
 * form step
 * @param {string} progress Show the current set of the form
 *
 * @description Renders age input sliders for the calculator
 */
const AgeForm = ({
  setShowForms,
  formHeaderText,
  formData,
  previousStep,
  progress,
  setFormData,
}) => {
  const t = useTranslate()

  //Advances to another form,uses state object and after all inputs have been
  //validated sets contributionForm to true and others and itself to false which
  //tells the parent component that everything is okay and should render the
  //contribution which is the next step
  const nextStep = () => {
    setShowForms({
      sexForm: false,
      ageForm: false,
      contributionForm: true,
    })
  }

  return (
    <main className={style['onboarding-form']}>
      <Header title={formHeaderText} variant="spaced" />

      <FormProgress progress={progress} skipSpacing />

      <FormsLayout>
        <TontinatorInputs
          retirementSliders
          setFormData={setFormData}
          formData={formData}
          contributionSlidersClassName={style['onboarding-form__spacing']}
          skipComparePlanRangeAdjustment
        />
      </FormsLayout>
      <NavigationButtons
        onClickSecond={nextStep}
        onClickFirst={previousStep}
        secondButtonLabel={t('BUTTON_LABEL.NEXT')}
        backButtonWhite
      />
    </main>
  )
}

AgeForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setAgeFormCurrentAge: PropTypes.func,
  setAgeFormRetirementAge: PropTypes.func,
  formHeaderText: PropTypes.string,
  previousStep: PropTypes.func,
  progress: PropTypes.string,
  setShowForms: PropTypes.func,
}

export default AgeForm
