import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * Wraps the `navigate` function provided by `useNavigate` from
 * react router in a `useCallback` function to avoid redefinition every render
 */
export const useCustomNavigation = () => {
  const navigate = useNavigate()
  return useCallback(navigate, [navigate])
}
