import { sortArray } from '../../utils/UtilFunctions'
import { CONSTANTS } from '../../constants/ConstantValues'
import dayjs from 'dayjs'

/**
 * @param {array} contributions  Array of contributions
 *
 * @description Formats the contributions and sorts them by most recent date
 */
const formatContributions = (contributions) => {
  const formattedContributions = contributions
    ?.filter(
      (payout) => payout?.transaction?.[`type'`] === CONSTANTS.contribution
    )
    ?.map((contribution) => {
      return {
        ...contribution,
        //raw date to be used for sorting, and filtering. Removes the time and day
        //from the ISO UTC string, because we don't need it. The final date string
        //value is 'YYYY-MM-DD'
        rawDate: formatToPureISO(contribution?.transaction?.time),
      }
    })

  return sortArray(formattedContributions, CONSTANTS.TRANSACTION_SORT_KEY)
}
/**
 * @param {array} payouts Array of objects
 *
 * Formats and sorts the payouts by most recent by date
 */
const formatPayouts = (payouts) => {
  const initiatedPayouts = payouts
    ?.filter((payout) => payout?.transaction?.[`type'`] === CONSTANTS.payout)
    ?.map((payout) => {
      return {
        ...payout,
        //raw date to be used for sorting, and filtering. Removes the time and day
        //from the ISO UTC string, because we don't need it. The final date string
        //value is 'YYYY-MM-DD'
        rawDate: formatToPureISO(payout?.transaction?.time),
      }
    })

  return sortArray(initiatedPayouts, CONSTANTS.TRANSACTION_SORT_KEY)
}

const formatToPureISO = (date) => dayjs(date).format('YYYY-MM-DD')

export { formatContributions, formatPayouts }
