import PropTypes from 'prop-types'
import LottieAnimation from '../feedback/LottieAnimation'
import WebpImage from '../media-and-icons/WebpImage'
import style from '../../scss/components/Carousel.module.scss'

/**
 * @param {string} webImageName Name of the image to be used from `IMAGE` object
 * @param {string} backupImageName Name of the image to be used in case the
 * browser does not support 'webp' image format
 * @param {string} animationName Name of the animation to be used from
 * `ANIMATION` object
 * @param {string} title Title to be displayed below the image
 * @param {string} content Content to be displayed below the title
 *
 * @description Carousel item that contains the main content of the carousel.
 * Images and lottie animations are supported
 */
const CarouselItem = ({
  webpImageName,
  backupImageName,
  animationName,
  title,
  content,
}) => {
  return (
    <article className={style['carousel__item']}>
      <div className={style['carousel__item-container']}>
        {animationName ? (
          <LottieAnimation
            animationName={animationName}
            autoplay
            loop
            className={style[`carousel__image`]}
          />
        ) : (
          <WebpImage
            className={style['carousel__image']}
            webpName={webpImageName}
            alternativeImageName={backupImageName}
            alt={webpImageName}
          />
        )}
        <h1 className={style['carousel__title']}>{title}</h1>
        <p className={style['carousel__content']}>{content}</p>
      </div>
    </article>
  )
}

CarouselItem.propTypes = {
  webpImageName: PropTypes.string,
  backupImageName: PropTypes.string,
  animationName: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default CarouselItem
