import Header from '../typography/Header'
import Divider from '../data-display/Divider'
import style from '../../scss/layout/DividerHeader.module.scss'

/**
 * Renders a header with a divider below  that stretches full width,
 * this component is only rendered on **Desktop**
 */
const DividerHeader = ({
  headerText,
  className,
  additionalText,
}: {
  headerText?: string
  className?: string
  additionalText?: string
}) => {
  return (
    <div className={`${style[`dividerHeader`]} ${className}`}>
      <section className={style['dividerHeader__inner']}>
        <Header title={headerText} className={className} />
        {additionalText && (
          <p className={style['dividerHeader__additional-txt']}>
            {additionalText}
          </p>
        )}
      </section>
      <Divider />
    </div>
  )
}

export default DividerHeader
