import LottieAnimation from '../../components/feedback/LottieAnimation'
import { convertDateToClientLocale } from '../../utils/UtilFunctions'
import { ANIMATION } from '../../constants/Animations'
import NewsArticleContent from './NewsArticleContent'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import { MYTT_DASHBOARD, PRIVATE } from '../../routes/Route'
import { useGetRequest } from '../../hooks/useGetRequest'
import { API } from '../../api/API'
import { secondsToMinutes } from '../../utils/UtilFunctions'
import { useTranslate } from '../../hooks/useTranslate'
import { ASSET } from '../../constants/Assets'

/**
 * Fetching one article data from the Sanity CMS API
 */
const NewsArticle = () => {
  const t = useTranslate()
  const { news_id } = useParams()
  const { data } = useGetRequest(API.fetchArticleBySlug(news_id), undefined, {
    headers: {
      Authorization: `Bearer ${import.meta.env.VITE_SANITY_ACCESS_TOKEN}`,
    },
  })

  return (
    <Layout
      containerMt="nomt"
      navigateTo={`${PRIVATE.MYTT_DASHBOARD}/${MYTT_DASHBOARD.NEWS_FEED}`}
      pageTitle={data?.result[0]?.articleTitle}
      hideDividerHeader={!data?.result[0]?.articleTitle}
    >
      {data?.result?.length > 0 ? (
        <NewsArticleContent
          title={data?.result[0]?.articleTitle}
          date={
            convertDateToClientLocale(data?.result[0]?.articlePublishDate, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }).formattedLocaleDate
          }
          readingTime={`${secondsToMinutes(
            data?.result[0]?.articleReadingTime
          )} ${t('ARTICLE_READ_LABEL')}`}
          content={data?.result[0]?.articleBody}
          summary={data?.result[0]?.articleSummary}
          image={data?.result[0]?.articleImage?.imageField?.asset?.url}
          dateIcon={ASSET.iconaccountcalendarlihtblue}
        />
      ) : (
        <LottieAnimation
          animationName={ANIMATION.loadingLightBlueDots}
          loop
          autoplay
        />
      )}
    </Layout>
  )
}

NewsArticle.propTypes = {
  newsArticleId: PropTypes.string,
}

export default NewsArticle
