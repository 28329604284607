import PropTypes from 'prop-types'
import Header from '../../components/typography/Header'
import FormProgress from '../../components/feedback/FormProgress'
import { useTranslate } from '../../hooks/useTranslate'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import FormsLayout from '../../components/layout/FormsLayout'
import style from '../../scss/layout/OnboardingForm.module.scss'
import TontinatorInputs from '../../components/mytontine-dashboard/TontinatorInputs'

/**
 * @param {function} setShowForms  Controls the flow of the calculator forms
 * @param {function} setContributionFormDataMonthly Sets the state of the parent
 * component with the monthly contribution amount
 * @param {function} setContributionFormDataOneTime Sets the state of the parent
 * for one time contribution amount
 * @param {string} formHeaderText Text to be displayed above the sliders
 * @param {object} formData Contains the data for the sliders, this data is
 * meant for the calculator
 * @param {function} previousStep On click callbacks that goes to the previous
 * form step
 * @param {string} progress Shows the current step of the progress
 *
 * @description Renders contribution input sliders for the calculator
 */
const ContributionForm = ({
  setShowForms,
  setFormData,
  formHeaderText,
  formData,
  previousStep,
  progress,
}) => {
  const t = useTranslate()

  const nextStep = () => {
    setShowForms({
      sexForm: false,
      ageForm: false,
      contributionForm: false,
      forecast: true,
    })
  }

  return (
    <main className={style['onboarding-form']}>
      <Header title={formHeaderText} variant="spaced" />

      <FormProgress progress={progress} skipSpacing />

      <FormsLayout>
        <TontinatorInputs
          contributionSliders
          setFormData={setFormData}
          formData={formData}
          contributionSlidersClassName={style['onboarding-form__spacing']}
        />
      </FormsLayout>
      <NavigationButtons
        onClickSecond={nextStep}
        onClickFirst={previousStep}
        secondButtonLabel={t('BUTTON_LABEL.NEXT')}
        backButtonWhite
      />
    </main>
  )
}

ContributionForm.propTypes = {
  formData: PropTypes.object,
  setShowForms: PropTypes.func,
  setFormData: PropTypes.func,
  formHeaderText: PropTypes.string,
  previousStep: PropTypes.func,
  progress: PropTypes.string,
}

export default ContributionForm
