import { Trans } from 'react-i18next'
import { useTranslate } from '../../hooks/useTranslate'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import Button from '../../components/buttons/Button'
import Carousel from '../../components/carousel/Carousel'
import Divider from '../../components/data-display/Divider'
import { PUBLIC } from '../../routes/Route'
import OnboardingButtons from './OnboardingButtons'
import { ASSET } from '../../constants/Assets'
import style from '../../scss/pages/Home.module.scss'
import { isLite } from '../../config/lite'

const sliderChangeIntervalMilliseconds = 45_000

const carouselContent = [
  {
    imageWebp: ASSET.step1,
    imagePng: ASSET.step1,
    title: <Trans i18nKey={'MOBILE_MAGIC_LINK_SUCCESS_TITLE'} />,
    content: <Trans i18nKey={'CAROUSE.SLIDE_ONE_CONTENT'} />,
  },
  {
    imageWebp: ASSET.step2,
    imagePng: ASSET.step2,
    title: <Trans i18nKey={'CAROUSE.SLIDE_ONE_TITLE'} />,
    content: <Trans i18nKey={'REGISTER_FORM.FORM_TITLE'} />,
  },
  {
    imageWebp: ASSET.step3,
    imagePng: ASSET.step3,
    title: <Trans i18nKey={'FORECAST_PAGE.HOW_SAFE_INCOME_BUTTON_TEXT'} />,
    content: <Trans i18nKey={'ONBOARDING.SUBHEADER_3'} />,
  },
]

/**
 * @description Contains the carousel and buttons for the onboarding flow
 */
const Home = () => {
  //Hooks
  const t = useTranslate()
  const navigate = useCustomNavigation()

  //Navigator functions
  const navigateToCalculator = () => navigate(PUBLIC.TONTINATOR)
  const navigateToSignIn = () => navigate(PUBLIC.SIGN_IN)
  const navigateToSignUpForm = () => navigate(PUBLIC.SIGN_UP)

  return (
    <main className={style.home}>
      <section className={style['home__container']}>
        {!isLite && (
          <div className={style['home__login-btn']}>
            <Button variant="login" onClick={navigateToSignIn}>
              {t('BUTTON_LABEL.SIGN_IN')}
            </Button>
          </div>
        )}

        <Carousel
          slides={carouselContent}
          slideChangeIntervalMilliseconds={sliderChangeIntervalMilliseconds}
          autoSlide
        />

        <section className={style[`home__bottom-section`]}>
          <Divider className={style[`home__btn-divider`]} />
          <OnboardingButtons
            tontinatorButtonLabel={t('ONBOARDING.SEE_HOW_IT_WORKS_HEADLINE')}
            tontinatorButtonOnClick={navigateToCalculator}
            signupButtonLabel={t('BUTTON_LABEL.SIGN_UP')}
            signupButtonOnClick={navigateToSignUpForm}
          />
        </section>
      </section>
    </main>
  )
}

export default Home
