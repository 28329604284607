import { useState } from 'react'
import { ExtendedContentCardProps } from '../../types/Card.type'
import Card from './Card'
import style from '../../scss/components/Card.module.scss'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/** Extended content wrapper for the `Card` component, renders an additional component alongside the card. */
const ExtendedContentCard = ({
  onClick,
  extendedCardVariant,
  roundness = 'rounded',
  children,
  autoExpand = false,
  expandClickDisabled,
  dataTestID,
  ...restProps
}: ExtendedContentCardProps) => {
  const [expand, setExpand] = useState(autoExpand)
  const toggleExpand = () => {
    if (onClick) {
      onClick()
    }

    setExpand((prev) => !prev)
  }

  const cardClasses = [
    style.extendedCard,
    style[`extendedCard--${roundness}`],
    extendedCardVariant && style[`extendedCard--${extendedCardVariant}`],
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <article
      data-testid={dataTestID ?? UI_TEST_ID?.commonCardExtended}
      className={cardClasses}
    >
      <Card
        {...restProps}
        onClick={expandClickDisabled ? undefined : toggleExpand}
        roundness={'off'}
        rotateArrow={expand ? 'up' : 'down'}
      />
      {expand && children}
    </article>
  )
}

export default ExtendedContentCard
