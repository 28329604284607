import PropTypes from 'prop-types'

import { monthNumberToString } from '../../../../utils/UtilFunctions'
import DateBoxChunk from './DateBoxChunk'
import style from '../../../../scss/components/DateSliderBox.module.scss'

/**
 * @param {function} onClickIncrementMonths  Callback function that is called
 * when the increment button for months is clicked.
 * @param {function} onClickDecrementMonths  Callback function that is called
 * when the decrement button for months is clicked.
 * @param {function} onClickIncrementYears  Callback function that is called
 * when the increment button for years is clicked.
 * @param {function} onClickDecrementYears  Callback function that is called
 * when the decrement button for years is clicked.
 * @param {node} children The content to be rendered inside the container.
 * @param {number} month The current month (1-12)
 * @param {number} year The current year.
 * @param {string} monthLabel An optional label for the month.
 * @param {string} yearLabel An optional label for the year.
 * @param {string} locale Used for month locale formatting
 *
 * Renders a date header for month and year and range slider. Moving the range
 * slider updates the year, and adjusting the year in the header also moves the
 * slider
 */
const DateSliderBox = ({
  onClickIncrementMonths,
  onClickDecrementMonths,
  onClickIncrementYears,
  onClickDecrementYears,
  children,
  month,
  year,
  monthLabel,
  yearLabel,
  locale,
  disabledIncrementMonth,
  disabledDecrementMonth,
  disabledDecrementYear,
  disabledIncrementYear,
}) => {
  return (
    <article className={style['date-slider-box']}>
      <article className={style[`date-slider-box__inner`]}>
        <DateBoxChunk
          label={monthLabel}
          onClickIncrement={onClickIncrementMonths}
          onClickDecrement={onClickDecrementMonths}
          dateNumber={month}
          monthNumberToString={monthNumberToString}
          disabledIncrement={disabledIncrementMonth}
          disabledDecrement={disabledDecrementMonth}
          locale={locale}
        />
        <DateBoxChunk
          label={yearLabel}
          onClickIncrement={onClickIncrementYears}
          onClickDecrement={onClickDecrementYears}
          dateNumber={year}
          disabledDecrement={disabledDecrementYear}
          disabledIncrement={disabledIncrementYear}
          locale={locale}
          renderRight
        />
      </article>
      {children}
    </article>
  )
}

DateSliderBox.propTypes = {
  onClickIncrementMonths: PropTypes.func,
  onClickDecrementMonths: PropTypes.func,
  onClickIncrementYears: PropTypes.func,
  onClickDecrementYears: PropTypes.func,
  children: PropTypes.node,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  monthLabel: PropTypes.string,
  yearLabel: PropTypes.string,
  locale: PropTypes.string,
  disabledIncrementMonth: PropTypes.bool,
  disabledDecrementMonth: PropTypes.bool,
  disabledDecrementYear: PropTypes.bool,
  disabledIncrementYear: PropTypes.bool,
}

export default DateSliderBox
