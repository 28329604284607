import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from '../media-and-icons/Icon'
import style from '../../scss/layout/MobileNav.module.scss'

type MobileBarItemProps = {
  to: string
  buttonLabel: string
  iconName: string
  notification?: boolean
  activeIcon?: string
  dataTestID?: string
  overrideActive?: boolean
}

/**
 * Mobile bar item with an icon and text, active item highlighting
 * done with react `<NavLink />` that comes from react router. For the active
 * icon only a `active` prefix is added, there is no need to pass in an
 * additional active icon.
 *
 * @note In order for the active icon item to work the following icon name
 * convention must be followed: `active-icon-name.svg` when naming icon files.
 */
const MobileBarItem = ({
  to,
  buttonLabel,
  iconName,
  notification,
  activeIcon,
  dataTestID,
  overrideActive,
}: MobileBarItemProps) => {
  /**
   * If the item is active, it will highlight the item with a
   * background change the inactive icon to an active one
   */
  const activeItem = ({ isActive }: { isActive: boolean }) => {
    // Used only for desktop to override the normal active style of the router
    // reason being that the index NAV and the ACCOUNT MENU ( sub nav ) HAVE TO
    // both be active during desktop navigation
    if (overrideActive) {
      isActive = overrideActive
    }

    const desktopIcons = () => (!isActive ? activeIcon : iconName)

    return (
      <div
        className={
          style[`mobileNav__text-wrapper${isActive ? '--active' : ''}`]
        }
      >
        {notification && <div className={style['mobileNav__notification']} />}
        <Icon
          fileName={desktopIcons() as string}
          className={style['mobileNav__icon']}
        />
        <span className={style[`mobileNav__text${isActive ? '--active' : ''}`]}>
          {buttonLabel}
        </span>
      </div>
    )
  }

  return (
    <NavLink to={to} data-testid={dataTestID}>
      {activeItem}
    </NavLink>
  )
}

MobileBarItem.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  notification: PropTypes.bool,
  activeIcon: PropTypes.string,
  dataTestID: PropTypes.string,
}

export default MobileBarItem
