import { environment } from 'MyTontineConfig'
import PropTypes from 'prop-types'
import { lazy, useState } from 'react'
import GraphSwitches from '../../components/buttons/GraphSwitches'
import IncomeStats from '../../components/data-display/IncomeStats'
import ErrorBoundaryAndSuspense from '../../components/error-handling/ErrorBoundaryAndSuspense'
import BannerMessage from '../../components/feedback/BannerMessage'
import TextError from '../../components/typography/TextError'
import { isLite } from '../../config/lite'
import { ANIMATION } from '../../constants/Animations'
import { CALCULATOR_FORMS, CONSTANTS } from '../../constants/ConstantValues'
import ForecastID from '../../dev/ForecastID'
import { useLocalization } from '../../hooks/useLocalization'
import { useRequestIncomeForecast } from '../../hooks/useRequestIncomeForecast'
import { useTranslate } from '../../hooks/useTranslate'
import style from '../../scss/pages/TontinatorDashboard.module.scss'
import { Styling } from '../../visualization/Styling'
import { useLineToggle } from '../../visualization/hooks/useLineToggle'
import { generateLegendItem } from '../../visualization/utils/D3DataUtils'
import { track } from '../../analytics/Analytics'
import { GraphEvent } from '../../analytics/EventData'
import { EVENT_DESC } from '../../analytics/EventDescription'
import { useBankingService } from '../../state-management/banking/useBankingService'
const TontinatorLineChart = lazy(
  () => import('../../visualization/TontinatorLineChart')
)

const MAIN_SVG_ID = 'tontinator-svg-container'
const X_AXIS_CSS_CLASS = 'x-axis-tontinator-age'
const Y_AXIS_CSS_CLASS = 'y-axis-tontinator-payouts'
const CONTAINER_CSS_CLASS = 'forecast-graph-container'

const LINE_KEYS = Object.freeze({
  tontineLineKey: `line-tontine`,
  fixedAnnuityLineKey: `fixed-annuity`,
  bankDepositKey: `bank-deposit`,
})

const percentDigitsFormatting = {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
}

const currencyDigitsFormatting = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  maximumSignificantDigits: 2,
  minimumSignificantDigits: 2,
}

/**
 * @param {boolean} renderUI
 *
 * Makes a request to the `payout_forecast` endpoint using the `getForecastHook`
 * and renders the results on the Tontinator chart
 */
const TontinatorDashboard = ({
  incomeForecastParams,
  isAuthenticated,
  renderUI,
  // If this component is placed side-by-side the ID needs to be unique per
  // instance
  svgID,
}) => {
  const t = useTranslate()
  const { formatAmount } = useLocalization()
  const { bankContext } = useBankingService()

  const { depositLine, setDepositLine, annuityLine, setAnnuityLine } =
    useLineToggle({
      //If all lines should be rendered initially
      renderTontineLine: true,
      renderAnnuityLine: true,
      renderDepositLine: true,
    })

  const {
    percentage,
    handlePercentage,
    breakeven,
    handleBreakeven,
    inflation,
    handleInflation,
    bannerMessageKey,
  } = useGraphToggles()

  //MIght have to add an additional check to NOT forecast if there are no params
  const { error, isLoading, forecastData } = useRequestIncomeForecast({
    //Tontinator only has one set of params, and this will not change any time
    //soon, so the params passed in are wrapped in an array
    incomeForecastParams: incomeForecastParams
      ? [
          {
            ...incomeForecastParams,
            writeDraftPlan: isAuthenticated,
            paramsId: 'tontinator',
          },
        ]
      : undefined,
  })

  return (
    <main>
      {environment !== 'production' && !isLite && (
        <ForecastID
          view_id={forecastData?.[0]?.view_id}
          isLoading={isLoading}
        />
      )}
      {error && (
        <TextError
          errorText={error}
          className={style['tontinatorDashboard__error-text']}
        />
      )}
      <section className={`${style['tontinatorDashboard__section']}`}>
        <TontinatorLineChart
          isLoading={isLoadingUI(renderUI, isLoading, isAuthenticated)}
          onHoverOrTapStart={() =>
            trackChartHover({ event: GraphEvent.hover_start })
          }
          onHoverOrTapLeave={() =>
            trackChartHover({ event: GraphEvent.hover_end })
          }
          formatter={(number) => {
            //Prevents from formatting `null` or `undefined` values
            if (number) {
              return formatAmount({
                amount: number,
                style: percentage ? 'percent' : 'currency',
                currency: forecastData?.[0]?.results?.currency,
                digits: percentage
                  ? percentDigitsFormatting
                  : currencyDigitsFormatting,
              }).formattedAmountWithSymbol
            }
          }}
          numOfTicksForX={8}
          numOfTicksForY={8}
          xAxisCssClass={`${X_AXIS_CSS_CLASS}${svgID ? svgID : ''}`}
          yAxisCssClass={`${Y_AXIS_CSS_CLASS}${svgID ? svgID : ''}`}
          mainSVGContainerID={svgID ?? MAIN_SVG_ID}
          forecastData={forecastData}
          containerCssClass={CONTAINER_CSS_CLASS}
          axisDistanceFromGraph={12}
          tontineLineKey={`${LINE_KEYS.tontineLineKey}${svgID ? svgID : ''}`}
          tontineLineStyle={{
            ...Styling.tontinatorLineChart.tontineLine,
            areaOpacity: inflation
              ? Styling.tontinatorLineChart.tontineLine.inflationAreaOpacity
              : Styling.tontinatorLineChart.tontineLine.areaOpacity,
          }}
          bankDepositKey={`${LINE_KEYS.bankDepositKey}${svgID ? svgID : ''}`}
          bankDepositsStyle={{
            ...Styling.tontinatorLineChart.bankDepositLine,
            areaOpacity:
              inflation && depositLine
                ? Styling.tontinatorLineChart.bankDepositLine
                    .inflationAreaOpacity
                : Styling.tontinatorLineChart.bankDepositLine.areaOpacity,
            color: depositLine
              ? Styling.tontinatorLineChart.bankDepositLine.color
              : Styling.hidden,
          }}
          fixedAnnuityLineKey={`${LINE_KEYS.fixedAnnuityLineKey}${svgID ? svgID : ''}`}
          fixedAnnuityStyle={{
            ...Styling.tontinatorLineChart.annuityLine,
            color: annuityLine
              ? isJapan(incomeForecastParams)
                ? Styling.hidden
                : Styling.tontinatorLineChart.annuityLine.color
              : Styling.hidden,

            areaOpacity:
              inflation && annuityLine
                ? Styling.tontinatorLineChart.annuityLine.inflationAreaOpacity
                : Styling.tontinatorLineChart.annuityLine.areaOpacity,
          }}
          toggles={{
            inflation,
            breakeven,
            percent: percentage,
            depositLine,
            annuityLine,
          }}
          showVerticalHoveringLine
          drawingAnimation={ANIMATION.jarWithCoins}
          showFocusCircleOnPath
          showHoveringMouseAnnotation
          legendData={[
            generateLegendItem({
              itemColor: Styling.tontinatorLineChart.tontineLine.color,
              text: t('DASHBOARD.CHART_SUBTITLE_MYTONTINE', {
                return: bankContext?.returns?.rate?.toFixed(1) ?? '-',
              }),
              renderLine: true,
              id: LINE_KEYS.tontineLineKey,
            }),
            generateLegendItem({
              itemColor: Styling.tontinatorLineChart.bankDepositLine.color,
              text: t('GRAPH_LEGEND.DEPOSIT_ACCOUNT_BAR', {
                return: bankContext?.returns?.rate?.toFixed(1) ?? '-',
              }),
              onClick: () =>
                setDepositLine({
                  value: depositLine,
                  label: t('GRAPH_LEGEND.DEPOSIT_ACCOUNT_BAR'),
                }),
              renderLine: depositLine,
              id: LINE_KEYS.bankDepositKey,
            }),
            generateLegendItem({
              itemColor: isJapan(incomeForecastParams)
                ? Styling.hidden
                : Styling.tontinatorLineChart.annuityLine.color,
              text: t('GRAPH_LEGEND.ANNUITIES_BAR'),
              onClick: () =>
                setAnnuityLine({
                  value: annuityLine,
                  label: t('GRAPH_LEGEND.ANNUITIES_BAR'),
                }),
              renderLine: annuityLine,
              id: LINE_KEYS.fixedAnnuityLineKey,
            }),
          ]}
        />

        <GraphSwitches
          breakevenLabel={t(
            breakeven ? 'AGE.TO.BREAKEVEN.HIDE' : 'AGE.TO.BREAKEVEN.SHOW'
          )}
          inflationLabel={t(
            inflation
              ? 'FORECAST_PAGE.GRAPH_SWITCH_INFLATION_LABEL_HIDE'
              : 'FORECAST_PAGE.GRAPH_SWITCH_INFLATION_LABEL',
            {
              inflationPercent: isLoading
                ? // Prevents the user seeing NaN for percent value while it is
                  // being fetched from the backend
                  '-'
                : forecastData?.[0]?.results?.annual_inflation_rate * 100,
            }
          )}
          pde
          percentage={percentage}
          handlePercentage={handlePercentage}
          breakeven={breakeven}
          handleBreakeven={handleBreakeven}
          inflation={inflation}
          handleInflation={handleInflation}
          togglesVariant="button"
        />

        {bannerMessageKey && (
          <div className={style['tontinatorDashboard__banner-container']}>
            <BannerMessage
              hideIcon
              variant="info"
              message={t(bannerMessageKey)}
            />
          </div>
        )}
      </section>

      <div className={style[`tontinatorDashboard__payout-container`]}>
        <div className={style[`tontinatorDashboard__payout-center`]}>
          <ErrorBoundaryAndSuspense hideNavButton>
            <IncomeStats
              isLoading={isLoading}
              variant={'blue-faint'}
              contributionAmount={
                forecastData?.[0]?.stats?.total_contributions ?? 0
              }
              contributionLabel={t('FORECAST_PAGE.TOTAL_CONTRIBUTION_LABEL')}
              incomeAmount={forecastData?.[0]?.stats?.total_payouts ?? 0}
              incomeLabel={t('FORECAST_PAGE.PAYOUTS_BY_100_LABEL')}
              incomePercentage={
                forecastData?.[0]?.stats?.payout_percentage ?? 0
              }
              currency={forecastData?.[0]?.results?.currency ?? 'USD'}
            />
          </ErrorBoundaryAndSuspense>
        </div>
      </div>
    </main>
  )
}

TontinatorDashboard.propTypes = {
  incomeForecastParams: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  renderUI: PropTypes.bool,
  svgID: PropTypes.string,
  hideDivider: PropTypes.bool,
}

/**
 * @param {boolean} renderUI
 * @param {boolean} isLoading
 * @param {boolean} isAuthenticated
 *
 * Puts the Tontinator in a loading state if the UI is initializing or if a
 * forecast is being performed. If user isAuthenticated then  we need to wait
 * for the UI to initialize with age thresholds, if not then we just need to
 * wait for the forecast to complete
 *
 */
const isLoadingUI = (renderUI, isLoading, isAuthenticated) => {
  if (isAuthenticated) {
    return !renderUI || isLoading
  }

  return isLoading
}

const isJapan = (incomeForecastParams) =>
  incomeForecastParams?.countryOfResidence ===
  CONSTANTS.SUPPORTED_RESIDENCIES.JPN

/**
 * @typedef {{ percentage: boolean, breakeven: boolean, inflation: boolean }}
 *   Toggles
 *
 * @param {Toggles} toggles
 */
const messageFromToggle = (toggles) => {
  if (toggles?.percentage) {
    return 'BANNER_INFO.PERCENT'
  }
  if (toggles?.breakeven) {
    return 'BANNER_INFO.BREAKEVEN'
  }
  if (toggles?.inflation) {
    return 'BANNER_INFO.INFLATION'
  }

  return ''
}

/**
 * Controls the graph switches, whether to hide or show certain graph elements
 *
 * - **Breakeven** and **percentage** can be `true` at the same time
 * - **Breakeven** or **percentage** set **inflation** to `false`
 * - **Inflation** sets **breakeven** and **percentage** to `false`
 */
const useGraphToggles = () => {
  //TODO: useReducer is the way to go here, if a state depends on another state
  const [percentage, setPercentage] = useState(
    CALCULATOR_FORMS.GRAPH_TOGGLES_DEFAULT_VALUES.percentage
  )
  const [breakeven, setBreakeven] = useState(
    CALCULATOR_FORMS.GRAPH_TOGGLES_DEFAULT_VALUES.breakeven
  )
  const [inflation, setInflation] = useState(
    CALCULATOR_FORMS.GRAPH_TOGGLES_DEFAULT_VALUES.inflation
  )
  const [bannerMessageKey, setBannerMessageKey] = useState(
    messageFromToggle({
      breakeven,
      inflation,
      percentage,
    })
  )

  /**
   * Enables percentage view of the chart and renders percentage banner also
   * disables inflation toggle and inflation banner.
   */
  const handlePercentage = () => {
    setPercentage((prevState) => {
      setInflation(false)

      setBannerMessageKey(
        messageFromToggle({
          percentage: !prevState,
          // Makes sure if both breakeven and percentage are toggled
          // to only display the most recently clicked toggle banner
          breakeven: breakeven && prevState,
          inflation: false,
        })
      )

      return !prevState
    })
  }

  /**
   * Enables breakeven circles on the chart and enables breakeven banner, also
   * disables inflation and inflation banner
   */
  const handleBreakeven = () =>
    setBreakeven((prevState) => {
      setInflation(false)

      setBannerMessageKey(
        messageFromToggle({
          // Makes sure if both breakeven and percentage are toggled
          // to only display the most recently clicked toggle banner
          percentage: percentage && prevState,
          breakeven: !prevState,
          inflation: false,
        })
      )

      return !prevState
    })

  /**
   * Enables inflation view of the chart and inflation banner. Disables
   * percentage and breakeven
   */
  const handleInflation = () =>
    setInflation((prevState) => {
      setBreakeven(false)
      setPercentage(false)

      setBannerMessageKey(
        messageFromToggle({
          percentage: false,
          breakeven: false,
          inflation: !prevState,
        })
      )

      return !prevState
    })

  return {
    bannerMessageKey,
    percentage,
    handlePercentage,
    breakeven,
    handleBreakeven,
    inflation,
    handleInflation,
  }
}

const trackChartHover = ({ event }) => {
  void track({
    event,
    properties: {
      object_id: 'tontinator',
      description: EVENT_DESC.chartHoverOrTap,
    },
  })
}

export default TontinatorDashboard
