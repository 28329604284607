import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  DisabledDayParams,
  DisabledMonthParams,
} from '../types/CommonInterfaces.interfaces'
import {
  AgeMonthNumber,
  AgeMonthString,
  DemographicData,
  DisablePickerValues,
  ForecastAges,
  IncomeForecastRequestBody,
  UnparsedForecastParams,
  InitialForecastParams,
  SupportedResidencies,
  ValidationData,
  StateCodesAlpha2,
  IncomeForecastParams,
} from '../types/CommonTypes.types'
import { CONSTANTS } from '../constants/ConstantValues'
import i18n from '../config/i18n'
import {
  getCountryInformation,
  hasCompletedL1KYC,
  i18Translation,
} from './UtilFunctions'
import stateData from '../constants/usa-states.json'
import { captureException } from '@sentry/browser'
import { regex } from '../constants/Regex'
import {
  LitePensionPlan,
  UserDetails,
} from '../state-management/authentication/AuthMachineTypes.type'
import {
  CountryCode,
  getExampleNumber,
  parsePhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'
import { BankMachineContext } from '../state-management/banking/BankMachineTypes.type'
import axios from 'axios'
import { API } from '../api/API'
import { CardVariantType } from '../types/Card.type'
import {
  UserReferralStats,
  ReferralDetails,
} from '../pages/referral/ReferralTypes.type'

const { SUPPORTED_RESIDENCIES } = CONSTANTS as {
  SUPPORTED_RESIDENCIES: SupportedResidencies
}

/**
 * Extracts the year and a month from `AgeMonthString` type format. For example
 * `65-2` input returns the object of type `AgeMonthNumber`
 * ```typescript
 * {
 *   age: 65,
 *   month: 2,
 * }
 * ```
 */
const extractYearFromAgeMonthString = (
  ageMonthString: AgeMonthString
): AgeMonthNumber => {
  // Split the ageMonthString string into two parts using the hyphen as the
  // delimiter
  const parts = ageMonthString ? ageMonthString?.split('-') : ['']

  if (parts?.length == 2) {
    // Return the age and month in number format
    return {
      age: parseInt(parts[0]),
      month: parseInt(parts[1]),
    }
  }

  return {
    age: 0,
    month: 0,
  }
}

/**
 * Parses income params to tontinator request body.
 */
const parseIncomeForecastParams = ({
  contributionAge,
  monthlyContribution,
  oneTimeContribution,
  payoutAge,
  countryOfResidence,
  sex,
  isAuthenticated,
  pastContributions,
  writeDraftPlan,
  planID,
}: UnparsedForecastParams): IncomeForecastRequestBody => {
  /**
   * Check if the passed in residency is supported if it is, sends it with the
   * request body if not, defaults to USA residency
   */
  const getSupportedResidency = (countryOfResidence: string) => {
    if (SUPPORTED_RESIDENCIES[countryOfResidence]) {
      return countryOfResidence
    }
    return SUPPORTED_RESIDENCIES.USA
  }

  if (
    planID ||
    ((monthlyContribution > 0 || oneTimeContribution > 0) && payoutAge)
  ) {
    const demographic_data: DemographicData = {}

    if (isAuthenticated) {
      demographic_data.override_residency =
        getSupportedResidency(countryOfResidence)
    } else {
      demographic_data.full = {
        country_of_residence: getSupportedResidency(countryOfResidence),
        sex,
        current_age: contributionAge,
      }
    }

    //If all params are in place init an contribution params object
    const incomeForecastRequestBody = {
      future_contributions: {
        contribution_params: {
          monthly_amount: monthlyContribution,
          onetime_amount: oneTimeContribution,
          payout_age: payoutAge,
        },
      },
      past_contributions: pastContributions ?? false,
      write_draft_plan: writeDraftPlan ?? false,
    }

    //Plan forecast minimal required params from the API
    if (planID) {
      return {
        future_contributions: {
          plan_id: planID,
        },
        past_contributions: pastContributions,
        write_draft_plan: writeDraftPlan,
        demographic_data,
      }
    }

    return {
      ...incomeForecastRequestBody,
      demographic_data,
    }
  }

  throw new Error(
    `Failed to parse income params, missing params or plan ID plan_id:${planID}, got:${JSON.stringify(
      {
        contributionAge,
        monthlyContribution,
        oneTimeContribution,
        payoutAge,
        countryOfResidence,
        sex,
      }
    )}`
  )
}

/**
 * Converts age number and month number to `AgeMonthString` format. If no month
 * is passed in it defaults to `0`
 */
const numberToAgeMonthString = (age: number, month = 0): AgeMonthString => {
  if (age > 0 && month >= 0) {
    return `${age}-${month}`
  }
  return `${age}-${month}`
}

/**
 * Modifies `retirementAge` and `contributionAge` if they are equal, by adding a
 *  month to the payout age when converting to `AgeMonthString type. If
 *  `AgeMonthString` type is passed in then no modification is performed, the
 *  same values are returned
 */
const adjustAndConvertToAgeMonthString = (
  retirementAge: number | AgeMonthString,
  contributionAge: number | AgeMonthString
): ForecastAges => {
  //For "HOW IT WORKS" flow, in order to support age sliders
  if (
    typeof contributionAge === 'number' &&
    typeof retirementAge === 'number'
  ) {
    //Bit of circle in terms of getting the current age, since we already have
    //the current age
    const { yearsOld: contributionYearsOld, monthsOld: contributionMonthsOld } =
      dobToYearsAndMonthsOld(
        //We assume user is born on 1st of January
        `${CONSTANTS.CURRENT_YEAR - contributionAge}-01-01`
      )

    const retirementYearsOld = retirementAge
    //retirementAge and contributionAge are equal send the contribution months
    //old, the `modifyContributionAgeAndRetirementAge` will apply the necessary
    //corrections, otherwise send 0 retirement months old
    const retirementMonthsOld =
      contributionYearsOld === retirementAge ? contributionMonthsOld : 0

    contributionAge = numberToAgeMonthString(
      contributionYearsOld,
      contributionMonthsOld
    )

    retirementAge = modifyContributionAgeAndRetirementAge(
      contributionYearsOld,
      contributionMonthsOld,
      retirementYearsOld,
      retirementMonthsOld
    )
  } //Onboarding ANON params (UI is only in age precision) handling ends here

  //Handles AUTH params where UI is in AGE-MONTH old precision
  if (retirementAge === contributionAge) {
    const { age: retirementYearsOld, month: retirementMonthsOld } =
      extractYearFromAgeMonthString(retirementAge as AgeMonthString)

    const { age: contributionYearsOld, month: contributionMonthsOld } =
      extractYearFromAgeMonthString(contributionAge as AgeMonthString)

    retirementAge = modifyContributionAgeAndRetirementAge(
      contributionYearsOld,
      contributionMonthsOld,
      retirementYearsOld,
      retirementMonthsOld
    )
  }

  return {
    contributionAge: contributionAge as AgeMonthString,
    retirementAge: retirementAge as AgeMonthString,
  }
}

/**
 * Modifies the `retirementAge` if it is equal to the `contributionAge`, by
 * incrementing the retirement month by 1. Month overflow is also handled in
 * case the `retirementMonthsOld` is above 11, valid ranges are [0,11].
 *
 * The returned value is in `AgeMonthString` format
 */
const modifyContributionAgeAndRetirementAge = (
  contributionYearsOld: number,
  contributionMonthsOld: number,
  retirementYearsOld: number,
  retirementMonthsOld: number
): AgeMonthString => {
  let modifiedRetirementMonth = retirementMonthsOld
  let modifiedRetirementAge = retirementYearsOld

  //Contribution age and retirement age are equal increment month by 1, so if we
  //have a scenario 65-7 65-7 we send to the tontinator 65-7 65-8
  if (
    contributionYearsOld === retirementYearsOld &&
    contributionMonthsOld === retirementMonthsOld
  ) {
    modifiedRetirementMonth = contributionMonthsOld + 1
  }

  //Month overflow check, if overflow, set month to 0 and increase retirement
  //years old by 1
  if (
    contributionYearsOld === retirementYearsOld &&
    modifiedRetirementMonth > CONSTANTS.MONTHS_OLD_MAX
  ) {
    modifiedRetirementMonth = 0
    modifiedRetirementAge = modifiedRetirementAge + 1
  }

  return numberToAgeMonthString(modifiedRetirementAge, modifiedRetirementMonth)
}

/**
 * For passed in string DoB `YYYY-MM-DD` format returns an object with
 * `yearsOld`, `monthBornOn` and `monthsOld` properties
 */
const dobToYearsAndMonthsOld = (
  dateOfBirth: string
): {
  yearsOld: number
  monthBornOn: number
  monthsOld: number
  birthYear: number
} => {
  const today = new Date()
  const birthDate = new Date(dateOfBirth)

  let yearsOld = today.getFullYear() - birthDate.getFullYear()
  const monthBornOn = birthDate.getMonth() + 1

  // Check if the person's birthday has already occurred this year
  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate())
  ) {
    yearsOld--
  }

  // Calculate the total number of months between the birth date and current
  // date
  const totalMonthsOld =
    (today.getFullYear() - birthDate.getFullYear()) * 12 +
    (today.getMonth() - birthDate.getMonth()) -
    (today.getDate() < birthDate.getDate() ? 1 : 0)

  // Convert the person's age in years to months
  const yearsInMonths = yearsOld * 12

  // Calculate the person's age in months by subtracting the complete years in
  // months from the total months
  const monthsOld = totalMonthsOld - yearsInMonths

  return {
    yearsOld,
    monthBornOn,
    monthsOld,
    birthYear: birthDate.getFullYear(),
  }
}

/**
 * Formats a number based on passed in options and returns a string
 */
const numberFormatter = (
  number: number | bigint,
  locale = 'en-US',
  options?: Intl.NumberFormatOptions
): string => new Intl.NumberFormat(locale, options).format(number)

/**
 * For passed in data returns a forecast params shape that is used in the
 * `<SliderPage />` and `<TontinatorDashboard />` components
 *
 * @note This shape might change in the near future, but for now it seems to fit
 * all requirements on the `<TontinatorPage />`
 */
const initialForecastParams = ({
  retirementAge,
  yearsOld,
  monthsOld,
  year,
  month,
  monthlyContribution,
  oneTimeContribution,
  countryOfResidence,
}: InitialForecastParams): InitialForecastParams => {
  return {
    retirementAge,
    yearsOld,
    monthsOld,
    year,
    month,
    monthlyContribution,
    oneTimeContribution,
    countryOfResidence,
  }
}

/**
 * Disables values from and to a certain condition or a range by returning the
 * disabled class
 */
const disabledPickerValues = ({
  value,
  disabledFrom,
  disabledTo,
}: DisablePickerValues): string => {
  const bothParamsPresent = Boolean(disabledFrom) && Boolean(disabledTo)
  const isFullRange =
    bothParamsPresent && (value >= disabledFrom || value <= disabledTo)

  const isFromOnly = Boolean(
    disabledFrom && !disabledTo && value >= disabledFrom
  )
  const isToOnly = Boolean(disabledTo && !disabledFrom && value <= disabledTo)

  // Disables all values outside the range
  if (isFullRange) {
    return 'date-picker--disabled'
  } else if (isFromOnly) {
    return 'date-picker--disabled'
  } else if (isToOnly) {
    return 'date-picker--disabled'
  }

  //Empty string not to spam the dom tree
  return ''
}

/**
 * Decides if the DoB modal should be rendered or not
 */
const showDoBModal = ({
  userClosedModalOnce,
  hasUnverifiedOrVerifiedDoB,
  verifiedDoB,
}: {
  userClosedModalOnce: boolean
  hasUnverifiedOrVerifiedDoB: boolean
  verifiedDoB: boolean
}): boolean | undefined => {
  if (verifiedDoB) {
    return false
  }

  if (!userClosedModalOnce && !hasUnverifiedOrVerifiedDoB) {
    return true
  }

  return undefined
}

/**
 * Does not allow the user to select a month where they are above the max and
 * min age
 */
const disableNextMonth = ({
  userDoB,
  currentYear,
  currentMonth,
  currentAgeMin,
  currentAgeMax,
}: DisabledMonthParams): number | undefined => {
  const dobYear = new Date(userDoB).getFullYear()
  const maxYearForCalendar = currentYear - (currentAgeMax ?? 0)
  const minYearForCalendar = currentYear - (currentAgeMin ?? 0)

  if (dobYear === minYearForCalendar) {
    return currentMonth + 1
  }

  if (dobYear === maxYearForCalendar) {
    return currentMonth
  }

  return undefined
}

/**
 * Disables the currentDay + 1, so it does not allow the user to select a day
 * where they are above max and below the min age
 */
const disableDay = ({
  userDoB,
  currentYear,
  currentMonth,
  currentDay,
  currentAgeMin,
  currentAgeMax,
}: DisabledDayParams): number | undefined => {
  const editedDate = new Date(userDoB)
  const editedYear = editedDate.getFullYear()
  const editedMonth = editedDate.getMonth()
  const currentEditedMonth = editedMonth + 1 === currentMonth

  const maxDob = currentYear - (currentAgeMax ?? 0)
  const minDob = currentYear - (currentAgeMin ?? 0)

  if (editedYear === minDob && currentEditedMonth) {
    return currentDay
  } else if (editedYear === maxDob && currentEditedMonth) {
    return currentDay
  }

  return undefined
}

type DateDifferenceValues = {
  years: number
  months: number
  days: number
  targetDateParsed: dayjs.Dayjs
  startDateParsed: dayjs.Dayjs
}

/**
 * Calculates the difference in years,months and days between two dates using
 * Dayjs library also returns the date objects parsed with dayjs
 */
const dateDifference = (
  startDateISO: string,
  targetDateISO: string
): string | DateDifferenceValues => {
  //Necessary to have strict parsing In order to avoid parsing 2024-00-37 to
  //Tue, 04 Jan 2022
  dayjs.extend(customParseFormat)

  const startDateParsed = dayjs(startDateISO, 'YYYY-MM-DD', true)
  const targetDateParsed = dayjs(targetDateISO, 'YYYY-MM-DD', true)

  const yearsDiff = targetDateParsed.diff(startDateParsed, 'years')
  const monthsDiff = targetDateParsed.diff(startDateParsed, 'months')
  const daysDiff = targetDateParsed.diff(startDateParsed, 'day')

  //Shallow valid because, only checks format and not if the date is actually
  //The library does not really have a good definition what is valid or
  //invalid... in the docs https://day.js.org/docs/en/parse/string-format
  const shallowValid = startDateParsed.isValid() && targetDateParsed.isValid()

  //If the parsed date is invalid the difference will be a NaN
  const validDifference =
    !isNaN(yearsDiff) && !isNaN(monthsDiff) && !isNaN(daysDiff)

  if (shallowValid && validDifference) {
    return {
      years: yearsDiff,
      months: monthsDiff,
      days: daysDiff,
      targetDateParsed,
      startDateParsed,
    }
  }

  //JS natively does this, I am not sure if this is okay or not
  return CONSTANTS.INVALID_DATE_ERROR_MSG
}

/**
 * Formats date using `datjs` library
 */
const formatDate = (
  date: string | number | dayjs.Dayjs | Date | null | undefined,
  format?: string
): string => {
  return dayjs(date).format(format)
}

/**
 * Returns the date in YYYY-MM-DD format for passed in `year`,`month` and `day`
 */
const formatDateToDateString = ({
  year,
  month,
  day,
  format = 'YYYY-MM-DD',
}: {
  year: number
  month: number
  day: number
  format?: string
}): string => {
  //Must be month - 1 because the date object month operates from 0 to 11
  const dateObject = new Date(year, month - 1, day)

  return formatDate(dateObject, format)
}

/**
 * Parses a date string or a date object to `{year, month, day}` as numbers
 * `day` - day of the month
 */
const parseDateToNumbers = (
  date: string | number | dayjs.Dayjs | Date | null | undefined
) => {
  return {
    year: dayjs(date).year(),
    month: dayjs(date).month() + 1,
    day: dayjs(date).date(),
  }
}

/**
 * Checks if the passed in year is a leap year
 */
const isLeapYear = (year: number): boolean =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

/**
 * Returns a `--disabled` modifier
 */
const isDisabled = (disabled: boolean) => (disabled ? '--disabled' : '')

/**
 * Check if the user has verified their identity and returns the verified user
 * information, otherwise returns the unverified user information.
 */
const verifiedPersonalDetails = (user_details: UserDetails) => {
  //Unverified user information
  const {
    unverified_first_name,
    unverified_last_name,
    unverified_date_of_birth,
    unverified_residency,
    unverified_sex,
    unverified_ssn,
    unverified_age,
    unverified_phone_number,
  } = user_details

  //Verified user information from ID Verification
  const {
    verified_first_name,
    verified_last_name,
    verified_date_of_birth,
    verified_sex,
    verified_ssn,
    verified_age,
    verified_phone_number,
  } = user_details

  /**
   * Temporary fix for residency where local can be `jpn` but residency MKD for
   * example.
   */
  const allowedResidenciesForNow: { [key: string]: string } = {
    USA: 'USA',
    JPN: 'JPN',
  }

  return {
    ...user_details,
    first_name: verified_first_name ?? unverified_first_name,
    last_name: verified_last_name ?? unverified_last_name,
    date_of_birth: verified_date_of_birth ?? unverified_date_of_birth,
    residency:
      // Only to render the frontend to make sense instead of ".илјд US$"
      allowedResidenciesForNow?.[unverified_residency] ??
      CONSTANTS.FALLBACK_COUNTRY_CODE,
    sex: verified_sex ?? unverified_sex,
    ssn: verified_ssn ?? unverified_ssn,
    age: verified_age ?? unverified_age,
    phone_number: verified_phone_number ?? unverified_phone_number,
  }
}

/**
 * Helper function to add a hyphen at the specified index in a string.
 */
const addHyphenAt = (str: string, index: number): string =>
  `${str.slice(0, index)}-${str.slice(index)}`

/**
 * Formats a string into SSN format (XXX-XX-XXXX)
 */
const formatToSSN = (str: string): string => {
  if (str.length > 5) {
    return addHyphenAt(addHyphenAt(str, 3), 6)
  }

  if (str.length > 3) {
    return addHyphenAt(str, 3)
  }

  return str
}

/**
 * Limits the length of a string to the specified length.
 */
const limitLength = (str: string, maxLength: number): string => {
  return str.length > maxLength ? str.slice(0, maxLength) : str
}

/**
 * Formats a string ssn to a XXX-XX-XXXX format.
 */
const formatSSN = (ssn: string): string => {
  if (!ssn) {
    return ''
  }

  // Remove any non-digit characters from the input.
  let cleanedSSN = ssn.replace(/\D/g, '')

  // Limit the length of the SSN to 9 digits.
  cleanedSSN = limitLength(cleanedSSN, 9)

  // Format the SSN.
  cleanedSSN = formatToSSN(cleanedSSN)

  return cleanedSSN
}

/**
 * Validates if retirement age in `AgeMonth` format is valid
 */
const validateRetirementAge = (
  retirementAge: AgeMonthString | undefined | number
): boolean => {
  if (retirementAge) {
    const { age, month } = extractYearFromAgeMonthString(
      retirementAge as AgeMonthString
    )

    return age > 0 && month >= 0
  }
  return false
}

/**
 * Displays a greeting to the user depending on what period of the day it is.
 * Example: Good Morning!, Good Evening
 */
const getGreeting = (): string => {
  const currentHour = dayjs().hour()

  if (currentHour >= 5 && currentHour < 12) {
    return i18n.t('MYTT_MORNING_GREETING')
  } else if (currentHour >= 12 && currentHour < 18) {
    return i18n.t('MYTT_AFTERNOON_GREETING')
  }
  return i18n.t('MYTT_EVENING_GREETING')
}

/**
 * Returns supported countries info
 */
const getSupportedTontinatorParams = (alpha3CountryCode: string) => {
  const passedParams = getCountryInformation('alpha3', alpha3CountryCode, true)

  const fallbackParams = getCountryInformation(
    'alpha3',
    //USA for now
    CONSTANTS.FALLBACK_COUNTRY_CODE,
    true
  )

  return passedParams || fallbackParams
}

/**
 * Returns the difference between two numbers in percentage
 */
const differenceInPercentage = (num1: number, num2: number): number =>
  ((num1 - num2) / num1) * 100

/**
 * Checks if user has completed KYC L2 requirements and KYC L1 requirements
 */
const hasCompletedL2KYC = (user_details: UserDetails): boolean =>
  (hasCompletedL1KYC(user_details) as boolean) &&
  user_details?.id_review_status === CONSTANTS.APPROVED

/**
 * Returns a object containing `valid` property and `message` property
 */
const generateValidationData = (
  valid: boolean,
  i18nKey?: string,
  values?: object
): ValidationData => ({
  valid,
  message: i18Translation(i18nKey) as string,
  i18nKey,
  values,
})

/**
 * Generates a validation object that contains `valid` property that signals if
 * the input is valid and `message` property which is used to render an error
 * message to the user by using i18n translation
 */
const issueValidationData = ({
  valid,
  i18nKey,
  setStateAction,
  values,
}: {
  valid: boolean
  setStateAction: (state: ValidationData | undefined) => void
  i18nKey?: string
  values?: object
}) => {
  try {
    setStateAction(generateValidationData(valid, i18nKey, values))
  } catch (err) {
    console.error(err)
  }
}

/**
 * Takes in validator functions and setState function to set the validation
 * data. Validator functions only set the error state if the given input, does
 * not pass validation
 * - Validation to check if input is empty is done by default
 */
const validateInputWithError = ({
  input,
  validateFormat,
  emptyInputErrorI18nKey,
  invalidInputErrorI18nKey,
  valuesForInvalidInput,
  extendedValidationErrorI18nKey,
  valuesForExtendedValidation,
  setStateAction,
  extendedValidator,
  optionalField = false,
}: {
  input?: string
  validateFormat?: (input: string) => boolean
  extendedValidator?: (input: string) => boolean
  emptyInputErrorI18nKey: string
  invalidInputErrorI18nKey?: string
  valuesForInvalidInput?: object
  extendedValidationErrorI18nKey?: string
  valuesForExtendedValidation?: object
  setStateAction: (data: ValidationData | undefined) => void
  optionalField?: boolean
}) => {
  //Input by default is invalid, but no error message in order for a submit
  //button to be disabled
  if (input === undefined) {
    issueValidationData({
      valid: false,
      setStateAction,
    })
    //Input invalid if only white space is entered or user wipes the data in the
    //input. Common case if input should not be empty
  } else if (input === null || input.length === 0 || !input.trim().length) {
    issueValidationData({
      //If optional field is true, this validation check is valid by default
      valid: false || optionalField,
      i18nKey: optionalField ? undefined : emptyInputErrorI18nKey,
      setStateAction,
    })
    //Validates input format depending what is passed in, common case
  } else if (validateFormat && validateFormat(input)) {
    issueValidationData({
      valid: false,
      i18nKey: invalidInputErrorI18nKey,
      values: valuesForInvalidInput,
      setStateAction,
    })
    //Extended validation if needed
  } else if (extendedValidator && extendedValidator(input)) {
    issueValidationData({
      valid: false,
      i18nKey: extendedValidationErrorI18nKey,
      values: valuesForExtendedValidation,
      setStateAction,
    })
  } else {
    issueValidationData({
      valid: true,
      setStateAction,
    })
  }
}

/**
 * Returns state information sourced from `usa-states.json`
 */
const getStateInformation = (stateAlpha2ISOCode: StateCodesAlpha2) => {
  if (!stateAlpha2ISOCode) {
    throw new TypeError(
      `Expected stateAlpha2ISOCode got >>${stateAlpha2ISOCode as string}<<`
    )
  }
  try {
    return stateData.find((state) => state['iso_code'] === stateAlpha2ISOCode)
  } catch (error) {
    console.error(error)
  }
  return undefined
}

/**
 * Captures an exception and sends an alert to sentry
 */
const captureExceptionWithSentry = (error: unknown, options?: object) =>
  captureException(error, options)

/**
 * Calculates retirement data based on user's `contributionAge` and
 * `retirementAge`
 */
const calculateYearForRetirementAge = (
  contributionAge: AgeMonthString,
  retirementAge: AgeMonthString
) => {
  const { age: yearsOldOnRetirement, month: monthsOldOnRetirement } =
    extractYearFromAgeMonthString(retirementAge)

  const { age: yearsOldNow, month: monthsOldNow } =
    extractYearFromAgeMonthString(contributionAge)

  // Check if the contribution age is larger than the retirement age
  if (
    yearsOldOnRetirement < yearsOldNow ||
    (yearsOldOnRetirement === yearsOldNow &&
      monthsOldOnRetirement < monthsOldNow)
  ) {
    throw new Error('Contribution age cannot be larger than retirement age.')
  }

  try {
    const currentYear = dayjs().year()
    const currentMonth = dayjs().month() + 1

    let retirementYear = yearsOldOnRetirement - yearsOldNow + currentYear
    let retirementMonth = monthsOldOnRetirement - monthsOldNow + currentMonth

    // if the retirement month is negative, add 12 and subtract 1 from the
    // retirement year
    if (retirementMonth < 0) {
      retirementMonth += CONSTANTS.DECEMBER
      retirementYear -= 1
    }

    // if the retirement month is zero, set it to 12 and subtract 1 from the
    // retirement year
    if (retirementMonth === 0) {
      retirementMonth = CONSTANTS.DECEMBER
      retirementYear -= 1
    }

    //Handles month overflow
    if (retirementMonth > 12) {
      retirementMonth -= 12
      retirementYear += 1
    }

    return {
      yearsOldOnRetirement,
      monthsOldOnRetirement,
      yearsOldNow,
      monthsOldNow,
      retirementYear,
      retirementMonth,
    }
  } catch (error) {
    captureExceptionWithSentry(error)
  }

  return undefined
}

/**
 * Returns how many years and months will the user have from the provided
 * FutureDate
 */
const birthAgeAndMonthsFromFutureDate = (
  birthDate: string,
  futureDate: string
): { age: number; months: number } | undefined => {
  try {
    if (!birthDate || !futureDate) {
      throw new TypeError(
        `Invalid date or could not parse arg1: >>${birthDate}<<  arg2:>>${futureDate}<<`
      )
    }

    const dateDifferencesValues: string | DateDifferenceValues = dateDifference(
      birthDate,
      futureDate
    )

    if (typeof dateDifferencesValues === 'string') {
      throw new TypeError(`Invalid date, from dateDifference`)
    }

    //Convert the total months difference into remainder months
    const remainderMonths: number = Math.round(
      dateDifferencesValues?.months % 12
    )

    return {
      age: dateDifferencesValues?.years,
      months: remainderMonths,
    }
  } catch (error) {
    console.error(error)
  }

  return undefined
}

/**
 * Calculates how old the user will be on their retirement date in months and
 * years
 */
const calculateRetirementValues = (
  user_details: Partial<UserDetails>,
  retirementData: {
    year: number
    month: number
    day?: number
  }
) => {
  try {
    const { date_of_birth } = user_details

    const dateToConstruct = `${retirementData?.year}-${retirementData?.month}-${
      //Uses the current DAY of birth to accurately calculate the retirement
      //date
      retirementData?.day ?? dayjs(date_of_birth).get('D')
    }`

    //Constructs a retirement date from passed in values in `YYYY-MM-DD` format
    //Retirement data contains day and year values as numbers
    const retirementDateISO = dayjs(dateToConstruct).format('YYYY-MM-DD')

    const calculatedValues = birthAgeAndMonthsFromFutureDate(
      date_of_birth ?? '',
      retirementDateISO
    )

    if (!calculatedValues) {
      throw new TypeError(
        `Could not calculate retirement values got >>${JSON.stringify(
          calculatedValues
        )}<<`
      )
    }

    return calculatedValues
  } catch (error) {
    console.error(error)
  }

  return undefined
}

/**
 * - Removes trailing and leading whitespace with `.trim()`
 * - Replaces duplicate whitespace with one whitespace
 *
 * If nothing is passed in an empty string is returned
 */
const sanitizeInputValue = ({
  inputValue,
  onlySpaces,
}: {
  inputValue: string
  onlySpaces?: boolean
}): string => {
  if (inputValue) {
    inputValue = inputValue.replace(regex.duplicateSpaces, ' ')
    if (onlySpaces) {
      return inputValue.trimStart()
    }
    return inputValue.trim()
  }

  return ''
}

/**
 * Returns translated string from i18n. Is not content is found for the passed
 * in key, simply the key itself is returned.
 */
const i18nTrans = (key: string, options?: string) => {
  if (options) {
    return i18n.t(key, options)
  }
  return i18n.t(key)
}

/**
 * @important Firefox handles permissions differently, a the moment only
 * `camera` permission is supported for firefox.
 *
 * Checks if a browser permission has been granted. Firefox is handled
 * differently, and it is not reliable as the chromium based browsers API
 *
 * `PermissionStatus` is only returned if browser is chromium based
 */
const checkForBrowserPermissionStatus = async (
  permissionName: PermissionName | 'camera'
): Promise<
  | {
      state: 'granted' | 'prompt' | 'denied'
      name: PermissionName | 'camera'
      deviceId?: string
    }
  | PermissionStatus
> => {
  if (!navigator) {
    throw new Error('Navigator not defined')
  }

  // This is only defined on Firefox it is not conventional
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const isFirefox = typeof InstallTrigger !== 'undefined'

  if (isFirefox) {
    return new Promise((resolve, reject) => {
      navigator?.mediaDevices
        ?.enumerateDevices()
        .then((devices) => {
          devices?.forEach((device) => {
            if (device.kind === 'videoinput') {
              resolve({
                state: device.label ? 'granted' : 'prompt',
                name: permissionName,
                deviceId: device.deviceId,
              })
            }
          })
        })
        .catch(() =>
          reject({
            state: 'denied',
            name: permissionName,
          })
        )
    })
  }

  // Chromium handling
  return new Promise((resolve, reject) => {
    navigator.permissions
      .query({ name: permissionName as PermissionName })
      .then((data: PermissionStatus) => resolve(data))
      .catch(reject)
  })
}

/**
 * Picks properties from an object and returns a new object
 */
const pickProperty = (
  obj: { [key: string]: object },
  ...keys: Array<string>
) => {
  return keys.reduce((accumulator, key) => {
    const { [key]: value } = obj
    return { ...accumulator, [key]: value }
  }, {})
}

/**
 * Warning message to be shown to users as a security precaution
 */
const consoleWarningMessage = () => {
  console.log(
    `%c      WARNING! READ ME!

    If someone told you to copy-paste something here, DO NOT do it. It is a malicious intent! 
    `,
    `color:red; font-size:25px; font-weight:700`
  )
}

/**
 * Parses a phone number only if there is a phone number and it is valid,
 * otherwise the `parsePhoneNumber` parser will throw an error
 */
const parsePhoneNum = (phoneNumber: string) => {
  try {
    // Check only the length of the phone number, this is loose validation in
    // order for the parser not to spam error messages
    // if necessary very strict check can be made with `isValidPhoneNumber` function
    if (phoneNumber && validatePhoneNumberLength(phoneNumber) !== 'TOO_SHORT') {
      const { countryCallingCode, number } = parsePhoneNumber(phoneNumber)

      const formattedPhoneNumber =
        parsePhoneNumber(phoneNumber).formatInternational()

      return {
        dialCode: `+${countryCallingCode}`,
        formattedPhoneNumber: formattedPhoneNumber.replaceAll(
          `+${countryCallingCode}`,
          ''
        ),
        phoneNumber: number,
        formattedFullPhoneNumber: formattedPhoneNumber,
      }
    }
  } catch (error) {
    console.error(error)
  }

  return undefined
}

/**
 * Returns a example phone number placeholder without dial code for the passed in
 * country dial code. If the dial code does not exist, then USA international
 * example number is returned
 */
const showExamplePhoneNumber = (dialCode: string) => {
  const alpha2CountryCode: CountryCode =
    (getCountryInformation('dial_code', dialCode)?.alpha2 as CountryCode) ??
    // We do not use Alpha2 in our codebase, won't be added in the coasts file
    'US'

  return getExampleNumber(alpha2CountryCode, examples)
    ?.formatInternational()
    ?.replace(dialCode, '')
}

/**
 * Renders nominal balance from the banking context
 */
const renderNominalBalance = (
  formatAmount: (param: {
    amount: number | bigint
    residency?: string
    currency?: string
    style?: 'percent' | 'currency'
    notation?: 'standard' | 'engineering' | 'compact' | 'scientific'
    digits?: {
      minimumFractionDigits?: number
      maximumFractionDigits?: number
      maximumSignificantDigits?: number
      minimumSignificantDigits?: number
    }
  }) =>
    | undefined
    | {
        formattedAmountWithSymbol: string
      },
  bankContext: {
    bankingInfo: {
      payinHistory?: Array<{
        nominalBalance: {
          amount: number
          currency: string
        }
      }>
    }
  } & BankMachineContext,
  notation?: 'compact' | 'standard'
):
  | undefined
  | {
      formattedAmountWithSymbol: string
    } => {
  if (bankContext?.bankingInfo?.payinHistory) {
    const contributions = bankContext?.bankingInfo?.payinHistory ?? []

    if (contributions?.length > 0) {
      const amount =
        contributions?.[contributions?.length - 1]?.nominalBalance?.amount
      const currency =
        contributions?.[contributions?.length - 1]?.nominalBalance?.currency

      return formatAmount({
        amount: Math.trunc(amount),
        currency,
        style: 'currency',
        notation: notation ?? 'compact',
        digits: {
          maximumFractionDigits: 2,
        },
      })
    }
  }

  return {
    formattedAmountWithSymbol: '-',
  }
}

/**
 *  Parses the magic login url and extracts the magic login token
 */
const parseMagicLink = (pathname: string, magicLoginParam: string) => {
  if (pathname) {
    // Split and filter out empty strings, undefined and null
    const magicLoginParams = pathname.split('/').filter((param) => param)

    //The order must be `/magic_login/:magic_login_token`
    if (
      magicLoginParams.includes(magicLoginParam) &&
      magicLoginParams?.length === 2
    ) {
      // Magic login param must be `magic_login`
      if (magicLoginParams?.[0] === magicLoginParam) {
        return {
          param: magicLoginParams?.[0],
          token: magicLoginParams?.[1],
        }
      }
    }
  }

  return undefined
}

/**
 * Parses params for email
 */
const parseParamsForEmail = (incomeForecastParams: IncomeForecastParams) => {
  if (incomeForecastParams) {
    const {
      monthlyContribution,
      oneTimeContribution,
      sex,
      countryOfResidence,
      retirementAge,
      contributionAge,
    } = incomeForecastParams

    const {
      contributionAge: adjustedContributionAge,
      retirementAge: adjustedPayoutAge,
    } = adjustAndConvertToAgeMonthString(retirementAge, contributionAge)

    return parseIncomeForecastParams({
      monthlyContribution,
      oneTimeContribution,
      countryOfResidence: countryOfResidence,
      sex,
      contributionAge: adjustedContributionAge,
      payoutAge: adjustedPayoutAge,
      isAuthenticated: false,
      pastContributions: false,
      writeDraftPlan: false,
      planID: '',
    })
  }
  return undefined
}

/**
 * Fetches the user's geo location and only stores the `countryCode` for now
 */
const getIpGeoLocation = () => {
  if (!sessionStorage?.getItem('user_country')) {
    axios
      .get(API.ipGeoLocation)
      .then(
        (response: {
          data: {
            geolocation: {
              countryCode: string
              ans: string
              cityName: string
              ip: string
              isProxy: boolean
              latitude: number
              longitude: number
              regionName: string
              timeZone: string
              usingIdentifier: string
            }
            userAgent: string
          }
        }) => {
          localStorage?.setItem(
            'user_country',
            response.data?.geolocation?.countryCode
          )
        }
      )
      .catch((error) => {
        // No need to handle just warn that could not fetch
        // geo location data
        console.warn('Could not fetch geo location', error)
      })
  }
}

/**
 * Returns user's ip detected country code if present in local storage, if not
 * it fallbacks to `USA`
 */
const getDetectedIpCountry = () => {
  const geoIp = localStorage?.getItem('user_country')

  if (geoIp) {
    return geoIp
  }

  return CONSTANTS.FALLBACK_COUNTRY_CODE
}

/**
 * FaceTec SDK browser compatible browsers
 */
const faceTecCompatibleBrowser = (isMobileOrTablet: boolean): boolean => {
  const userBrowser = navigator.userAgent

  // FaceTec compatible browsers
  const compatibleBrowsers: Array<string> = ['Chrome', 'Safari', 'Firefox']

  // This filters out Mozilla Firefox for mobile devices since FaceTec does not support it
  const filteredBrowsers = isMobileOrTablet
    ? compatibleBrowsers.filter((browser) => browser !== 'Firefox')
    : compatibleBrowsers

  return filteredBrowsers.some((browser) => userBrowser.includes(browser))
}

/** Returns the appropriate CardAlert status for the passed in status
 */
const idVerificationAlertStatus = (status: string) => {
  if (status === CONSTANTS.APPROVED) return 'completed'
  if (status === CONSTANTS.SUBMITTED) return 'pending'
  if (status === CONSTANTS.REJECTED) return 'error'
  return 'warn'
}

/**
 *  Returns a throttled function of the passed in function
 */

type ThrottledFunction<T extends Array<unknown>> = (...args: T) => void
const throttle = <T extends Array<unknown>>(
  func: (...args: T) => void,
  timeout: number = 300
): ThrottledFunction<T> => {
  let timer: NodeJS.Timeout | null = null

  return (...args: T): void => {
    if (!timer) {
      func.apply(this, args)
      timer = setTimeout(() => {
        timer = null
      }, timeout)
    }
  }
}

/**
 * Detects a device type depending on the screen size.
 *
 * @note This is NOT an accurate way to detect user is using a certain device.
 */
const detectDeviceType = (): 'desktop' | 'tablet' | 'mobile' => {
  let deviceType: 'desktop' | 'tablet' | 'mobile' = 'desktop'

  if (window.innerWidth >= 992) {
    // Desktops and laptops typically have screens wider than 992px
    deviceType = 'desktop'
  } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
    // Tablets typically have screens between 768px and 991px wide
    deviceType = 'tablet'
  } else {
    // Mobile devices typically have screens narrower than 767px
    deviceType = 'mobile'
  }
  return deviceType
}

/**
 * Returns a debounced function of the passed in function
 */
const debounce = <T extends unknown[]>(
  func: (...args: T) => void,
  timeout = 300
) => {
  let timer: NodeJS.Timeout

  return (...args: T): void => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

/** Retrieves the variant if it exists in the provided variants record. */
const getCardVariant = <T extends CardVariantType>({
  variants,
  variant,
}: {
  variants: Record<string, T>
  variant?: CardVariantType
}): T | undefined => {
  return variant
    ? Object.values(variants).find((v) => v === variant)
    : undefined
}

/**
 * Parses forecast params from lite version of the webapp
 */
const parseLiteParams = (
  liteForecastParams: IncomeForecastRequestBody
): (LitePensionPlan & { monthlyContribution: number }) | undefined => {
  try {
    // TODO: I know it is awful, everything is awful, should be fixed
    // in the near future, because we have 4 set of forecast params from the
    // backend it is insufferable
    if (liteForecastParams) {
      return {
        contributionAge:
          extractYearFromAgeMonthString(
            liteForecastParams?.demographic_data?.full?.current_age ?? '0-0'
          ).age ?? 0,
        retirementAge:
          extractYearFromAgeMonthString(
            liteForecastParams?.future_contributions?.contribution_params
              ?.payout_age ?? '0-0'
          ).age ?? 0,
        countryOfResidence:
          liteForecastParams?.demographic_data?.full?.country_of_residence ??
          '',
        oneTimeContribution:
          liteForecastParams?.future_contributions?.contribution_params
            ?.onetime_amount ?? 0,
        sex: liteForecastParams?.demographic_data?.full?.sex ?? '',
        monthlyContribution:
          liteForecastParams?.future_contributions?.contribution_params
            ?.monthly_amount ?? 0,
      }
    }
  } catch (error) {
    console.error('Could not parse lite params response', error)
  }

  return undefined
}

/**
 * Parses the array returned from the referral API. Does not handle customized code
 */
const parseLiteReferralData = (data?: UserReferralStats): ReferralDetails => {
  if (!data) {
    throw new Error(`No referral data returned from API`)
  }

  return {
    referralCode: data[0].referral_code,
    redeemCount: data[0].count_redeemed,
  }
}

export {
  getIpGeoLocation,
  parseIncomeForecastParams,
  numberToAgeMonthString,
  adjustAndConvertToAgeMonthString,
  extractYearFromAgeMonthString,
  dobToYearsAndMonthsOld,
  numberFormatter,
  initialForecastParams,
  disabledPickerValues,
  showDoBModal,
  disableDay,
  disableNextMonth,
  dateDifference,
  formatDateToDateString,
  formatDate,
  parseDateToNumbers,
  isLeapYear,
  isDisabled,
  verifiedPersonalDetails,
  formatSSN,
  validateRetirementAge,
  getGreeting,
  getSupportedTontinatorParams,
  differenceInPercentage,
  hasCompletedL2KYC,
  validateInputWithError,
  getStateInformation,
  captureExceptionWithSentry,
  addHyphenAt,
  calculateYearForRetirementAge,
  birthAgeAndMonthsFromFutureDate,
  calculateRetirementValues,
  sanitizeInputValue,
  i18nTrans,
  checkForBrowserPermissionStatus,
  pickProperty,
  consoleWarningMessage,
  parsePhoneNum,
  showExamplePhoneNumber,
  renderNominalBalance,
  parseMagicLink,
  modifyContributionAgeAndRetirementAge,
  parseParamsForEmail,
  getDetectedIpCountry,
  idVerificationAlertStatus,
  faceTecCompatibleBrowser,
  throttle,
  detectDeviceType,
  debounce,
  getCardVariant,
  parseLiteParams,
  parseLiteReferralData,
}
