/**
 * All `robo-actuary` used by the webapp
 */
const endpoint = {
  userDetails: '/user_account/read',
  healthCheck: '/health_check',
  logout: '/auth_token',
  logoutAllDevices: '/all_auth_tokens',
  checkSession: '/auth_token/seconds_remaining',
  savePin: '/pin',
  extendLoginSessionWithPin: '/session/extend',
  checkSessionWSS: '/auth_token/seconds_remaining_ws/?access_token=',
  sendEmailMagicLinkNewTab: '/magic_login/send_email/new_tab',
  loginMagicLinkNewTab: '/magic_login/redeem_token',
  getScanSessionToken: '/face_scan/session_token',
  enrollFace: '/face_scan/enroll',
  authenticateFace: '/face_scan/authenticate',
  scanId: '/id_scan',
  register: `/user_account/create`,
  editUserDetails: `/user_account/edit`,
  saveFeedback: `/user_account/feedback/create`,
  closeAccount: `/user_account/close`,
  cancelClosingAccount: `/user_account/cancel_closure`,
  addPhoneNumber: `/phone_number/update`,
  verifyPhoneNumber: `/phone_number/verify`,
  changePin: `/pin/update`,
  forgotPin: `/pin/forgot`,
  resetPin: `/pin/reset`,
  saveRating: `/feedback/rating`,
  saveTextFeedback: `/feedback/text`,
  getAllUserFeedback: `/all_feedback`,
  createReferralCode: '/referral_code/create',
  redeemReferralCode: '/referral_code/redeem',
  referralStats: '/referral_code/stats',
  agreement: '/agreement',
  nominalBalance: '/logical_tx/balance_history',
  readOwnBank: '/user/read/own',
  payoutAccount: '/payout_account',
  payoutAccountUpdate: '/payout_account/update',
  readNextPayout: '/payouts/own',
  thresholds: '/thresholds',
  updatePayoutAccount: '/payout_account/update',
  deletePayoutAccount: '/payout_account/delete',
  tontinatorForecast: '/payout_forecast/tontinator',
  readDraftPlan: '/plan/read/draft',
  updateLitePlan: '/user_account/update_lite',
  readFormFields: '/form',
  saveFormProgress: '/form/update',
  submitForm: '/form/submit',
  signAgreement: '/agreement/agree',
  refreshSession: '/session/refresh',
  resendVerificationEmail: '/user_account/resend_verify',
  liteAuth: '/user_account/verify',
  ipGeoLocation: '/geolocation/ip ',
  tracking: '/tracking',
  returns: '/returns',
} as const

export { endpoint }
