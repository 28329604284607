import axios from 'axios'
import { API } from '../API'
import { axiosConfig } from '../RequestConfig'

/**
 * @param {number} rating
 *
 * Saves user's rating to UAS and returns a  `feedback_id` with the
 * `onSuccess` callback that is used to save additional text feedback
 */
const saveRating = (rating, authToken, { onSuccess, onFailure }) => {
  axios
    .post(
      API.saveRating,
      {
        rating,
      },
      axiosConfig({ authToken })
    )
    .then((response) => {
      return onSuccess(response.data)
    })
    .catch((error) => {
      return onFailure(error.response)
    })
}

/**
 * @param {string} text
 * @param {string} feedbackId
 *
 * @description Saves user's text feedback to UAS, takes in text and
 * `feedbackId` returned from `saveRating`
 */
const saveTextFeedback = (
  text,
  feedbackId,
  authToken,
  { onSuccess, onFailure }
) => {
  axios
    .post(
      API.saveTextFeedback,
      {
        //Need to have an empty string so the request does not fail if the user
        //does not provide any text feedback
        text: text || ' ',
        feedback_id: feedbackId,
      },
      axiosConfig({ authToken })
    )
    .then(onSuccess)
    .catch((error) => {
      return onFailure(error.response)
    })
}

export const tontineFeedback = { saveRating, saveTextFeedback }
