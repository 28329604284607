import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import { CONSTANTS } from '../../constants/ConstantValues'
import Button from '../../components/buttons/Button'
import { filterByDateRange } from '../../utils/UtilFunctions'
import DateRange from '../../components/inputs/date/DateRange'
import dayjs from 'dayjs'
import style from '../../scss/components/ContributionHistory.module.scss'

/**
 * Today's date with the last day of the month
 */
const transactionHistoryEndRange = () => {
  return dayjs().endOf('month').format('YYYY-MM-DD')
}

/**
 * First occurrence of a transaction
 */
const transactionHistoryStart = (array) => {
  const oldestTransactionDate = new Date(
    Math.min(...array.map((e) => new Date(e.rawDate).getTime()))
  )

  return dayjs(oldestTransactionDate).startOf('month').format('YYYY-MM-DD')
}

/**
 * @param {array} array
 * @param {function} onFiltered  Callback that returns the filtered array
 * @param {string} filterKey  Object key to filter by that is passed to the
 * filter util functions
 *
 * @description Renders filter options for filtering contributions, and returns
 * the passed in array filtered by the selected option
 */
const FilteringOptions = ({ array, onFiltered, filterKey }) => {
  //Hooks
  const t = useTranslate()

  //Filtering states
  const [fromDate, setFromDate] = useState({
    dateStringISO: transactionHistoryStart(array),
  })
  const [toDate, setToDate] = useState({
    dateStringISO: transactionHistoryEndRange(),
  })

  //Component states
  const [showCustomDate, setShowCustomDate] = useState(false)

  //Wrapper function that sets the state of the months to filter by
  const showAll = () =>
    onFiltered({
      filteredArray: array,
    })

  //Shows and hides the custom data range component
  const toggleCustomDateRange = () => {
    if (showCustomDate) {
      onFiltered({ filteredArray: array })
    }
    setShowCustomDate((prev) => !prev)
  }

  useEffect(() => {
    if (fromDate?.dateStringISO && toDate?.dateStringISO) {
      onFiltered({
        filteredArray: filterByDateRange(
          array,
          filterKey,
          dayjs(fromDate?.dateStringISO).startOf('month').format('YYYY-MM-DD'),
          dayjs(toDate?.dateStringISO).endOf('month').format('YYYY-MM-DD')
        ),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey, fromDate?.dateStringISO, toDate?.dateStringISO])

  return (
    <div className={style['contributionHistory__filter-options']}>
      <div className={style['contributionHistory__months-container']}>
        <Button variant="primary" onClick={showAll}>
          {t('FILTER_LAST_SIX_MONTHS')}
        </Button>
        <Button onClick={toggleCustomDateRange} variant="alternative">
          {showCustomDate ? t('RESET_FILTERS') : t('FILTER_CUSTOM')}
        </Button>
      </div>
      {showCustomDate && (
        <DateRange
          fromDateTitle={t('FROM_DATE_RANGE_INPUT')}
          onChangeFromDate={setFromDate}
          fromDate={fromDate}
          toDateTitle={t('BANKING.PAYOUT_HISTORY_FILTER_TO')}
          onChangeToDate={setToDate}
          toDate={toDate}
          pickersMode={CONSTANTS?.DATE_PICKER_MODES?.MONTHS}
          dateFormat={'MM/YYYY'}
          // First occurrence of a transaction, previous month and year are
          // disabled
          fromDisabledMonthTo={dayjs(transactionHistoryStart(array)).get(
            'month'
          )}
          fromDisabledYearTo={
            dayjs(transactionHistoryStart(array)).get('year') - 1
          }
          // Next month and year should be disabled since this is payout history
          // not payout future
          toDisabledMonthsFrom={CONSTANTS.CURRENT_MONTH + 1}
          toDisabledYearFrom={CONSTANTS.CURRENT_YEAR + 1}
        />
      )}
    </div>
  )
}

FilteringOptions.propTypes = {
  array: PropTypes.array.isRequired,
  onFiltered: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
  additionalFilters: PropTypes.bool,
}

export default FilteringOptions
