import RangeSlider from './RangeSlider'
import PropTypes from 'prop-types'
import { useAccountService } from '../../../state-management/authentication/useAccountService'
import { UI_TEST_ID } from '../../../constants/DataTestIDs'

/**
 * @param {string} className  A class name for the component.
 * @param {number} retirementAge  The age selected on the retirement age slider.
 * @param {Function} setRetirementAge  A function for setting the retirement
 * age.
 * @param {number[]} steps  An array of numbers representing all possible values
 * for the sliders.
 * @param {string} retirementSliderLabel  The label for the retirement age
 * slider.
 * @param {number} currentAge  The age selected on the current age slider.
 * @param {Function} setCurrentAge  A function for setting the current age.
 * @param {string} currentAgeSliderLabel  The label for the current age slider.
 *
 * Renderers a range slider for retirement age and for the current age
 */
const AgeSliders = ({
  className,
  retirementAge,
  setRetirementAge,
  retirementSliderLabel,
  currentAge,
  setCurrentAge,
  currentAgeSliderLabel,
  disabledRetirementSlider,
  currentAgeSteps,
  retirementAgeSteps,
  disabledIncrementCurrentAge,
  disabledDecrementCurrentAge,
  disabledIncrementRetirementAge,
  disabledDecrementRetirementAge,
  hideCurrentAge,
  variant,
  currentAgeBoxDataTestID,
  retirementAgeBoxDataTestID,
  retAgeIncrementDataTestID,
  retAgeDecrementDataTestID,
  currentAgeIncrementDataTestID,
  currentAgeDecrementDataTestID,
  trackingActivityCurrentAgeSlider,
  trackingActivityRetirementSlider,
  trackCurrenAgeRangeActivity,
  trackRetAgeRangeActivity,
  enabledRetirementSteps,
}) => {
  const { isAuthenticated } = useAccountService()

  return (
    <main className={className}>
      {!hideCurrentAge && (
        <RangeSlider
          value={currentAge}
          onChange={setCurrentAge}
          steps={currentAgeSteps}
          label={currentAgeSliderLabel}
          disabled={isAuthenticated}
          disabledIncrement={disabledIncrementCurrentAge}
          disabledDecrement={disabledDecrementCurrentAge}
          variant={variant}
          boxValueDataTestID={currentAgeBoxDataTestID}
          incrementButtonDataTestID={currentAgeIncrementDataTestID}
          decrementButtonDataTestID={currentAgeDecrementDataTestID}
          sliderTestID={UI_TEST_ID.currentAgeSlider}
          trackActivity={trackingActivityCurrentAgeSlider}
          trackRangeActivity={trackCurrenAgeRangeActivity}
        />
      )}
      <RangeSlider
        value={retirementAge}
        onChange={setRetirementAge}
        steps={retirementAgeSteps}
        label={retirementSliderLabel}
        disabled={disabledRetirementSlider}
        disabledIncrement={disabledIncrementRetirementAge}
        disabledDecrement={disabledDecrementRetirementAge}
        variant={variant}
        boxValueDataTestID={retirementAgeBoxDataTestID}
        incrementButtonDataTestID={retAgeIncrementDataTestID}
        decrementButtonDataTestID={retAgeDecrementDataTestID}
        sliderTestID={UI_TEST_ID.retirementSlider}
        trackActivity={trackingActivityRetirementSlider}
        trackRangeActivity={trackRetAgeRangeActivity}
        enabledSteps={enabledRetirementSteps}
      />
    </main>
  )
}

AgeSliders.propTypes = {
  className: PropTypes.string,
  retirementAge: PropTypes.number,
  setRetirementAge: PropTypes.func,
  steps: PropTypes.array,
  retirementSliderLabel: PropTypes.string,
  currentAge: PropTypes.number,
  setCurrentAge: PropTypes.func,
  currentAgeSliderLabel: PropTypes.string,
  disabledRetirementSlider: PropTypes.bool,
  currentAgeSteps: PropTypes.array,
  retirementAgeSteps: PropTypes.array,
  disabledIncrementCurrentAge: PropTypes.bool,
  disabledDecrementCurrentAge: PropTypes.bool,
  disabledIncrementRetirementAge: PropTypes.bool,
  disabledDecrementRetirementAge: PropTypes.bool,
  hideCurrentAge: PropTypes.bool,
  variant: PropTypes.string,
  currentAgeBoxDataTestID: PropTypes.string,
  retirementAgeBoxDataTestID: PropTypes.string,
  retAgeIncrementDataTestID: PropTypes.string,
  retAgeDecrementDataTestID: PropTypes.string,
  currentAgeIncrementDataTestID: PropTypes.string,
  currentAgeDecrementDataTestID: PropTypes.string,
  trackingActivityCurrentAgeSlider: PropTypes.object,
  trackingActivityRetirementSlider: PropTypes.object,
  trackCurrenAgeRangeActivity: PropTypes.object,
  trackRetAgeRangeActivity: PropTypes.object,
  enabledRetirementSteps: PropTypes.array,
}

export default AgeSliders
