import axios, { AxiosResponse, AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { axiosConfig } from '../api/RequestConfig'
import { generateApiError } from '../utils/UtilFunctions'
import { useAccountService } from '../state-management/authentication/useAccountService'
type GetAPIs = string | Array<string>

/**
 * Uses axios GET, to make GET requests to API/s. The hook also supports an
 * `onSuccess` callback when the request is successful
 */
export const useGetRequest = (
  apis: GetAPIs,
  onSuccess?: (response: AxiosResponse) => void,
  headers?: Headers
) => {
  const { context } = useAccountService()
  //Axios cancel token
  const abortController = useRef<AbortController>(new AbortController())

  const [data, setData] = useState<undefined | AxiosResponse | object>(
    undefined
  )

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined | object>()

  const refetchFn = useRef<() => void | undefined>()

  useEffect(() => {
    //Cancels the previous pending api request
    if (abortController.current) {
      abortController.current.abort()
    }
    //Assign new request source reference something like new request ID
    abortController.current = new AbortController()

    const handleSuccessfulReading = (response: AxiosResponse): void => {
      //Check if a response is an array meaning that the an array of APIs has
      //been passed in
      if (Array.isArray(response)) {
        setData(response)
      } else {
        setData(response?.data as object)
      }

      onSuccess && onSuccess(response)
    }

    const handleFailedReading = (error?: AxiosError): void => {
      //Skips setting the error state if it is an error of type cancelled from
      //axios
      if (!axios.isCancel(error)) {
        setError(generateApiError(error).translatedError)
      }
    }

    //If API/s are passed in, then make a call to the API
    if (apis) {
      callOneOrMultiple(
        apis,
        abortController.current.signal,
        context?.authToken ?? '',
        headers
      )
        .then((response) => handleSuccessfulReading(response as AxiosResponse))
        .catch(handleFailedReading)
        .finally(() => setIsLoading(false))
    }

    refetchFn.current = () => {
      if (apis) {
        setIsLoading(true)
        setError(undefined)

        callOneOrMultiple(
          apis,
          abortController.current.signal,
          context?.authToken ?? ''
        )
          .then((response) =>
            handleSuccessfulReading(response as AxiosResponse)
          )
          .catch(handleFailedReading)
          .finally(() => setIsLoading(false))
      }
    }

    setIsLoading(true)
    setError(undefined)

    return () => abortController.current.abort()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof apis === 'string' ? apis : undefined, JSON.stringify(headers)])
  //The line above prevents infinite re-rendering. If the `apis` is not an array
  //then it should be included in the dependency array in order when there is an
  //api change like new param for the hook to re-run and refetch new data.
  //Refetching is not needed if there is an array of API that need to be called
  //in parallel, at least for now

  return {
    error,
    isLoading,
    data,
    refetch: refetchFn.current,
  }
}

/**
 * If an array of apis is provided then a parallel call is made, otherwise
 * normal one axios get request is made
 */
const callOneOrMultiple = (
  apis: GetAPIs,
  signal: AbortSignal,
  authToken: string,
  headers?: Headers
) => {
  if (Array.isArray(apis)) {
    return Promise.all(
      apis.map((api: string) =>
        axios.get(api, { ...axiosConfig({ signal, authToken }), ...headers })
      )
    )
  }

  return axios.get(apis, { ...axiosConfig({ signal, authToken }), ...headers })
}
