import style from '../../scss/layout/Divider.module.scss'

type DividerProps = {
  number?: number
  className?: string
  active?: boolean
}

/**
 * Divider line that also supports a number to be added in the
 * middle of the divider
 */
const Divider = ({ number, className = '', active }: DividerProps) => {
  return (
    <div className={`${style.divider} ${className}`}>
      {number && <div className={style['divider__number']}>{number}</div>}
      <hr className={`${style[`divider__line${active ? '--active' : ''}`]}`} />
    </div>
  )
}

export default Divider
