import { useState } from 'react'
import { useParams } from 'react-router-dom'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import PermissionModal from '../../components/overlay/PermissionModal'
import { ASSET } from '../../constants/Assets'
import { PUBLIC } from '../../routes/Route'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useTranslate } from '../../hooks/useTranslate'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import ScanInstructions from './ScanInstructions'
import TontineModal from '../../components/overlay/Modal'
import Layout from '../../components/layout/Layout'
import ErrorBoundaryAndSuspense from '../../components/error-handling/ErrorBoundaryAndSuspense'
import { ErrorStorage } from '../../state-management/CommonState.type'
import style from '../../scss/pages/FaceScan.module.scss'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

type ScanRoutes = 'enroll-face' | 'match-id' | 'auth-scan'
type PageSetting = {
  pageTitle: string
  scanType: 'ENROLLMENT' | 'PHOTO_ID_SCAN' | 'AUTHENTICATION'
  scanError: string
}

type FaceScanProps = {
  email?: string
  onClickExitScan?: () => void
  asModal?: boolean
  scanType: ScanRoutes
  onSuccessfulScan?: () => void
}

const ScanParamToPageSetting: { [key in ScanRoutes]: PageSetting } = {
  'enroll-face': {
    pageTitle: 'FACESCAN.REGISTER.FACE',
    scanType: 'ENROLLMENT',
    scanError: 'BIOMETRICS.FACE_ENROLL_FAILED',
  },
  'match-id': {
    pageTitle: 'FACE_SCAN.ID_SCAN',
    scanType: 'PHOTO_ID_SCAN',
    scanError: 'BIOMETRICS.FACE_MATCH_TO_ID_FAILED',
  },
  'auth-scan': {
    pageTitle: 'FACE_SCAN.AUTHENTICATION',
    scanType: 'AUTHENTICATION',
    scanError: 'BIOMETRIC.CARD_FACESCAN_ERROR',
  },
}

/**
 * Handles the face scan and changes the content depending on which type
 * of scan the user is doing
 *
 * - Handles browser permissions
 * - Starts a biometrics facescan
 * - Provides scan guidance (more design work is needed)
 *
 * @note For enrolling use `match-id` where enrolling and IDV is merged
 * into one flow!
 */
const FaceScan = ({
  email,
  onClickExitScan,
  asModal,
  scanType,
  onSuccessfulScan,
}: FaceScanProps) => {
  const { isMobileOrTablet } = useDeviceScreen()
  const { t, startScan, scanIsInProgress, scanError, navigate, pageSetting } =
    useFaceScan({
      email,
      scanType,
    })
  const [permissionError, setPermissionError] = useState<
    { error: string } | undefined
  >(undefined)
  const [isOpenScanModal, setIsOpenScanModal] = useState(Boolean(asModal))

  const startScanWithModal = (asModal: boolean) => {
    if (asModal) {
      startScan(({ idScanCompleted, enrollmentCompleted }) => {
        // Only close the modal if the whole math-id has been completed
        if (scanType === 'match-id' && idScanCompleted && enrollmentCompleted) {
          setIsOpenScanModal(false)
          onSuccessfulScan?.()
        }
        if (scanType === 'auth-scan' || scanType === 'enroll-face') {
          setIsOpenScanModal(false)
          onSuccessfulScan?.()
        }
      })
    } else {
      //TODO: Scan as a page, might need to add callbacks when that is the case
      startScan()
    }
  }

  const scanInstructions = (
    <ErrorBoundaryAndSuspense>
      <main className={style[`face-scan__scanInstructions`]}>
        <PermissionModal
          icon={ASSET.infoCircle}
          title={t('FACE_SCAN_PERMISSION.TITLE')}
          content={t('FACE_SCAN_PERMISSION.CONTENT')}
          onPermissionGranted={() => {
            startScanWithModal(asModal ?? false)
            setPermissionError(undefined)
          }}
          onPermissionDenied={() => {
            // Denied permissions render a explanation modal
            setPermissionError({
              error: 'NO_CAM_PERMISSIONS',
            })
          }}
          permission="video"
          askOnMount
        />

        <ScanInstructions
          title={
            asModal && !scanError && !permissionError
              ? t(pageSetting.pageTitle)
              : ''
          }
          scanningErrorMessage={
            permissionError ? permissionError?.error : scanError
          }
        />
        <NavigationButtons
          onClickFirst={
            onClickExitScan
              ? onClickExitScan
              : () => navigate(PUBLIC.GO_TO_PREV_PAGE)
          }
          onClickSecond={() => startScanWithModal(asModal ?? false)}
          disabledSecond={Boolean(permissionError?.error)}
          secondButtonLabel={t('VERIFY_ACC_INSTRUCTIONS.JOIN_BUTTON_TEXT')}
          secondButtonLoading={scanIsInProgress}
          textOnLoading={t('LOADING_TEXT')}
          disabledFirst={scanIsInProgress}
        />
      </main>
    </ErrorBoundaryAndSuspense>
  )

  if (asModal) {
    const modalInstructions = (
      <TontineModal
        isOpen={isOpenScanModal}
        hasOnlyContent
        backdrop
        backdropType={isMobileOrTablet ? 'full-color' : undefined}
      >
        {scanInstructions}
      </TontineModal>
    )
    if (isMobileOrTablet) {
      return (
        // Layout needs to be added in mobile view only in order for the modal
        // to overlay the mobile nav bar
        <Layout onClickAction={onClickExitScan}>{modalInstructions}</Layout>
      )
    }

    return modalInstructions
  }

  return (
    <Layout
      onClickAction={
        onClickExitScan
          ? onClickExitScan
          : () => navigate(PUBLIC.GO_TO_PREV_PAGE)
      }
      pageTitle={t(pageSetting?.pageTitle)}
    >
      {scanInstructions}
    </Layout>
  )
}

/**
 * Handles the face scan and changes some page content depending on which type
 * of scan the user is doing
 */
const useFaceScan = ({
  email,
  scanType,
}: {
  email?: string
  scanType?: ScanRoutes
}) => {
  const [scanError, setScanError] = useState<undefined | string>(undefined)
  // Hooks
  const { send, context, currentState, states } = useAccountService()
  const { scan_type } = useParams() as {
    scan_type?: ScanRoutes
  }
  const navigate = useCustomNavigation()
  const t = useTranslate()

  // Default to auth scan always, no need to explicitly set it
  const pageSetting =
    ScanParamToPageSetting[scanType ?? scan_type ?? 'auth-scan']

  const startScan = (
    onSuccess?: (data: {
      idScanCompleted?: boolean
      enrollmentCompleted?: boolean
    }) => void,
    onFailure?: () => void
  ) => {
    send({
      type: 'START_FACE_SCAN',
      payload: {
        scanType: pageSetting.scanType,
        email: email ?? context.user_details?.email,
        authToken: context?.authToken,
        successCallback: (data) =>
          onSuccess?.(
            data as {
              idScanCompleted?: boolean
              enrollmentCompleted?: boolean
            }
          ),
        failureCallback: (error) => {
          if (error) {
            if (
              !(error as ErrorStorage & { data: { isInit: boolean } })?.data
                ?.isInit
            ) {
              // Problem with facescan SDK not initializing
              setScanError('ERROR_GENERIC')
            } else {
              // Wrap the error so it renders something generic per scan type
              setScanError(pageSetting.scanError)
            }
          }
          onFailure?.()
        },
      },
    })
  }

  return {
    t,
    navigate,
    scan_type,
    pageSetting,
    scanIsInProgress:
      currentState === states.ANON_FACE_SCAN_IN_PROGRESS ||
      currentState === states.AUTH_FACE_SCAN_IN_PROGRESS,
    startScan,
    scanError,
  }
}

export default FaceScan
