import { ReactNode } from 'react'
import MissingInformation from '../pages/user-info/MissingInformation'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { hasCompletedL1KYC } from '../utils/UtilFunctions'
import Layout from '../components/layout/Layout'
import { useTranslate } from '../hooks/useTranslate'

/**
 * Checks if the user has completed L1 KYC, if not, renders a
 * `<MissingInformation />` page for the user to complete the kyc checklist
 */
const KYCGuard = ({
  children,
  requestKyc,
}: {
  children: ReactNode
  requestKyc?: boolean
}) => {
  const t = useTranslate()
  const {
    context: { user_details },
  } = useAccountService()

  if (requestKyc) {
    if (hasCompletedL1KYC(user_details)) {
      return children
    }

    return (
      <Layout
        pageTitle={t('MISSING_DETAILS.EXPLAINER_TITLE')}
        containerWidth="small"
      >
        <MissingInformation />
      </Layout>
    )
  }

  return children
}

export default KYCGuard
