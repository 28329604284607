import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share'
import SmsShare from './SmsShare'
import Icon from './Icon'
import { ASSET } from '../../constants/Assets'
import { ObjectIdProperty } from '../../analytics/ObjectId'
import { track } from '../../analytics/Analytics'
import { ReferralLinkEvent } from '../../analytics/EventData'

/**
 * Render social media icons when clicked share content
 */
const SocialShare = ({
  size,
  urlToShare,
  postTitle,
  postContent,
  facebookHashtag,
  hashTags,
  twitterAccountsToFollow,
  roundIcons,
}: {
  size: number
  urlToShare: string
  postTitle: string
  postContent: string
  facebookHashtag?: string
  hashTags?: Array<string>
  twitterAccountsToFollow?: Array<string>
  roundIcons?: boolean
}) => {
  const trackSocialShare = ({ objectId }: { objectId: ObjectIdProperty }) => {
    void track({
      event: ReferralLinkEvent.share,
      properties: {
        object_id: objectId,
        object_value: urlToShare,
      },
    })
  }

  return (
    <section
      style={{
        display: 'flex',
        gap: '10px',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      <FacebookShareButton
        url={urlToShare}
        hashtag={`#${facebookHashtag}`}
        onClick={() => trackSocialShare({ objectId: 'facebook' })}
      >
        <FacebookIcon size={size} round={roundIcons} />
      </FacebookShareButton>

      <TwitterShareButton
        url={urlToShare}
        title={postTitle}
        hashtags={hashTags}
        related={twitterAccountsToFollow}
        onClick={() => trackSocialShare({ objectId: 'x' })}
      >
        <XIcon size={size} round={roundIcons} />
      </TwitterShareButton>

      <WhatsappShareButton
        url={urlToShare}
        title={postTitle}
        onClick={() => trackSocialShare({ objectId: 'whatsapp' })}
      >
        <WhatsappIcon size={size} round={roundIcons} />
      </WhatsappShareButton>

      <SmsShare
        contentToShare={urlToShare}
        onClick={() => trackSocialShare({ objectId: 'sms' })}
      >
        <div style={{ width: `${size}px`, height: `${size}px` }}>
          <Icon
            fileName={ASSET.smsRounded}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </SmsShare>

      <ViberShareButton
        url={urlToShare}
        title={postTitle}
        onClick={() => trackSocialShare({ objectId: 'viber' })}
      >
        <ViberIcon size={size} round={roundIcons} />
      </ViberShareButton>

      <TelegramShareButton
        url={urlToShare}
        title={postTitle}
        onClick={() => trackSocialShare({ objectId: 'telegram' })}
      >
        <TelegramIcon size={size} round={roundIcons} />
      </TelegramShareButton>

      <EmailShareButton
        url={urlToShare}
        subject={postTitle}
        body={postContent}
        onClick={() => trackSocialShare({ objectId: 'email' })}
      >
        <EmailIcon size={size} round={roundIcons} />
      </EmailShareButton>
    </section>
  )
}

export default SocialShare
