import { useState } from 'react'
import { track } from '../../analytics/Analytics'
import { LegendEvent } from '../../analytics/EventData'
import { ObjectIdProperty } from '../../analytics/ObjectId'

const trackLegend = ({
  legendItemId,
  legendItemValue,
  legendItemLabel,
}: {
  legendItemId: ObjectIdProperty
  legendItemValue: boolean
  legendItemLabel: string
}) => {
  void track({
    event: LegendEvent.toggled,
    properties: {
      object_id: legendItemId,
      object_value: legendItemValue,
      label: legendItemLabel,
    },
  })
}

/**
 * Controls if the lines should be rendered or not
 */
export const useLineToggle = (params: {
  renderTontineLine: boolean
  renderDepositLine: boolean
  renderAnnuityLine: boolean
}) => {
  const { renderTontineLine, renderDepositLine, renderAnnuityLine } = params

  const [tontineLine, setTontineLine] = useState<boolean>(renderTontineLine)
  const [depositLine, _setDepositLine] = useState<boolean>(renderDepositLine)
  const [annuityLine, _setAnnuityLine] = useState<boolean>(renderAnnuityLine)

  const setDepositLine = ({
    value,
    label,
  }: {
    value: boolean
    label: string
  }) => {
    _setDepositLine(!value)
    trackLegend({
      legendItemId: 'tontinator_legend_item_bank',
      legendItemValue: !value,
      legendItemLabel: label,
    })
  }
  const setAnnuityLine = ({
    value,
    label,
  }: {
    value: boolean
    label: string
  }) => {
    _setAnnuityLine(!value)
    trackLegend({
      legendItemId: 'tontinator_legend_item_annuity',
      legendItemValue: !value,
      legendItemLabel: label,
    })
  }

  return {
    tontineLine,
    setTontineLine,
    depositLine,
    setDepositLine,
    annuityLine,
    setAnnuityLine,
  }
}
