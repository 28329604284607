import { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import { toast } from 'react-toastify'
import { ANIMATION } from '../../constants/Animations'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import Button from '../buttons/Button'
import { CONSTANTS } from '../../constants/ConstantValues'
import PinInput from '../inputs/PinInput'
import PinSetup from '../inputs/PinSetup'
import LottieAnimation from '../feedback/LottieAnimation'
import ToastMessage from '../feedback/ToastMessage'
import SessionExtensionTimerAndTitle from './SessionExtensionTimerAndTitle'
import Modal from './Modal'
import { ErrorStorage } from '../../state-management/CommonState.type'
import style from '../../scss/components/SessionExtensionModal.module.scss'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/**
 * Appears only when user session is about to expire, this
 * component should be displayed and rendered early as possible in the DOM.
 * Component is built with `<TontineModal />`
 */
const SessionExtensionModal = () => {
  //hooks
  const t = useTranslate()
  const { isAuthenticated, send, context } = useAccountService()

  const [error, setError] = useState<string | null | undefined>(null)
  const [hidePinAuth, setHidePinAuth] = useState(false)
  const [dataFromPinSetup, setDataFromPinSetup] = useState<
    { pin: string } | undefined
  >(undefined)

  //If the correct pin is provided to the server, it will respond with a new
  //auth_token so here we send the auth_token to the AuthMachine so it continues
  //checking session with the new auth token
  const handleOnSuccess = () => {
    setHidePinAuth(false)

    toast.success(
      <ToastMessage title={t('EXPIRE_SESSION_MODAL.EXTENDED_SESSION_TITLE')} />
    )
  }

  const extendFirstTimeSetup = (dataFromSetup: { pin: string }) => {
    setDataFromPinSetup(dataFromSetup)
    setHidePinAuth(true)
  }

  // Only executed if user wants to perform an action that requires for them to
  // enter pin and they don't have a pin, so they first set a pin and after
  // successfully setting a pin we call the API that requires pin authorization
  // with a freshly set pin
  useEffect(() => {
    // Check if the user has set a pin successfully and if the context has been
    // updated
    if (dataFromPinSetup?.pin && context?.user_details?.pin_set) {
      send({
        type: 'EXTEND_SESSION',
        payload: {
          pin: dataFromPinSetup?.pin,
          successCallback: handleOnSuccess,
          failureCallback: handleOnFailedPinSubmit,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.user_details?.pin_set, dataFromPinSetup?.pin])

  /**
   * Changes the modal content depending on how much has the user
   * completed the account setup process
   */
  const sessionModalExplainerText = () => {
    return t('COMMON.MODAL_LOGIN_ABOUT_TO_EXPIRE_ENTER_PIN')
  }

  const handleOnFailedPinSubmit = (error: unknown) =>
    setError((error as ErrorStorage)?.translatedError)

  /**
   * Renders pin setup component if the user has not set a pin
   */
  const renderModalContent = () => {
    if (!context?.user_details?.pin_set) {
      return (
        <SessionExtensionTimerAndTitle
          content={sessionModalExplainerText()}
          serverTime={context?.sessionAboutToExpire?.secondsRemaining}
        >
          <PinSetup
            onSuccessfulPinSubmit={extendFirstTimeSetup}
            onFailedPinSubmit={handleOnFailedPinSubmit}
            authMachineEvent={'CREATE_NEW_PIN'}
          />
        </SessionExtensionTimerAndTitle>
      )
    }

    if (!hidePinAuth) {
      return (
        <SessionExtensionTimerAndTitle
          content={sessionModalExplainerText()}
          serverTime={context?.sessionAboutToExpire?.secondsRemaining}
        >
          <PinInput
            label={''}
            type={CONSTANTS.HIDE_PIN}
            authMachineEvent={'EXTEND_SESSION'}
            numberOfFields={CONSTANTS.PIN_INPUT_FIELDS}
            onSuccessfulPinSubmit={handleOnSuccess}
            onFailedPinSubmit={handleOnFailedPinSubmit}
            errorMessage={error}
            onTyping={() => setError(null)}
            loadingState
            loadingMessage={t('PIN_SUBMITTING_MESSAGE')}
          />
        </SessionExtensionTimerAndTitle>
      )
    }

    return (
      <LottieAnimation
        animationName={ANIMATION.loadingLightBlueDots}
        autoplay
        loop
      />
    )
  }

  if (isAuthenticated) {
    return (
      <Modal
        isOpen={Boolean(context?.sessionAboutToExpire?.notifyUser)}
        backdrop
        dataTestID={UI_TEST_ID.sessionExtensionModal}
      >
        {renderModalContent()}
        <Button
          className={style['sessionExtensionModal__dismiss-button']}
          onClick={() => {
            send({
              type: 'SESSION_ABOUT_TO_EXPIRE',
              payload: {
                hasDeclinedSessionExtension: true,
              },
            })
          }}
        >
          {t('COMMON.CONTINUE_BUTTON')}
        </Button>
      </Modal>
    )
  }

  return <></>
}

export default SessionExtensionModal
