import { isCancel } from 'axios'
import { fromPromise } from 'xstate'
import { captureExceptionWithSentry } from '../utils/TSUtilFunctions'
import { generateApiError } from '../utils/UtilFunctions'
import { ErrorStorage } from './CommonState.type'
import { enrollFaceToFaceAuth } from '../../facetec/API'
import { ScanType } from '../../facetec/FaceBiometrics'
import {
  AuthMachineContext,
  AuthMachineEvent,
  AuthMachineSelf,
} from './authentication/AuthMachineTypes.type'
import {
  LegalMachineContext,
  LegalMachineEvent,
} from './legal/LegalMachineTypes.types'

/**
 * Extracts all of the possible states that an xstate machine can be in
 */
const generateStatesObject = (states: object): object => {
  return Object.keys(states).reduce(
    (acc, state) => ({
      ...acc,
      [state]: state,
    }),
    {} as object
  )
}

/**
 * @note The `error` is rethrow again in order to trigger an `onError` state
 * transition in a state machine Axios cancel error is ignored by default
 *
 * - Issues a failureCallback in case of an error
 * - Sends an alert with Sentry to devs
 * - Rethrows the error that occurred, unless explicitly specified not to do so
 */
const writeToConsoleAndIssueAlert = ({
  error,
  failureCallback,
  skipRethrow,
}: {
  error: unknown
  failureCallback?: (parsedError: ErrorStorage) => void
  errorMessage?: string
  skipRethrow?: boolean
}): void => {
  if (!isCancel(error)) {
    //Issues a callback all the way to the `sendEvent`
    failureCallback &&
      failureCallback(
        generateApiError(error as object) as unknown as ErrorStorage
      )

    console.error(
      'Error thrown unparsed:',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      error?.response?.data ? error?.response?.data : error
    )
    captureExceptionWithSentry(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      error?.response?.data ? error?.response?.data : error
    )

    //Triggers the onError state transition in a xstate machine for invoked
    //services, by re-throwing the same error
    if (!skipRethrow) {
      throw error
    }
  }
}

/**
 * Used for mocking a facescan response based in the passed in scan type
 */
const mockFaceScan = async ({
  scanType,
  authToken,
}: {
  scanType: ScanType
  authToken: string
}) => {
  const scanTypeToEndpoint: { [key: string]: string } = {
    ENROLLMENT: 'http://localhost:8081/face_scan/enroll',
  }

  if (scanTypeToEndpoint[scanType]) {
    const data = await enrollFaceToFaceAuth({
      api: scanTypeToEndpoint.ENROLLMENT,
      authToken,
      email: 'ignore not important',
      scanResultBody: {
        audit_trail_image: 'fake',
        face_scan: 'fake',
        low_quality_audit_trail_image: 'fake',
        session_id: 'fake',
      },
      mockResponse: true,
    })

    console.log('Success mocking face scan response for scan type:', scanType)

    return {
      authTokenInfo: data.auth_token_info,
      userAccountInfo: data.user_account_info,
      enrollmentCompleted: data.enrollment_complete,
      idScanCompleted: false,
    }
  }

  console.warn(
    'Did not provide valid scan type, allowed scan types are: ENROLLMENT'
  )
  return undefined
}

/**
 * Wraps a formerly known as AuthMachine "service" functions from xstate4 to
 * newly actor pattern using `fromPromise` in xstate5
 */
const promiseFuncToPromiseActor = <T>(
  promiseFunction: (
    context: AuthMachineContext,
    event: AuthMachineEvent,
    self: AuthMachineSelf
  ) => Promise<T>
) =>
  fromPromise(
    ({
      input,
    }: {
      input: {
        context: AuthMachineContext
        event: AuthMachineEvent
        self: AuthMachineSelf
      }
    }) => promiseFunction(input?.context, input?.event, input?.self)
  )

/**
 * Wraps a formerly known as LegalMachine "service" functions from xstate4 to
 * newly actor pattern using `fromPromise` in xstate5
 */
const legalPromiseToPromiseActor = <T>(
  promiseFunction: (
    context: LegalMachineContext,
    event: LegalMachineEvent
  ) => Promise<T>
) =>
  fromPromise(
    ({
      input,
    }: {
      input: { context: LegalMachineContext; event: LegalMachineEvent }
    }) => promiseFunction(input?.context, input?.event)
  )

/**
 * Util function used for providing inputs to promise actors. Generally typed,
 * will be broken down to actual statically typed arguments
 */
const promiseActorInput = <T>(params: T) => ({
  ...params,
})

export {
  generateStatesObject,
  writeToConsoleAndIssueAlert,
  mockFaceScan,
  promiseFuncToPromiseActor,
  promiseActorInput,
  legalPromiseToPromiseActor,
}
