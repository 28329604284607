import { useAccountService } from '../../state-management/authentication/useAccountService'
import PropTypes from 'prop-types'
import style from '../../scss/layout/AccountSummary.module.scss'

/**
 * Displays user's personal details, can be used without the
 * `<AccountSummary />` wrapper, to build similar components
 */
const AccountSummaryBody = ({ className }: { className?: string }) => {
  const {
    context: { user_details },
  } = useAccountService()

  const userInitials = () => {
    if (user_details) {
      const { first_name, last_name } = user_details
      if (first_name && last_name) {
        return first_name[0] + last_name[0]
      }
    }
    return ''
  }

  return (
    <article className={className}>
      <div className={style['accountSummary__info']}>
        <div className={style['accountSummary__icon']}>
          <div
            className={`${style['accountSummary__user-initials']} bold-text`}
          >
            {userInitials()}
          </div>
        </div>

        <div className={style['accountSummary__mail-name']}>
          <p className={`${style[`accountSummary__fullname`]} bold-text`}>{`${
            user_details?.first_name ?? ' '
          } ${user_details?.last_name ?? ''}`}</p>
          <p className={style['accountSummary__email']}>
            {user_details?.email}
          </p>
        </div>
      </div>
    </article>
  )
}

AccountSummaryBody.propTypes = {
  className: PropTypes.string,
}

export default AccountSummaryBody
