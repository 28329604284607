import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { daysUntilNextPayout } from '../../utils/UtilFunctions'
import NextPayout from './NextPayout'
import { useBankingService } from '../../state-management/banking/useBankingService'
import { useLocalization } from '../../hooks/useLocalization'
import { CONSTANTS } from '../../constants/ConstantValues'
import { ASSET } from '../../constants/Assets'
import ExtendedContentCard from '../cards/ExtendedContentCard'

/**
 *  Renders a next payout card with a title and a progress bar until
 * the next payout has completed, a spinner is rendered while the component is
 * fetching data
 */
const NextPayoutCard = ({ className }) => {
  const { error, nextPayoutInfo, loading } = usePayoutData()
  const { formatAmount } = useLocalization()

  //Render an empty div for now if there is an error,
  //there needs to be further discussion about this behavior
  if (error) {
    return <></>
  }

  if (!loading && nextPayoutInfo?.next) {
    return (
      <div className={className}>
        <ExtendedContentCard
          headerImage={ASSET.iconnextpayoutcard}
          title={
            <Trans
              i18nKey={
                daysUntilNextPayout().remaining === 0
                  ? 'NEXT.PAYOUT.TODAY'
                  : 'NEXT_PAYOUT_COUNTDOWN'
              }
              values={{
                day: daysUntilNextPayout().remaining,
              }}
            />
          }
          hoverDisabled
          autoExpand
          variant={'gray-dirty'}
          headerImageSize="large"
          extendedCardVariant="payout"
          expandClickDisabled
        >
          <NextPayout
            maxProgress={daysUntilNextPayout()?.currentDaysInMonth}
            currentProgress={daysUntilNextPayout()?.todayDate}
            previousAmount={
              formatAmount({
                amount: nextPayoutInfo?.previous,
                currency: nextPayoutInfo?.currency,
                style: 'currency',
                digits: CONSTANTS.CURRENCY_DIGITS_FORMATTING,
                notation: 'compact',
              }).formattedAmountWithSymbol
            }
            nextAmount={
              formatAmount({
                amount: nextPayoutInfo?.next,
                currency: nextPayoutInfo?.currency,
                style: 'currency',
                notation: 'compact',
                digits: CONSTANTS.CURRENCY_DIGITS_FORMATTING,
              }).formattedAmountWithSymbol
            }
            variant={'gray-dirty'}
          />
        </ExtendedContentCard>
      </div>
    )
  }

  return <></>
}

NextPayoutCard.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
}

/*
 * Uses the bankContext data to render next payout card
 */
const usePayoutData = () => {
  const { bankContext, currentBankState, states } = useBankingService()

  return {
    loading: currentBankState === states.FETCHING_BANK_INFO,
    nextPayoutInfo: {
      previous: Math.abs(
        bankContext?.bankingInfo?.nextPayout?.[0]?.gross?.amount ?? 0
      ),
      next: Math.abs(
        bankContext?.bankingInfo?.nextPayout?.[1]?.gross?.amount ?? 0
      ),
      currency: bankContext?.bankingInfo?.nextPayout?.[1]?.gross?.currency,
    },
    error: bankContext?.bankingInfoError,
  }
}

export default NextPayoutCard
