import PropTypes from 'prop-types'
import Icon from './Icon'

/**
 * @param {string} webpName  Image filename
 * @param {string} alternativeImageName  Alternative image in another format
 * like `png`
 * @param {string} alt  Alt image attribute
 * @param {string} className  General class name
 *
 * @description Used for displaying images in `webp` format, with a fallback
 * image in case the browser does not support `webp` format
 */

const WebpImage = ({ webpName, alternativeImageName, alt, className }) => {
  return (
    <Icon
      fileName={webpName ?? alternativeImageName}
      fromWeb={webpName ?? alternativeImageName}
      className={className}
      alt={alt}
    />
  )
}

WebpImage.propTypes = {
  webpName: PropTypes.string.isRequired,
  alternativeImageName: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
}

export default WebpImage
