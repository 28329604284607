import { uas, banking, content } from 'MyTontineConfig'
import { endpoint } from './Endpoints'

/**
 * API's with `baseUrl` and endpoints. The `baseUrl` is sourced from the
 * `config` directory depending which build `env` has been passed in to the
 * build scripts that are located in `my-tontine`
 */
export const API = {
  healthCheck: `${uas.baseUrl}${endpoint.healthCheck}`,
  logout: `${uas.baseUrl}${endpoint.logout}`,
  logoutAllDevices: `${uas.baseUrl}${endpoint.logoutAllDevices}`,
  checkSession: `${uas.baseUrl}${endpoint.checkSession}`,
  refreshSession: `${uas.baseUrl}${endpoint.refreshSession}`,
  savePin: `${uas.baseUrl}${endpoint.savePin}`,
  extendLoginSessionWithPin: `${uas.baseUrl}${endpoint.extendLoginSessionWithPin}`,
  checkSessionWSS: `${uas.baseUrlWSS}${endpoint.checkSessionWSS}`,
  sendEmailMagicLinkNewTab: `${uas.baseUrl}${endpoint.sendEmailMagicLinkNewTab}`,
  loginMagicLinkNewTab: `${uas.baseUrl}${endpoint.loginMagicLinkNewTab}`,
  getScanSessionToken: `${uas.baseUrl}${endpoint.getScanSessionToken}`,
  enrollFace: `${uas.baseUrl}${endpoint.enrollFace}`,
  authenticateFace: `${uas.baseUrl}${endpoint.authenticateFace}`,
  scanId: `${uas.baseUrl}${endpoint.scanId}`,
  register: `${uas.baseUrl}${endpoint.register}`,
  userDetails: `${uas.baseUrl}${endpoint.userDetails}`,
  editUserDetails: `${uas.baseUrl}${endpoint.editUserDetails}`,
  saveFeedback: `${uas.baseUrl}${endpoint.saveFeedback}`,
  closeAccount: `${uas.baseUrl}${endpoint.closeAccount}`,
  cancelClosingAccount: `${uas.baseUrl}${endpoint.cancelClosingAccount}`,
  addPhoneNumber: `${uas.baseUrl}${endpoint.addPhoneNumber}`,
  verifyPhoneNumber: `${uas.baseUrl}${endpoint.verifyPhoneNumber}`,
  changePin: `${uas.baseUrl}${endpoint.changePin}`,
  forgotPin: `${uas.baseUrl}${endpoint.forgotPin}`,
  resetPin: `${uas.baseUrl}${endpoint.resetPin}`,
  getAgreement: `${uas.baseUrl}${endpoint.agreement}`,
  signAgreement: `${uas.baseUrl}${endpoint.signAgreement}`,
  createReferralCode: `${uas.baseUrl}${endpoint.createReferralCode}`,
  redeemReferralCode: `${uas.baseUrl}${endpoint.redeemReferralCode}`,
  getReferralStats: `${uas.baseUrl}${endpoint.referralStats}`,
  saveRating: `${uas.baseUrl}${endpoint.saveRating}`,
  saveTextFeedback: `${uas.baseUrl}${endpoint.saveTextFeedback}`,
  getAllUserFeedback: `${uas.baseUrl}${endpoint.getAllUserFeedback}`,
  readNextPayout: `${uas.baseUrl}${endpoint.readNextPayout}`,
  ageThresholds: `${uas.baseUrl}${endpoint.thresholds}`,
  updatePayoutAccount: `${uas.baseUrl}${endpoint.updatePayoutAccount}`,
  deletePayoutAccount: `${uas.baseUrl}${endpoint.deletePayoutAccount}`,
  readUserBankingInfo: `${uas.baseUrl}${endpoint.readOwnBank}`,
  readUserNominalBalance: `${uas.baseUrl}${endpoint.nominalBalance}`,
  tontinatorForecast: `${banking.baseUrl}${endpoint.tontinatorForecast}`,
  readDraftPlan: `${uas.baseUrl}${endpoint.readDraftPlan}`,
  readInvestmentFormFields: `${uas.baseUrl}${endpoint.readFormFields}`,
  saveFormProgress: `${uas.baseUrl}${endpoint.saveFormProgress}`,
  submitForm: `${uas.baseUrl}${endpoint.submitForm}`,
  resendVerificationEmail: `${uas.baseUrl}${endpoint.resendVerificationEmail}`,
  liteAuth: `${uas.baseUrl}${endpoint.liteAuth}`,
  ipGeoLocation: `${uas.baseUrl}${endpoint.ipGeoLocation}`,
  track: `${uas.baseUrl}${endpoint.tracking}`,
  getReturns: `${uas.baseUrl}${endpoint.returns}`,
  updateLitePlan: `${uas.baseUrl}${endpoint.updateLitePlan}`,
  // Sanity studio
  getAllSanityArticles: `${content.baseUrl}?query=*%5B_type%20%3D%3D%20%22article%22%5D%20%7C%20order(featuredArticle%20desc%2C%20articlePublishDate%20desc)%20%7B%0A%20%20%20%20_id%2C%0A%20%20%20%20_createdAt%2C%0A%20%20%20%20articleAuthor-%3E%7B%0A%20%20%20%20%20%20%20%20authorName%0A%20%20%20%20%7D%2C%0A%20%20%20%20articleBody%2C%0A%20%20%20%20articleEiisVisibility%2C%0A%20%20%20%20articleImage%7B%0A%20%20%20%20%20%20%20%20imageField%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20asset-%3E%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20url%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20originalFilename%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%7D%2C%0A%20%20%20%20articleImageCaption%2C%0A%20%20%20%20articlePublishDate%2C%0A%20%20%20%20articlePusblishedDateIcon%7B%0A%20%20%20%20%20%20%20%20altImageText%2C%0A%20%20%20%20%20%20%20%20imageField%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20asset-%3E%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20url%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20originalFilename%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%7D%2C%0A%20%20%20%20articleReadingTime%2C%0A%20%20%20%20articleReadingTimeIcon%7B%0A%20%20%20%20%20%20%20%20altImageText%2C%0A%20%20%20%20%20%20%20%20imageField%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20asset-%3E%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20url%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20originalFilename%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%7D%2C%0A%20%20%20%20articleSlug%7B%0A%20%20%20%20%20%20%20%20current%2C%0A%20%20%20%20%7D%2C%0A%20%20%20%20articleSummary%2C%0A%20%20%20%20articleTitle%2C%0A%20%20%20%20featuredArticle%2C%0A%7D%0A`,
  fetchArticleBySlug: (slug: string) =>
    `${content?.baseUrl}?query=*%5B_type%20%3D%3D%20%22article%22%20%26%26%20articleSlug.current%20%3D%3D%20%22${slug}%22%5D%20%7B%0A%20%20%20%20%20%20articleAuthor-%3E%7B%0A%20%20%20%20%20%20%20%20authorName%0A%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20articleBody%2C%0A%20%20%20%20%20%20articleEiisVisibility%2C%0A%20%20%20%20%20%20articleImage%7B%0A%20%20%20%20%20%20%20%20imageField%7B%0A%20%20%20%20%20%20%20%20%20%20asset-%3E%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20url%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20originalFilename%2C%0A%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20articleImageCaption%2C%0A%20%20%20%20%20%20articlePublishDate%2C%0A%20%20%20%20%20%20articlePusblishedDateIcon%7B%0A%20%20%20%20%20%20%20%20altImageText%2C%0A%20%20%20%20%20%20%20%20imageField%7B%0A%20%20%20%20%20%20%20%20%20%20asset-%3E%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20url%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20originalFilename%0A%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20articleReadingTime%2C%0A%20%20%20%20%20%20articleReadingTimeIcon%7B%0A%20%20%20%20%20%20%20%20altImageText%2C%0A%20%20%20%20%20%20%20%20imageField%7B%0A%20%20%20%20%20%20%20%20%20%20asset-%3E%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20url%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20originalFilename%2C%0A%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20articleSlug%7B%0A%20%20%20%20%20%20%20%20current%0A%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20articleSummary%2C%0A%20%20%20%20%20%20articleTitle%0A%20%20%20%20%7D`,
} as const
