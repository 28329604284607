import { authenticateWithFace, enrollFaceToFaceAuth, scanId } from './API'
import { getFaceTecStyle } from './FaceTecStyleConfig'

/**
 * @private
 */
const FaceTecScanType = {
  ENROLLMENT: 'ENROLLMENT',
  AUTHENTICATION: 'AUTHENTICATION',
  PHOTO_ID_SCAN: 'PHOTO_ID_SCAN',
}

/**
 * Provides the following scan lifecycle functions:
 *
 * `processSessionResultWhileFaceTecSDKWaits`
 * `onFaceTecSDKCompletelyDone`
 *
 * Handles the processing of facescan image, with
 * `processSessionResultWhileFaceTecSDKWaits`, this is where you would make API
 * requests to your API
 *
 * Takes in
 * @param sessionResult  scan image generated by the SDK
 * @param faceScanResultCallback  that uses `faceScanResultCallback.succeed()`
 * to progress the scan to the next step and
   `faceScanResultCallback.cancel()`
 * to exit the session and close the scanning modal
 *
 * `onFaceTecSDKCompletelyDone`  is executed after the
 * `faceScanResultCallback`, this is where you would issue callbacks to the
 * controller
 *
 * @param sessionToken  scan session token from FaceTec servers that UAS
   provides
 * @param controllerReference  reference for the scan controller where you
 * would handle callbacks
 * @param scanType  type of scan that the processor will perform example
   `ENROLLMENT`
 * @param email  some scan types need an email so this is where you can provide
   it
 */
export class FaceScanProcessor {
  /**
   * @typedef {{faceAuth:string, faceEnroll: string, idScan: string}} Endpoint
   *
   * @param {string} sessionToken Scan session token from FaceTec servers
   * @param {'ENROLLMENT' | 'AUTHENTICATION' | 'PHOTO_ID_SCAN'} scanType
   * @param {string} email
   * @param {string} baseUrl
   * @param {Endpoint} endpoint
   * @param {string=} authToken
   * @param {(data:object) => void} onComplete
   * @param {(data:object) => void} onEnrollmentOnlyDone
   */
  constructor(
    sessionToken,
    scanType,
    email,
    baseUrl,
    endpoint,
    authToken,
    onComplete,
    onEnrollmentOnlyDone
  ) {
    this.sessionToken = sessionToken
    this.scanType = scanType
    this.email = email
    this.error = undefined
    this.authTokenInfo = {
      // In cases where we do an enroll scan or photo id match
      // the auth token will be passed in from the current session
      authToken: authToken,
      remainingTime: 0,
      refreshToken: undefined,
      permissions: undefined,
    }
    this.enrollmentCompleted = false
    this.userAccountInfo = {}
    this.face_scan_reference_id = ''
    this.idScanCompleted = false
    this.endpoint = endpoint
    this.baseUrl = baseUrl

    // Callbacks to communicate with whoever has instantiated this class
    this.onComplete = onComplete
    this.onEnrollmentOnlyDone = onEnrollmentOnlyDone

    //Set the FaceTec oval theme TODO: This will be sorted out by the SDK setup
    //flow
    FaceTecSDK.setCustomization(getFaceTecStyle(FaceTecSDK))

    //start a facescan session
    new FaceTecSDK.FaceTecSession(this, sessionToken)
  }

  //This is where you process the scan and make API requests
  processSessionResultWhileFaceTecSDKWaits(
    sessionResult,
    faceScanResultCallback
  ) {
    if (
      sessionResult.status !==
      FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
    ) {
      //Scanning errors will be handled here Session result status is a number
      //that returns a text message from the array of FaceTecScan errors
      this.error = {
        ...this.error,
        sessionStatus: FaceTecSDK.FaceTecSessionStatus[sessionResult.status],
        sessionResultStatusCode: sessionResult.status,
      }

      console.warn(
        `Session was not completed successfully, cancelling. Session Status: 
         ${
           FaceTecSDK.FaceTecSessionStatus[sessionResult.status]
         } status code: ${sessionResult.status} `
      )
      //Always cancel the scanning if there is a scanning error
      faceScanResultCallback.cancel()
    } else {
      //Creates a request body with the scan results
      const scanResultBody = {
        audit_trail_image: sessionResult?.auditTrail[0],
        face_scan: sessionResult.faceScan,
        low_quality_audit_trail_image: sessionResult?.lowQualityAuditTrail[0],
        session_id: this.sessionToken,
      }

      //Sending the facescan object to APIs starts here
      switch (this.scanType) {
        case FaceTecScanType.AUTHENTICATION:
          authenticateWithFace({
            api: `${this.baseUrl}${this.endpoint.faceAuth}`,
            scanResultBody,
            email: this.email,
          })
            .then(
              ({ scan_result_blob, auth_token_info, user_account_info }) => {
                // Class data is only set if there is auth token returned from
                // the API
                if (auth_token_info?.authToken) {
                  this.authTokenInfo = {
                    ...auth_token_info,
                  }
                  this.userAccountInfo = user_account_info
                  this.error = undefined
                }

                // The client SDK decides the next step of the scan by using the
                // scan_result_blob returned from the server
                faceScanResultCallback.proceedToNextStep(
                  scan_result_blob,
                  //This is an auth scan, always skip PHOTO ID match
                  1
                )
              }
            )
            .catch((error) => {
              this.error = {
                ...this.error,
                uas: error,
              }
              faceScanResultCallback.cancel()
            })

          break

        default:
          //Currently enrollment and id scan is merged in our flow, but can
          //easily be separated, by using FaceTecIDScanNextStep.SKIP
          enrollFaceToFaceAuth({
            api: `${this.baseUrl}${this.endpoint.faceEnroll}`,
            scanResultBody,
            email: this.email,
            authToken: this.authTokenInfo?.authToken,
          })
            .then(
              ({
                scan_result_blob,
                enrollment_complete,
                auth_token_info,
                user_account_info,
                face_scan_reference_id,
              }) => {
                // Only set the data if there is a successful scan
                if (enrollment_complete && auth_token_info?.authToken) {
                  //Returned flag from FaceTec that tells us if the enrollment
                  //has been successfully completed
                  this.enrollmentCompleted = enrollment_complete
                  this.authTokenInfo = {
                    ...auth_token_info,
                  }
                  this.userAccountInfo = user_account_info
                  this.face_scan_reference_id = face_scan_reference_id
                  this.error = undefined

                  // No need to call onEnrollmentOnlyDone if ID scan is going
                  // to be skipped, because onComplete event will be issued
                  if (this.scanType !== 'ENROLLMENT') {
                    this.onEnrollmentOnlyDone?.({
                      authTokenInfo: {
                        ...auth_token_info,
                      },
                      error: undefined,
                      enrollmentCompleted: enrollment_complete,
                      userAccountInfo: user_account_info,
                      // Only enrollment has been completed, there is no id scan
                      // so the value will always be false
                      idScanCompleted: false,
                    })
                  }
                }

                // 1 means skip 0 means don't skip
                const skipIDScan = this.scanType === 'PHOTO_ID_SCAN' ? 0 : 1

                faceScanResultCallback.proceedToNextStep(
                  scan_result_blob,
                  skipIDScan
                )
              }
            )
            .catch((error) => {
              this.error = {
                ...this.error,
                uas: error,
              }
              //Stops the face scan and exits the FaceScan SDK
              faceScanResultCallback.cancel()
            })

          break
      }
    }
  }

  // After `processSessionResultWhileFaceTecSDKWaits` has been executed and the
  // face scan has been successful
  processIDScanResultWhileFaceTecSDKWaits(idScanResult, idScanResultCallback) {
    if (
      idScanResult.status !==
      FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
    ) {
      //Scanning errors will be handled here Session result status is a number
      //that returns a text message from the array of FaceTecScan errors
      this.error = {
        ...this.error,
        sessionStatus: FaceTecSDK.FaceTecSessionStatus[idScanResult.status],
        idScanResultStatusCode: idScanResult.status,
      }

      console.warn(
        `Photo ID Session was not completed successfully, cancelling. Session Status: 
         ${
           FaceTecSDK.FaceTecSessionStatus[idScanResult.status]
         } with status code: ${idScanResult.status} `
      )
      //Always cancel the scanning if there is a scanning error
      idScanResultCallback.cancel()
    } else {
      //Creates a request body with the  idScanResults
      const idScanResultBody = {
        id_scan: idScanResult?.idScan,
        session_id: idScanResult?.sessionId,
        id_front_image: idScanResult?.frontImages
          ? idScanResult?.frontImages[0]
          : null,
        id_back_image: idScanResult?.backImages
          ? idScanResult?.backImages[0]
          : null,
      }
      // Custom in-house API starts from here
      scanId({
        api: `${this.baseUrl}${this.endpoint.idScan}`,
        idScanResultBody,
        faceScanRefId: this.face_scan_reference_id,
        authToken: this.authTokenInfo?.authToken,
      })
        .then(({ scan_result_blob, completed, user_account_info }) => {
          //The returned blob from the FaceTec server will decide how the
          //FaceTec SDK will proceed with the scan
          if (completed) {
            this.idScanCompleted = completed
            this.error = undefined
            this.userAccountInfo = user_account_info
          }
          idScanResultCallback.proceedToNextStep(scan_result_blob)
        })
        .catch((error) => {
          this.error = {
            ...this.error,
            uas: error,
          }

          idScanResultCallback.cancel()
        })
    }
  }

  // SDK lifecycle function, DO NOT delete must be present here otherwise
  // FaceTecSDK will crash
  onFaceTecSDKCompletelyDone() {
    // Callback sent when the SDK has completed a scan, does not mean successfully
    this.onComplete?.({
      authTokenInfo: {
        ...this.authTokenInfo,
      },
      error: this.error,
      enrollmentCompleted: this.enrollmentCompleted,
      userAccountInfo: this.userAccountInfo,
      idScanCompleted: this.idScanCompleted,
    })
  }
}
