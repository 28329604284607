import Button from '../buttons/Button'
import { showReportDialog } from '@sentry/react'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import Icon from '../media-and-icons/Icon'
import { ASSET } from '../../constants/Assets'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useTranslate } from '../../hooks/useTranslate'
import { PUBLIC } from '../../routes/Route'
import style from '../../scss/pages/GeneralError.module.scss'
import { To } from 'react-router-dom'

type GeneralErrorProps = {
  hideErrorTitle?: boolean
  errorMessage?: string
  hideNavButton?: boolean
  navTo?: To
}

/**
 * @note Do **not** include sensitive data as an `errorMessage`, the error
 * message should be vague as possible when shown to the user!
 *
 * General error component, displays an error with default arguments
 */
const GeneralError = ({
  hideErrorTitle,
  errorMessage,
  hideNavButton,
  navTo,
}: GeneralErrorProps) => {
  const { isAuthenticated, context } = useAccountService()
  const t = useTranslate()
  const navigate = useCustomNavigation()

  const reportCrash = () => {
    if (isAuthenticated) {
      showReportDialog({
        user: {
          name: `${context?.user_details?.first_name} ${context?.user_details?.last_name}`,
          email: context?.user_details?.email,
        },
      })
    } else {
      showReportDialog()
    }
  }

  return (
    <main className={style['general-error']}>
      <section className={style[`general-error__container`]}>
        <Icon
          fileName={ASSET.infoCircle}
          className={style[`general-error__icon`]}
        />
        {!hideErrorTitle && (
          <p className={style[`general-error__user-text`]}>
            {t('ERROR_GENERIC')}
          </p>
        )}
        {errorMessage && (
          <p className={style[`general-error__error-msg`]}>{errorMessage}</p>
        )}
        <article className={style[`general-error__btn-container`]}>
          <Button onClick={reportCrash}>{t('CONTACT_US_BTN_LABEL')}</Button>
          {!hideNavButton && (
            <Button onClick={() => navigate(navTo ?? PUBLIC.GO_TO_PREV_PAGE)}>
              {t('TAKE_ME_BACK_BTN_LABEL')}
            </Button>
          )}
        </article>
      </section>
    </main>
  )
}

export default GeneralError
