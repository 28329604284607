import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../components/buttons/Button'
import Divider from '../../components/data-display/Divider'
import TextInput from '../../components/inputs/TextInput'
import Layout from '../../components/layout/Layout'
import TextError from '../../components/typography/TextError'
import { ANIMATION } from '../../constants/Animations'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/pages/LoginForm.module.scss'
import { inputRestrictionRegex } from '../../constants/Regex'
import { PUBLIC } from '../../routes/Route'
import { useTranslate } from '../../hooks/useTranslate'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { millisecondsToSeconds } from '../../utils/UtilFunctions'
import FaceScan from './FaceScan'
import { INPUT_LIMIT } from '../../constants/InputLimits'
import { ASSET } from '../../constants/Assets'
import useEmailHandling from '../../hooks/useEmailHandling'
import { useState } from 'react'
import { ErrorStorage } from '../../state-management/CommonState.type'
import ConfirmationModal from '../../components/overlay/ConfirmationModal'
import { AuthMachineContext } from '../../state-management/authentication/AuthMachineTypes.type'
import { faceTecCompatibleBrowser } from '../../utils/TSUtilFunctions'
import { CONSTANTS } from '../../constants/ConstantValues'
import SupportedBrowsers from '../../components/data-display/SupportedBrowsers'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

const sessionTerminationMessage = {
  normalExpiredNotification: 'ACCOUNT.MODAL_LOGIN_EXPIRED_MESSAGE',
  sessionErrorNotification: 'LOGIN.SESSION_ERROR',
}

/**
 * This component renders the login form. It includes email
 * input, FaceTec biometric login, and magic link login options.
 * The component handles various states such as email validation,
 * session expiration, and FaceTec compatibility checks.
 */
const LoginForm = () => {
  const {
    t,
    userEmail,
    emailSent,
    emailValidated,
    validateEmail,
    handleTyping,
    currentState,
    error,
    faceTecLogin,
    setFaceTecLogin,
    isLoginButtonDisabled,
    showFaceTecAuthentication,
    handleMagicLogin,
    sessionExpired,
    dismissExpiredSessionModal,
    resetAuthMachineToNoAuthState,
  } = useAuth()
  const { isMobileOrTablet } = useDeviceScreen()
  const loadingButtonState = currentState === 'SENDING_NEW_TAB_EMAIL'

  const isFaceTecCompatibleBrowser = faceTecCompatibleBrowser(isMobileOrTablet)
  const [renderunsupBrowserModal, setRenderunsupBrowserModal] = useState(false)

  return (
    <main className={style['login-form']}>
      {renderunsupBrowserModal ? (
        <SupportedBrowsers
          setRenderunsupBrowserModal={setRenderunsupBrowserModal}
        />
      ) : (
        <>
          {emailSent && (
            <SuccessModal
              userEmail={userEmail}
              handleMagicLogin={handleMagicLogin}
              resetAuthMachineToNoAuthState={resetAuthMachineToNoAuthState}
            />
          )}

          {sessionExpired?.renderNotification && (
            <ExpiredSessionModal
              sessionExpired={sessionExpired}
              dismissExpiredSessionModal={dismissExpiredSessionModal}
            />
          )}

          {faceTecLogin && (
            <FaceScan
              email={userEmail}
              asModal
              scanType={'auth-scan'}
              onClickExitScan={() => setFaceTecLogin(false)}
            />
          )}

          <Layout
            headerTitle={t('LOGIN_FORM.HEADER_TITLE')}
            navigateTo={PUBLIC.HOME}
            headerVariant="spaced"
            headerTextColor="blue"
            containerWidth="small"
            containerHeight="auto"
            containerMt="nomt"
            hideDividerHeader
            hideMobileSecondaryIcon
          >
            <section className={style[`login-form__main-content`]}>
              <Divider
                number={CONSTANTS.STEP_ONE}
                className={style[`login-form__divider-top`]}
              />

              <TextInput
                onChange={handleTyping}
                value={userEmail}
                inputMode="email"
                placeholder={t('EMAIL_PLACEHOLDER_TEXT')}
                label={t('INPUT_LABEL.SIGN_UP_EMAIL')}
                validatorFunction={validateEmail}
                errorMessage={emailValidated}
                maxLength={INPUT_LIMIT.GENERIC_MAX}
                autoComplete="on"
                dataTestID={UI_TEST_ID.emailInput}
                restrictionRegex={inputRestrictionRegex.noWhiteSpace}
                optional
              />

              <Divider
                number={CONSTANTS.STEP_TWO}
                className={style[`login-form__divider`]}
              />

              <section className={style['login-form__buttons']}>
                {error && (
                  <TextError
                    errorText={error?.translatedError}
                    className={style['login-form__error-message']}
                  />
                )}
                <Button
                  disabled={
                    !isFaceTecCompatibleBrowser
                      ? !isFaceTecCompatibleBrowser
                      : isLoginButtonDisabled
                  }
                  variant="primary"
                  onClick={showFaceTecAuthentication}
                  dataTestID={UI_TEST_ID.faceScanLoginButton}
                  loading={loadingButtonState}
                >
                  {t('LOGIN_FORM.FACETEC_LOGIN_BUTTON')}
                </Button>
                {!isFaceTecCompatibleBrowser && (
                  <FaceTecUnsupportedBrowserMessage
                    setRenderunsupBrowserModal={setRenderunsupBrowserModal}
                  />
                )}
                <p className={style['login-form__btn-separator']}>
                  {t('BUTTON_SEPARATOR')}
                </p>
                <Button
                  onClick={handleMagicLogin}
                  variant="alternative"
                  disabled={isLoginButtonDisabled}
                  loading={loadingButtonState}
                >
                  {t('LOGIN_FORM.LOGIN_BUTTON_LEGACY')}
                </Button>
                <p className={style['login-form__explainer-text']}>
                  <Trans i18nKey={'LOGIN_FORM.ACCOUNT_ACCESS_EXPLAINER'} />
                </p>
              </section>
            </section>
          </Layout>
          <SignUpButton />
        </>
      )}
    </main>
  )
}

export default LoginForm

const FaceTecUnsupportedBrowserMessage = ({
  setRenderunsupBrowserModal,
}: {
  setRenderunsupBrowserModal: (value: boolean) => void
}) => {
  const t = useTranslate()
  return (
    <p
      className={style['login-form__facetec-browser-error-text']}
      data-testid={UI_TEST_ID.faceScanBrowserErrorText}
    >
      {t('LOGIN_FORM.FACETEC_UNSUPPORTED_BROWSER')}
      <a
        className={style['login-form__facetec-browser-error-link']}
        onClick={() => {
          setRenderunsupBrowserModal(true)
        }}
        data-testid={UI_TEST_ID.faceScanBrowserErrorLink}
      >
        {t('LOGIN_FORM.FACETEC_UNSUPPORTED_BROWSER_LINK')}
      </a>
    </p>
  )
}

/**
 * Renders a sign-up button with a link to the sign-up page.
 */
const SignUpButton = () => {
  const t = useTranslate()
  return (
    <section className={style['login-form__sign-up-section']}>
      <span>{t('LOGIN_FORM.SIGN_UP_FOR_ACCOUNT')}</span>
      <Link to={PUBLIC.SIGN_UP}>
        <span className={style['login-form__sign-up-btn']}>
          {t('BUTTON_LABEL.SIGN_UP')}
        </span>
      </Link>
    </section>
  )
}

/**
 * Custom hook for handling authentication logic.
 */
const useAuth = () => {
  // Hooks
  const {
    send,
    context: { sessionExpired },
    currentState,
  } = useAccountService()
  const t = useTranslate()

  const { userEmail, emailValidated, validateEmail, handleTyping } =
    useEmailHandling()

  // Render states
  const [error, setError] = useState<ErrorStorage | null | undefined>(null)
  const [emailSent, setEmailSent] = useState(false)
  const [faceTecLogin, setFaceTecLogin] = useState(false)

  /**
   * Issues a state transition from `READ_RESEND_EMAIL` to `NO_AUTH_TOKEN` state
   * which dismisses the email sent modal
   */
  const resetAuthMachineToNoAuthState = () => {
    // Necessary to close the success modal
    send({
      type: 'CLOSE_SUCCESS_MODAL',
    })
    setEmailSent(false)
  }
  /**
   * Sends an event to the auth machine, to start the login flow
   * depending if the user is from a phone or from a desktop pc
   */
  const handleMagicLogin = () => {
    send({
      type: 'SEND_NEW_TAB_EMAIL',
      payload: {
        email: userEmail,
        successCallback: () => setEmailSent(true),
        failureCallback: (error) => setError(error),
      },
    })
    // Wipes previous error if there is one
    setError(null)
  }

  /**
   * Wrapper function that sets the facetec login state to `true`
   */
  const showFaceTecAuthentication = () => setFaceTecLogin(true)

  /**
   * Dismisses the session expired modal and clears all the error sessions,
   * assuming the user has gotten the message
   */
  const dismissExpiredSessionModal = () => {
    // Modify session data so the notification is not shown
    send({
      type: 'MODIFY_EXPIRED_SESSION_DATA',
      payload: {
        renderNotification: false,
      },
    })
  }

  return {
    context: {
      sessionExpired,
    },
    currentState,
    t,
    userEmail,
    emailValidated,
    validateEmail,
    handleTyping,
    error,
    setError,
    faceTecLogin,
    setFaceTecLogin,
    isLoginButtonDisabled: !emailValidated?.valid,
    showFaceTecAuthentication,
    handleMagicLogin,
    emailSent,
    sessionExpired,
    dismissExpiredSessionModal,
    resetAuthMachineToNoAuthState,
  }
}

/**
 * Renders a success modal if the email was successfully sent,
 * `emailSuccessfullySent` function does the check if the app is in the
 * successfully sent email state
 */
const SuccessModal = ({
  userEmail,
  handleMagicLogin,
  resetAuthMachineToNoAuthState,
}: {
  userEmail: string | undefined
  handleMagicLogin: () => void
  resetAuthMachineToNoAuthState: () => void
}) => {
  const t = useTranslate()
  const { currentState } = useAccountService()

  return (
    <ConfirmationModal
      isOpen
      title={'SUCCESS.HEADERTEXT.MODAL'}
      content={'MODAL.BODY.TEXT.VERIFICATION.SENT'}
      contentValues={{
        email: userEmail,
      }}
      animatedIcon={ANIMATION.checkmark}
      timerButtonAction={handleMagicLogin}
      timerButtonLabel={t('ACCOUNT.EMAIL_SEND_AGAIN_BUTTON')}
      timerButtonSeconds={millisecondsToSeconds(
        CONSTANTS.RESEND_EMAIL_TIMER_MILLISECONDS
      )}
      timerButtonDisabled={currentState === 'SENT_NEW_TAB_EMAIL'}
      firstButtonAction={resetAuthMachineToNoAuthState}
      firstButtonLabel={t('SUCCESS.MODAL.DISMISS.BUTTON')}
    />
  )
}

/**
 * Renders a success modal if the session is expired, a session
 * is expired if `context.session_expire_message` is not `null`
 */
const ExpiredSessionModal = ({
  sessionExpired,
  dismissExpiredSessionModal,
}: {
  sessionExpired: AuthMachineContext['sessionExpired']
  dismissExpiredSessionModal: () => void
}) => {
  const t = useTranslate()

  return (
    <ConfirmationModal
      isOpen
      content={t(
        sessionTerminationMessage[
          sessionExpired?.notifyUiWith ?? 'normalExpiredNotification'
        ]
      )}
      icon={ASSET.infoamber}
      firstButtonLabel={t('COMMON.CONTINUE_BUTTON')}
      firstButtonAction={dismissExpiredSessionModal}
    />
  )
}
