import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import Layout from '../../components/layout/Layout'
import TextError from '../../components/typography/TextError'
import { CONSTANTS } from '../../constants/ConstantValues'
import { ACCOUNT_MENU } from '../../routes/Route'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useTranslate } from '../../hooks/useTranslate'
import NavigationButtons from '../../components/navigation/NavigationButtons'
import FilteringOptions from './FilteringOptions'
import { formatContributions, formatPayouts } from './HistoryUtils'
import UserTransactions from './UserTransactions'
import { useBankingService } from '../../state-management/banking/useBankingService'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import ErrorBoundaryAndSuspense from '../../components/error-handling/ErrorBoundaryAndSuspense'
import style from '../../scss/components/ContributionHistory.module.scss'

/**
 * @param {string=} payoutHistory Renders payout transactions
 * Renders contribution history by default, if `payoutHistory` is
 * specified then payout history will be rendered.
 */
const PayoutAndPayinHistory = ({ payoutHistory }) => {
  const { context } = useAccountService()
  const { bankContext, currentBankState, states } = useBankingService()
  //Hooks
  const t = useTranslate()
  const navigate = useCustomNavigation()
  //Render states
  const [unfilteredTransactions, setUnfilteredTransactions] = useState(false)
  //API states
  const [transactions, setTransactions] = useState(
    payoutHistory
      ? bankContext?.bankingInfo?.payoutHistory
      : bankContext?.bankingInfo?.payinHistory
  )
  const [formattedTransactions] = useState(
    payoutHistory
      ? formatPayouts(transactions)
      : formatContributions(transactions)
  )

  /**
   * @param {array} filteredArray Empty or array with elements
   * Callback issued by `<FilteringOptions />` containing a
   * filtered array
   */
  const filteredTransactions = useCallback(({ filteredArray }) => {
    //Filter did not return any data
    if (filteredArray?.length === 0) {
      setUnfilteredTransactions(true)
    } else {
      setUnfilteredTransactions(false)
    }

    setTransactions(filteredArray)
  }, [])

  const changePageTitle = () =>
    payoutHistory
      ? 'BANKING.PAYOUT_HISTORY_PAGE_TITLE'
      : 'BANKING.PAGE_TITLE_CONTRIBUTION_HISTORY'

  const userHasTransactions = () =>
    formattedTransactions && formattedTransactions?.length !== 0

  return (
    <Layout
      containerHeight="sh"
      containerWidth="small"
      navigateTo={
        payoutHistory ? ACCOUNT_MENU.PAYOUTS_MENU : ACCOUNT_MENU.FUND_PLAN_MENU
      }
      pageTitle={t(changePageTitle())}
      card={
        userHasTransactions() && (
          <FilteringOptions
            onFiltered={filteredTransactions}
            array={formattedTransactions}
            filterKey={CONSTANTS.TRANSACTION_SORT_KEY}
          />
        )
      }
      bottomSection={
        context?.user_details ? (
          <NavigationButtons
            onClickSecond={() => navigate(ACCOUNT_MENU.FUND_PLAN_MENU)}
            secondButtonLabel={t('CONTRIBUTION_HISTORY.TO_FUND_PENSION')}
            hideActionButton={payoutHistory || transactions?.length > 0}
            className={style['contributionHistory__nav-btns']}
            onClickFirst={() =>
              navigate(
                payoutHistory
                  ? ACCOUNT_MENU.PAYOUTS_MENU
                  : ACCOUNT_MENU.FUND_PLAN_MENU
              )
            }
          />
        ) : null
      }
    >
      {bankContext?.bankingInfoError?.translatedError ? (
        <TextError errorText={bankContext?.bankingInfoError?.translatedError} />
      ) : (
        <ErrorBoundaryAndSuspense>
          <UserTransactions
            loading={currentBankState === states.FETCHING_BANK_INFO}
            transactions={transactions}
            unfilteredTransactions={unfilteredTransactions}
            formattedTransactions={formattedTransactions}
            payoutHistory={payoutHistory}
          />
        </ErrorBoundaryAndSuspense>
      )}
    </Layout>
  )
}

PayoutAndPayinHistory.propTypes = {
  payoutHistory: PropTypes.bool,
}

export default PayoutAndPayinHistory
