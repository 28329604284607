import PropTypes from 'prop-types'
import { ReactNode } from 'react'
import style from '../../scss/components/SecondaryMenuContainer.module.scss'

/**
 *  Wraps menu items and modifies their font properties
 */
const SecondaryMenuContainer = ({ children }: { children: ReactNode }) => (
  <div className={style['secondary-menu-container']}>{children}</div>
)

SecondaryMenuContainer.propTypes = {
  children: PropTypes.node,
}

export default SecondaryMenuContainer
