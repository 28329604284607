/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { CONSTANTS } from '../../constants/ConstantValues'
import { regex } from '../../constants/Regex'
import TextError from '../typography/TextError'
import { focusDomElement } from '../../utils/UtilFunctions'
import { useConfirmModal } from '../../hooks/useConfirmModal'
import { ANIMATION } from '../../constants/Animations'
import InputLabel from './InputLabel'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import style from '../../scss/components/PinInput.module.scss'

const firstFieldToFocus = `#pi${0}`

/**
 * @param {number=} numberOfFields Number of pin fields
 * @param {string=} type  To hide or show pin input numbers
 * @param {string=} label  Label for the input
 * @param {string=} autoCompleteMode  If enabled the input will be autocompleted
 * on mobile devices when the sms is received
 * @param {object=} authData  Passing `authData` will send `authData` as the
 * request body and the pin as a header
 * @param {callback=} onFailedPinSubmit  Callback when a pin submit fails
 * @param {function=} tontineAuthEndpoint  Reference to a function from
 * `tontineAuth` that needs to be called in this component
 * @param {callback=} onSuccessfulPinSubmit  Callback when a pin has been
 * submitted successfully
 * @param {string=} errorMessage  Error message to display when pin submit fails
 * @param {callback=} onTyping  Indicates when the user is typing
 * @param {callback=} onChange  Returns the pin value
 * @param {boolean=} confirmPin Generates unique IDs for pin input fields, so the
 * component can act as a confirm pin component
 * @param {boolean=} focusOnMount Focus first pin on mount, `true` by default
 * @param {boolean=} focusPinFieldOnError Focus first pin field on error, `true`
 * by default
 * @param {boolean=} loadingState Renders a loading modal when the pin has been
 * submitted, a pin is submitted when all of the pin fields have values
 * @param {boolean=} openLoadingModal Allows the loading modal to be controlled
 * by the parent component
 *
 * Numeric pin input with dynamically generated fields the default. The default
 * number of fields is 1. The component takes in different API endpoints as
 * props from `tontineAuth` library, also `onSuccessfulPinSubmit` and
 * `onFailedPinSubmit` callbacks are supported to handle promises. `onTyping`
 * callback is issued when the user is typing and is not debounced. Pin
 * submitting is debounced to prevent multiple calls to the API.
 */
const PinInput = ({
  numberOfFields = 1,
  type = 'text',
  label,
  autoCompleteMode = 'off',
  onSuccessfulPinSubmit,
  onFailedPinSubmit,
  errorMessage,
  onTyping,
  onChange,
  clearFields,
  authData,
  confirmPin,
  focusOnMount = true,
  focusPinFieldOnError = true,
  loadingState,
  openLoadingModal,
  loadingMessage,
  authMachineEvent,
}) => {
  const { send } = useAccountService()

  //Displays a loading modal while the pin authorized request is being fulfilled
  const { setOpen, confirmationModal } = useConfirmModal({
    isOpen: openLoadingModal,
    title: loadingMessage,
    animatedIcon: ANIMATION.loadingLightBlueDots,
  })

  const focusFirstPinField = useCallback(
    () =>
      focusDomElement(`${firstFieldToFocus}${confirmPin ? confirmPin : ''}`),
    [confirmPin]
  )

  const { field, setField, handleChange } = usePinInput({
    numberOfFields,
    focusPinFieldOnError,
    focusFirstPinField,
    setOpen,
    onFailedPinSubmit,
    onSuccessfulPinSubmit,
    loadingState,
    authData,
    authMachineEvent,
    onTyping,
    onChange,
    clearFields,
    send,
    openLoadingModal,
  })

  const { handleKeyDown, selectTextFieldAlways } = usePinFocus({
    setField,
    field,
    confirmPin,
    focusOnMount,
    focusFirstPinField,
  })

  const confirmPinID = (index) => `pi${index}${confirmPin ? confirmPin : ''}`

  /**
   * Generates pin input fields
   */
  const generateFields = () =>
    field.map((data, index) => {
      return (
        <input
          className={style[`pinInput__field${errorMessage ? '--error' : ''}`]}
          type={type}
          name="field"
          maxLength={1}
          key={confirmPinID(index)}
          id={confirmPinID(index)}
          value={data}
          onKeyDown={(e) => handleKeyDown(e, e.target, index)}
          onChange={(event) => handleChange(event, index)}
          onFocus={selectTextFieldAlways}
          onMouseUp={selectTextFieldAlways}
          onMouseDown={selectTextFieldAlways}
          onKeyUp={selectTextFieldAlways}
          autoComplete={autoCompleteMode}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          pattern="[0-9]"
          inputMode="numeric"
        />
      )
    })

  return (
    <article className={style['pinInput']}>
      {confirmationModal}
      {label && <InputLabel label={label} />}
      <div className={style[`pinInput__container`]}>{generateFields()}</div>
      {errorMessage && (
        <TextError
          errorText={errorMessage}
          dataTestID={UI_TEST_ID.pinInputError}
        />
      )}
    </article>
  )
}

PinInput.propTypes = {
  numberOfFields: PropTypes.number.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  authData: PropTypes.any,
  autoCompleteMode: PropTypes.string,
  onSuccessfulPinSubmit: PropTypes.func,
  onFailedPinSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  onTyping: PropTypes.func,
  onChange: PropTypes.func,
  clearFields: PropTypes.bool,
  confirmPin: PropTypes.string,
  focusOnMount: PropTypes.bool,
  focusPinFieldOnError: PropTypes.bool,
  loadingState: PropTypes.bool,
  openLoadingModal: PropTypes.bool,
  loadingMessage: PropTypes.string,
  authMachineEvent: PropTypes.string,
}

/**
 * Handles pin typing and event sending when user has finished typing
 */
const usePinInput = ({
  numberOfFields,
  focusPinFieldOnError,
  focusFirstPinField,
  setOpen,
  onFailedPinSubmit,
  onSuccessfulPinSubmit,
  loadingState,
  authData,
  authMachineEvent,
  onTyping,
  onChange,
  clearFields,
  send,
  openLoadingModal,
}) => {
  const emptyStringsArray = new Array(numberOfFields).fill('')
  //State
  const [field, setField] = useState(emptyStringsArray)

  /**
   * @param {HTMLElement} inputElement
   * @param {number} inputElementIndex
   * @param {number} numberOfFields
   *
   * Check if the input elements is in bounds, so it does not focus an field that
   * does not exist
   */
  const inFieldBounds = (inputElement, inputElementIndex, numberOfFields) =>
    inputElement.nextSibling && inputElementIndex + 1 < numberOfFields

  /**
   * Executed when the user is typing their pin
   */
  const handleChange = (event, inputElementIndex) => {
    let inputElement = event?.target

    //Looks weird but it prevents input if anything other than 0-9 has been
    //entered
    if (!regex.pinInputDigits.test(inputElement.value)) {
      return false
    }

    setField([
      ...field.map((data, dataIndex) =>
        dataIndex === inputElementIndex ? inputElement.value : data
      ),
    ])

    //Focus next input
    if (inFieldBounds(inputElement, inputElementIndex, numberOfFields)) {
      inputElement.nextSibling.focus()
    }
  }

  /**
   * Validates if the pin string is only contains digits from 0-9 and if it is
   * equal to number of fields
   * @param {string} pin
   */
  const validatedPin = (pin) =>
    pin?.length === numberOfFields && regex.pinInputDigits.test(pin)

  const handleError = (error) => {
    if (error) {
      setField(emptyStringsArray)
      focusPinFieldOnError ? focusFirstPinField() : null

      //Close the loading modal
      setOpen(false)

      ///Lifts the error message to the parent component
      //in case the parents needs it, if a callback is provided otherwise this
      //line is not executed
      onFailedPinSubmit ? onFailedPinSubmit(error) : null
    }
  }

  // Call the API when the conditions are met Conditions, valid pin and all
  //fields are filled
  useEffect(() => {
    //Merges the array into one string value example 1,3,4,5 would become 1345
    let pinValue = field.join('')

    //Calls the passed in tontine auth endpoint from props if the has been
    //validated
    const submitPin = () => {
      if (validatedPin(pinValue)) {
        //If there is data provided that requires authorization, the the
        //endpoint is called with the data, otherwise the endpoint is only
        //called with the pin The reason is to cover the cases for the endpoints
        //that need data to be passed as a body and the pin in the headers
        const pinSubmittedSuccessfully = (callback) => {
          //Stop the loading modal the request has already been completed
          setOpen(false)

          onSuccessfulPinSubmit?.(callback)
        }

        const callbacks = {
          onSuccess: pinSubmittedSuccessfully,
          onFailure: handleError,
        }

        if (authMachineEvent) {
          // Looks weird to send the closureFeedback and reset_token here but
          // it's the only way it works at the moment in order not to cause code
          // repetition
          send({
            type: authMachineEvent,
            payload: {
              pin: pinValue,
              // For close account
              closureFeedback: authData,
              // For pin reset
              reset_token: authData,
              // For phone number verification
              verification_code: pinValue,
              phone_number: authData,
              // Need to have this for now
              successCallback: callbacks?.onSuccess,
              failureCallback: callbacks?.onFailure,
            },
          })
        }

        //Opens a loading modal when the user has entered their pin
        loadingState ? setOpen(true) : null
      }
    }

    const timeoutId = setTimeout(() => {
      submitPin()
    }, CONSTANTS.DEBOUNCE_TIME)

    //Calls the passed in callback on typing HACK: Checking for empty first
    //field is a react18 workaround
    onTyping && field[0] ? onTyping() : null

    onChange ? onChange(pinValue) : null

    return () => clearTimeout(timeoutId)
  }, [field])

  useEffect(() => {
    //If there is an error then all the fields are cleared this is only added
    //here because of the confirm pin component the function handle error is
    //called here to clear the inputs in case the pins don't match, where the
    //checking is done on the frontend side
    if (clearFields) {
      handleError(clearFields)
    }
  }, [clearFields])

  //Controls the `openLoadingModal` this is a specific case and it is only used
  //by the `<PinSetupPage /> for now it will do, but in the future this might
  //not be the real solution This is needed because of awful derived state... in
  //ConfirmModal component
  useEffect(() => {
    if (openLoadingModal) {
      setOpen(openLoadingModal)
    }
  }, [openLoadingModal, setOpen])

  return {
    field,
    setField,
    handleChange,
  }
}
/**
 * Handles the pin focus when typing and deleting a pin from a field on a
 * keyboard or a virtual mobile keyboard keydown
 *
 * @param {Object} options - The options object.
 * @param {Function} options.setField - The function to set the field.
 * @param {Array} options.field - The array of field values.
 * @param {boolean} options.confirmPin - Whether to confirm the pin.
 * @param {boolean} options.focusOnMount - Whether to focus on mount.
 * @return {Object} The object containing the handleKeyDown, focusFirstPinField,
 * and selectTextFieldAlways functions.
 */
const usePinFocus = ({
  setField,
  field,
  confirmPin,
  focusOnMount,
  focusFirstPinField,
}) => {
  /**
   * Allows the user to use "Backspace" or "<-" on an virtual mobile keyboard.
   */
  const handleKeyDown = (event, inputElement, inputElementIndex) => {
    //We use keycode in order to also have virtual keyboard support
    if (event?.keyCode === CONSTANTS?.BACKSPACE && inputElementIndex >= 0) {
      // Clear the current input field
      setField([
        ...field.map((data, dataIndex) =>
          dataIndex === inputElementIndex ? '' : data
        ),
      ])

      // Focus the previous input field Supports all combination of fields,
      // confirm and setup pin
      focusDomElement(
        `${'#pi' + (inputElementIndex - 1) + (confirmPin ? confirmPin : '')}`
      )
    }
  }

  /**
   * Makes sure the current pin input field is always selected, in order for
   * focusing previous pin input field and next pin input field works properly.
   * Also reduces user error margin by a lot
   */
  const selectTextFieldAlways = (event) => event?.target?.select()

  useEffect(() => {
    //On first render focus the first pin field
    if (focusOnMount) {
      focusFirstPinField()
    }
  }, [])

  return {
    handleKeyDown,
    selectTextFieldAlways,
  }
}

export default PinInput
