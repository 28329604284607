import DashboardNavigation from '../navigation/DashboardNavigation'
import NewsFeed from '../../pages/mytt-dashboard/NewsFeed'
import ErrorBoundaryAndSuspense from '../error-handling/ErrorBoundaryAndSuspense'
import { ReactNode } from 'react'
import style from '../../scss/layout/TontineDashboardLayout.module.scss'
import { isLite } from '../../config/lite'

/**
 * Child component to be rendered between the
 * `<DashboardNavigation />` and `<NewsFeed />` component
 *
 * Layout used for the Tontine Dashboard, the child component is
 * rendered in the middle middle, sandwiched by the DashboardNavigation and the
 * NewsFeed.
 */
const TontineDashboardLayout = ({ children }: { children: ReactNode }) => {
  return (
    <main className={style['tontine-dashboard-layout']}>
      <section className={style[`tontine-dashboard-layout__container`]}>
        {!isLite && (
          <DashboardNavigation
            className={style[`tontine-dashboard-layout__nav`]}
          />
        )}

        <section className={style[`tontine-dashboard-layout__main-content`]}>
          <ErrorBoundaryAndSuspense hideNavButton>
            {children}
          </ErrorBoundaryAndSuspense>
        </section>

        {!isLite && (
          <section className={style[`tontine-dashboard-layout__news-feed`]}>
            <NewsFeed />
          </section>
        )}
      </section>
    </main>
  )
}

export default TontineDashboardLayout
