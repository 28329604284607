import { useEffect, useState } from 'react'

/**
 * @param {any} onIntersect Callback issued when the observed target is
 * intersecting
 * @param {string=} root Root
 * @param {string=} rootMargin Margin of the root element
 * @param {number=} threshold Threshold
 *
 *
 * Uses `IntersectionObserver` to observe a node element if it
 * shows on the screen. Use `setElement` to attach an observer to an element
 * reference
 */
export const useIntersectObserver = ({
  onIntersect,
  root = null,
  rootMargin = '20px',
  threshold = 0,
  elementToAttachObserverTo,
}) => {
  const [element, setElement] = useState(elementToAttachObserverTo)

  useEffect(() => {
    /**
     * @param {array} entries Entries from the observer
     * @description Handler for the observer, where we check if the target is
     * into view
     */
    const handleObserver = (entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        onIntersect(target.isIntersecting)
      }
    }

    const observer = new IntersectionObserver(handleObserver, {
      root,
      rootMargin,
      threshold,
    })

    //There is a reference to the element we want to observe, pass the reference
    //to the observer
    if (element) {
      observer.observe(element)
    }

    //Disconnect the observer when the component unmounts
    return () => observer.disconnect()
    //onIntersect removed, because it needs to be wrapped in a
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, root, rootMargin, threshold])

  return { setElement }
}
