import PropTypes from 'prop-types'
import { CONSTANTS } from '../../constants/ConstantValues'
import { useTranslate } from '../../hooks/useTranslate'
import { generateRange } from '../../utils/UtilFunctions'
import { getDetectedIpCountry } from '../../utils/TSUtilFunctions'
import IncomeScheduler from '../inputs/IncomeScheduler'
import AgeSliders from '../inputs/slider/AgeSliders'
import { ContributionSliders } from '../inputs/slider/ContributionSliders'
import { useSupportedCountries } from '../../hooks/useSupportedCountries'
import { useLocalization } from '../../hooks/useLocalization'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/**
 * @param {Object} formsParams Object containing data for the input forms.
 * @param {Function} setNewFormData  Function for setting new data for the input
 * forms.
 * @param {boolean} retirementSliders  Renders only retirement sliders
 * @param {boolean} contributionSliders  Renders only contribution sliders
 * @param {string} contributionSlidersClassName Class name for the contribution
 * sliders.
 *
 * Renders IncomeScheduler (age-month accuracy) slider if specified and if
 * `ageThreshold` is present. Otherwise renders AgeSliders and
 * ContributionSliders (age accuracy)
 */
const TontinatorInputs = ({
  formData,
  setFormData,
  retirementSliders,
  contributionSliders,
  contributionSlidersClassName,
  disabledSliderTooltipText,
  ageMonthRetirementSlider,
  sliderSteps,
  setSliderSteps,
  ageThreshold,
  overrideDisable,
  hideCurrentAgeSlider,
  sliderVariant,
  forceDisableRetAgeDecrement,
  p2RetAgeSliderBoxTestId,
  p2RetAgeSliderIncrementTestId,
  p2RetAgeSliderDecrementTestId,
  p2OneTimeSliderBoxTestId,
  p2OneTimeIncrementButtonTestId,
  p2OneTimeDecrementButtonTestId,
  trackingActivityCurrentAgeSlider,
  trackingActivityRetirementSlider,
  trackActivityOneTimeContribution,
  trackCurrenAgeRangeActivity,
  trackRetAgeRangeActivity,
  trackRangeOneTime,
  skipComparePlanRangeAdjustment,
}) => {
  const t = useTranslate()
  const { formatAmount } = useLocalization()

  /**
   * Initializes the slider values based on supported params
   */
  const {
    supportedCountry: { tontinatorParams: supportedTontinatorParams },
  } = useSupportedCountries(formData?.countryOfResidence)

  const {
    handleMonthly,
    handleOneTime,
    handleRetirementAge,
    handleCurrentAge,
  } = useSlidersAdjustment({
    formData,
    setFormData,
    minRetirementAge: supportedTontinatorParams?.minRetirementAge,
    maxRetirementAge: supportedTontinatorParams?.maxRetirementAge,
    params: supportedTontinatorParams,
  })

  const shouldDisabledUSA = disableRetirementSliderUSA({
    currentAge: formData?.contributionAge,
    maxRetirementAgeForCountry: supportedTontinatorParams?.maxRetirementAge,
  })

  /**
   * Controls the range of the sliders based on enabled and disabled ranges. If
   * only sliders would have `min` and `max` :(
   */
  const enabledSliderSteps = () => {
    if (shouldDisabledUSA) {
      return generateRange(0, 0)
    }

    if (
      formData?.contributionAge > supportedTontinatorParams?.minRetirementAge &&
      !skipComparePlanRangeAdjustment &&
      formData?.contributionAge < supportedTontinatorParams?.maxRetirementAge
    ) {
      return generateRange(
        formData?.contributionAge,
        supportedTontinatorParams?.maxRetirementAge
      )
    }

    return generateRange(
      supportedTontinatorParams?.minRetirementAge,
      supportedTontinatorParams?.maxRetirementAge
    )
  }

  return (
    <>
      <section className={`pension-plan__lower-section`}>
        {ageMonthRetirementSlider && ageThreshold && (
          <IncomeScheduler
            setRetirementData={setFormData}
            retirementData={formData}
            setSliderSteps={setSliderSteps}
            sliderSteps={sliderSteps}
            ageThresholds={ageThreshold}
          />
        )}
      </section>

      {retirementSliders && (
        <AgeSliders
          trackCurrenAgeRangeActivity={trackCurrenAgeRangeActivity}
          trackRetAgeRangeActivity={trackRetAgeRangeActivity}
          trackingActivityCurrentAgeSlider={trackingActivityCurrentAgeSlider}
          trackingActivityRetirementSlider={trackingActivityRetirementSlider}
          currentAgeBoxDataTestID={UI_TEST_ID.currentAgeSliderBox}
          retirementAgeBoxDataTestID={
            p2RetAgeSliderBoxTestId ?? UI_TEST_ID.retirementAgeSliderBox
          }
          retAgeIncrementDataTestID={
            p2RetAgeSliderIncrementTestId ??
            UI_TEST_ID.retirementAgeIncrementButton
          }
          retAgeDecrementDataTestID={
            p2RetAgeSliderDecrementTestId ??
            UI_TEST_ID.retirementAgeDecrementButton
          }
          currentAgeIncrementDataTestID={UI_TEST_ID.currentAgeIncrementButton}
          currentAgeDecrementDataTestID={UI_TEST_ID.currentAgeDecrementButton}
          variant={sliderVariant}
          hideCurrentAge={hideCurrentAgeSlider}
          className={contributionSlidersClassName}
          retirementAge={formData?.retirementAge}
          setRetirementAge={handleRetirementAge}
          //The reason retirement age and current age start the same range is
          //because we want the slider thumbs to aligned. The limits for
          //retirement age slider are handled with checkRetirementAgeIsBelowMin
          //function
          currentAgeSteps={generateRange(
            supportedTontinatorParams?.minCurrentAge,
            supportedTontinatorParams?.maxCurrentAge
          )}
          retirementAgeSteps={generateRange(
            supportedTontinatorParams?.minCurrentAge,
            supportedTontinatorParams?.maxCurrentAge
          )}
          retirementSliderLabel={t('DESIRED_GOAL.RETIREMENT_AGE')}
          currentAge={formData?.contributionAge}
          setCurrentAge={handleCurrentAge}
          currentAgeSliderLabel={t('INPUT_LABEL.CURRENT_AGE')}
          monthlyContribution={parseInt(formData?.monthlyContribution)}
          setMonthlyContribution={handleMonthly}
          // Onetime contribution and setter function.
          oneTimeContribution={parseInt(formData?.oneTimeContribution)}
          setOneTimeContribution={handleOneTime}
          disabledRetirementSlider={shouldDisabledUSA}
          disabledIncrementRetirementAge={
            formData?.retirementAge ===
              supportedTontinatorParams?.maxRetirementAge &&
            formData?.contributionAge <
              supportedTontinatorParams?.maxRetirementAge
          }
          disabledDecrementRetirementAge={
            // If force is passed in it will override the default behavior
            forceDisableRetAgeDecrement
              ? forceDisableRetAgeDecrement
              : formData?.retirementAge ===
                supportedTontinatorParams?.minRetirementAge
          }
          enabledRetirementSteps={enabledSliderSteps()}
        />
      )}

      {contributionSliders && (
        <ContributionSliders
          enabledStepsMonthly={
            formData?.contributionAge === formData?.retirementAge ||
            disableSliderIfUSA(formData?.countryOfResidence)
              ? [0, 0]
              : supportedTontinatorParams?.monthlyContribution
          }
          trackRangeOneTime={trackRangeOneTime}
          trackActivityOneTime={trackActivityOneTimeContribution}
          oneTimeBoxDataTestID={
            p2OneTimeSliderBoxTestId ?? UI_TEST_ID.oneTimeSliderBox
          }
          monthlyBoxDataTestID={UI_TEST_ID.monthlySliderBox}
          incOneTimeDataTestID={
            p2OneTimeIncrementButtonTestId ?? UI_TEST_ID.oneTimeIncrementButton
          }
          incMonthlyDataTestID={UI_TEST_ID.monthlyIncrementButton}
          decOneTimeDataTestID={
            p2OneTimeDecrementButtonTestId ?? UI_TEST_ID.oneTimeDecrementButton
          }
          decMonthlyDataTestID={UI_TEST_ID.monthlyDecrementButton}
          variant={sliderVariant}
          monthlySteps={supportedTontinatorParams?.monthlyContribution}
          oneTimeSteps={supportedTontinatorParams?.oneTimeContribution}
          oneTimeStepsIfRetired={
            supportedTontinatorParams?.oneTimeContributionIfRetired
          }
          className={contributionSlidersClassName}
          labelOneTime={t('CONTRIBUTION_SLIDER.ONE_TIME')}
          labelMonthly={`${t('CONTRIBUTION_SLIDER.MONTHLY')} ${
            disableSliderIfUSA(formData?.countryOfResidence)
              ? CONSTANTS.MONTHLY_COMING_SOON
              : ''
          }`}
          // Pass in ages so the sliders can determine to be disabled.
          currentAge={parseInt(formData?.contributionAge)}
          retirementAge={parseInt(formData?.retirementAge)}
          formatter={(number) =>
            formatAmount({
              amount: number,
              residency: formData?.countryOfResidence,
              style: 'currency',
              digits: CONSTANTS.CURRENCY_DIGITS_FORMATTING,
            })?.formattedAmountWithSymbol
          }
          // Monthly contribution and setter function.
          monthlyContribution={(() => {
            if (overrideDisable) {
              return parseInt(formData?.monthlyContribution)
            }

            return formData?.contributionAge === formData?.retirementAge ||
              disableSliderIfUSA(formData?.countryOfResidence)
              ? 0
              : parseInt(formData?.monthlyContribution)
          })()}
          setMonthlyContribution={handleMonthly}
          // Onetime contribution and setter function.
          oneTimeContribution={parseInt(formData?.oneTimeContribution)}
          setOneTimeContribution={handleOneTime}
          disabledSliderTooltipText={disabledSliderTooltipText}
          //This disables the monthly slider if user is from USA, if they are
          //not rom USA then the slider is only disabled if current age matches
          //the retired age
          isRetired={
            overrideDisable
              ? false
              : disableSliderIfUSA(formData?.countryOfResidence) ||
                formData?.contributionAge === formData?.retirementAge
          }
        />
      )}
    </>
  )
}

TontinatorInputs.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  retirementSliders: PropTypes.bool,
  contributionSliders: PropTypes.bool,
  contributionSlidersClassName: PropTypes.string,
  disabledSliderTooltipText: PropTypes.string,
  ageMonthRetirementSlider: PropTypes.any,
  sliderSteps: PropTypes.array,
  setSliderSteps: PropTypes.func,
  isLoading: PropTypes.bool,
  ageThreshold: PropTypes.object,
  disabledRetirementAgeSlider: PropTypes.bool,
  inputsPrefix: PropTypes.string,
  overrideDisable: PropTypes.bool,
  hideCurrentAgeSlider: PropTypes.bool,
  sliderVariant: PropTypes.string,
  forceDisableRetAgeDecrement: PropTypes.bool,
  p2RetAgeSliderBoxTestId: PropTypes.string,
  p2RetAgeSliderIncrementTestId: PropTypes.string,
  p2RetAgeSliderDecrementTestId: PropTypes.string,
  p2OneTimeSliderBoxTestId: PropTypes.string,
  p2OneTimeIncrementButtonTestId: PropTypes.string,
  p2OneTimeDecrementButtonTestId: PropTypes.string,
  trackingActivityCurrentAgeSlider: PropTypes.object,
  trackingActivityRetirementSlider: PropTypes.object,
  trackActivityOneTimeContribution: PropTypes.object,
  trackCurrenAgeRangeActivity: PropTypes.object,
  trackRetAgeRangeActivity: PropTypes.object,
  trackRangeOneTime: PropTypes.object,
  skipComparePlanRangeAdjustment: PropTypes.bool,
}

const disableRetirementSliderUSA = ({
  currentAge,
  maxRetirementAgeForCountry,
}) => currentAge >= maxRetirementAgeForCountry

/**
 * Prioritizes residency that is passed in by the user, otherwise uses the ip
 * detected country
 */
const disableSliderIfUSA = (chosenResidency) => {
  if (chosenResidency) {
    return chosenResidency === CONSTANTS.FALLBACK_COUNTRY_CODE
  }

  return getDetectedIpCountry() === CONSTANTS.FALLBACK_COUNTRY_CODE
}

/**
 * Handles slider state and makes necessary adjustments to the state
 */
const useSlidersAdjustment = ({
  setFormData,
  formData,
  minRetirementAge,
  maxRetirementAge,
  params,
}) => {
  //This is needed because the slider steps need go from 18 to 85, so that means
  //the slider can go below 50, this makes sure the slider does not go below 50.
  //The reason it is handled like this is to give both the age slider and the
  //retirement slider thumbs equal steps so the thumbs are always alignment...
  const checkRetirementAgeIsBelowMin = (retirementAge, currentAge) => {
    const maxAge = Math.max(minRetirementAge, currentAge)
    return Math.max(retirementAge, maxAge)
  }

  /**
   * Checks if one time and monthly contributions are 0
   */
  const contributionValuesAreZero = (
    oneTimeContribution,
    monthlyContribution
  ) => oneTimeContribution === 0 && monthlyContribution === 0

  /**
   * Adjusts the retirement age and prevents it from going below minimum
   * retirement age
   */
  const adjustRetirementAgeSlider = (currentAge, retirementAge) => {
    if (retirementAge < minRetirementAge) {
      return minRetirementAge
    }

    //Returns the max age, and does not allow the user to move the slider more
    //than the max
    if (retirementAge > maxRetirementAge && currentAge < maxRetirementAge) {
      return maxRetirementAge
    }

    return retirementAge
  }

  /**
   * Adjusts the current age inside the retirement age slider, and allows the
   * retirement slider to modify the current age slider increment or decrement
   */
  const adjustCurrentAgeSliderInsideRetirementAgeSlider = (
    currentAge,
    retirementAge
  ) => {
    //Moving the retirement slider also moves the contribution slider if the USA
    //MIN/MAX conditions are met
    if (currentAge >= maxRetirementAge && retirementAge > maxRetirementAge) {
      return retirementAge
    }

    //Allows the retirement slider to modify the currentAge slider also known as
    //both slider moving at the same time at the same value
    if (currentAge > retirementAge && currentAge > minRetirementAge) {
      return retirementAge
    }

    return currentAge
  }

  /**
   * Makes sure the retirement age is not less than the current age
   */
  const adjustRetirementAgeViaCurrentAgeCall = (retirementAge, currentAge) => {
    if (retirementAge < minRetirementAge) {
      retirementAge = minRetirementAge
    }

    if (currentAge > retirementAge) {
      return currentAge
    }

    //ONLY FOR USA WE NEED TO MODIFY THE BEHAVIOR FOR THE SLIDERS
    if (currentAge < maxRetirementAge && retirementAge > maxRetirementAge) {
      return maxRetirementAge
    }

    if (currentAge >= maxRetirementAge && retirementAge > maxRetirementAge) {
      return currentAge
    }

    return retirementAge
  }

  /**
   * Makes sure that contribution values are no 0 by adjusting the one time
   * contribution
   */
  const adjustOneTimeContribution = (
    monthlyContribution,
    oneTimeContribution
  ) => {
    if (contributionValuesAreZero(oneTimeContribution, monthlyContribution)) {
      return params.oneTimeContributionMinIfOnly
    }
    return oneTimeContribution
  }

  /**
   * Makes sure that contribution values are no 0 by adjusting the monthly
   * contribution
   */
  const adjustMonthlyContribution = (
    monthlyContribution,
    oneTimeContribution
  ) => {
    if (contributionValuesAreZero(monthlyContribution, oneTimeContribution)) {
      return params.monthlyContributionMinIfOnly
    }
    return monthlyContribution
  }

  /**
   * Adjusts contribution values if the user is in a retired state USA or not
   * does not matter
   */
  const adjustContributions = (
    isRetired,
    formData,
    prevFormDataState,
    params
  ) => {
    const { oneTimeContribution } = formData

    //Adjust monthly contribution if user is retired
    const monthlyContributionAdjusted = isRetired
      ? 0
      : prevFormDataState.monthlyContribution

    const oneTimeContributionAdjusted =
      isRetired &&
      //Check adjusted monthly contribution, and adjust the one time
      //contribution to be none zero
      contributionValuesAreZero(
        oneTimeContribution,
        monthlyContributionAdjusted
      )
        ? params.oneTimeContributionMinIfOnly
        : prevFormDataState.oneTimeContribution

    return { oneTimeContributionAdjusted, monthlyContributionAdjusted }
  }

  /**
   * Handles the monthly contribution slider `onChange` event. Before the value
   * is committed to the state it adjustments are made with
   */
  const handleMonthly = (monthlyContribution) => {
    setFormData((prevFormDataState) => {
      const adjustedOneTimeContribution = adjustOneTimeContribution(
        monthlyContribution,
        prevFormDataState.oneTimeContribution
      )

      return {
        ...prevFormDataState,
        monthlyContribution,
        oneTimeContribution: adjustedOneTimeContribution,
      }
    })
  }

  /**
   * Handles the monthly contribution slider `onChange` event. Before the value
   * is committed to the state it adjustments are made with
   */
  const handleOneTime = (oneTimeContribution) => {
    setFormData((prevFormDataState) => {
      const adjustedMonthlyContribution = adjustMonthlyContribution(
        prevFormDataState.monthlyContribution,
        oneTimeContribution
      )
      return {
        ...prevFormDataState,
        oneTimeContribution,
        monthlyContribution: adjustedMonthlyContribution,
      }
    })
  }

  /**
   * Handles current age slider `onChange` event. Before the values are
   * committed to state adjustments are done. The adjustments that this function
   * does are:
   * - Moves the retirement slider if the retirement age is below the current
   *   age `adjustRetirementAgeViaCurrentAgeCall`
   * - Adjusts contribution values if 0 and the user is retired (`currentAge`
   *   === `retirementAge`)
   */
  const handleCurrentAge = (currentAge) => {
    setFormData((prevFormDataState) => {
      const isRetired =
        adjustRetirementAgeSlider(
          prevFormDataState.retirementAge,
          currentAge
        ) === currentAge

      //Adjusts contributions before committing them to the state
      const { oneTimeContributionAdjusted, monthlyContributionAdjusted } =
        adjustContributions(isRetired, formData, prevFormDataState, params)

      //Makes sure the current age slider follow the retirement age slider
      const adjustedRetirementAge = adjustRetirementAgeViaCurrentAgeCall(
        prevFormDataState.retirementAge,
        currentAge
      )

      return {
        ...prevFormDataState,
        contributionAge: currentAge,
        retirementAge: adjustedRetirementAge,
        monthlyContribution: monthlyContributionAdjusted,
        oneTimeContribution: oneTimeContributionAdjusted,
      }
    })
  }

  /**
   * Handles retirement age slider `onChange` event. Before the values are
   * committed to state adjustments are done. The adjustments that this function
   * does are:
   * - Prevents the retirement age going below the current age
   *   `adjustRetirementAgeSlider`
   * - Prevents the retired age going below the the minimum retirement age
   *   `adjustRetirementAgeSlider`
   * - Adjusts contribution values if 0 and the user is retired (`currentAge`
   *   === `retirementAge`)
   */
  const handleRetirementAge = (retirementAge) => {
    setFormData((prevFormDataState) => {
      //Checks if the retirementAge returned from the retirementAge slider is
      //equal to the current age while prevent the retired age from going below
      //the minimum retired age. This is needed to be checked in order to adjust
      //the contribution values
      const isRetired =
        checkRetirementAgeIsBelowMin(
          retirementAge,
          prevFormDataState.contributionAge
        ) === prevFormDataState.contributionAge

      //Adjusts contributions before committing them to the state
      const { oneTimeContributionAdjusted, monthlyContributionAdjusted } =
        adjustContributions(isRetired, formData, prevFormDataState, params)

      //Makes sure retirement age is not below the minimum retired age and
      //current age
      const adjustedRetirementAge = adjustRetirementAgeSlider(
        prevFormDataState.contributionAge,
        retirementAge
      )

      const adjustedContributionAge =
        adjustCurrentAgeSliderInsideRetirementAgeSlider(
          prevFormDataState.contributionAge,
          adjustedRetirementAge
        )

      return {
        ...prevFormDataState,
        retirementAge: adjustedRetirementAge,
        contributionAge: adjustedContributionAge,
        monthlyContribution: monthlyContributionAdjusted,
        oneTimeContribution: oneTimeContributionAdjusted,
      }
    })
  }

  return {
    handleMonthly,
    handleOneTime,
    handleRetirementAge,
    handleCurrentAge,
  }
}

export default TontinatorInputs
