import PropTypes from 'prop-types'
import Button from '../buttons/Button'
import Modal from './Modal'
import Icon from '../media-and-icons/Icon'
import TextError from '../typography/TextError'
import TimerButton from '../buttons/TimerButton'
import LottieAnimation from '../feedback/LottieAnimation'
import { Trans } from 'react-i18next'
import style from '../../scss/components/ConfirmationModal.module.scss'
import { UI_TEST_ID } from '../../constants/DataTestIDs'

/**
 * @param {boolean=} isOpen if the modal is open or not
 * @param {string=} title Title of the modal
 * @param {string=} content Short description that explains the purpose of the
 * modal
 * @param {function=} firstButtonAction Adds `onClick` event on the first button
 * rendered in the modal
 * @param {function=} firstButtonLabel Label of the first button
 * @param {boolean=} firstButtonLoading Uses the button loading state when
 * clicked
 * @param {function=} secondButtonAction Adds `onClick` event on the second
 * button
 * @param {string=} secondButtonLabel Label of the second button
 * @param {function=} timerButtonAction Adds `onClick` event on the third button
 * @param {string=} timerBUttonLabel Label of the third button
 * @param {boolean=} timerButtonDisabled - Disables the timer button
 * @param {number=} timerButtonSeconds - Seconds to start the countdown from
 * @param {function():boolean=} onCountdownFinishedTimerButton - Callback when
 * the countdown has reached 0
 * @param {function=} dismissButtonAction Adds `onClick` event on the dismiss
 * button
 * @param {string=} dismissButtonLabel Label of the dismiss button
 * @param {string=} icon Icon to be displayed on the modal on top of the title.
 * @param {string=} animatedIcon Animated icon to be displayed on the modal on
 * top of the title
 * @param {object=} contentValues Variables for i18n translations
 * @param {boolean=} backdrop
 *
 * General purpose modal with a backdrop that provides three call
 * to action buttons.
 */
const ConfirmationModal = ({
  isOpen,
  title,
  content,
  icon,
  animatedIcon,
  firstButtonAction,
  firstButtonLabel,
  firstButtonLoading,
  firstButtonTextOnLoading,
  firstButtonType,
  firstButtonDisabled,
  secondButtonAction,
  secondButtonLabel,
  timerButtonAction,
  timerButtonLabel,
  timerButtonDisabled,
  timerButtonSeconds,
  onCountdownFinishedTimerButton,
  errorMessage,
  dismissButtonAction,
  dismissButtonLabel,
  confirmButtonTestID,
  cancelButtonTestID,
  contentValues,
  children,
  showCloseButton,
  onClickCloseButton,
  onOutsideModalContentClick,
  dataTestID = UI_TEST_ID.confirmationModal,
  confirmationButtonTrackId,
  timerButtonTrackId,
  dismissButtonTrackId,
}) => {
  return (
    <div>
      <Modal
        dataTestID={dataTestID}
        backdrop
        isOpen={isOpen}
        showCloseButton={showCloseButton}
        onClickCloseButton={onClickCloseButton}
        onOutsideModalContentClick={onOutsideModalContentClick}
      >
        <section className={style.confirmationModal}>
          {icon && !animatedIcon && (
            <Icon
              fileName={icon}
              className={style['confirmationModal__icon']}
            />
          )}
          {animatedIcon && (
            <LottieAnimation
              animationName={animatedIcon}
              autoplay
              loop
              className={style['confirmationModal__animation']}
            />
          )}
          <div className={style['confirmationModal__title']}>
            <Trans i18nKey={title} values={contentValues} />
          </div>
          <div className={style['confirmationModal__explainer']}>
            <Trans i18nKey={content} values={contentValues} />
          </div>

          {children}

          {errorMessage && <TextError errorText={errorMessage} />}

          {firstButtonLabel && (
            <Button
              onClick={firstButtonAction}
              disabled={firstButtonDisabled ?? errorMessage}
              loading={firstButtonLoading}
              textOnLoading={firstButtonTextOnLoading}
              variant={firstButtonType}
              dataTestID={confirmButtonTestID}
              trackActivity={{
                trackId: confirmationButtonTrackId,
              }}
            >
              {firstButtonLabel}
            </Button>
          )}

          <div className={style['confirmationModal__button-spacing']} />

          {secondButtonLabel && (
            <Button
              onClick={secondButtonAction}
              variant={'alternative'}
              dataTestID={cancelButtonTestID}
              trackActivity={{
                trackId: dismissButtonTrackId,
              }}
            >
              {secondButtonLabel}
            </Button>
          )}

          {timerButtonLabel && (
            <TimerButton
              onClick={timerButtonAction}
              variant={'alternative'}
              disabled={timerButtonDisabled}
              seconds={timerButtonSeconds}
              onCountdownFinished={onCountdownFinishedTimerButton}
              trackActivity={{
                trackId: timerButtonTrackId,
              }}
            >
              {timerButtonLabel}
            </TimerButton>
          )}

          {dismissButtonLabel && (
            <div
              className={style['confirmationModal__dismiss-btn']}
              onClick={dismissButtonAction}
            >
              {dismissButtonLabel}
            </div>
          )}
        </section>
      </Modal>
    </div>
  )
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  animatedIcon: PropTypes.object,
  firstButtonAction: PropTypes.func,
  firstButtonLabel: PropTypes.string,
  firstButtonLoading: PropTypes.bool,
  firstButtonTextOnLoading: PropTypes.string,
  secondButtonAction: PropTypes.func,
  secondButtonLabel: PropTypes.string,
  timerButtonAction: PropTypes.func,
  timerButtonLabel: PropTypes.string,
  timerButtonDisabled: PropTypes.bool,
  timerButtonSeconds: PropTypes.number,
  onCountdownFinishedTimerButton: PropTypes.func,
  errorMessage: PropTypes.string,
  dismissButtonAction: PropTypes.func,
  dismissButtonLabel: PropTypes.string,
  firstButtonType: PropTypes.string,
  confirmButtonTestID: PropTypes.string,
  cancelButtonTestID: PropTypes.string,
  contentValues: PropTypes.object,
  backdrop: PropTypes.bool,
  firstButtonDisabled: PropTypes.bool,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
  onClickCloseButton: PropTypes.func,
  onOutsideModalContentClick: PropTypes.func,
  dataTestID: PropTypes.string,
  confirmationButtonTrackId: PropTypes.string,
  timerButtonTrackId: PropTypes.string,
  dismissButtonTrackId: PropTypes.string,
}

export default ConfirmationModal
