import { ASSET } from '../../constants/Assets'
import { useTranslate } from '../../hooks/useTranslate'
import { TrackActivity } from '../Common.types'
import SelectValue from './SelectValue'

/**
 * Renders buttons with Male and Female sex
 */
const SelectSex = ({
  sex,
  setSex,
  trackActivities,
}: {
  sex: string
  setSex: (sex: string) => void
  /**
   * Index 0 for the array is MALE
   */
  trackActivities?: Array<TrackActivity>
}) => {
  const t = useTranslate()
  return (
    <SelectValue
      value={sex}
      setValue={setSex}
      optionsToSelect={['Male', 'Female']}
      buttonLabels={[t('SEX_BUTTON.MALE_LABEL'), t('SEX_BUTTON.FEMALE_LABEL')]}
      buttonIcons={[ASSET.icononboardinmale, ASSET.icononboardinfemale]}
      activeButtonIcons={[
        ASSET.icononboardinmalewhite,
        ASSET.icononboardinfemalewhite,
      ]}
      trackActivities={trackActivities}
    />
  )
}

export default SelectSex
