import { ReactNode } from 'react'
import style from '../../scss/components/MenuCard.module.scss'

/**
 * Menu card for menu items, serves as a container for the menu card item
 */
const MenuCard = ({
  title,
  children,
  variant,
  comingSoon,
}: {
  title?: string
  children?: ReactNode
  variant?: 'alternative'
  comingSoon?: boolean
}) => {
  return (
    <div className={style[`menu-card${comingSoon ? '--coming-soon' : ''}`]}>
      <div
        className={style[`menu-card__title${variant ? `--${variant}` : ''}`]}
      >
        {title}
      </div>
      {children}
    </div>
  )
}

export default MenuCard
