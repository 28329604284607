import { uas } from 'MyTontineConfig'

import { FaceScanBiometrics, SDKConfig } from '../../../facetec/FaceBiometrics'
import { endpoint } from '../../api/Endpoints'
import { CONSTANTS } from '../../constants/ConstantValues'
import { FaceScanData } from './AuthMachineTypes.type'

// Use only with the AuthMachine!

/**
 * @private
 *
 * Starts a facescan with the passed in params and returns a response with
 * face scan data when scan has been completed successfully.
 *
 * A scan is considered successful only if the API responds with:
 * - Auth token
 * - User account info data
 * - Permissions
 *
 * Everything else is considered an error
 */
const _startFaceScan = async ({
  email,
  authToken,
  scanType,
  onEnrollmentOnlyDone,
}: {
  email: string
  authToken?: string
  scanType: 'AUTHENTICATION' | 'ENROLLMENT' | 'PHOTO_ID_SCAN'
  onEnrollmentOnlyDone: (data: FaceScanData) => void
}): Promise<FaceScanData> => {
  // For enrollment and photo id scan we need an auth token
  if (scanType === 'ENROLLMENT' || scanType === 'PHOTO_ID_SCAN') {
    if (!authToken) {
      throw new TypeError(
        'Auth token not provided, for enrollment. Did you forget to provide the auth token?'
      )
    }
  }

  const { baseUrl } = uas as unknown as {
    baseUrl: URL
  }

  return new Promise((resolve, reject) => {
    const handleCompletedScan = (data: FaceScanData) => {
      // Promise is only resolved if the scan has been completed successfully
      // and the API responds is the necessary data
      if (
        // We must check if there is an auth token, because the API can return a
        // 200 with data but not return an auth token, user_account_info and
        // permissions, because that scan is considered faulty and the API is
        // communicating with the web SDK
        data?.authTokenInfo.authToken &&
        data?.authTokenInfo.permissions &&
        data?.userAccountInfo
      ) {
        resolve(data)
      } else {
        reject({
          error: data?.error,
          errorData:
            'Missing data from FaceScanProcess callback. authToken, permissions or user account info missing',
        })
      }
    }

    FaceScanBiometrics.startScan({
      // Scan config
      baseUrl,
      endpoint: {
        sessionToken: endpoint.getScanSessionToken,
        faceAuth: endpoint.authenticateFace,
        faceEnroll: endpoint.enrollFace,
        idScan: endpoint.scanId,
      },
      // User passed in params
      email,
      authToken,
      scanType,
      onComplete: (data) =>
        handleCompletedScan(data as unknown as FaceScanData),
      onEnrollmentOnlyDone: (data) =>
        onEnrollmentOnlyDone(data as unknown as FaceScanData),
    })
  })
}

/**
 * @private
 *
 * Initializes the face scan SDK and persists the initialized status in `sessionStorage`.
 *
 * If the the SDK has already been initialized, it will return return the
 * `sessionStorage` entry, otherwise it will re-initialize the SDK
 */
const _initSDK = async (sdkConfig: SDKConfig) => {
  const isInitializedAlready = sessionStorage?.getItem(
    CONSTANTS.FACETEC_INIT_KEY
  )

  return new Promise((resolve, reject) => {
    if (isInitializedAlready) {
      resolve(Boolean(isInitializedAlready))
    } else {
      const onSdkInitialized = (isInit: boolean) => {
        if (isInit) {
          sessionStorage.setItem(CONSTANTS.FACETEC_INIT_KEY, isInit.toString())
          resolve(isInit)
        } else {
          reject({
            isInit,
            error:
              'Failed to initialize SDK, might be server issue or browser issue preventing an init',
          })
        }
      }

      FaceScanBiometrics.initializeSDK(sdkConfig, onSdkInitialized)
    }
  })
}

export { _startFaceScan, _initSDK }
