import DateInput from './DateInput'
import PropTypes from 'prop-types'
import style from '../../../scss/components/ContributionHistory.module.scss'

/**
 * @param {string} fromDateTitle Label of the fromDate input
 * @param {string} toDateTitle Label of the toDate input
 * @param {function:void()} onChangeFromDate Callback issued when the fromDate changes
 * @param {function:void()} onChangeToDate Callback issued when the toDate changes
 * @param {string} pickersMode Puts both date pickers in the same picker mode,
 * 'years', 'months', 'days'
 * @param {string | Date} fromDate Valid string date or date object
 * @param {string | Date} toDate Valid string date or date object
 * @param {object} dateFormat Object used to format the date
 *
 * @description Date range picker, renders two date input fields
 */
const DateRange = ({
  fromDateTitle,
  toDateTitle,
  onChangeFromDate,
  onChangeToDate,
  pickersMode,
  fromDate,
  toDate,
  dateFormat,
  toDisabledMonthsFrom,
  toDisabledYearFrom,
  fromDisabledMonthTo,
  fromDisabledYearTo,
}) => {
  return (
    <div className={style['contributionHistory__range-input']}>
      <DateInput
        value={fromDate}
        dateFormat={dateFormat}
        onChange={onChangeFromDate}
        label={fromDateTitle}
        pickerMode={pickersMode}
        onlyMonths
        pickerMonthFormat={'short'}
        optional
        disabledMonthsTo={fromDisabledMonthTo}
        disabledYearTo={fromDisabledYearTo}
      />
      <DateInput
        value={toDate}
        dateFormat={dateFormat}
        onChange={onChangeToDate}
        label={toDateTitle}
        pickerMode={pickersMode}
        onlyMonths
        pickerMonthFormat={'short'}
        optional
        disabledMonthsFrom={toDisabledMonthsFrom}
        disabledYearFrom={toDisabledYearFrom}
      />
    </div>
  )
}

DateRange.propTypes = {
  fromDateTitle: PropTypes.string,
  toDateTitle: PropTypes.string,
  onChangeFromDate: PropTypes.func,
  onChangeToDate: PropTypes.func,
  pickersMode: PropTypes.string,
  fromDate: PropTypes.any,
  toDate: PropTypes.any,
  dateFormat: PropTypes.string,
  toDisabledMonthsFrom: PropTypes.number,
  toDisabledYearFrom: PropTypes.number,
  fromDisabledMonthTo: PropTypes.number,
  fromDisabledYearTo: PropTypes.number,
}

export default DateRange
