import { UI_TEST_ID } from '../../constants/DataTestIDs'
import style from '../../scss/components/Card.module.scss'
import { CardHeaderProps } from '../../types/Card.type'
import Icon from '../media-and-icons/Icon'
import CardAlert from './CardAlert'
import CardArrow from './CardArrow'

/**
 * Renders the header section for the `Card` component.
 */
const CardHeader = ({
  headerImage,
  headerImageSize,
  alertAndArrowPosition,
  alert,
  variant,
  rotateArrow,
  showArrow,
  arrowInvisible,
}: CardHeaderProps) => (
  <div
    data-testid={UI_TEST_ID?.cardHeader}
    className={`${style[`card__header`]} ${style[`card__header${variant ? `--${variant}` : ''}`]}`}
  >
    {headerImage && (
      <Icon
        fileName={headerImage}
        className={`
          ${style[`card__image`]} 
          ${style[`card__image${variant ? `--${variant}` : ''}`]} 
          ${style[`card__image${headerImageSize ? `--${headerImageSize}` : ''}`]}
        `}
      />
    )}
    {alertAndArrowPosition === 'start' && (
      <>
        <div
          className={`${style[`card__info`]} ${style[`card__info${variant ? `--${variant}` : ''}`]}`}
        >
          <CardAlert alert={alert ?? 0} />
          {(showArrow || arrowInvisible) && (
            <CardArrow
              rotateArrow={rotateArrow}
              variant={variant}
              arrowInvisible={arrowInvisible}
            />
          )}
        </div>
      </>
    )}
  </div>
)

export default CardHeader
